import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import InfoRow from './InfoRow';

function AccountSettings(props) {
    return (
        <>
            <div className={css(styles.Title)}>Epic Account Information</div>
            <InfoRow label={'Subscriber name'} data={props.subData.first_name + ' ' + props.subData.last_name} />
            <InfoRow label={'Subscriber email'} data={props.subData.email} />
            <InfoRow label={'Account created on'} data={props.subData.created_at} />
            {props.subData.address && <InfoRow label="Registered address" data={props.subData.address} />}
        </>
    );
}

// {
/* <div className={css(styles.InfoRow)}>Subscriber name</div>
<div className={css(styles.InfoRow)}>Subscriber email</div>
<div className={css(styles.InfoRow)}>Account created on</div> */
// }

// <InfoRow label='Subscriber name' value={subscriberData.first_name + " " + subscriberData.last_name} />
// <InfoRow label='Subscriber email' value={subscriberData.email} />
// <InfoRow label='Account created on' value={subscriberData.created_at} />
// {subscriberData.address && <InfoRow label='Registered address' value={subscriberData.address} />}

export default AccountSettings;

const styles = StyleSheet.create({
    Title: {
        fontSize: '1.5em',
        marginBottom: '1em',
    },
    // InfoRow: {
    //     fontSize: '1.1em'
    // }
});
