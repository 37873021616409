import React, { Component } from 'react';
//import bugsnag from 'lib/bugsnag';
import { StyleSheet, css } from 'aphrodite';
// import ReactLoading from 'react-loading';
// import { createRequireFromPath } from 'module';
// import { relative } from 'path';

// import SystemSettings from './SystemSettings';
import AccountSettings from './AccountSettings';
import NavBar from '../Common/Containers/NavBarContainer';

import { Switch, Route, withRouter, NavLink } from 'react-router-dom';

export default withRouter(
    class MyShows extends Component {
        constructor() {
            super();
            this.state = {
                uriSwitch: false,
                videoAssetURL: '',
                videoPaused: true,
                hasInitiallyUpdated: false,
                buffering: false,
                // navItems: [
                //     'Account',
                //     'System'
                // ],
                menuIndex: 7,
                //offsetX: new Animated.Value(xScale(-1470)),
                //xVal: xScale(-1470),
                yIndex: 0,
                showList: [],
                //offsetY: new Animated.Value(yScale(0)),
                //yVal: yScale(0),
                myShowsSeriesView: false,
                //opacityIn: new Animated.Value(0),
                //opacityOut: new Animated.Value(1),
                backgroundLoadedIn: false,
                backgroundUri: null,
                fadingOut: false,
                singleShowTypes: ['MO'],
            };
            this.is_Mounted = false;
        }
        // _renderItem = ({ item, index }) => (
        //     this.props.titlesIndex === index && this.props.cardsEnabled === false ?
        //     <View style={[styles.selectedTextContainer, {width: this.props.screenProps.width }]}>
        //         <View style={[ styles.textContainer, { marginLeft: xScale(220) }]}>
        //             <Text style={{ fontFamily: 'OpenSans-Regular', color: 'black', fontSize: fontScale(22), marginLeft: xScale(10) }}>
        //                 {item.program_name}
        //             </Text>
        //         </View>
        //     </View>
        //     :
        //     <AnimatedText programName={item.program_name} />

        componentDidUpdate(prevProps, prevState) {
            // console.log('myshows',this.props.assetData);
            if (this.props.isLoadingAssets === false && this.props.assetData.length > 0 && this.state.hasInitiallyUpdated === false) {
                // let programInfo = {};
                // programInfo.image = this.props.assetData[0].backgroundImage;
                // programInfo.name = this.props.assetData[0].program_name;
                // programInfo.description = this.props.assetData[0].description;
                // programInfo.cast = this.props.assetData[0].cast;
                // programInfo.program_id = this.props.assetData[0].program_id;

                // this.props.onUpdateShowInfo(programInfo);

                this.setState({
                    hasInitiallyUpdated: true,
                });
            }

            // if (prevState.yIndex !== this.state.yIndex || this.props.showList !== prevProps.showList) {
            //     // this.setState(() => {
            //     //     return {yVal: yScale(-55 * this.state.yIndex)}
            //     // })
            //     // Animated.timing(this.state.offsetY, {
            //     //     toValue: yScale(-55 * this.state.yIndex),
            //     //     duration: 200,
            //     //     useNativeDriver: true
            //     // }).start();
            //     if (this.state.backgroundLoadedIn) {
            //         if (!this.state.fadingOut) {
            //             this.setState({
            //                 fadingOut: true
            //             }, () => this.onLoadFadeOut())
            //         }
            //     } else {
            //         this.onLoadFadeIn()
            //     }
            // }

            if (!prevProps.isFullScreen && this.props.isFullScreen) {
                this._myShowsEventEmitter && this._myShowsEventEmitter.remove();
                this._myShowsEventEmitter = null;
            }
            if (prevProps.isFullScreen && !this.props.isFullScreen) {
                // if(!this._myShowsEventEmitter) {
                //     this._myShowsEventEmitter = DeviceEventEmitter.addListener('onKeyDown', this._listenerFunction)
                // };
            }
        }

        componentDidMount() {}

        getNavItems() {
            return this.state.navItems.map((cur) => <div className={css(styles.NavColumn)}>{cur}</div>);
        }

        handleNavChange = (selectedNav) => {
            this.setstate({
                selectedNav: selectedNav,
            });
        };

        //     <NavLink className={css(styles.NavItem)} to="/settings">Settings</NavLink>
        //     <NavLink className={css(styles.NavItem)} to="/myshows">Log Out</NavLink>
        // </div>
        // </nav>
        // <Route exact path="/" render={() => (
        // <Redirect to="/myshows"/>
        // )}/>
        // <Route path="/myshows" component={MyShowsContainer} />

        render() {
            //onClick={this.closeDropDowns}

            return (
                <>
                    <NavBar />
                    <Switch>
                        <Route path={'/settings'}>
                            <div className={css(styles.MainContainer)}>
                                <div className={css(styles.NavColumn)}>
                                    {
                                        <>
                                            <div className={css(styles.NavHeader)}>Settings</div>
                                            <NavLink
                                                activeStyle={{ backgroundColor: '#303030' }}
                                                to="/settings/account_settings"
                                                className={css(styles.NavItem)}>
                                                {' '}
                                                Account{' '}
                                            </NavLink>
                                            {/* <NavLink activeStyle={{backgroundColor: '#303030'}} to="/settings/system_settings" className={css(styles.NavItem)} >System</NavLink> */}
                                        </>
                                    }
                                </div>
                                <div className={css(styles.ContentContainer)}>
                                    <div className={css(styles.SubContainer)}>
                                        <Route path="/settings/account_settings" render={() => <AccountSettings subData={this.props.subscriberData} />} />
                                        {/* <Route path="/settings/system_settings" component={SystemSettings} /> */}
                                    </div>
                                </div>
                                {/* <Route path={`/settings/account`}>
                            </Route> */}
                            </div>
                        </Route>
                    </Switch>
                </>
            );
        }
    }
);

const styles = StyleSheet.create({
    List: {
        listStyle: 'none',
        padding: '0',
    },
    CategoryName: {
        fontSize: '2em',
        marginTop: '1em',
        // fontSize: '32px',
        // marginTop: '30px'
    },
    SeriesTitle: {
        fontSize: '1em',
        margin: '.2em',
        padding: '.4em',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#303030',
            borderRadius: '.5em',
        },
        //textAlign: 'left',
        //   backgroundColor: '#282c34',
        //   minHeight: '100vh',
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   fontSize: 'calc(12px + 2vmin)',
        //   color: 'white',
    },
    NavColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '16em',
        // borderColor: 'cyan',
        // borderStyle: 'solid',
        // borderWidth: '2px',
        backgroundColor: '#202020',
    },
    NavItem: {
        whiteSpace: 'nowrap',
        //marginRight: '10%',
        textDecoration: 'none',
        fontSize: '1.3em',
        color: 'white',
        //margin: '.05em',
        padding: '1.5em',
        // borderColor: '#303030',
        // borderStyle: 'solid',
        // borderWidth: '2px',
        ':hover': {
            // borderColor: 'white',
            // borderStyle: 'solid',
            // borderWidth: '2px',
            backgroundColor: 'grey',
        },
        // '@media screen and (max-width: 800px)': { //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
        //     display: 'none',
        // },
    },
    NavHeader: {
        whiteSpace: 'nowrap',
        marginRight: '10%',
        textDecoration: 'none',
        fontSize: '1.5em',
        color: 'white',
        margin: '30px',
        padding: '7px',
        //textAlign: 'center',
        // borderColor: '#303030',
        // borderStyle: 'solid',
        // borderWidth: '2px',
        // ':hover': {
        //     borderColor: 'white',
        //     borderStyle: 'solid',
        //     borderWidth: '2px',
        //     backgroundColor: 'grey'
        // },
        // '@media screen and (max-width: 800px)': { //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
        //     display: 'none',
        // },
    },
    MainContainer: {
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'yellow',
        //textAlign: 'center',
        backgroundColor: '#404040',
        minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder
        display: 'flex',
        flexDirection: 'row',
        //alignItems: 'center',
        //alignContent: 'center',
        //justifyContent: 'center',
        //fontSize: 'calc(12px + 2vmin)',
        color: 'white',
        width: '100%',
        overflow: 'hidden',
    },
    ContentContainer: {
        display: 'flex',
        width: '100%',
        //marginLeft: '100%'
    },
    SubContainer: {
        //display: 'flex',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'cyan',
        marginLeft: '10vw',
        width: '50vw',
        //minWidth: '30em',
        padding: '2em',
        fontSize: '1.1em',
        minWidth: 'auto',
        //maxWidth: '40em'
    },
    CardContainer: {
        marginTop: '2em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'cyan',
        // textAlign: 'center',
        // backgroundColor: '#404040',
        display: 'grid',
        width: '95%',
        //alignItems: 'center',
        //justifyItems: 'center',
        justifyContent: 'center',
        // marginTop: '2em'
        //minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder

        //gridTemplateColumns: '100px 100px 100px',
        gridTemplateColumns: 'repeat(auto-fit, 19em)',
        gridGap: '1em',
        //gridColumnGap: '10px',
        //gridRowGap: '15px'
    },
    QuantityBox: {
        //width: '2em',
        //height: '2em',
        backgroundColor: 'black',
        position: 'absolute',
        top: '.7em',
        right: '.7em',
        borderRadius: '.5em',
        justifyContent: 'center',
        alignContent: 'center',
        //paddingTop:'.4em',
        padding: '.5em .8em ',
        zIndex: 10,
        pointerEvents: 'none',
    },
    GridItem: {
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'yellow',
        // width: '5em',
        // height: '3em',
        overflow: 'hidden',
        display: 'flex',
        //width: '100%',
        height: '14.25em',
        //margin: '10px',
        width: '19em',
        position: 'relative',
        // ':hover' : {
        //     height: '110%',
        //     width: '110%',
        //     zIndex: 10
        // },
        /* Horizontal and Vertical centering */
        justifyContent: 'center',
        alignItems: 'center',
        //border: '2px solid #D8D8D8',
        borderRadius: '.5em',
        backgroundColor: '#1c57b5',
    },
    GridItemImage: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        // maxWidth: '105%',
        // maxHeight: '105%',
        width: '19em',
        height: '14.25em',
        borderRadius: '.5em',
        alignContent: 'center',
        transformOrigin: 'center center',
        transition: 'transform .25s', // filter .5s ease-in-out',
        //filter: 'brightness(50%)',
        ':hover': {
            //filter: 'brightness(100%)',
            transform: 'scale(1.2)',
        },
    },
    GridItemTitle: {
        position: 'absolute',
        bottom: '1em',
        zIndex: 10,
        pointerEvents: 'none',
    },
    GridItemOverlay: {
        zindex: 8,
        borderRadius: '.5em',
        flex: 1,
        position: 'absolute',
        width: '19em',
        height: '14.25em',
        backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0), rgba(255,0,0,0), rgba(255,0,0,0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
        pointerEvents: 'none',
        zIndex: 9,
    },
    PickerContainer: {},
    Picker: {
        display: 'flex',
        //border: '2px solid #D8D8D8',
        //margin: '10px',
        //borderRadius: '',
        padding: '.2em',
        position: 'relative',
    },
    DropContainer: {
        borderRadius: '.2em',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '12em',
        //height: '500px',
        position: 'absolute',
        top: '1.3em',
        //border: '2px solid yellow',
        backgroundColor: '#303030',
        display: 'flex',
        zIndex: 11,
    },
    PickerItem: {
        margin: '.3em',
        padding: '.3em',
        //borderBottom: '2px solid #D8D8D8'
    },
    PickerBottomDiv: {
        height: '.1em',
        backgroundColor: '#a2a2a2',
        width: '100%',
        //position: 'absolute'
    },
    CaretDown: {
        marginLeft: '.3em',
        marginTop: '.3em',
    },
    LoadingContainer: {
        margin: '1em',
        alignSelf: 'center',
        marginLeft: 'calc(50% - 2em)',
    },
});
