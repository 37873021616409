import React from 'react';
import { connect } from 'react-redux';
import LoginPage from '../LoginPage';

import { loginSequence, onLoginDataReady, onLoginNeeded } from '../../../actions/login';
import { changePage, resetAppState, saveBoxModel } from '../../../actions/main';
import Blank from '../../Common/Blank';

const LoginPageContainer = (props) => {
    if (props.currentPage === 'Login') {
        return (
            <LoginPage
                lightweightVersion={props.lightweightVersion}
                navigation={props.navigation}
                isLoadingOnLogin={props.isLoadingOnLogin}
                isErrorOnLogin={props.isErrorOnLogin}
                errorMessageOnLogin={props.errorMessageOnLogin}
                isLoginSuccess={props.isLoginSuccess}
                isLoadingProgramData={props.isLoadingProgramData}
                isLoadingAssets={props.isLoadingAssets}
                gotGuideData={props.gotGuideData}
                onLoginDataReady={props.onLoginDataReady}
                loginSequence={props.loginSequence}
                onLoginNeeded={props.onLoginNeeded}
                currentPage={props.currentPage}
                onChangePage={props.onChangePage}
                fetchGuideDataError={props.fetchGuideDataError}
                getAssetsError={props.getAssetsError}
                getImagesLoadError={props.getImagesLoadError}
                resetAppState={props.resetAppState}
                authError={props.authError}
                generalError={props.generalError}
                saveBoxModel={props.saveBoxModel}
                boxModel={props.boxModel}
                keyMap={props.keyMap}
                allStates={props.allStates}
                originalErrorResponse={props.originalErrorResponse}
                isResetNoticeSet={props.isResetNoticeSet}
            />
        );
    } else {
        return <Blank />;
    }
};

const mapStateToProps = (state) => ({
    lightweightVersion: state.main.lightweightVersion,
    allStates: state,
    boxModel: state.main.boxModel,
    fetchGuideDataError: state.main.fetchGuideDataError,
    isLoadingOnLogin: state.login.isLoadingOnLogin,
    isErrorOnLogin: state.login.isErrorOnLogin,
    errorMessageOnLogin: state.login.errorMessageOnLogin,
    isLoginSuccess: state.login.isLoginSuccess,
    isLoadingProgramData: state.myshows.isLoadingProgramData, //images loaded for homescreen
    isLoadingAssets: state.myshows.isLoadingAssets,
    gotGuideData: state.main.gotGuideData,
    currentPage: state.main.currentPage,
    getAssetsError: state.myshows.getAssetsError,
    getImagesLoadError: state.myshows.getImagesLoadError,
    authError: state.main.authError,
    generalError: state.main.generalError,
    keyMap: state.main.keyMap,
    originalErrorResponse: state.login.originalErrorResponse,
    isResetNoticeSet: state.login.isResetNoticeSet,
});

export const mapDispatchToProps = (dispatch) => ({
    saveBoxModel: (boxModel) => dispatch(saveBoxModel(boxModel)),
    onLoginDataReady: (subscriberData) => dispatch(onLoginDataReady(subscriberData)),
    loginSequence: () => dispatch(loginSequence()),
    onLoginNeeded: () => dispatch(onLoginNeeded()),
    onChangePage: (page) => dispatch(changePage(page)),
    resetAppState: () => dispatch(resetAppState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
