import { CHANGE_STATE_SPEED, SET_LIVE_LOCATION } from '../types/progressbar';

export const setLiveLocation = (liveLocation) => ({
    type: SET_LIVE_LOCATION,
    liveLocation,
});

export const changeStateSpeed = (speed) => ({
    type: CHANGE_STATE_SPEED,
    speed,
});
