import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { motion } from 'framer-motion';

export default class SignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
        };
    }

    setShowPassword = () => {
        this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    };

    render() {
        const {
            handleChange,
            handleSubmit,
            userVal,
            passVal,
            displayUsernameError,
            displayPasswordError,
            usernameErrorText,
            passwordErrorText,
            resetDisplayError,
        } = this.props;

        return (
            <div className={css(styles.container)}>
                <form className={css(styles.formContainer)} onSubmit={(e) => handleSubmit(e)}>
                    <div className={css(styles.middleContainer)}>
                        <h2 className={css(styles.subHeaderText)}>Please enter username / email and password</h2>
                        <div className={css(styles.inputTextContainer)}>
                            <input
                                className={css(styles.input)}
                                type="text"
                                value={userVal}
                                placeholder={'Username / Email'}
                                name="username"
                                onChange={(e) => handleChange(e, 'username')}
                                onFocus={resetDisplayError}
                            />
                            <div className={css(styles.inputTextHelperContainer)}>
                                {displayUsernameError && <p className={css(styles.inputTextHelper)}>{usernameErrorText}</p>}
                            </div>
                        </div>
                        <div className={css(styles.inputTextContainer)}>
                            <input
                                className={css(styles.input)}
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={passVal}
                                placeholder={'Password'}
                                name="password"
                                onChange={(e) => handleChange(e, 'password')}
                                onFocus={resetDisplayError}
                            />
                            <div className={css(styles.inputTextHelperContainer)}>
                                {displayPasswordError && <p className={css(styles.inputTextHelper)}>{passwordErrorText}</p>}
                            </div>
                        </div>
                        <div
                            className={css(styles.showPasswordContainer, this.state.showPassword ? styles.showPasswordContainerActive : null)}
                            onClick={() => {
                                this.setShowPassword();
                            }}>
                            <motion.div
                                whileHover={{
                                    scale: 1.1,
                                }}
                                whileTap={{ scale: 0.85 }}
                                className={css(styles.scaledContainer)}>
                                <span className={css(styles.passwordCircle, this.state.showPassword ? styles.passwordToggled : null)}>
                                    <span className={css(styles.passwordCheck, this.state.showPassword ? styles.passwordCheckToggled : null)} />
                                </span>
                                <p className={css(styles.showPasswordText)}>Show Password</p>
                            </motion.div>
                        </div>
                    </div>
                    <input className={css(styles.submitInput)} type="submit" value="LOG IN" />
                </form>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        '@media (max-width: 600px)': {
            alignItems: 'center',
            padding: '0 3vh',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minWidth: '300px',
        minHeight: '400px',
        marginBottom: '16vh',
    },
    subHeaderText: {
        '@media (max-width: 600px)': {
            fontSize: '0.75em',
        },
        margin: 0,
        marginBottom: '3vh',
        fontSize: 'calc(1.0vw + 1.0vh)',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
    },
    formContainer: {
        '@media (max-width: 600px)': {
            width: '100%',
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-evenly',
    },
    middleContainer: {
        '@media (max-width: 600px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    inputTextContainer: {
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            height: '7vh',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '2vh',
        height: '5vh',
    },
    input: {
        '@media (max-width: 600px)': {
            height: '60%',
            fontSize: '1.2rem',
            '::placeholder': {
                fontSize: '0.7rem',
            },
        },
        '::placeholder': {
            fontStyle: 'italic',
            fontSize: '0.9rem',
        },
        ':focus': {
            outline: 'none',
            border: '2px solid rgb(210,210,210)',
        },
        backgroundColor: 'initial',
        borderColor: 'rgb(150,150,150)',
        height: '100%',
        width: '35vw',
        minWidth: '200px',
        maxWidth: '400px',
        minHeight: '25px',
        padding: '0.1rem 5%',
        color: '#fff',
        fontSize: '1.5rem',
    },
    inputFocused: {
        outline: 'none',
    },
    showPasswordContainer: {
        '@media (max-width: 600px)': {
            margin: '0 2vh',
            width: '35vw',
        },
        ':hover': {
            cursor: 'pointer',
        },
        ':hover *': {
            borderColor: '#00B59C',
            color: '#00B59C',
        },
        ':active *': {
            borderColor: '#00B59C',
            color: '#00B59C',
        },
        display: 'inline-flex',
        minWidth: '200px',
        maxWidth: '400px',
    },
    //necessary for onClick to still work
    scaledContainer: {
        '@media (max-width: 600px)': {
            justifyContent: 'space-around',
        },
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    showPasswordContainerActive: {
        ':hover *': {
            borderColor: 'black',
        },
        ':active *': {
            borderColor: 'black',
        },
    },
    passwordCircle: {
        '@media (max-width: 600px)': {
            marginRight: 0,
        },
        borderRadius: '50%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgb(150,150,150)',
        height: 'calc(0.75vh + 0.75vw)',
        width: 'calc(0.75vh + 0.75vw)',
        display: 'flex',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        transition: 'border-color 0.5s',
        marginRight: '2vw',
    },
    passwordToggled: {
        backgroundColor: '#00B59C',
        borderColor: 'transparent',
    },
    passwordCheck: {
        transform: 'rotate(-45deg) translate(-10%, -10%)',
        height: 'calc(0.3vh + 0.3vw)',
        width: 'calc(0.3vh + 0.3vw)',
        borderColor: 'rgb(150,150,150)',
        borderBottom: '2px solid rgb(150,150,150)',
        borderRight: '2px solid rgb(150,150,150)',
        transition: 'border-color 0.5s',
    },
    passwordCheckToggled: {
        borderColor: 'black',
    },
    showPasswordText: {
        margin: 0,
        padding: 0,
        fontSize: '0.75em',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        color: 'rgb(150,150,150)',
        transition: 'color 2s',
    },
    inputTextHelperContainer: {
        '@media (max-width: 600px)': {
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
    },
    inputTextHelper: {
        '@media (max-width: 600px)': {
            fontSize: '0.62em',
        },
        margin: 0,
        marginLeft: '5vw',
        fontStyle: 'italic',
        fontSize: '0.5em',
    },
    submitInput: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(200,200,200)',
            color: '#000',
        },
        ':focus': {
            outline: 'none',
        },
        backgroundColor: 'initial',
        borderColor: 'rgb(150,150,150)',
        color: 'rgb(150,150,150)',
        display: 'inline',
        width: '200px',
        borderStyle: 'solid',
        padding: '10px 20px',
        appearance: 'none',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
});
