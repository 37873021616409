import React from 'react';
import { connect } from 'react-redux';
import { toggleSubtitle, changeChannel, toggleFullScreenOptions, toggleFullScreen, setGlobalProgramIndex } from '../../../actions/main';
import {
    toggleRecordingScreen,
    toggleTicketScreen,
    onFullScreenInfoOpen,
    onChannelBarInfo,
    //getChannels
} from '../../../actions/guide';
import VideoPlayerWithBars from '../VideoPlayerWithBars';

const VideoPlayerWithBarsContainer = (props) => (
    <VideoPlayerWithBars
        lightweightVersion={props.lightweightVersion}
        liveTV={props.liveTV}
        channelIndex={props.channelIndex}
        globalProgramIndex={props.globalProgramIndex}
        currentTime={props.currentTime}
        isFullScreenInfoOpen={props.isFullScreenInfoOpen}
        isProgressBarOpen={props.isProgressBarOpen}
        toggleRecordingScreen={props.toggleRecordingScreen}
        isRecordingScreenOpen={props.isRecordingScreenOpen}
        toggleTicketScreen={props.toggleTicketScreen}
        isTicketScreenOpen={props.isTicketScreenOpen}
        closePB={props.closePB}
        onToggleSubtitle={props.onToggleSubtitle}
        selectedProgram={props.selectedProgram}
        selectedChannelIndex={props.selectedChannelIndex}
        channels={props.channels}
        currentChannel={props.currentChannel}
        tempChannelHolder={props.tempChannelHolder}
        channelIndex={props.activeRowIndex}
        isSubtitleToggled={props.isSubtitleToggled}
        screenSize={props.screenSize}
        onFullScreenInfoOpen={props.onFullScreenInfoOpen}
        isPlaying={props.isPlaying}
        isChannelBarInfoOpen={props.isChannelBarInfoOpen}
        onChannelBarInfo={props.onChannelBarInfo}
        tempChannelHolder={props.tempChannelHolder}
        isPlayerShowing={props.isPlayerShowing}
        isFullScreen={props.isFullScreen}
        boxModel={props.boxModel}
        channelList={props.channelList}
        onChangeChannel={props.onChangeChannel}
        currentPage={props.currentPage}
        assetData={props.assetData}
        orderData={props.orderData}
        channelsList={props.channelsList}
        onFullScreenOptionsOpen={props.onFullScreenOptionsOpen}
        isFullScreenOptionsOpen={props.isFullScreenOptionsOpen}
        allStates={props.allStates}
        connTimeOut={props.connTimeOut}
        onVideoBufferEnd={props.onVideoBufferEnd}
        keyMap={props.keyMap}
        channelUp={props.channelUp}
        channelDown={props.channelDown}
        onToggleFullScreen={props.onToggleFullScreen}
        alertEAS={props.alertEAS}
        //getChannels={props.getChannels}
        onSetGlobalProgramIndex={props.setGlobalProgramIndex}
        isPlayerSourceOpened={props.isPlayerSourceOpened}
        isDeviceLimitReached={props.isDeviceLimitReached}
    />
);

const mapStateToProps = (state) => ({
    isDeviceLimitReached: state.main.isDeviceLimitReached,
    lightweightVersion: state.main.lightweightVersion,
    keyMap: state.main.keyMap,
    allStates: state,
    assetData: state.myshows.assetData,
    orderData: state.myshows.orderData,
    currentChannel: state.main.currentChannel,
    globalProgramIndex: state.main.globalProgramIndex,
    isSubtitleToggled: state.main.isSubtitleToggled,
    isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
    isTicketScreenOpen: state.guide.isTicketScreenOpen,
    selectedChannelIndex: state.guide.selectedChannelIndex,
    programIndex: state.guide.programIndex,
    channels: state.main.channels,
    activeRowIndex: state.guide.activeRowIndex,
    isPlaying: state.main.isPlaying,
    isChannelBarInfoOpen: state.guide.isChannelBarInfoOpen,
    tempChannelHolder: state.guide.tempChannelHolder,
    isPlayerShowing: state.main.isPlayerShowing,
    isFullScreen: state.main.isFullScreen,
    boxModel: state.main.boxModel,
    channelList: state.main.channelList,
    currentPage: state.main.currentPage,
    isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
    channelsList: state.guide.channelsList,
    isOptionsOpen: state.guide.isOptionsOpen,
    isFullScreenOptionsOpen: state.main.isFullScreenOptionsOpen,
    connTimeOut: state.main.connTimeOut,
    isPlayerSourceOpened: state.main.isPlayerSourceOpened,
});

export const mapDispatchToProps = (dispatch) => ({
    onToggleFullScreen: (isFullScreen) => dispatch(toggleFullScreen(isFullScreen)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    onToggleSubtitle: (isSubtitleToggled) => dispatch(toggleSubtitle(isSubtitleToggled)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
    onChannelBarInfo: (isChannelBarInfoOpen) => dispatch(onChannelBarInfo(isChannelBarInfoOpen)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    onFullScreenOptionsOpen: (isFullScreenOptionsOpen) => dispatch(toggleFullScreenOptions(isFullScreenOptionsOpen)),
    //getChannels: (series_id, source_id) => dispatch(getChannels(series_id, source_id)),
    setGlobalProgramIndex: (globalProgramIndex) => dispatch(setGlobalProgramIndex(globalProgramIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerWithBarsContainer);
