export const CHANGE_CHANNEL = 'CHANGE_CHANNEL';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_PREVIOUS_PAGE = 'CHANGE_PREVIOUS_PAGE';
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN';
export const TOGGLE_SUBTITLE = 'TOGGLE_SUBTITLE';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_CHANNEL_LIST = 'SET_CHANNEL_LIST';
export const PLAY_LIVE_TV = 'PLAY_LIVE_TV';
export const PAUSE_LIVE_TV = 'PAUSE_LIVE_TV';
export const RESUME_LIVE_TV = 'RESUME_LIVE_TV';
export const STOP_LIVE_TV = 'STOP_LIVE_TV';
export const HIDE_MINI_PLAYER = 'HIDE_MINI_PLAYER';
export const TOGGLE_HALF_HOUR_LOAD = 'TOGGLE_HALF_HOUR_LOAD';
export const TOGGLE_FULLSCREEN_LIVE = 'TOGGLE_FULLSCREEN_LIVE';
export const TOGGLE_PLAYING_RECORDING = 'TOGGLE_PLAYING_RECORDING';
export const UPDATE_GUIDE_END_PAGES_TIMES = 'UPDATE_GUIDE_END_PAGES_TIMES';
export const SUSPEND_LIVE_TV = 'SUSPEND_LIVE_TV';
export const SET_LIGHTWEIGHT_VERSION = 'SET_LIGHTWEIGHT_VERSION';
export const UPDATE_ORDER_DATA_LOADING = 'UPDATE_ORDER_DATA_LOADING';
export const RETURN_TO_LIVE_AFTER_EAS = 'RETURN_TO_LIVE_AFTER_EAS';
export const SET_DEVICE_LIMIT_REACHED = 'SET_DEVICE_LIMIT_REACHED';
export const TOGGLE_SOTAL_REFRESH_LOADING = 'TOGGLE_SOTAL_REFRESH_LOADING';
export const TOGGLE_INACTIVATION_MESSAGE = 'TOGGLE_INACTIVATION_MESSAGE';
export const TRIGGER_LIVE_CHANNEL_RELOAD = 'TRIGGER_LIVE_CHANNEL_RELOAD';

// Fetch future channel data
export const FETCH_CHANNELS = 'FETCH_CHANNELS';
export const FETCH_CHANNELS_SUCCESSFUL = 'FETCH_CHANNELS_SUCCESSFUL';
export const FETCH_CHANNELS_ERROR = 'FETCH_CHANNELS_ERROR';
export const UPDATE_ALL_CHANNELS = 'UPDATE_ALL_CHANNELS';
export const UPDATING_CHANNEL_DATA = 'UPDATING_CHANNEL_DATA';

// Fetch past channel data
export const FETCH_PAST_CHANNELS = 'FETCH_PAST_CHANNELS';
export const FETCH_PAST_CHANNELS_SUCCESSFUL = 'FETCH_PAST_CHANNELS_SUCCESSFUL';
export const FETCH_PAST_CHANNELS_ERROR = 'FETCH_PAST_CHANNELS_ERROR';

// No reducers written yet:
export const SET_DIMENSIONS = 'SET_DIMENSIONS';

// Save detected box to the store
export const SAVE_BOX_MODEL = 'SAVE_BOX_MODEL';
export const SAVE_DEVICE_INFO = 'SAVE_DEVICE_INFO';

//navigate through progress bar navigation
export const PROGRESS_BAR_INDEX = 'PROGRESS_BAR_INDEX';

//global isPaused
export const IS_PAUSED = 'IS_PAUSED';

//Error Handling
export const FETCH_SUBSCRIBER_DATA_BEGIN = 'FETCH_SUBSCRIBER_DATA_BEGIN';
export const FETCH_SUBSCRIBER_DATA_SUCCESSFUL = 'FETCH_SUBSCRIBER_DATA_SUCCESSFUL';
export const FETCH_SUBSCRIBER_DATA_ERROR = 'FETCH_SUBSCRIBER_DATA_ERROR';
export const UPDATE_FETCHING_TEXT = 'UPDATE_FETCHING_TEXT';
export const UPDATE_ERROR_LOGIN_TEXT = 'UPDATE_ERROR_LOGIN_TEXT';
export const UPDATE_REQUEST_STATUS_CODE = 'UPDATE_REQUEST_STATUS_CODE';

//full screen options menu
export const TOGGLE_FULLSCREEN_OPTIONS = 'TOGGLE_FULLSCREEN_OPTIONS';

//network conectivity
export const CONNECTIVITY_CHANGE = 'CONNECTIVITY_CHANGE';
export const CONNECTIVITY_STATUS = 'CONNECTIVITY_STATUS';

//handling LogOut
export const ON_LOGOUT_BEGIN = 'ON_LOGOUT_BEGIN';
export const ON_LOGOUT_SUCCESS = 'ON_LOGOUT_SUCCESS';
export const ON_LOGOUT_ERROR = 'ON_LOGOUT_ERROR';
export const PREPARE_FOR_NEXT_LOGOUT_ATTEMPT = 'PREPARE_FOR_NEXT_LOGOUT_ATTEMPT';
export const RESET_APP_STATE = 'RESET_APP_STATE';

//EAS
export const UPDATE_PREVIOUS_ALERTS_ARR = 'UPDATE_PREVIOUS_ALERTS_ARR';
export const ALERT_EAS = 'ALERT_EAS';
export const CLEAR_EAS = 'CLEAR_EAS';

//Authentication error
export const AUTH_ERROR = 'AUTH_ERROR';
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';

//general error
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const GENERAL_ERROR_RESET = 'GENERAL_ERROR_RESET';

//set global Program index so when press ok - info updates with program
export const SET_GLOBAL_PROGRAM_INDEX = 'SET_GLOBAL_PROGRAM_INDEX';
//allow for login after one out of two calls is completed so when the second finishes, login finishes
export const SET_LOGIN_READY = 'SET_LOGIN_READY';
export const SET_PLAYER_SOURCE_OPENED = 'SET_PLAYER_SOURCE_OPENED';
//to set overscan
export const SET_OVERSCAN = 'SET_OVERSCAN';
//pusher updates
export const UPDATE_PUSHER_STATE = 'UPDATE_PUSHER_STATE';

//debug check
export const TOGGLE_VIDEO_DEBUG_OPTION = 'TOGGLE_VIDEO_DEBUG_OPTION';

//update tracks
export const UPDATE_NUM_TRACKS = 'UPDATE_NUM_TRACKS';

//nav utility switch
export const NAV_UTILITY = 'NAV_UTILITY';

//tool tips
export const TOGGLE_TOOL_TIPS = 'TOOGLE_TOOL_TIPS';

//number of pages in the guide to iterate through
export const SET_NUMBER_OF_PAGES = 'SET_NUMBER_OF_PAGES';
