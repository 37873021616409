//import moment from 'moment';
//before moving this, try and get basic funcionality of video playing to work without it, since it wont be needed initially anyway once finished

export const getPageData = (
    startChannelIndex,
    gridRows,
    channels,
    startTime,
    eventListWithCatchup,
    assetDataProgramIDLookup,
    orderData,
    horizontalPageIndex
) => {
    //pass in other channel data??
    // console.log('update page channels has been cal
    const pageStartTime = startTime * 1000;

    const endTime = startTime + 8100;
    //return (dispatch) => {
    let channelData = [];
    let isChannelListInTwoHourWindow = true;

    //Check if time being fetched is more than 3 days(259200 seconds) in the past - return null if true
    //(current time - guide page start time) > 259200 seconds

    // ///////////////////////////////////////commenting out for now:
    // let currentTime = moment().subtract(moment().minute() % 30, 'minutes').subtract(moment().second(), 'seconds').unix(); //math.floor(closest 30 minutes)
    // if(currentTime - startTime > 259200)
    //     return null;

    //why do channel indexes need to be updated if we are already returning all the channel data we need for the page?
    //ideally
    //let page = {};
    // console.log("gridRows", gridRows);
    let index = startChannelIndex;
    for (let i = 0; i < gridRows; i++) {
        if (index >= channels.length) {
            // console.log('startChannelIndex: ', startChannelIndex);
            index = index % channels.length;
            // console.log('index: ', index);
        } else if (index < 0) {
            index = channels.length + i;
        }

        if (channels[index].programs) {
            let copiedChannel = { ...channels[index] };
            copiedChannel.programs = replaceChannelProgramList(
                startTime,
                endTime,
                copiedChannel,
                isChannelListInTwoHourWindow,
                pageStartTime,
                eventListWithCatchup,
                assetDataProgramIDLookup,
                orderData,
                horizontalPageIndex
            );
            // replaceChannelProgramList(startTime, endTime, channels[index], isChannelListInTwoHourWindow, pageStartTime, eventListWithCatchup,  assetDataProgramIDLookup, orderData);
            channelData.push(copiedChannel);
        }
        index++;
    }
    channelData.map((channel, i) => {
        let isCustom = channel.custom_guide === true && channel.type === 'local';
        const defaultTitle = 'No Program Data Unavailable';

        if (isCustom) {
            let programTitle = channel.guide_title !== '' ? channel.guide_title : defaultTitle;
            channel.programs[0].programTitle = programTitle;
        }
    });
    // console.log(channelData)
    if (isChannelListInTwoHourWindow) {
        return channelData;
    } else {
        return null; //may need to be chaned to []
    }
};

const replaceChannelProgramList = (
    startUnixTime,
    endUnixTime,
    channel,
    isChannelListInTwoHourWindow,
    pageStartTime,
    eventListWithCatchup,
    assetDataProgramIDLookup,
    orderData,
    horizontalPageIndex
) => {
    // if(!isChannelListInTwoHourWindow)
    //     return false;
    let programList = [];
    let foundFirstProgram = false;
    const firstIndex = closestTimeIndex(startUnixTime * 1000, channel.programs);
    let programRatiosCount = 0;
    let ratioCountLimit = 4;
    if (horizontalPageIndex > -1) {
        ratioCountLimit = 4.25; // if in present/future, show channel preview of next page, to match stb design
    }
    for (let i = firstIndex; i < channel.programs.length; i++) {
        //checks if the page start time is between the first program's start and end times (less than or equal to zero)
        if (
            !foundFirstProgram &&
            channel.programs[i].unix_start_time &&
            startUnixTime !== channel.programs[i].unix_end_time / 1000 &&
            checkBetweenNumbers(startUnixTime, channel.programs[i].unix_start_time / 1000, channel.programs[i].unix_end_time / 1000)
        ) {
            const isFirstProgram = true;
            addExtraDataPerProgram(channel.programs[i], pageStartTime, eventListWithCatchup, assetDataProgramIDLookup, orderData);
            //add program ratio
            let programRatio = getProgramRatio(channel.programs[i], isFirstProgram, pageStartTime);
            if (programRatio <= ratioCountLimit) {
                channel.programs[i].programRatio = programRatio;
                channel.programs[i].programRatiosCount = programRatiosCount;
            } else {
                channel.programs[i].programRatio = ratioCountLimit;
                channel.programs[i].programRatiosCount = ratioCountLimit;
            }
            //add program ratio count up to this program
            programRatiosCount += programRatio;
            programList = [...programList, channel.programs[i]];
            foundFirstProgram = true;
        } else {
            //checks if program start times are between the 2-hour window of start and end unix times on the current page (less than or equal to zero)
            if (channel.programs[i].unix_start_time && checkBetweenNumbers(channel.programs[i].unix_start_time / 1000, startUnixTime, endUnixTime)) {
                const isFirstProgram = false;
                addExtraDataPerProgram(channel.programs[i], pageStartTime, eventListWithCatchup, assetDataProgramIDLookup, orderData);
                //add program ratio
                let programRatio = getProgramRatio(channel.programs[i], isFirstProgram, pageStartTime);
                //add program ratio count up to this program
                if (programRatiosCount + programRatio >= ratioCountLimit) {
                    channel.programs[i].programRatio = ratioCountLimit - programRatiosCount;
                    channel.programs[i].programRatiosCount = ratioCountLimit;
                    programRatiosCount = ratioCountLimit;
                    programList = [...programList, channel.programs[i]];
                    break;
                } else {
                    channel.programs[i].programRatio = programRatio;
                    programRatiosCount += programRatio;
                }
                channel.programs[i].programRatiosCount = programRatiosCount;
                programList = [...programList, channel.programs[i]];
                //breaks loop if first program was already found and next program didn't pass the previous condition
            } else if (foundFirstProgram) {
                break;
            }
        }
    }
    /* TODO: *Guide Refactor - Test these conditions below */
    //Conditions where updating the page data is invalid
    //No first show
    // if(!programList[0]){
    //     // console.log("false1", channel.programs[firstIndex]);
    //     // return false;
    // }
    //Check if start time on the page falls outside range of the first show's time slots (greater than zero)
    // if(programList[0] && (startUnixTime - (programList[0].unix_start_time / 1000)) * (startUnixTime - (programList[0].unix_end_time / 1000)) > 0){
    //     // console.log("false2: ", programList[0]);
    //     //Check for 11Alive Weather(not enough data comes in for this show(maybe due to time zone??)) *change after fix
    //     // if(programList[0].source_id !== "69027727")
    //     //     return false;
    // }
    // //Check if end time on the page falls within range of the last show's time slots (greater than zero)
    // if(programList[programList.length - 1] && (endUnixTime - (programList[programList.length - 1].unix_start_time / 1000)) * (endUnixTime - (programList[programList.length - 1].unix_end_time / 1000)) > 0){
    //     // console.log("false3: ", programList[programList.length - 1]);
    //     // if(programList[programList.length - 1].source_id !== "69027727")
    //     //     return false;
    // }
    // channel.programs = [...programList];
    return programList;
    // return true;
};

const closestTimeIndex = (pageStartTime, arr) => {
    if (arr.length > 0) {
        let closestNumber = 0;
        let mid;
        let lo = 0;
        let hi = arr.length - 1;
        //grab the closest index out the row through binary search
        while (hi - lo > 1) {
            //create mid-point with lo and hi values
            mid = Math.floor((lo + hi) / 2);
            if (arr[mid].unix_start_time < pageStartTime) {
                //move lo up to middle if our midpoint is less than the unix time we're looking for
                lo = mid;
            } else {
                //else move hi to midpoint
                hi = mid;
            }
        }

        //set number to lo or hi depending on which is closer
        if (pageStartTime - arr[lo].unix_start_time <= arr[hi].unix_start_time - pageStartTime) {
            closestNumber = lo;
        } else {
            closestNumber = hi;
        }

        // check if page start time is between the start and end times of the current, next or previous program
        if (arr[closestNumber] && checkBetweenNumbers(pageStartTime, arr[closestNumber].unix_start_time, arr[closestNumber].unix_end_time))
            return closestNumber;
        else if (arr[closestNumber + 1] && checkBetweenNumbers(pageStartTime, arr[closestNumber + 1].unix_start_time, arr[closestNumber + 1].unix_end_time)) {
            return closestNumber + 1;
        } else if (arr[closestNumber - 1] && checkBetweenNumbers(pageStartTime, arr[closestNumber - 1].unix_start_time, arr[closestNumber - 1].unix_end_time)) {
            return closestNumber - 1;
        } else {
            return closestNumber;
        }
    } else {
        return 0;
    }
};

const addExtraDataPerProgram = (program, pageStartTime, eventListWithCatchup, assetDataProgramIDLookup, orderData) => {
    //prevRatio? - try not to use
    //add eventList with catchup - modify to an object by schedule_id
    //is orderData sorted? can binary search in case this takes awhile

    // const { item, screenProps, assetDataProgramIDLookup } = this.props;
    // const ratioArr = item && item.programs && (Array.isArray(item.programs) && item.programs.length>0) ? item.programs.map((program) => {
    // return this.getProgramRatio(program);
    // })  : [];

    // let rowProgramsExtraData = [];
    // this.props.item.programs.map((program, i) => {

    // let prevRatio = 0;
    let restartResource = null;
    // let recordedSingleError = false;
    // let recordedSeriesError = false;

    // const endTrimmed = +program.unix_end_time / 1000;

    restartResource =
        program &&
        eventListWithCatchup[
            eventListWithCatchup.findIndex((cur) => {
                //when program.schedule.id === "0", we can not restart - program
                return program?.schedule_id !== '0' && cur.ext_id === program.schedule_id; //props.selectedProgram.schedule_id
            })
        ];
    program.restartAvailable = restartResource ? true : false;
    program.restartResource = restartResource;

    /* Add in constant check if program ended (in schedule row memo - isEqual()?) - or update every 5 minutes (save last unix time?)?*/
    // const programEnded = endTrimmed > moment().unix(); //all programs that have not completed airing, includes future
    // program.hasEndedWhenPageAdded =  ;

    const defaultTitle = 'Program Data Unavailable';
    let programTitle = program.long_title !== '' ? program.long_title : defaultTitle;
    program.programTitle = programTitle;
    // const rowScale = (this.props.gridRows/10) + ((10 - this.props.gridRows)*.04);

    // let recordedSeriesDone = false;
    // let recordedSeriesIncomplete = false;

    program.seriesExcluded = false;
    program.recordedSeries = false;
    program.recordedSingle = false;

    if (program.series_id !== '' && orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0]) {
        for (let i = 0; i < orderData.length; i++) {
            if (orderData[i].series_id === program.series_id.toString()) {
                // removing the below check for now, and just displaying icons based on: if present from backend and sc recording status
                // if (checkIfIncluded(orderData[i], program)) { //no need to proceed if the episode does not fit criteria of the order
                program.recordedSeries = true;
                // console.log("series id match... ")
                if (checkIfIncluded(orderData[i], program)) {
                    if (assetDataProgramIDLookup[program.program_id]) {
                        //extra validation needed for showtype: "SM" - all program IDs for new programs are the same, so must check schedule ID as well
                        if (program.show_type === 'SM') {
                            if (assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id]?.sc_recording_state) {
                                program.sc_recording_state =
                                    assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id].sc_recording_state;
                                program.order_id = assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id].order_id;
                            } else {
                                program.seriesExcluded = true;
                                program.order_id = orderData[i].order_id;
                            }
                        } else {
                            program.sc_recording_state = assetDataProgramIDLookup[program.program_id].sc_recording_state;
                            program.order_id = assetDataProgramIDLookup[program.program_id].order_id;
                        }
                    } else {
                        //excluded recording
                        program.seriesExcluded = true;
                        program.order_id = orderData[i].order_id;
                    }
                } // else: it's not on the right channel, and user will not see any icon
                break;
            }
        }
    }

    // let recordedSingleDone = false;
    // let recordedSingleIncomplete = false;

    //log recordedSeriesDone property when missing - undefined?
    if (!program.recordingSeries && !program.seriesExcluded) {
        if (assetDataProgramIDLookup[program.program_id]) {
            //extra validation needed for showtype: "SM" - all program IDs for new programs are the same, so must check schedule ID as well
            if (program.show_type === 'SM') {
                if (assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id]?.sc_recording_state) {
                    program.recordedSingle = true;
                    program.sc_recording_state = assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id].sc_recording_state;
                    program.order_id = assetDataProgramIDLookup[program.program_id].schedule_id_instances[program.schedule_id].order_id;
                }
            } else {
                program.recordedSingle = true;
                program.sc_recording_state = assetDataProgramIDLookup[program.program_id].sc_recording_state;
                program.order_id = assetDataProgramIDLookup[program.program_id].order_id;
            }
        }
    }

    const TWO_HOURS_MS = 7200000;
    program.removeRightBorderInPast = program.unix_end_time === pageStartTime + TWO_HOURS_MS;
    //****try not to use adjust icon & try to scale with flexbox/percentages if possible
    // const adjustIcon = (prevRatio < 4 && prevRatio + programRatio >= 4.25) && (recordedSeriesDone || recordedSingleDone || recordedSingleIncomplete);
};

const getProgramRatio = (program, isFirstProgram, pageStartTime) => {
    if (isFirstProgram) {
        return (program.unix_end_time - pageStartTime) / 1800000;
    } else {
        return (program.unix_end_time - program.unix_start_time) / 1800000;
    }
};

const checkIfIncluded = (order, program) => {
    let isIncluded = true;
    //console.log('channel match check:', 'current ch.:', item.source_id.toString(), 'ch. of order:', order.channel);
    if (order.source_id !== program.source_id.toString()) {
        //if order is set to specific channel only, and program is not on that channel, program is not included in the order
        isIncluded = false;
    }

    // if (order.start_from !== '0') {
    //     if (order.start_from === "New") {
    //         //Back End added story to pass this down so we can check if program is "new" or not here
    //         //so for now we can not restrict based on this parameter
    //     } else if (+program.se_name.split(' ')[1] < +order.start_from) {
    //         isIncluded = false;
    //     }
    // }

    return isIncluded;
};

//checks if a number is between two other numbers or equal to one of the numbers
const checkBetweenNumbers = (numberToCheck, number1, number2) => {
    if ((numberToCheck - number1) * (numberToCheck - number2) <= 0) {
        return true;
    } else {
        return false;
    }
};
