import { CHANGE_CARD_INDEX, CHANGE_CARD_MENU_INDEX, CHANGE_ROW_INDEX, UPDATE_SHOW_INFO, TOGGLE_CASTS } from '../types/showinfo';

const initialState = {
    selectedCardIndex: 0,
    selectedMenuIndex: 0,
    selectedRowIndex: 0,
    showInfo: {},
    hasCasts: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CARD_INDEX:
            return {
                ...state,
                selectedCardIndex: action.cardIndex,
            };
        case CHANGE_CARD_MENU_INDEX:
            return {
                ...state,
                selectedMenuIndex: action.menuIndex,
            };
        case CHANGE_ROW_INDEX:
            return {
                ...state,
                selectedRowIndex: action.rowIndex,
                selectedCardIndex: 0,
                selectedMenuIndex: 0,
            };
        case UPDATE_SHOW_INFO:
            return {
                ...state,
                showInfo: { ...action.newInfo },
            };
        case TOGGLE_CASTS:
            return {
                ...state,
                hasCasts: action.hasCasts,
            };
        default:
            return state;
    }
};
