import React, { Component } from 'react';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
// import truncate from 'lodash/truncate';
import WebPlayer from '../../Player/Container/WebPlayerContainer';
import defaultBGImage from '../../../assets/ic_color_splash_seq_04.png';
import SeriesViewList from './SeriesViewList';
import OptionsMyShowsContainer from '../Containers/OptionsMyShowsContainer';
import { withRouter } from 'react-router-dom';
import LoadingAnimation from '../../Login/LoadingAnimation';
import { AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allDaysOfWeek } from '../../../utils/ClosestChNumber';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default withRouter(
    class MyShowsSeriesView extends Component {
        constructor(props) {
            super(props);

            this.state = {
                episodes: [],
                upcomingEpisodes: [],
                seasonSortedArray: [],
                upcomingSeasonArray: [],
                episodeNumbers: [],
                seasonIndex: 0,
                episodeIndex: 0,
                allSENumsAvailable: false,
                doesSeriesOrderExist: false,
                isPlayerShowing: false,
                isOptionsShowing: false,
                optionMenuState: '',
                countFav: 0,
                isUpcomingSelected: false,
            };
        }

        componentDidMount() {
            this.setEpisodes();
        }

        componentDidUpdate(prevProps, prevState) {
            this._countFav();

            // const { seasonSortedArray, seasonIndex, episodeIndex } = this.state;
            let prevProgram = null;
            if (
                prevState.seasonSortedArray.length > 0 &&
                prevState.seasonSortedArray[prevState.seasonIndex] &&
                prevState.seasonSortedArray[prevState.seasonIndex].episodes &&
                prevState.seasonSortedArray[prevState.seasonIndex].episodes[prevState.episodeIndex]
            ) {
                prevProgram = prevState.seasonSortedArray[prevState.seasonIndex].episodes[prevState.episodeIndex];
            }

            if (this.props.isLoadingAssets === false && prevProps.isLoadingAssets === true) {
                this.setEpisodes();
            } else if (prevState.countFav !== this.state.countFav) {
                this.setEpisodes();
            } else if (this.props.globalShowList !== prevProps.globalShowList && this.props.isLoadingAssets === false) {
                if (prevProgram !== null) {
                    if (this.props.globalShowList.filter((x) => x.program_name === prevProgram.program_name).length === 0) {
                        //this runs when a show is deleted and user user is currently viewing that show. It exits the user from SeriesView
                        if (this.props.isTicketScreenOpen) {
                            this.props.toggleTicketScreen(false);
                            this.props.history.goBack();
                            this.props.closeSeriesView(false);
                        } else {
                            this.props.history.goBack();
                            this.props.closeSeriesView(false);
                        }
                    } else {
                        //this will run when a show is deleted from pusher, but not the current index. It preserves the users show they have highlighted/selected
                        this.setEpisodes(prevProgram);
                        // console.log(prevProgram);
                    }
                }
            }
            // console.log('myshowsseriesview',this.state)
        }

        _countFav = () => {
            if (this.props.globalShowList.length > 0) {
                let allFavorites = this.props.globalShowList.filter((x) => x.program_name && x.favorite);
                if (allFavorites.length !== this.state.countFav) {
                    this.setState({ countFav: allFavorites.length });
                }
                // console.log("array allFavorites", allFavorites)
            }
        };

        handleSeasonClick = (index) => {
            if (this.state.seasonIndex !== index) {
                this.setState({ seasonIndex: index, episodeIndex: 0 });
            }
        };

        handleEpisodeClick = (index) => {
            if (this.state.episodeIndex !== index) {
                this.setState({ episodeIndex: index });
            }
        };

        handlePlayStopButton = () => {
            this.setState((prevState) => ({ isPlayerShowing: !prevState.isPlayerShowing }));
        };

        handleOptionsButton = (menuState) => {
            this.setState({ isOptionsShowing: true, optionMenuState: menuState });
        };

        onBackClick = () => {
            this.props.history.goBack();
        };

        closePlayer = () => {
            if (this.state.isPlayerShowing) {
                this.setState({ isPlayerShowing: false });
            }
        };

        updateOptionMenuState = (menuState) => {
            this.setState({ optionMenuState: menuState });
        };

        onOptionMyShowsClose = () => {
            this.setState({ isOptionsShowing: false });
            if (this.props.isTicketScreenOpen) {
                this.props.toggleTicketScreen(false);
            }
        };

        setEpisodes(prevRecording) {
            //search through global show list and find the selected series
            const allEpisodes = this.props.globalShowList.filter((x) => x.program_name === this.props.selectedSeries); //was assetData
            const allUpcoming = this.props.upcomingData.filter((x) => x.program_name === this.props.selectedSeries);

            //sort and push empty seasons / episodes to end
            allEpisodes.sort(function (a, b) {
                const episode1 = a.episode_number;
                const episode2 = b.episode_number;
                if (episode1 === '' || episode1 === null) return 1;
                if (episode2 === '' || episode2 === null) return -1;
                if (episode1 === episode2) return 0;
                return episode1 - episode2;
            });
            allUpcoming.sort(function (a, b) {
                const episode1 = a.episode_number;
                const episode2 = b.episode_number;
                if (episode1 === '' || episode1 === null) return 1;
                if (episode2 === '' || episode2 === null) return -1;
                if (episode1 === episode2) return 0;
                return episode1 - episode2;
            });

            allEpisodes.sort(function (a, b) {
                const season1 = a.season_number;
                const season2 = b.season_number;
                if (season1 === '' || season1 === null) return 1;
                if (season2 === '' || season2 === null) return -1;
                if (season1 === season2) return 0;
                return season1 - season2;
            });

            allUpcoming.sort(function (a, b) {
                const season1 = a.season_number;
                const season2 = b.season_number;
                if (season1 === '' || season1 === null) return 1;
                if (season2 === '' || season2 === null) return -1;
                if (season1 === season2) return 0;
                return season1 - season2;
            });

            const seasonSortedArray = []; //array of the season_numbers for a series: nonrepeating
            // let allSENumsAvailable = true;
            const upcomingSeasonArray = [];

            allEpisodes.forEach((cur, i) => {
                //do we need to truncate the string for overflow? or determine paragraph height against container height?
                // cur.display_descrption = truncate(cur.description, {
                //     length: 300,
                //     separator: ' ',
                // });

                /* 
                example format: [
                    {
                    season: 'S5',
                    episodes: [{ episode1 },{ episode2 }],
                    },
                    {
                    season: 'S6',
                    episodes: [{ episode1},{ episode2 }],
                    }
                ]
            */
                if (cur.season_number) {
                    const seasonIndex = seasonSortedArray.findIndex((i) => i.season === `S${cur.season_number}`);

                    if (cur.episode_number.length < 1) {
                        cur.episode_placeholder = moment.unix(+cur.unix_start_time).format('MM-DD-YYYY');

                        if (cur.episode_placeholder === 'Invalid date') cur.episode_placeholder = 'N/A'; //Invalid Date
                    }

                    if (seasonIndex === -1) {
                        seasonSortedArray.push({
                            season: `S${cur.season_number}`,
                            episodes: [cur],
                        });
                    } else {
                        seasonSortedArray[seasonIndex].episodes.push(cur);
                    }
                } else {
                    const missingSeasonIndex = seasonSortedArray.findIndex((i) => i.season === 'N/A');
                    //pass in recorded date
                    cur.episode_placeholder = moment.unix(+cur.unix_start_time).format('MM-DD-YYYY');
                    if (cur.episode_placeholder === 'Invalid date') {
                        cur.episode_placeholder = 'N/A';
                    } //Invalid Date

                    if (missingSeasonIndex === -1) {
                        seasonSortedArray.push({
                            season: 'N/A',
                            episodes: [cur],
                        });
                    } else {
                        seasonSortedArray[missingSeasonIndex].episodes.push(cur);
                    }
                }

                //check if a favorite program & add it there also
                if (cur.favorite) {
                    //addd it to beginning
                    if (seasonSortedArray[0].season !== 'Favorites') {
                        seasonSortedArray.splice(0, 0, {
                            season: 'Favorites',
                            episodes: [cur],
                        });
                    } else {
                        seasonSortedArray[0].episodes.push(cur);
                    }
                }

                if (cur.expires_unix < moment().unix() + 604800 && seasonSortedArray[0].season !== 'Favorites') {
                    //addd it to beginning of array
                    if (seasonSortedArray[0].season !== 'Expiring') {
                        seasonSortedArray.splice(0, 0, {
                            season: 'Expiring',
                            episodes: [cur],
                        });
                    } else {
                        seasonSortedArray[0].episodes.push(cur);
                    }
                }

                if (cur.expires_unix < moment().unix() + 604800 && seasonSortedArray[0].season === 'Favorites') {
                    //addd it to beginning of array
                    if (seasonSortedArray[1].season !== 'Expiring') {
                        seasonSortedArray.splice(1, 0, {
                            season: 'Expiring',
                            episodes: [cur],
                        });
                    } else {
                        seasonSortedArray[1].episodes.push(cur);
                    }
                }
            });

            allUpcoming.forEach((cur, i) => {
                if (cur.season_number) {
                    const seasonIndex = upcomingSeasonArray.findIndex((i) => i.season === `S${cur.season_number}`);
                    if (cur.episode_number.length < 1) {
                        cur.episode_placeholder = moment.unix(+cur.unix_start_time).format('MM-DD-YYYY');
                        if (cur.episode_placeholder === 'Invalid date') cur.episode_placeholder = 'N/A'; //Invalid Date
                    }

                    if (seasonIndex === -1) {
                        upcomingSeasonArray.push({
                            season: `S${cur.season_number}`,
                            episodes: [cur],
                        });
                    } else {
                        upcomingSeasonArray[seasonIndex].episodes.push(cur);
                    }
                } else {
                    const missingSeasonIndex = upcomingSeasonArray.findIndex((i) => i.season === 'N/A');
                    //pass in recorded date
                    cur.episode_placeholder = moment.unix(+cur.unix_start_time).format('MM-DD-YYYY');
                    if (cur.episode_placeholder === 'Invalid date') cur.episode_placeholder = 'N/A'; //Invalid Date

                    if (missingSeasonIndex === -1) {
                        upcomingSeasonArray.push({
                            season: 'N/A',
                            episodes: [cur],
                        });
                    } else {
                        upcomingSeasonArray[missingSeasonIndex].episodes.push(cur);
                    }
                }
            });

            const doesSeriesOrderExist = allEpisodes.length ? allEpisodes[0].type === 'series' : true;

            this.setState({
                episodes: allEpisodes,
                upcomingEpisodes: allUpcoming,
                seasonSortedArray: seasonSortedArray,
                upcomingSeasonArray,
                episodeIndex: 0,
                doesSeriesOrderExist: doesSeriesOrderExist,
                seasonIndex: 0,
            });
        }

        render() {
            const { showType, bgImage, isLoadingAssets } = this.props;
            const {
                seasonSortedArray,
                seasonIndex,
                episodeIndex,
                isPlayerShowing,
                isOptionsShowing,
                optionMenuState,
                upcomingSeasonArray,
                upcomingEpisodes,
            } = this.state;
            //*current program will be the correct program for series or single order types
            let currentProgram = {};
            let seasonListData = [];
            let episodeListData = [];
            let upcomingSeasonList = [];
            let upcomingEpisodeList = [];
            let programTitle = '';

            if (
                seasonSortedArray.length > 0 &&
                seasonSortedArray[seasonIndex] &&
                seasonSortedArray[seasonIndex].episodes &&
                seasonSortedArray[seasonIndex].episodes[episodeIndex]
            ) {
                seasonListData = seasonSortedArray;
                episodeListData = seasonSortedArray[seasonIndex].episodes;
            }
            if (
                upcomingSeasonArray.length > 0 &&
                upcomingSeasonArray[seasonIndex] &&
                upcomingSeasonArray[seasonIndex].episodes &&
                upcomingSeasonArray[seasonIndex].episodes[episodeIndex]
            ) {
                upcomingSeasonList = upcomingSeasonArray;
                upcomingEpisodeList = upcomingSeasonArray[seasonIndex].episodes;
            }
            if (this.state.isUpcomingSelected && upcomingSeasonArray.length) {
                currentProgram = upcomingSeasonArray[seasonIndex].episodes[episodeIndex];
                programTitle = currentProgram.program_id ? currentProgram.long_title || currentProgram.program_name : '';
            } else if (this.props.onUpcomingMenu && !(showType === 'SM' || showType === 'SE')) {
                if (upcomingEpisodes.length) {
                    currentProgram = upcomingEpisodes[0];
                    programTitle = currentProgram.program_id ? currentProgram.long_title || currentProgram.program_name : '';
                }
            } else if (seasonSortedArray.length && !this.state.isUpcomingSelected) {
                currentProgram = seasonSortedArray[seasonIndex].episodes[episodeIndex];
                programTitle = currentProgram.program_id ? currentProgram.long_title || currentProgram.program_name : '';
            } else if (seasonSortedArray.length && this.state.isUpcomingSelected) {
                let tempProgram = seasonSortedArray[0].episodes[0];
                programTitle = tempProgram.program_id ? tempProgram.long_title || tempProgram.program_name : '';
            }
            const isEpFavorite = currentProgram.favorite === true;
            const bgImageURL = bgImage && bgImage.length ? bgImage : defaultBGImage;
            const dayOfWeek =
                currentProgram && currentProgram.unix_start_time
                    ? new Date(currentProgram.unix_start_time * 1000).getDay()
                    : currentProgram && currentProgram.starts_at
                    ? new Date(currentProgram.starts_at).getDay()
                    : '';
            let upcomingMovieRecordingTime = () => {
                try {
                    if (currentProgram.unix_start_time) {
                        return allDaysOfWeek[dayOfWeek]
                            ? `${allDaysOfWeek[dayOfWeek]} ${moment.unix(currentProgram.unix_start_time).format('MM/DD hh:mm A')} - ${moment
                                  .unix(currentProgram.unix_end_time)
                                  .format('hh:mm A')}`
                            : '';
                    } else {
                        return `${allDaysOfWeek[dayOfWeek]} ${moment(currentProgram.starts_at).format('MM/DD hh:mm A')} - ${moment(
                            currentProgram.ends_at
                        ).format('hh:mm A')}`;
                    }
                } catch (e) {
                    console.log(e);
                    return '';
                }
            };

            return (
                <div
                    className={css(styles.mainContainer)}
                    style={{
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.93), rgba(0, 0, 0, 0.66)), url(${bgImageURL}) no-repeat center center fixed`,
                    }}>
                    {!isLoadingAssets ? (
                        (seasonSortedArray.length > 0 || upcomingSeasonArray.length > 0) && (
                            <div className={css(styles.fixedContainer)}>
                                {/* <div className={css(styles.channelInfoContainer)}>
                            <p className={css(styles.channelLogo)}></p>
                            <h3 className={css(styles.channelName)}></h3>
                        </div> */}
                                <div className={css(styles.headerContainer)}>
                                    <div className={css(styles.backButtonContainer)}>
                                        <FontAwesomeIcon onClick={this.onBackClick} className={css(styles.backButton)} icon={faArrowLeft} />
                                    </div>
                                    <div className={css(styles.channelInfoContainer)}>
                                        <img className={css(styles.channelLogo)} src={this.props.channelLogo} alt={''} />
                                        <h3 className={css(styles.channelName)}>
                                            {this.props.channelNumber} {this.props.channelName}
                                        </h3>
                                    </div>
                                    <h2 className={css(styles.programTitle)}>{programTitle}</h2>
                                    <div className={css(styles.recordingsContainer)}>
                                        {(showType === 'SM' || showType === 'SE') && (
                                            <>
                                                <div className={css(styles.upcomingButtonsContainer)}>
                                                    <button
                                                        className={this.state.isUpcomingSelected ? css(styles.button) : css(styles.selectedButton)}
                                                        onClick={() => this.setState({ isUpcomingSelected: false, seasonIndex: 0, episodeIndex: 0 })}>
                                                        Recorded
                                                    </button>
                                                    <button
                                                        className={!this.state.isUpcomingSelected ? css(styles.button) : css(styles.selectedButton)}
                                                        onClick={() => this.setState({ isUpcomingSelected: true, seasonIndex: 0, episodeIndex: 0 })}>
                                                        Upcoming
                                                    </button>
                                                </div>
                                                {this.state.isUpcomingSelected ? (
                                                    upcomingEpisodeList.length ? (
                                                        <>
                                                            <SeriesViewList
                                                                isSeasonList={true}
                                                                seasonSortedArray={upcomingSeasonArray}
                                                                seasonIndex={seasonIndex}
                                                                listData={upcomingSeasonList}
                                                                activeIndex={seasonIndex}
                                                                handleListItemClick={this.handleSeasonClick}
                                                            />
                                                            <SeriesViewList
                                                                isSeasonList={false}
                                                                seasonSortedArray={upcomingSeasonArray}
                                                                seasonIndex={seasonIndex}
                                                                listData={upcomingEpisodeList}
                                                                activeIndex={episodeIndex}
                                                                handleListItemClick={this.handleEpisodeClick}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div>No upcoming shows scheduled to record in the next 3 days</div>
                                                    )
                                                ) : episodeListData.length ? (
                                                    <>
                                                        <SeriesViewList
                                                            isSeasonList={true}
                                                            seasonSortedArray={seasonSortedArray}
                                                            seasonIndex={seasonIndex}
                                                            listData={seasonListData}
                                                            activeIndex={seasonIndex}
                                                            handleListItemClick={this.handleSeasonClick}
                                                        />
                                                        <SeriesViewList
                                                            isSeasonList={false}
                                                            seasonSortedArray={seasonSortedArray}
                                                            seasonIndex={seasonIndex}
                                                            listData={episodeListData}
                                                            activeIndex={episodeIndex}
                                                            handleListItemClick={this.handleEpisodeClick}
                                                        />
                                                    </>
                                                ) : (
                                                    <div>No shows recorded yet</div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className={css(styles.episodeInfoContainer)}>
                                        {((this.props.onUpcomingMenu && this.props.showType !== 'SE' && this.props.showType !== 'SM') ||
                                            (this.state.isUpcomingSelected && this.state.upcomingEpisodes.length > 0)) && (
                                            <div className={css(styles.scheduledContainer)}>
                                                <FontAwesomeIcon color={'rgba(0,131,159,255)'} icon="info-circle" style={{ fontSize: '1.3em' }} />
                                                <div className={css(styles.excludedDisclaimer)}>This Program is Scheduled to Record</div>
                                            </div>
                                        )}
                                        <div className={css(styles.episodeInfoInnerContainer)}>
                                            <h3 className={css(styles.episodeTitle)}>
                                                {currentProgram.program_id
                                                    ? `${currentProgram.episode_name} ${
                                                          currentProgram.release_year ? '(' + currentProgram.release_year + ')' : ''
                                                      } ${currentProgram.season_number ? 'S' + currentProgram.season_number : ''} ${
                                                          currentProgram.episode_number ? 'E' + currentProgram.episode_number : ''
                                                      } ${upcomingMovieRecordingTime()}`
                                                    : 'N/A'}
                                            </h3>
                                            <img
                                                className={css(styles.favEpisode, !isEpFavorite ? styles.favEpisodeHidden : null)}
                                                src={require('../../../assets/SC_SVGs/ic_favorite_filled.svg')}
                                            />
                                        </div>
                                        <div>
                                            <p className={css(styles.episodeDescription)}>
                                                {currentProgram && currentProgram.program_id ? currentProgram.description : 'no details available'}
                                            </p>
                                        </div>
                                    </div>
                                    {!this.state.isUpcomingSelected && episodeListData.length ? (
                                        <div className={css(styles.buttonsContainer)}>
                                            <button className={css(styles.button)} onClick={this.handlePlayStopButton}>
                                                {!isPlayerShowing ? 'Play' : 'Stop'}
                                            </button>
                                            <button
                                                className={css(
                                                    styles.button,
                                                    currentProgram.show_type !== 'SE' && currentProgram.show_type !== 'SM' ? styles.hideMyTicketButton : null
                                                )}
                                                onClick={() =>
                                                    (currentProgram.show_type === 'SE' || currentProgram.show_type === 'SM') &&
                                                    this.handleOptionsButton('SeriesRecording')
                                                }>
                                                Series Options
                                            </button>
                                            {!isEpFavorite ? (
                                                <button className={css(styles.button)} onClick={() => this.handleOptionsButton('Favorite')}>
                                                    Favorite
                                                </button>
                                            ) : (
                                                <button className={css(styles.button)} onClick={() => this.handleOptionsButton('Unfavorite')}>
                                                    Unfavorite
                                                </button>
                                            )}
                                            <button
                                                className={css(styles.button, styles.deleteButton)}
                                                onClick={() => this.handleOptionsButton('DeleteRecording')}>
                                                Delete Recording
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={css(styles.buttonsContainer)}></div>
                                    )}
                                </div>
                                <div className={css(styles.webPlayerContainer)}>
                                    {isPlayerShowing && <WebPlayer isRecordingStream={true} recording={currentProgram} />}
                                </div>
                            </div>
                        )
                    ) : (
                        <div className={css(styles.loadingIconContainer)}>
                            <LoadingAnimation svgStyles={styles.loading} />
                        </div>
                    )}
                    <AnimatePresence>
                        {isOptionsShowing && (
                            <div className={css(styles.optionsOverlay)} onClick={() => this.onOptionMyShowsClose()}>
                                <OptionsMyShowsContainer
                                    onOptionMyShowsClose={this.onOptionMyShowsClose}
                                    recording={currentProgram}
                                    isSeries={currentProgram.show_type === 'SE' || currentProgram.show_type === 'SM'}
                                    optionMenuState={optionMenuState}
                                    updateOptionMenuState={this.updateOptionMenuState}
                                    closePlayer={this.closePlayer}
                                />
                            </div>
                        )}
                    </AnimatePresence>
                </div>
            );
        }
    }
);

const styles = StyleSheet.create({
    mainContainer: {
        '@media screen and (min-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            fontSize: '1.2em',
        },
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignContent: 'stretch',
        minHeight: '100vh',
        alignItems: 'left',
        minWidth: '66em',
        fontWeight: '300', //this is the default for the guide. must be set on child to overwrite
        // width: '90vw',
        height: '100vh',
        // overflow: 'hidden',
        position: 'relative',
        // backgroundSize: '100% 100%',
    },
    fixedContainer: {
        width: '85em',
        // height: '40em',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '4em',
        marginRight: '4em',
        // paddingRight: "3em",
        paddingTop: '10vh',
        // padding: '5vh',
        // marginTop: '81px',
        justifyContent: 'space-around',
        userSelect: 'none',
        position: 'relative',
    },
    headerContainer: {
        // backgroundColor: "blue",
        '@media screen and (max-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            width: '1100px',
        },
        '@media screen and (min-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            width: '1100px',
        },
        '@media screen and (max-height: 800px)': {
            //if above 1600px and it expands relative to the width of the viewport
            height: '700px',
            marginTop: '35px',
        },
        '@media screen and (min-height: 800px)': {
            //if above 1600px and it expands relative to the width of the viewport
            height: '700px',
            // paddingTop: '30px'
        },
        minHeight: '80vh',
        width: '60vw',
        paddingTop: '5vh',
        marginRight: '2vw',
        // width: "60vw"
    },
    recordingsContainer: {
        minHeight: '30%',
        marginTop: '1em',
        // backgroundColor: "yellow"
    },
    optionsOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        backgroundColor: 'rgba(1,1,1,0.7)',
    },
    loadingIconContainer: {
        position: 'absolute',
        height: 'calc(100vh - 81px)',
        width: '100vw',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        height: '30vw',
        width: '30vw',
    },
    webPlayerContainer: {
        '@media screen and (max-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            width: '600px',
        },
        '@media screen and (min-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            width: '700px',
        },
        '@media screen and (min-height: 800px)': {
            //if above 1600px and it expands relative to the width of the viewport
            height: '350px',
        },
        '@media screen and (max-height: 800px)': {
            //if above 1600px and it expands relative to the width of the viewport
            height: '408px',
        },
        // position: 'absolute',
        // border: '1px solid red',
        // right: 0,
        // top: 0,
        marginTop: '8vh',
        height: '45vh',
        width: '50vw',
        // backgroundColor: "yellow"

        // marginRight: "0vw",
    },
    channelInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // backgroundColor: "blue"
    },
    channelLogo: {
        width: '10%',
        height: 'auto',
        // margin: 0,
    },
    channelName: {
        // fontFamily: 'Roboto',
        fontSize: '1.6em',
        fontWeight: '400',
        margin: 0,
        marginLeft: '1%',
    },
    programTitle: {
        fontWeight: '300',
        fontSize: '3em',
        display: 'inline-block',
        margin: 0,
        textAlign: 'initial',
        // width: "50%"
    },
    episodeTitle: {
        // alignSelf: 'baseline',
        // textAlign: 'initial',
        fontSize: '1.1em',
        margin: 0,
        marginBottom: '1%',
    },
    favEpisode: {
        // textAlign: 'initial',
        // fontSize: '1.1em',
        marginLeft: '10px',
        // marginBottom: '1%',
        height: '30px',
    },
    favEpisodeHidden: {
        opacity: 0,
    },
    episodeInfoInnerContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: '1%',
    },
    episodeInfoContainer: {
        height: '9em',
        minHeight: '9em',
        // whiteSpace: "pre-line",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    episodeDescription: {
        textAlign: 'initial',
        margin: 0,
        fontSize: '1em',
    },
    buttonsContainer: {
        height: '2em',
        minHeight: '30px',
        textAlign: 'initial',
    },
    upcomingButtonsContainer: {
        height: '5vh',
        textAlign: 'initial',
        marginBottom: '2%',
        marginLeft: '2%',
    },
    button: {
        ':focus': {
            outline: 'none',
        },
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(220,220,220)',
            color: '#000',
        },
        backgroundColor: 'transparent',
        color: '#fff',
        border: '1px solid #fff',
        padding: 0,
        appearance: 'none',
        display: 'inline',
        width: '15%',
        height: '100%',
        minHeight: '30px',
        fontWeight: '900',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    selectedButton: {
        ':focus': {
            outline: 'none',
        },
        backgroundColor: '#00B59C',
        color: '#000',
        border: '1px solid #fff',
        padding: 0,
        appearance: 'none',
        display: 'inline',
        width: '15%',
        height: '100%',
        minHeight: '30px',
        fontWeight: '900',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    hideMyTicketButton: {
        ':hover': {
            cursor: 'initial',
        },
        opacity: 0,
    },
    deleteButton: {
        ':hover': {
            backgroundColor: '#FF4060',
        },
        marginLeft: '20%',
    },
    playButton: {},
    optionsButton: {},
    backButtonContainer: {
        width: '100%',
        height: '10%',
        // marginLeft: '4em',
        // marginTop: '81px',
    },
    backButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            pointer: 'arrow',
        },
    },
    excludedDisclaimer: {
        color: 'rgba(0,131,159,255)',
        fontSize: '1.3em',
        fontWeight: 'bold',
        // marginTop: '0.2em',
        marginLeft: '0.3em',
        fontWeight: '300',
    },
    scheduledContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '.3em',
    },
});
