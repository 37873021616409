import React from 'react';
import { connect } from 'react-redux';

import { postRecording, updateRecording, deleteRecording, onFullScreenInfoOpen } from '../../../actions/guide';

import { toggleTicketScreen, toggleCancelTicketScreen } from '../../../actions/guide';
import SeasonMyTicket from '../SeasonMyTicket';

const SeasonMyTicketContainer = (props) => <SeasonMyTicket {...props} />;

const mapStateToProps = (state) => {
    // console.log("state from seasonMyTicket: ", state)
    return {
        alertEAS: state.main.alertEAS,
        isTicketScreenOpen: state.guide.isTicketScreenOpen,
        subscriberID: state.login.subscriberData.id,
        assetData: state.myshows.assetData,
        currentPage: state.main.currentPage,
        isPostingOrder: state.guide.isPostingOrder,
        orderData: state.myshows.orderData,
        isFullScreen: state.main.isFullScreen,
        isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
        connTimeOut: state.main.connTimeOut,
        authError: state.main.authError,
        generalError: state.main.generalError,
        keyMap: state.main.keyMap,
        allChannels: state.main.channels,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    toggleCancelTicketScreen: (isCancelTicketScreenOpen) => dispatch(toggleCancelTicketScreen(isCancelTicketScreenOpen)),
    postRecording: (order_type, schedule_id, subscriber_id, start_recording, stop_recording, start_from, channel, get_hd, record_state, is_start_from_year) =>
        dispatch(
            postRecording(
                order_type,
                schedule_id,
                subscriber_id,
                start_recording,
                stop_recording,
                start_from,
                channel,
                get_hd,
                record_state,
                is_start_from_year
            )
        ),
    updateRecording: (order_type, order_id, subscriber_id, start_recording, stop_recording, start_from, channel, get_hd, record_state, is_start_from_year) =>
        dispatch(
            updateRecording(order_type, order_id, subscriber_id, start_recording, stop_recording, start_from, channel, get_hd, record_state, is_start_from_year)
        ),
    deleteRecording: (type, id) => dispatch(deleteRecording(type, id)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonMyTicketContainer);
