import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SeasonMyTicket from '../../Guide/Containers/SeasonMyTicketContainer';
import { updateAssetFavorite } from '../../../services/AssetsService';
import { motion } from 'framer-motion';

export const Item = ({ text, isSelected, onSelection }) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                onSelection();
            }}
            className={css(styles.OptionItem)}>
            {text}
        </div>
    );
};

export default class OptionsMyShows extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            isMovie: false,
            isSeries: false,
            isConfirmationWindowOpen: false,
            deleteCancelHeader: '',
            favorite: false,
        };
    }

    componentDidMount = () => {
        // const { recording } = this.props;
        // console.log('mounted');
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.generalError && this.props.generalError) {
            this.props.isOptionsOpen && this.props.toggleOptionsMenu(false);
        }
    };

    componentWillUnmount() {
        //    console.log('unmounted');
    }

    favoriteConfirm(command) {
        if (this.props.recording.favorite !== this.state.favorite) {
            const assetDataCopy = [...this.props.assetData];
            const itemIndex = assetDataCopy.findIndex((asset) => {
                //cross-check asset data for item index
                return asset.schedule_id === this.props.recording.schedule_id;
            });
            if (itemIndex !== -1) {
                let asyncUpdateFailed = false;
                updateAssetFavorite(assetDataCopy[itemIndex].asset_id, this.state.favorite).catch((e) => {
                    // const message = e.error.response.data && e.error.response.data.message;
                    asyncUpdateFailed = true;
                    // this.props.onTriggerFavoriteError(true, message);
                    if (this.props.assetData[itemIndex].favorite && this.props.assetData[itemIndex].schedule_id === this.props.recording.schedule_id) {
                        const assetDataCopy = [...this.props.assetData];
                        assetDataCopy[itemIndex].favorite = false;
                        this.props.onUpdateAssetData(assetDataCopy);
                    }
                });

                assetDataCopy[itemIndex].favorite = this.state.favorite;
                !asyncUpdateFailed && this.props.onUpdateAssetData(assetDataCopy);
            }
        }
    }

    //determine which menu items to display
    getOptionItems() {
        const { recording, optionMenuState, onOptionMyShowsClose, updateOptionMenuState } = this.props;

        const items = [];

        //option menu state is defined in MyShowsSeriesView
        if (optionMenuState === 'DeleteRecording') {
            items.push(
                <Item
                    text={'Confirm Deletion'}
                    onSelection={() => {
                        const type = recording.type;
                        const id = type === 'series' ? recording.asset_id : recording.order_id;
                        this.props.deleteRecording(type, id);
                        onOptionMyShowsClose();
                        this.props.closePlayer();
                    }}
                    isSelected={this.state.selectedIndex === 0}
                    key={0}
                />
            );
            items.push(
                <Item
                    text={'Exit'}
                    //return to series view
                    onSelection={() => onOptionMyShowsClose()}
                    isSelected={this.state.selectedIndex === 1}
                    key={1}
                />
            );
        } else if (optionMenuState === 'SeriesRecording') {
            //state only available for series recordings
            if (recording.type === 'series') {
                items.push(
                    <Item
                        text={'Modify Series Options'}
                        onSelection={() => {
                            this.props.getChannels(recording.series_id, recording.source_id);
                            this.props.toggleTicketScreen(true); //case for series
                        }}
                        isSelected={this.state.selectedIndex === 0}
                        key={0}
                    />,
                    <Item
                        text={'Cancel Series Recording'}
                        onSelection={() => {
                            updateOptionMenuState('DeleteOrCancelConfirm');
                            this.setState({
                                isConfirmationWindowOpen: true,
                                deleteCancelHeader: 'Cancel Series Recording',
                            });
                        }}
                        isSelected={this.state.selectedIndex === 2}
                        key={2}
                    />,

                    <Item
                        text={'Delete All and Cancel Series'}
                        onSelection={() => {
                            updateOptionMenuState('DeleteOrCancelConfirm');
                            this.setState({
                                isConfirmationWindowOpen: true,
                                deleteCancelHeader: 'Delete All and Cancel Series',
                            });
                        }}
                        isSelected={this.state.selectedIndex === 3}
                        key={3}
                    />
                );
            } else {
                items.push(
                    <Item
                        text={'Create series recording'}
                        onSelection={() => {
                            this.props.getChannels(recording.series_id, recording.source_id);
                            this.props.toggleTicketScreen(true); //case for series
                        }}
                        isSelected={this.state.selectedIndex === 0}
                        key={0}
                    />
                );
            }
        } else if (optionMenuState === 'DeleteOrCancelConfirm') {
            let onSelection = () => {};
            let type = '';
            let id = '';
            switch (this.state.deleteCancelHeader) {
                case 'Delete All Recordings':
                    onSelection = () => {
                        type = 'single'; //this will add a parameter to the request that will preserve the order but delete the assets before the api call is made
                        id = this.props.recording.order_id;
                        this.props.deleteRecording(type, id);
                        onOptionMyShowsClose();
                    };
                    break;
                case 'Delete All and Cancel Series':
                    onSelection = () => {
                        type = 'single'; //by defining type as single, the entire order will be deleted
                        id = this.props.recording.order_id;
                        this.props.deleteRecording(type, id);
                        onOptionMyShowsClose();
                    };
                    break;
                case 'Cancel Series Recording':
                    onSelection = () => {
                        type = 'disableOrder'; //this will add a parameter to the request that will preserve the recordings, but disable order
                        id = this.props.recording.order_id;
                        this.props.deleteRecording(type, id);
                        onOptionMyShowsClose();
                    };
                    break;
                default:
                    onSelection = () => {
                        console.log('empty click');
                    };
                    break;
            }
            items.push(
                <Item text={'Confirm'} onSelection={onSelection} isSelected={this.state.selectedIndex === 0} key={0} />,
                <Item text={'Exit'} onSelection={() => onOptionMyShowsClose()} isSelected={this.state.selectedIndex === 1} key={1} />
            );
        } else if (optionMenuState === 'Favorite') {
            // console.log('recordings', recording);
            items.push(
                <Item
                    text={'Add'}
                    onSelection={() => {
                        this.setState(
                            () => {
                                return { favorite: true };
                            },
                            () => {
                                this.favoriteConfirm('Add');
                                onOptionMyShowsClose();
                                this.props.closePlayer();
                            }
                        );
                    }}
                    isSelected={this.state.selectedIndex === 0}
                    key={0}
                />
            );
            items.push(
                <Item
                    text={'Cancel'}
                    //return to series view
                    onSelection={() => onOptionMyShowsClose()}
                    isSelected={this.state.selectedIndex === 1}
                    key={1}
                />
            );
        } else if (optionMenuState === 'Unfavorite') {
            // console.log('unfavorite');
            items.push(
                <Item
                    text={'Remove'}
                    onSelection={() => {
                        this.setState(
                            () => {
                                return { favorite: false };
                            },
                            () => {
                                this.favoriteConfirm('Remove');
                                onOptionMyShowsClose();
                                this.props.closePlayer();
                            }
                        );
                    }}
                    isSelected={this.state.selectedIndex === 0}
                    key={0}
                />
            );
            items.push(
                <Item
                    text={'Cancel'}
                    //return to series view
                    onSelection={() => onOptionMyShowsClose()}
                    isSelected={this.state.selectedIndex === 1}
                    key={1}
                />
            );
        }
        return items;
    }

    render() {
        const { recording, isSeries, onOptionMyShowsClose } = this.props;
        const { deleteCancelHeader, isConfirmationWindowOpen } = this.state;
        // console.log(this.state);
        const optionItems = this.getOptionItems();
        return (
            <motion.div
                key="optionsMyShows"
                initial={{ y: '110%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                exit={{ y: '110%', opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={css(styles.motionContainer)}>
                {!this.props.isTicketScreenOpen ? (
                    <>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={css(styles.Container)}>
                            <FontAwesomeIcon onClick={this.props.onOptionMyShowsClose} className={css(styles.ExitButton)} icon="times" />
                            <div className={css(styles.Header)}>
                                <p className={css(styles.HeaderText)}>
                                    {!isConfirmationWindowOpen
                                        ? recording.long_title !== ''
                                            ? recording.long_title
                                            : 'Program Data Unavailable'
                                        : 'Are you sure?'}
                                </p>
                            </div>
                            <div className={css(styles.SubHeader)}>
                                <p className={css(styles.SubHeaderText)}>
                                    {!isConfirmationWindowOpen ? isSeries && recording.episode_name : deleteCancelHeader}
                                </p>
                            </div>
                            <div className={css(styles.Options)}>{optionItems}</div>
                        </div>
                    </>
                ) : (
                    this.props.isTicketScreenOpen && (
                        <SeasonMyTicket
                            currentPage={this.props.currentPage}
                            channelsList={this.props.channelsList}
                            selectedProgram={recording}
                            isRecordedSeries={recording.type === 'series'}
                            selectedAssetInfo={recording}
                            onOptionClose={() => onOptionMyShowsClose()}
                            onOptionMyShowsClose={onOptionMyShowsClose}
                            isCurrentlyMyShows={true}
                        />
                    )
                )}
            </motion.div>
        );
    }
}

const styles = StyleSheet.create({
    motionContainer: {
        zIndex: 3,
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    Container: {
        // width: '20em',
        minHeight: '8em',
        position: 'absolute',
        backgroundColor: '#202020',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        marginBottom: '3em',
        width: '17em',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    OptionItem: {
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        padding: '.6em',
    },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        marginLeft: '4em',
        width: '40em',
    },
    HeaderText: {
        fontSize: '1.3em',
        textAlign: 'initial',
        color: '#00CC99',
    },
    SubHeader: {
        marginBottom: '1.5em',
        marginLeft: '4em',
        width: '40em',
    },
    SubHeaderText: {
        fontSize: '1.1em',
        textAlign: 'initial',
        color: 'white',
    },
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
});
