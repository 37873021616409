import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ReactLoading from 'react-loading';
import NavBar from '../Common/Containers/NavBarContainer';
import RecordingToast from '../Guide/RecordingToast';

import { Switch, Route, Link, Redirect, withRouter } from 'react-router-dom';
import MyShowsSeriesView from './MyShowsSeriesView/MyShowsSeriesView';
import ImageHelper from '../Common/ImageHelper';

export default withRouter(
    class MyShows extends Component {
        constructor() {
            super();
            this.state = {
                uriSwitch: false,
                videoAssetURL: '',
                videoPaused: true,
                hasInitiallyUpdated: false,
                buffering: false,
                menuItems: [
                    { menuTitle: 'All' },
                    { menuTitle: 'TV Series' },
                    { menuTitle: 'Movies' },
                    { menuTitle: 'Sports' },
                    { menuTitle: 'Kids' },
                    { menuTitle: 'News' },
                    { menuTitle: 'Upcoming' },
                    { menuTitle: 'Favorites' },
                    { menuTitle: 'Expiring' },
                ],
                menuIndex: 0,
                yIndex: 0,
                showList: [],
                myShowsSeriesView: false,
                backgroundLoadedIn: false,
                backgroundUri: null,
                fadingOut: false,
                singleShowTypes: ['MO'],
                countFav: 0,
            };
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.props.isLoadingAssets === false && this.props.assetData.length > 0 && this.state.hasInitiallyUpdated === false) {
                this.setState({
                    hasInitiallyUpdated: true,
                });
            }

            if (!prevProps.isFullScreen && this.props.isFullScreen) {
                this._myShowsEventEmitter && this._myShowsEventEmitter.remove();
                this._myShowsEventEmitter = null;
            }
            if (prevProps.isFullScreen && !this.props.isFullScreen) {
                // if(!this._myShowsEventEmitter) {
                //     this._myShowsEventEmitter = DeviceEventEmitter.addListener('onKeyDown', this._listenerFunction)
                // };
            }
            if (this.state.menuItems[this.state.menuIndex].menuTitle === 'Favorites') {
                this._countFav();
            }
        }

        shouldComponentUpdate(nextProps) {
            // console.log('shouldComponentUpdate()')
            // if (this.props.isLoadingAssets === false && this.state.hasInitiallyUpdated === false) {
            //     this.filterListItems();
            // }
            if (this.state.yIndex > 0 && this.props.showList && this.props.showList !== nextProps.showList) {
                // compares an array of assets to check for any changes
                const curListIDs = Object.keys(this.props.showList).map((cur) => this.props.showList[cur].series_id);
                const nextListIDs = Object.keys(nextProps.showList).map((cur) => nextProps.showList[cur].series_id);
                if (JSON.stringify(curListIDs) !== JSON.stringify(nextListIDs)) {
                    // if there's a difference, the index value is adjusted to keep the selected show
                    const prevShow = this.props.showList[this.state.yIndex];
                    const resetIndex = nextProps.showList[prevShow];
                    this.setState({
                        yIndex: resetIndex > -1 ? resetIndex : this.state.yIndex - 1,
                    });
                }
            }
            return true;
        }

        componentDidMount() {
            this.props.onClearRestart();
            window.globalVideoRef && window.globalVideoRef.requestFullscreen();
            // console.log("my shows componentDidMount props: ", this.props)

            // bugsnag.leaveBreadcrumb("<MyShows /> DidMount", {
            //     type: 'navigation',
            //     component: "<MyShows />",
            //   })

            this.props.updateFilter(this.state.menuIndex);
            // console.log(this.props.showList)
        }

        _guideButtonHandler = (e) => {
            // console.log("guide handler:");
            this.guide();
        };

        guide = () => {
            this.props.toggleTicketScreen(false);
            this.props.toggleCancelTicketScreen(false);
            this.props.onChangePage('Guide');
            this.props.navigation.navigate('Guide');
        };

        onLoadFadeIn = () => {
            // const background = this.props.showList[this.state.yIndex ].backgroundImage || this.props.showList[this.state.yIndex ].cardImage
            // if (this.props.showList[this.state.yIndex].hasOwnProperty('backgroundImage') || this.props.showList[this.state.yIndex].hasOwnProperty('cardImage')) {
            //     //this.state.opacityIn.setValue(0);
            //     //Animated.timing(this.state.opacityIn).stop();
            //     this.setState({backgroundUri: background});
            //     this.state.opacityIn.setValue(0);
            //     // Animated.timing(this.state.opacityIn, {
            //     //     toValue: 1,
            //     //     duration: 500,
            //     //     //useNativeDriver: true
            //     // }).start(() => {
            //     //     this.setState({
            //     //         backgroundLoadedIn: true
            //     //     })
            //     // })
            // } else {
            //     this.setState({backgroundUri: null})
            // }
        };

        onLoadFadeOut = () => {
            // Animated.timing(this.state.opacityOut, {
            //     toValue: 0,
            //     duration: 500,
            //     //useNativeDriver: true
            // }).start(() => {
            //     this.setState({
            //         backgroundLoadedIn: false,
            //         fadingOut: false
            //     }, () => {
            //         this.onLoadFadeIn()
            //     })
            // })
        };

        componentWillUnmount() {
            this._myShowsEventEmitter && this._myShowsEventEmitter.remove();
            this._myShowsEventEmitter = null;
            this._onGuidePress && this._onGuidePress.remove();
            this._onGuidePress = null;
            this.props.onAllSENumsAvailable && this.props.onAllSENumsAvailable(false); //to reset it bakc in Redux
        }

        //animationEventDelay = () => {
        // this.setState({
        //     eventDelay: true
        // })
        // this.timer = setTimeout( () => {
        //     this.setState({
        //         eventDelay: false
        //     })
        // },200)
        //}

        _keyExtractor = (item, index) => index.toString();

        _renderItem = ({ item, index }) => (
            // <View style={[styles.selectedTextContainer, {width: this.props.screenProps.width }]}>
            //     <View style={[ styles.textContainer, { marginLeft: xScale(220), alignItems: 'center', flexDirection: 'row', marginRight: 'auto'}]}>
            //         <View >
            //             <Text style={styles.yListItems}>
            //                 {item.program_name}
            //             </Text>
            //         </View>
            //         {
            //             item.quantity > 1 ?
            //                 <View style={{ borderRadius: 3, backgroundColor: 'rgb(40, 40, 40)',top: yScale(2), height: yScale(20), alignItems: 'center', justifyContent: 'center', marginLeft: xScale(20), marginRight: 'auto' }}>
            //                     <Text style={{ color: '#cde4ec', fontSize: fontScale(16), paddingHorizontal: xScale(5) }}>{item.quantity}</Text>
            //                 </View>
            //             :

            //                 null
            //         }
            //     </View>
            // </View>
            <></>
        );

        handleBuffer = (meta) => {
            if (meta.isBuffering) {
                this.setState({ buffering: true });
            } else {
                this.setState({ buffering: false });
            }
        };

        setSeriesView = (i) => {
            this.setState({
                yIndex: i,
                myShowsSeriesView: true,
            });
        };

        closeSeriesView = (deletion) => {
            // in the case that this handler was triggered by deleting all recordings of a program, up button functionality will run so that index is not lost
            this.setState({ myShowsSeriesView: false });
            // if (deletion) {
            //     if (this.state.yIndex > 0) {
            //         //this.animationEventDelay()
            //         this.setState((prevState) => {
            //             return {
            //                 yIndex: prevState.yIndex - 1
            //             }
            //         })
            //         Animated.timing(this.state.offsetY, {
            //             toValue: this.state.yVal + yScale(55),
            //             duration: 200,
            //             useNativeDriver: true
            //         }).start();
            //     }
            //     this.onLoadFadeOut();
            // } else {
            // this.setState((prevState) => {
            //     return {
            //         yVal: prevState.yVal,
            //         yIndex: prevState.yIndex
            //     }
            // })
            // Animated.timing(this.state.offsetY, {
            //     toValue: this.state.yVal,
            //     duration: 0,
            //     useNativeDriver: true
            // }).start();
        };

        handlePickerClick = (e, pickerType) => {
            this.closeDropDowns();
            switch (pickerType) {
                case 'category':
                    this.setState({ isCategoryDropOpen: true });
                    break;
                case 'sort':
                    this.setState({ isSortDropOpen: true });
                    break;
                default:
                    break;
            }
            e.stopPropagation();
        };

        closeDropDowns = () => {
            this.setState({ isSortDropOpen: false, isCategoryDropOpen: false });
        };

        setFilter = (e, i, type) => {
            this.setState({ menuIndex: i });
            if (this.state.menuItems[i].menuTitle === 'Upcoming') {
                this.props.updateOnUpcomingMenu(true);
            }
            if (type === 'category') {
                this.props.updateFilter(i);
            } else {
                //do something when sort option is selected
            }
            this.closeDropDowns();
            e.stopPropagation();
        };

        _countFav = () => {
            if (this.props.globalShowList.length > 0) {
                let allFavorites = this.props.globalShowList.filter((x) => x.program_name && x.favorite);
                if (allFavorites.length !== this.state.countFav) {
                    this.setState({ countFav: allFavorites.length });
                }
                // console.log("array allFavorites", allFavorites)
            }
        };

        render() {
            // console.log('myshows props', this.props.showList);
            //const { currentTime, height, width, navigation } = this.props.screenProps;
            //const { buffering } = this.state;
            // console.log(this.props.location.pathname.split('/')[2]);
            // console.log(this.props.match);
            // const { isTicketScreenOpen, toggleTicketScreen } = this.props; //not used in this format
            // TODO: Clean up fetch blob code
            // let picDir = RNFetchBlob.fs.dirs.PictureDir;
            // let stringLit = `../../${picDir}/${this.props.assetData[0].schedule_id}BG`;
            // console.log(stringLit);

            // const background = (//this.state.yIndex > 1 && this.state.backgroundUri ? ///this.props.showList[this.state.yIndex].backgroundImage !== '' ?
            //     <View style={{flex: 1}}>
            //         <Animated.Image style={{opacity: this.state.fadingOut ? this.state.opacityOut : this.state.opacityIn, flex: 1}} source={{ uri: this.state.backgroundUri ? this.state.backgroundUri : null}} resizeMode='cover' />
            //         {/* {
            //              this.state.fadeOutUri &&
            //              <Animated.Image onLoad={this.onLoad} style={{opacity: this.state.opacityOut, flex: 1, zIndex:1}} source={{ uri: this.state.fadeOutUri}} resizeMode='cover' />
            //         } */}
            //     </View>
            // );
            // const sortOptions = ['Most recent', 'A-Z'];
            //console.log(this.props.showList);
            const showIndex = this.props.showList.findIndex((cur) => {
                return this.props.location.pathname.split('/')[2] === cur.program_name;
            });
            const isMyShowsMain = this.props.location.pathname === '/myshows';
            return (
                <>
                    <NavBar extraContainerStyles={!isMyShowsMain && styles.extraNavStyles} />
                    <div onClick={this.closeDropDowns} className={css(styles.MainContainer)}>
                        {this.props.isRecordingToastOpen && (
                            <RecordingToast
                                isPostingOrder={this.props.isPostingOrder}
                                toggleRecordingToast={this.props.toggleRecordingToast}
                                isUpdatingOrder={this.props.isUpdatingOrder}
                                isDeletingOrder={this.props.isDeletingOrder}
                                toastOrderMessage={this.props.toastOrderMessage}
                                isOrderRequestSuccessful={this.props.isOrderRequestSuccessful}
                                isOrderRequestCompleted={this.props.isOrderRequestCompleted}
                            />
                        )}
                        {
                            //!this.state.myShowsSeriesView &&
                            <Switch>
                                <Route exact path={'/myshows'}>
                                    {
                                        !this.props.lightweightVersion ? (
                                            <>
                                                {/* <div className={css(styles.CategoryName)}>{this.props.showList[0].program_name}</div> */}
                                                {/* <div className={css(styles.myshowsNavContainer)}> */}
                                                <div className={css(styles.categoryContainer)}>
                                                    {/* className={css(styles.DropContainer)} */}
                                                    {this.state.menuItems.map((cur, i, arr) => {
                                                        return (
                                                            <div className={css(styles.PickerItem)} key={i}>
                                                                <button
                                                                    className={css(
                                                                        this.state.menuIndex === i ? styles.selectedCategoryButton : styles.categoryButton
                                                                    )}
                                                                    onClick={(e) => this.setFilter(e, i, 'category')}>
                                                                    {cur.menuTitle}
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                <div className={css(styles.CardContainer)}>
                                                    {!this.props.isLoadingAssets ? (
                                                        this.props.showList.map((cur, i) => {
                                                            if (i !== 0) {
                                                                return (
                                                                    <Link
                                                                        to={`myshows/${cur.program_name}`}
                                                                        onClick={() => i !== 0 && this.setSeriesView(i)}
                                                                        style={{
                                                                            textDecoration: 'none',
                                                                            color: 'white',
                                                                        }}
                                                                        key={i}>
                                                                        <div
                                                                            className={css(styles.GridItem)}
                                                                            key={i + cur.program_name}
                                                                            onClick={() => i !== 0 && this.setSeriesView(i)}>
                                                                            {/* <div className={css(styles.GridItemOverlay)}> </div> */}
                                                                            {cur.quantity > 1 && (
                                                                                <div className={css(styles.QuantityBox)}>
                                                                                    <strong>{cur.quantity}</strong>
                                                                                </div>
                                                                            )}
                                                                            {cur.cardImage !== 'unavailable' ? (
                                                                                <ImageHelper
                                                                                    cardIndex={i}
                                                                                    alt_text={`image for ${cur.program_name}`}
                                                                                    image={cur.cardImage}
                                                                                    program_name={cur.program_name}
                                                                                />
                                                                            ) : (
                                                                                <p className={css(styles.GridItemText)}>{cur.program_name}</p>
                                                                            )}
                                                                        </div>
                                                                        <div className={css(styles.GridItemTitle)}>{cur.program_name}</div>
                                                                    </Link>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    ) : (
                                                        <>
                                                            {/* <li className={css(styles.CategoryName)}>Loading MyShows</li> */}
                                                            <ReactLoading
                                                                className={css(styles.LoadingContainer)}
                                                                type={'spinningBubbles'}
                                                                color={'#ffffff'}
                                                                height={'4em'}
                                                                width={'4em'}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                {/* </div> */}
                                            </>
                                        ) : (
                                            <Redirect to="/" />
                                        ) //extra prevention from user navigating to myshows without access
                                    }
                                </Route>
                                <Route path={`/myshows/:show`}>
                                    {showIndex > -1 ? (
                                        <MyShowsSeriesView
                                            navigation={this.props.navigation}
                                            globalShowList={this.props.globalShowList}
                                            isFullScreen={this.props.isFullScreen}
                                            assetData={this.props.assetData}
                                            selectedSeries={this.props.showList[showIndex] && this.props.showList[showIndex].program_name}
                                            bgImage={this.props.showList[showIndex] && this.props.showList[showIndex].backgroundImage}
                                            closeSeriesView={this.closeSeriesView}
                                            isPlayerShowing={this.props.isPlayerShowing}
                                            onFullscreenVideoToggle={this.props.onFullscreenVideoToggle}
                                            onSingleRecordingToggle={this.props.onSingleRecordingToggle}
                                            deleteRecording={this.props.deleteRecording}
                                            currentPage={this.props.currentPage}
                                            isTicketScreenOpen={this.props.isTicketScreenOpen}
                                            toggleTicketScreen={this.props.toggleTicketScreen}
                                            isCancelTicketScreenOpen={this.props.isCancelTicketScreenOpen}
                                            toggleCancelTicketScreen={this.props.toggleCancelTicketScreen}
                                            isLoadingAssets={this.props.isLoadingAssets}
                                            toggleSortBySeason={this.props.toggleSortBySeason}
                                            isSortBySeason={this.props.isSortBySeason}
                                            onStopLiveTV={this.props.onStopLiveTV}
                                            togglePlayingRecording={this.props.togglePlayingRecording}
                                            isPlayingRecording={this.props.isPlayingRecording}
                                            onAllSENumsAvailable={this.props.onAllSENumsAvailable}
                                            showList={this.props.showList}
                                            isOrderRequestSuccessful={this.props.isOrderRequestSuccessful}
                                            isDeletingOrder={this.props.isDeletingOrder}
                                            channelsList={this.props.channelsList}
                                            isSelectedRecordingOpen={this.props.isSelectedRecordingOpen}
                                            connTimeOut={this.props.connTimeOut}
                                            boxModel={this.props.boxModel}
                                            keyMap={this.props.keyMap}
                                            getChannels={this.props.getChannels}
                                            showType={this.props.showList[showIndex].show_type}
                                            upcomingData={this.props.upcomingData}
                                            onUpcomingMenu={this.props.onUpcomingMenu}
                                            updateOnUpcomingMenu={this.props.onUpcomingMenu}
                                            isUpcomingLoading={this.props.isUpcomingLoading}
                                            channelLogo={this.props.showList[showIndex].channel_logo}
                                            channelName={this.props.showList[showIndex].channel_name}
                                            channelNumber={this.props.showList[showIndex].channel_number}
                                        />
                                    ) : (
                                        <Redirect to="/myshows" />
                                    )}
                                </Route>
                            </Switch>
                        }
                    </div>
                </>
            );
        }
    }
);

const styles = StyleSheet.create({
    extraNavStyles: {
        backgroundColor: 'rgba(5,5,5,0)',
        position: 'absolute',
        borderStyle: 'none',
        width: '100%',
        zIndex: 2,
    },
    List: {
        listStyle: 'none',
        padding: '0',
    },
    CategoryName: {
        fontSize: '2em',
        marginTop: '1em',
        // fontSize: '32px',
        // marginTop: '30px'
    },
    SeriesTitle: {
        fontSize: '1em',
        margin: '.2em',
        padding: '.4em',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#303030',
            borderRadius: '.5em',
        },
        //textAlign: 'left',
        //   backgroundColor: '#282c34',
        //   minHeight: '100vh',
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   fontSize: 'calc(12px + 2vmin)',
        //   color: 'white',
    },
    MainContainer: {
        // textAlign: 'center',
        // backgroundColor: 'black',
        backgroundColor: '#050505',
        minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        // alignItems: 'center',
        // alignContent: 'center',
        //justifyContent: 'center',
        //fontSize: 'calc(12px + 2vmin)',
        color: 'white',
        width: '100%',
    },
    CardContainer: {
        marginTop: '5em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'cyan',
        // textAlign: 'center',
        // backgroundColor: '#404040',
        marginLeft: '3em',
        marginRight: '1em',
        display: 'grid',
        width: '95%',
        alignItems: 'flex-start',
        justifyItems: 'center',
        justifyContent: 'center',
        // marginTop: '2em'
        //minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder

        //gridTemplateColumns: '100px 100px 100px',
        gridTemplateColumns: 'repeat(auto-fit, 20em)',
        // gridGap: '1em',
        // gridColumn: 1,
        gridColumnGap: '1em',
        gridRowGap: '4em',
    },
    QuantityBox: {
        width: '1em',
        height: '1em',
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        top: '.7em',
        right: '.7em',
        borderRadius: '.5em',
        justifyContent: 'center',
        alignContent: 'center',
        //paddingTop:'.4em',
        padding: '.5em 1.5em .5em 1.5em ',
        zIndex: 10,
        pointerEvents: 'none',
        fontSize: 10,
    },
    GridItem: {
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'yellow',
        // width: '5em',
        // height: '3em',
        overflow: 'hidden',
        display: 'flex',
        //width: '100%',
        height: '10em',
        //margin: '10px',
        width: '20em',
        position: 'relative',
        // ':hover' : {
        //     height: '110%',
        //     width: '110%',
        //     zIndex: 10
        // },
        /* Horizontal and Vertical centering */
        justifyContent: 'center',
        alignItems: 'center',
        //border: '2px solid #D8D8D8',
        // borderRadius: '.5em',
        backgroundColor: '#1c57b5',
        marginBottom: 10,
    },
    GridItemText: {
        padding: '1em',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '500',
        alignContent: 'center',
        transformOrigin: 'center center',
        overflow: 'hidden', //following 3 lines is equivalent of numberOfLines={1}
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        transition: 'transform .25s', // filter .5s ease-in-out',
        ':hover': {
            transform: 'scale(1.2)',
        },
    },
    GridItemTitle: {
        // position: 'absolute',
        bottom: '1em',
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontSize: '17px',
        // zIndex: 10,
        pointerEvents: 'none',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'yellow',
        // display: 'flex'
    },
    // GridItemOverlay: {
    //     zindex: 8,
    //     borderRadius: '.5em',
    //     flex: 1,
    //     position: 'absolute',
    //     width: '19em',
    //     height: '14.25em',
    //     backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0), rgba(255,0,0,0), rgba(255,0,0,0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
    //     pointerEvents: 'none',
    //     zIndex: 9,
    //     marginBottom: 20
    // },
    PickerContainer: {},
    Picker: {
        display: 'flex',
        //border: '2px solid #D8D8D8',
        //margin: '10px',
        //borderRadius: '',
        padding: '.2em',
        position: 'relative',
    },
    DropContainer: {
        borderRadius: '.2em',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '12em',
        //height: '500px',
        position: 'absolute',
        top: '1.3em',
        //border: '2px solid yellow',
        backgroundColor: '#303030',
        display: 'flex',
        zIndex: 11,
    },
    PickerItem: {
        margin: '.3em',
        padding: '.3em',
        // borderBottom: '2px solid #D8D8D8'
        width: '5em',
    },
    categoryContainer: {
        display: 'inline-flex',
        // alignSelf: "flex-start",
        left: '65px',
        position: 'absolute',
        flexDirection: 'row',
        paddingBottom: '10px',
        paddingTop: '10px',
    },
    categoryButton: {
        backgroundColor: '#050505',
        color: '#fff',
        fontFamily: 'Roboto',
        fontSize: '17px',
        width: '6em',
        padding: '.5em',
        boxShadow: 'none',
        borderWidth: 0,
        borderColor: 'none',
        ':focus': {
            outline: 'none',
            borderColor: '#fff',
            borderWidth: '2px',
            borderRadius: '5em',
            borderStyle: 'solid',
        },
    },
    selectedCategoryButton: {
        backgroundColor: '#050505',
        color: '#fff',
        fontFamily: 'Roboto',
        fontSize: '17px',
        width: '6em',
        padding: '.5em',
        boxShadow: 'none',
        outline: 'none',
        borderColor: '#fff',
        borderWidth: '2px',
        borderRadius: '5em',
        borderStyle: 'solid',
    },
    sortContainer: {
        display: 'inline-flex',
        alignSelf: 'flex-end',
        position: 'relative',
        right: '100px',
        flexDirection: 'row',
        paddingBottom: '10px',
        paddingTop: '10px',
    },
    sortButton: {
        backgroundColor: '#050505',
        color: '#fff',
        fontFamily: 'Roboto',
        fontSize: '15px',
        width: '6em',
        padding: '.5em',
        boxShadow: 'none',
        borderWidth: 0,
        borderColor: 'none',
        ':focus': {
            outline: 'none',
            borderColor: '#fff',
            borderWidth: '2px',
            borderRadius: '5em',
            borderStyle: 'solid',
        },
    },
    PickerBottomDiv: {
        height: '.1em',
        backgroundColor: '#a2a2a2',
        width: '100%',
        //position: 'absolute'
    },
    CaretDown: {
        marginLeft: '.3em',
        marginTop: '.3em',
    },
    LoadingContainer: {
        margin: '1em',
        alignSelf: 'center',
        marginLeft: 'calc(50% - 2em)',
    },
});

//sort options
// {
/* <div className={css(styles.sortContainer)}>
                                        
{
    sortOptions.map((cur,i,arr) => {
        return (
            <>
                <div className={css(styles.PickerItem)} ><button className={css(styles.sortButton)} onClick={(e) => this.setFilter(e, i, 'sort')}>{cur}</button></div>
                {/* { i !== arr.length-1 && <div className={css(styles.PickerBottomDiv)} ></div> } */
// }
// </>
// )
// })
//}
//</div> */}
