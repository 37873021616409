import React from 'react';
import { connect } from 'react-redux';

import {
    toggleRecordingScreen,
    toggleTicketScreen,
    getChannels,
    toggleOptionsMenu,
    startRestartProgram,
    clearRestart,
    setPendingRestartProgram,
    toggleCancelTicketScreen,
} from '../../../actions/guide';
import { changeChannel, toggleFullScreen, stopLiveTV } from '../../../actions/main';

import Options from '../OptionsContainer';

const OptionsMenuContainer = (props) => (
    <Options
        lightweightVersion={props.lightweightVersion}
        toggleRecordingScreen={props.toggleRecordingScreen}
        toggleTicketScreen={props.toggleTicketScreen}
        channelInfo={props.channelInfo}
        programIndex={props.programIndex}
        isRecordingScreenOpen={props.isRecordingScreenOpen}
        isTicketScreenOpen={props.isTicketScreenOpen}
        header={props.header}
        assetData={props.assetData}
        orderData={props.orderData}
        selectedProgram={props.selectedProgram}
        channelsList={props.channelsList}
        isLoadingChannels={props.isLoadingChannels}
        onChangeChannel={props.onChangeChannel}
        isFullScreen={props.isFullScreen}
        onToggleFullScreen={props.onToggleFullScreen}
        currentPageData={props.currentPageData}
        activeRowIndex={props.activeRowIndex}
        onStopLiveTV={props.onStopLiveTV}
        isRecordButton={props.isRecordButton}
        onOptionClose={props.onOptionClose}
        channels={props.channels}
        isMovie={props.isMovie}
        resetGuideDataToLivePrograms={props.resetGuideDataToLivePrograms}
        connTimeOut={props.connTimeOut}
        keyMap={props.keyMap}
        getChannels={props.getChannels}
        generalError={props.generalError}
        isOptionsOpen={props.isOptionsOpen}
        toggleOptionsMenu={props.toggleOptionsMenu}
        eventListWithCatchup={props.eventListWithCatchup}
        onStartRestartProgram={props.onStartRestartProgram}
        restartProgram={props.restartProgram}
        restartProgramInProgress={props.restartProgramInProgress}
        onClearRestart={props.onClearRestart}
        pageData={props.pageData}
        previewProgramIndex={props.previewProgramIndex}
        previewChannelIndex={props.previewChannelIndex}
        currentTime={props.currentTime}
        isDeviceLimitReached={props.isDeviceLimitReached}
        onSetPendingRestartProgram={props.onSetPendingRestartProgram}
        isCancelTicketScreenOpen={props.isCancelTicketScreenOpen}
        toggleCancelTicketScreen={props.toggleCancelTicketScreen}
        resetGrid={props.resetGrid}
    />
);

const mapStateToProps = (state) => ({
    restartProgramInProgress: state.guide.restartProgramInProgress,
    restartProgram: state.guide.restartProgram,
    eventListWithCatchup: state.guide.eventListWithCatchup,
    lightweightVersion: state.main.lightweightVersion,
    keyMap: state.main.keyMap,
    isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
    isTicketScreenOpen: state.guide.isTicketScreenOpen,
    assetData: state.myshows.assetData,
    orderData: state.myshows.orderData,
    isFullScreen: state.main.isFullScreen,
    currentPageData: state.guide.currentPageData,
    activeRowIndex: state.guide.activeRowIndex,
    channels: state.main.channels,
    connTimeOut: state.main.connTimeOut,
    generalError: state.main.generalError,
    isOptionsOpen: state.guide.isOptionsOpen,
    pageData: state.guide.pageData,
    previewProgramIndex: state.guide.previewProgramIndex,
    previewChannelIndex: state.guide.previewChannelIndex,
    isDeviceLimitReached: state.main.isDeviceLimitReached,
    isCancelTicketScreenOpen: state.guide.isCancelTicketScreenOpen,
});

export const mapDispatchToProps = (dispatch) => ({
    onClearRestart: () => dispatch(clearRestart()),
    onSetPendingRestartProgram: (program, scAssetId, channel) => dispatch(setPendingRestartProgram(program, scAssetId, channel)),
    onStartRestartProgram: (program, scAssetId, channel) => dispatch(startRestartProgram(program, scAssetId, channel)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    onToggleFullScreen: (isFullScreen) => dispatch(toggleFullScreen(isFullScreen)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    onStopLiveTV: () => dispatch(stopLiveTV()),
    getChannels: (series_id, source_id) => dispatch(getChannels(series_id, source_id)),
    toggleOptionsMenu: (isOptionsOpen) => dispatch(toggleOptionsMenu(isOptionsOpen)),
    toggleCancelTicketScreen: (cancelTicketScreen) => dispatch(toggleCancelTicketScreen(cancelTicketScreen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsMenuContainer);
