import { bugSnagNotifier } from '../services/BugSnagService';

export const errorHandler = (error, sentFrom, subDeviceInfo, streamDetails) => {
    if (error.hasOwnProperty('requestCopy') || error.hasOwnProperty('body')) {
        //API reques error
        bugSnagNotifier(error, sentFrom, subDeviceInfo, streamDetails, error.retryCount, error.requestCopy, error.body);
        if (error.error?.response?.data?.message && error.requestCopy.baseURL.includes('https://sms')) {
            //second part of this condition can be removed if all error messages become uniform from backend. for now its just sms.
            //no need to call errorFormatter, sms error messages designed to be user facing from backend
            error.message = error.error.response.data.message; //for consistency with other error objects being returned
            return error;
        } else {
            //still using error formatter if there's no message from backend
            let err = errorFormatter(error.error, sentFrom, error);
            return err;
        }
    } else {
        //general error
        let err = errorFormatter(error, sentFrom);
        bugSnagNotifier(error, sentFrom, subDeviceInfo, streamDetails);
        return err;
    }
};

const errorFormatter = (error, sentFrom, fullError) => {
    let message = '';
    let status = null;
    let re = /\d+/;
    let re2 = /Network Error/;
    let re3 = /timeout/;
    let re4 = /pusherChannel/;
    let userErrorCode = ''; //five digit error code:
    let envPrefix = '';
    let sentFromCode = '';
    let statusCode = '';
    const genericMessage = 'Oops! Something went wrong';

    if (fullError && fullError.requestCopy && fullError.requestCopy.baseURL) {
        const url = fullError.requestCopy.baseURL;
        if (url.includes('lab')) {
            envPrefix = 'L';
        } else if (url.includes('aureon')) {
            envPrefix = 'A';
        } else if (url.includes('discovery') || url.includes('epic-mha')) {
            envPrefix = 'S';
        }
    } else if (error.videoErrorType) {
        envPrefix = 'V';
    } else if (error.youboraMessage) {
        envPrefix = 'Y';
    } else {
        envPrefix = 'N';
    }

    if (sentFrom) {
        if (sentFrom === 'postRecording') {
            //order/asset creation error
            sentFromCode = '01';
        } else if (sentFrom === 'updateRecording') {
            //order/asset update error
            sentFromCode = '02';
        } else if (sentFrom === 'deleteRecording') {
            //order/asset deletion error
            sentFromCode = '03';
        } else if (sentFrom === 'getChannels from guide.js') {
            //get available channels error
            sentFromCode = '04';
        } else if (sentFrom === 'onLoginDataReady') {
            sentFromCode = '05';
        } else if (sentFrom === 'getPrograms from main.js') {
            sentFromCode = '06';
        } else if (sentFrom === 'loadChannels from main.js') {
            sentFromCode = '07';
        } else if (sentFrom === 'onLogout') {
            sentFromCode = '08';
        } else if (sentFrom === 'getAssets inner .catch') {
            sentFromCode = '09';
        } else if (sentFrom === 'getAssets after else') {
            sentFromCode = '10';
        } else if (sentFrom === 'getAssets outer .catch') {
            sentFromCode = '11';
        } else if (sentFrom === 'SC completed recordings call failure') {
            sentFromCode = '12';
        } else if (sentFrom === 'get sotal JSON failed on login') {
            sentFromCode = '13';
        } else if (sentFrom === 'Restart') {
            sentFromCode = '14';
        } else if (sentFrom === 'Video Player') {
            sentFromCode = '15';
        } else if (sentFrom === 'get restart live event list') {
            sentFromCode = '16';
        } else if (sentFrom === 'completedRecordingsFilter from myshows.js') {
            sentFromCode = '17';
        } else if (sentFrom === 'loginSequence') {
            sentFromCode = '18';
        } else if (sentFrom === 'OrderCreateJobFailed') {
            sentFromCode = '19';
        } else if (sentFrom === 'Youbora') {
            sentFromCode = '20';
        } else if (sentFrom === 'noOfferings') {
            message = 'No Offerings';
            sentFromCode = '21';
        } else if (sentFrom === 'pause-live') {
            sentFromCode = '22';
        } else if (sentFrom === 'get sotal live stream') {
            sentFromCode = '23';
        }
    } else {
        sentFromCode = '00';
    }

    if (re.test(error) && !re2.test(error) && !re3.test(error) && !re4.test(error)) {
        //if numbers detected in the error, we can retrieve error status
        status = `${error}`.match(re)[0]; //takes error message and  retrieves status code
        switch (status) {
            case '400':
                message = genericMessage; //"Request incorrect or corrupted. Try again or contact info@epicvideo.tech for further assistance.";
                statusCode = '01';
                break;
            case '401':
                message = 'Unauthorized. Check credentials and try again or contact info@epicvideo.tech for further assistance.';
                statusCode = '02';
                break;
            case '403':
                message = 'Invalid username or password. Check credentials and try again'; //"Forbidden, these were not the correct credentials";
                statusCode = '03';
                break;
            case '404':
                message = genericMessage; //"Resource not found. Contact info@epicvideo.tech for further assistance.";
                statusCode = '04';
                break;
            case '405':
                message = genericMessage; //"Request can not be completed!"
                statusCode = '05';
                break;
            case '409':
                message = 'The order has already been created';
                statusCode = '06';
                break;
            case '422':
                message = 'Invalid username or password. Check credentials and try again'; //"Login credentials are incorrect, please check and try again. \nIf not successful, contact info@epicvideo.tech for further assistance."
                statusCode = '07';
                break;
            case '500': //Internal server error
                message = genericMessage; //"We experience technical difficulties. Try again later.";
                statusCode = '08';
                break;
            case '502': //Bad Gateway error is an HTTP status code that means that one server on the internet received an invalid response from another server
                message = genericMessage;
                statusCode = '09';
                break;
            case '504':
                message = genericMessage; //"Service Unavailable. Request timed out at response. Try again or contact info@epicvideo.tech for further assistance.";
                statusCode = '10';
                break;
            default:
                message = genericMessage; //`Error with status code, ${status}`;
                statusCode = '11';
                break;
        }
    } else if (re2.test(error)) {
        status = 'nError';
        message = 'Network Error Occured. \nPlease check access to the internet or contact info@epicvideo.tech for further assistance.';
        statusCode = '12';
    } else if (re3.test(error)) {
        //manual timeout
        status = 'timeout';
        message = genericMessage; //"Request timed out. Please check access to the Internet Or contact info@epicvideo.tech for further assistance.";
        statusCode = '13';
    } else if (re4.test(error)) {
        status = 'pusher';
        message = genericMessage; //"Pusher channel setup failure. Please check access to the Internet Or contact info@epicvideo.tech for further assistance.";
        statusCode = '14';
    } else if (error && error.status && error.status === 'postOrder') {
        status = error.status;
        message = genericMessage; //error.message;
        statusCode = '15';
    } else if (typeof error === 'string' && error.includes('Invalid Device Type')) {
        //only look into it when no error message
        status = 'error';
        message = `Request failed - ${error}. \nTry again on device you have registered or contact info@epicvideo.tech for further assistance.`;
        statusCode = '16';
    } else if (typeof error === 'string' && (error.includes('SN error') || error.includes('MAC error') || error.includes('DEVICE error'))) {
        status = 'error';
        message = `${error}. \nTry again on device you have registered or contact info@epicvideo.tech for further assistance.`;
        statusCode = '18';
    } else if (error.videoErrorType) {
        status = 'error';
        switch (
            error.videoErrorType //Video Errors TODO: Update status codes as errors come in (probably with regexes)
        ) {
            //create status code & error messages for bugsnag
            case 'source':
                message = error.message ? `Source Error - ${error.message}` : 'Video Source Error';
                statusCode = '17';
                break;
            case 'renderer':
                message = error.message ? `Renderer Error - ${error.message}` : 'Video Renderer Error';
                statusCode = '18';
                break;
            case 'unexpected':
                message = error.message ? `Unexpected Error - ${error.message}` : 'Video Unexpected Error';
                statusCode = '19';
                break;
            case 'outOfMemory':
                message = error.message ? `Out Of Memory Error - ${error.message}` : 'Low Memory Video Error';
                statusCode = '20';
                break;
            default:
                return;
        }
    } else if (error.youboraMessage) {
        status = 'error';
        message = error.youboraMessage;
    } else if (error.type === 'sotalCloudAssetUpdate') {
        message = error.programName ? `Unsuccessful recording of "${error.programName}"` : 'There was a problem with a recording.'; //should have the program name..
        if (error.size > 1) {
            message += `\n${error.size - 1} other recording(s) also failed.`;
        }
        statusCode = '30';
    } else {
        status = 'error';
        message = message !== '' ? message : genericMessage; //error.message ? error.message + ". We are experiencing technical difficulties. Try again or contact info@epicvideo.tech for further assistance." : "We are experiencing technical difficulties. Please check access to the Internet Or contact info@epicvideo.tech for further assistance.";
        statusCode = '00';
    }

    userErrorCode = `${envPrefix}${sentFromCode}${statusCode}`;
    if (error.message === 'Invalid Platform') {
        message = error.message;
    } else {
        message = message + '\n' + userErrorCode;
    }

    return {
        status,
        message,
        userErrorCode,
    };
    // return Promise.reject("Network Error Occured");
};

//this is an error object from responses, because we need all that info
export const makingErrorObject = (error, requestCopy, retryCount, body) => {
    return {
        error,
        requestCopy,
        retryCount,
        body,
    };
};
