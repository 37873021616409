import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
// import { createSwitchNavigator, createAppContainer } from 'react-navigation';
// import AppContainer from './AppContainer';
// import LoginContainer from './components/Login/Containers/LoginPageContainer';
// import DvrControl from './components/DvrControl/Containers/DvrControlContainer';
//import AppMain from './AppMain'
import WebNavigatorContainer from './WebNavigatorContainer';

// const AppNavigator = createAppContainer(createSwitchNavigator(
//   {
//     Login: {
//       screen: LoginContainer
//     },
//     MainApp: {
//       screen: ({ navigation }) => <AppContainer screenProps = {{ rootNavigation: navigation }} />//we have to pass screenProps from AppNavigator to MainNavigator so we can go back to Login screen if needed
//     },
//     DvrControl: {
//       screen: DvrControl
//     }

//   },
//   {
//     initialRouteName: 'Login'
//   }
// ));

export default class App extends Component {
    constructor(props) {
        super(props);

        //overwrite all console logs in production
        if (process.env.NODE_ENV === 'production') {
            function no_console() {
                //do nothing
            }
            console.log = no_console;
            console.error = no_console;
            console.debug = no_console;
            console.info = no_console;
            console.warn = no_console;
        }
    }

    render() {
        //webNavigator is being used in place of our RN version's AppNavigator. WebNavigator will either return LoginContainer or AppMain. Routing is handled within AppMain to replace MainNavigator
        return (
            <Provider store={store}>
                {/* <MainNavigator /> */}
                <WebNavigatorContainer />
            </Provider>
        );
    }
}
