import React, { Component } from 'react';
//import { Dimensions, StyleSheet, Text, View, Image, FlatList, Animated, DeviceEventEmitter, Easing } from 'react-native';
//import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';
import ScheduleRow from './ScheduleRow';
import ScheduleTimesAndDates from './ScheduleTimesAndDates';
import NavUtility from './NavUtility';
import { getPageData } from '../../utils/getPageData';
import { FSProgramIndex } from '../../utils/FSProgramIndex';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
import { closestChNumber, numberPad } from '../../utils/ClosestChNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleSheet, css } from 'aphrodite';

export default class Schedule extends Component {
    constructor(props) {
        super();
        this.state = {
            guideStartTime: props.currentTime.clone().subtract(props.currentTime.minute() % 30, 'minutes'),
            //guideTranslateOut: new Animated.ValueXY({ x: 0, y: 0}),
            pressedRight: false,
            // heightAnimation: new Animated.Value(360),
            x: '',
            y: '',
            width: '',
            height: '',
            navUtilityOpen: false,
            tempChannelHolder: '',
            lastChannel: {},
        };
        this.tempString = '';
        this.keyInputInterval = null;
    }

    setChannel = (keyCode) => {
        console.log('inside set channel???');
        //need to connect this
        if (this.tempString.length < 5) {
            this.keyInputInterval && clearInterval(this.keyInputInterval);
            this.tempString += numberPad[keyCode];
            this.setState(
                {
                    channelNumber: parseInt(this.tempString),
                    tempChannelHolder: this.tempString,
                },
                () => {
                    this.keyInputInterval = setInterval(() => {
                        clearInterval(this.keyInputInterval);
                        this.tempString = ''; //clearing temp string for another number
                        //the following logic is to determing our channel
                        if (this.state.tempChannelHolder) {
                            this.channelSelectorHelper(); //finalized channel
                        }
                    }, 3000); //after 2 seconds it has to find the program
                }
            );
        }
    };

    channelSelectorHelper = () => {
        let ourChannel;
        let tempChannelHolder = parseInt(this.state.tempChannelHolder);
        console.log('inside channelSelectorHelper', tempChannelHolder);
        if (this.props.channelList.includes(tempChannelHolder.toString())) {
            ourChannel = tempChannelHolder;
        } else if (
            tempChannelHolder > parseInt(this.props.channelList[0]) &&
            tempChannelHolder < parseInt(this.props.channelList[this.props.channelList.length - 1])
        ) {
            ourChannel = closestChNumber(this.props.channelList, tempChannelHolder);
        } else if (tempChannelHolder < parseInt(this.props.channelList[0])) {
            ourChannel = this.props.channelList[0];
        } else if (tempChannelHolder > parseInt(this.props.channelList[this.props.channelList.length - 1])) {
            ourChannel = this.props.channelList[this.props.channelList.length - 1];
        }
        //now let's handle the channelChange
        this.setState({ channelNumber: +ourChannel }, () => {
            this.logicToSelectChannel(+ourChannel);
        });
        return ourChannel;
    };

    logicToSelectChannel = (selectedChannel) => {
        //logic to change station by channel number
        if (selectedChannel) {
            let startIndex = this.props.channelList.indexOf(selectedChannel.toString());
            this.setState(
                {
                    tempChannelHolder: '',
                },
                () => {
                    const momentObj = moment();
                    const newStartTime = momentObj
                        .subtract(momentObj.minute() % 30, 'minutes')
                        .subtract(momentObj.second(), 'seconds')
                        .unix();
                    let pd = getPageData(
                        startIndex,
                        this.props.gridRows,
                        this.props.allChannels,
                        newStartTime,
                        this.props.eventListWithCatchup,
                        this.props.assetDataProgramIDLookup,
                        this.props.orderData,
                        0
                    );
                    const gridVals = {
                        pageData: pd,
                        previewProgramStartTime: pd[0].programs[0].unix_start_time,
                        pageStartTime: newStartTime,
                        horizontalPageIndex: 0,
                        previewProgramIndex: 0,
                        previewChannelIndex: 0,
                        pageStartingIndex: startIndex,
                    };
                    this.props.onGridNavigate('init', gridVals);
                }
            );
        }
    };

    //_keyExtractor = (item, index) => index.toString();

    componentDidMount() {
        //for now we'll just reset here
        this.props.onClearRestart();

        document.addEventListener('keyup', this._handleKeyPress);
        document.addEventListener('wheel', this._handleWheelScroll);
        this.setState({ lastChannel: this.props.currentChannel });
        //this.props.resetGuideDataToLivePrograms();
        // this._scheduleEventEmitter = DeviceEventEmitter.addListener('onKeyDown', this._listenerFunction);
        // this.onGuidePress = DeviceEventEmitter.addListener('onGuidePress', this._guideButtonHandler);

        this.props.resetGrid();
    }

    //////////////////////////////////////////////////////////////////////new stuff

    // resetGrid used to be here, moved it up to Guide.js

    //////////////////////////////////////////////////////////////////////new stuff end

    // changeDay = (isLeft) => {
    //     // console.log(isLeft);
    //     const { startTime, hPage } = this.get24HourTransitionTimes(isLeft);
    //     const horizontalPageIndex = this.props.horizontalPage + hPage;

    //     if(hPage !== 0)//Paging doesn't change here (reached last page)
    //         this.pageLeftRightTransition(startTime, startTime + 7200, horizontalPageIndex, isLeft, true);
    // }

    // get24HourTransitionTimes = (isLeft) => {
    //     const TWO_HOURS_IN_SEC = 7200;
    //     const TWENTY_FOUR_HOURS_IN_SEC = 86400;

    //     let startTime = 0;
    //     let hPage = 0;

    //     if(isLeft){
    //         startTime = this.props.channelsStartTime - TWENTY_FOUR_HOURS_IN_SEC;
    //         if(startTime < this.props.guideEndPagesTimes.guideFirstPageTime){
    //             startTime = this.props.guideEndPagesTimes.guideFirstPageTime;
    //         }

    //         hPage = (startTime - this.props.channelsStartTime) / TWO_HOURS_IN_SEC;
    //     }else{
    //         startTime = this.props.channelsStartTime + TWENTY_FOUR_HOURS_IN_SEC;
    //         if(startTime > this.props.guideEndPagesTimes.guideLastPageTime){
    //             startTime = this.props.guideEndPagesTimes.guideLastPageTime;
    //         }

    //         hPage = (startTime - this.props.channelsStartTime) / TWO_HOURS_IN_SEC;
    //     }

    //     return {
    //         startTime,
    //         hPage
    //     }

    // }

    pageLeft = (isFullPageOver) => {
        if (this.props.horizontalPageIndex > -36) {
            const newStartTime = this.props.pageStartTime - 7200;
            const horizontalPageIndex = this.props.horizontalPageIndex - 1;
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                newStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                horizontalPageIndex
            );
            const endIndex = isFullPageOver ? 0 : this.closestTimeIndex(this.props.pageStartTime * 1000 - 0.1, pd[this.props.previewChannelIndex].programs);
            //const lastProgramIndex = pd[this.props.previewChannelIndex].programs.length -1;
            const gridVals = {
                //gridvals are data needed to get new values. only current values. new values are determined in action
                tempAnimationData: this.props.pageData,
                pageData: pd,
                previewProgramIndex: endIndex,
                previewProgramStartTime: pd[this.props.previewChannelIndex].programs[endIndex].unix_start_time,
                pageStartTime: newStartTime,
                horizontalPageIndex: horizontalPageIndex,
            };
            this.props.onGridNavigate('leftPage', gridVals);
        }
    };
    pageRight = () => {
        const newStartTime = this.props.pageStartTime + 7200;
        if (this.props.horizontalPageIndex < 115 && newStartTime < this.props.guideEndPagesTimes.guideLastPageTime) {
            const horizontalPageIndex = this.props.horizontalPageIndex + 1;
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                newStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                horizontalPageIndex
            );
            const gridVals = {
                //gridvals are data needed to get new values. only current values. new values are determined in action
                tempAnimationData: this.props.pageData,
                pageData: pd,
                previewProgramIndex: 0,
                previewProgramStartTime: pd[this.props.previewChannelIndex].programs[0].unix_start_time,
                pageStartTime: newStartTime,
                horizontalPageIndex: horizontalPageIndex,
            };
            this.props.onGridNavigate('rightPage', gridVals);
        }
    };

    pageUp = (isFullPageOver) => {
        let newIndex = this.props.pageStartingIndex - this.props.gridRows;
        if (newIndex < 0) {
            newIndex = this.props.allChannels.length + newIndex;
        }
        const newPreviewChannel = isFullPageOver ? this.props.previewChannelIndex : this.props.gridRows - 1;
        const pd = getPageData(
            newIndex,
            this.props.gridRows,
            this.props.allChannels,
            this.props.pageStartTime,
            this.props.eventListWithCatchup,
            this.props.assetDataProgramIDLookup,
            this.props.orderData,
            this.props.horizontalPageIndex
        );
        const closestIndex = this.closestTimeIndex(this.props.previewProgramStartTime, pd[newPreviewChannel].programs);
        const gridVals = {
            tempAnimationData: this.props.pageData,
            previewChannelIndex: newPreviewChannel,
            pageStartingIndex: newIndex,
            pageData: pd,
            previewProgramIndex: closestIndex,
        };
        this.props.onGridNavigate('upPage', gridVals);
        //this.props.guideAnimationsEnabled && this.triggerPageAnimation(0, this.state.gridHeight * 1.10 * -1);
    };
    pageDown = (isFullPageOver) => {
        let newIndex = this.props.pageStartingIndex + this.props.gridRows;
        if (newIndex >= this.props.allChannels.length) {
            //return proper index near beginning
            newIndex = newIndex % this.props.allChannels.length;
        }
        const newPreviewChannel = isFullPageOver ? this.props.previewChannelIndex : 0;
        const pd = getPageData(
            newIndex,
            this.props.gridRows,
            this.props.allChannels,
            this.props.pageStartTime,
            this.props.eventListWithCatchup,
            this.props.assetDataProgramIDLookup,
            this.props.orderData,
            this.props.horizontalPageIndex
        );
        const closestIndex = this.closestTimeIndex(this.props.previewProgramStartTime, pd[newPreviewChannel].programs);
        const gridVals = {
            //tempAnimationData:  this.props.pageData,
            previewChannelIndex: newPreviewChannel,
            pageStartingIndex: newIndex,
            pageData: pd,
            previewProgramIndex: closestIndex,
        };
        this.props.onGridNavigate('downPage', gridVals);
        //this.props.guideAnimationsEnabled && this.triggerPageAnimation(0, this.state.gridHeight * 1.10);
    };

    componentDidUpdate = (prevProps) => {
        // NOTE: This is where the logic for changing the time happens.
        // const selectedChannelPrograms = this.props.selectedChannelPrograms;
        // if(prevProps.horizontalPage !== this.props.horizontalPage || prevProps.guideStartUnix !== this.props.guideStartUnix){
        //     if (this.props.horizontalPage >= 0)  // Scroll right
        //         this.setState({ guideStartTime: moment.unix(this.props.guideStartUnix + (7200 * this.props.horizontalPage)) });
        //     else
        //         this.setState({ guideStartTime: moment.unix(this.props.guideStartUnix - (7200 * Math.abs(this.props.horizontalPage))) });
        // }
        //if(prevProps.startIndex !== this.props.startIndex){//means cx extered the ch.number (detect by index)
        // console.log('execute page animation', prevProps.channelNumber, this.props.channelNumber);
        // this.pageFlip();
        //}
        // if (this.props.currentChannel !== prevProps.currentChannel) {
        //     this.props.resetGrid();
        // }

        //new pagedata if orderdata has changed so we can update
        if ((prevProps.isSCLoading && !this.props.isSCLoading) || (prevProps.isOrderDataLoading && !this.props.isOrderDataLoading)) {
            //to-do - needs a fix
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                this.props.pageStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                this.props.horizontalPageIndex
            );
            const gridVals = {
                pageData: pd,
                previewProgramStartTime: pd[this.props.previewChannelIndex].programs[this.props.previewProgramIndex].unix_start_time,
                pageStartTime: this.props.pageStartTime,
                horizontalPageIndex: this.props.horizontalPageIndex,
                previewProgramIndex: this.props.previewProgramIndex,
                previewChannelIndex: this.props.previewChannelIndex,
                pageStartingIndex: this.props.pageStartingIndex,
            };
            this.props.onGridNavigate('init', gridVals);
        }

        //guide 30 minute update or 6 hour update
        const pastStart = prevProps.currentTime.clone();
        const currentStart = this.props.currentTime.clone();
        const pastRoundedStart = pastStart
            .subtract(pastStart.minute() % 30, 'minutes')
            .subtract(pastStart.second(), 'seconds')
            .unix();
        const currentRoundedStart = currentStart
            .subtract(currentStart.minute() % 30, 'minutes')
            .subtract(currentStart.second(), 'seconds')
            .unix();
        if (pastRoundedStart !== currentRoundedStart || prevProps.allChannels[0].programs.length !== this.props.allChannels[0].programs.length) {
            //guide 30 minute update

            //periodically updating rstv availablity here for now
            this.props.onUpdateRestartAvailablity();

            const pageStartAdjustment = this.props.horizontalPageIndex * 7200;
            const adjustedRoundedStart = currentRoundedStart + pageStartAdjustment; //adjust for page the user is on currently
            // console.log('%%%', currentStart);
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                adjustedRoundedStart,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                this.props.horizontalPageIndex
            );
            //const programRatio = this.getProgramRatio(testCurPrograms[this.props.previewChannelIndex].programs[this.state.previewProgramIndex], false);
            //console.log(this.props.horizontalPageIndex);
            // console.log(pd);
            const gridVals = {
                pageData: pd,
                previewProgramStartTime: pd[this.props.previewChannelIndex].programs[0].unix_start_time,
                pageStartTime: adjustedRoundedStart,
                horizontalPageIndex: this.props.horizontalPageIndex,
                previewProgramIndex: 0,
                previewChannelIndex: this.props.previewChannelIndex,
                pageStartingIndex: this.props.pageStartingIndex,
            };
            this.props.onGridNavigate('init', gridVals);
        }
        if (this.props.currentChannel !== prevProps.currentChannel) {
            //we probably should use ch_number vs JSON as it was before
            this.setState({
                lastChannel: prevProps.currentChannel,
            });
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener('keyup', this._handleKeyPress);
        document.removeEventListener('wheel', this._handleWheelScroll);
        this._scheduleEventEmitter && this._scheduleEventEmitter.remove();
        this._scheduleEventEmitter = null;
        this.onGuidePress && this.onGuidePress.remove();
        this.onGuidePress = null;
    };

    ///////////////////////////////////////////////////////////////////////////

    currentChannel = async (chNumber) => {
        let ch = this.props.allChannels.filter((val, i) => {
            return val.custom_channel_number === chNumber;
        });
        return (await (ch && ch[0])) ? ch[0] : null;
    };

    closestTimeIndex = (programStartTime, arr) => {
        if (arr.length > 0) {
            let closestNumber = 0;
            let mid;
            let lo = 0;
            let hi = arr.length - 1;
            //grab the closest index out the row through binary search
            while (hi - lo > 1) {
                mid = Math.floor((lo + hi) / 2);
                if (arr[mid].unix_start_time < programStartTime) {
                    lo = mid;
                } else {
                    hi = mid;
                }
            }

            if (programStartTime - arr[lo].unix_start_time <= arr[hi].unix_start_time - programStartTime) {
                closestNumber = lo;
            } else {
                closestNumber = hi;
            }

            // check if number is between the start and end times of the next program
            if (arr[closestNumber] && (programStartTime - arr[closestNumber].unix_start_time) * (programStartTime - arr[closestNumber].unix_end_time) <= 0)
                return closestNumber;
            else if (
                arr[closestNumber + 1] &&
                (programStartTime - arr[closestNumber + 1].unix_start_time) * (programStartTime - arr[closestNumber + 1].unix_end_time) <= 0
            ) {
                return closestNumber + 1;
            } else if (
                arr[closestNumber - 1] &&
                (programStartTime - arr[closestNumber - 1].unix_start_time) * (programStartTime - arr[closestNumber - 1].unix_end_time) <= 0
            ) {
                return closestNumber - 1;
            } else {
                return closestNumber;
            }
        } else {
            return 0;
        }
    };

    _handleWheelScroll = (event) => {
        const { isFullScreen, activeRowIndex, currentPageData } = this.props;
        if (event.deltaY < 0) {
            //up scroll
            if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen) {
                //Up
                if (activeRowIndex > 0 && !this.props.pageTransition) {
                    //condition when it flips the page up
                    this.updateRowIndexesAndRatios(activeRowIndex - 1);
                } else if (activeRowIndex === 0 && this.props.guideAnimationsOff) {
                    this.updateRowIndexesAndRatios(currentPageData.length - 1);
                }
            }
        } else {
            //down scroll
            if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen && !(isFullScreen && this.props.isFullScreenInfoOpen)) {
                //Down
                if (activeRowIndex < currentPageData.length - 1 && !this.props.pageTransition) {
                    //stops update of indexes during page animation
                    this.updateRowIndexesAndRatios(activeRowIndex + 1);
                } else if (activeRowIndex === currentPageData.length - 1 && this.props.guideAnimationsOff) {
                    this.updateRowIndexesAndRatios(0);
                }
            }
        }
    };

    _handleKeyPress = (event) => {
        const { isFullScreen } = this.props;

        switch (event.key) {
            case numberPad[event.key]:
                if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen) {
                    this.setChannel(event.key);
                }
                break;
            case 'ArrowDown':
                if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen && !(isFullScreen && this.props.isFullScreenInfoOpen)) {
                    //Down
                    //     if (activeRowIndex < currentPageData.length - 1 && !this.props.pageTransition) {//stops update of indexes during page animation
                    //         this.updateRowIndexesAndRatios(activeRowIndex + 1);
                    //     } else if (activeRowIndex === currentPageData.length - 1 && this.props.guideAnimationsOff) {
                    //         this.updateRowIndexesAndRatios(0);
                    //     }
                    // }
                    if (this.props.previewChannelIndex < this.props.gridRows - 1) {
                        ///down
                        const closestIndex = this.closestTimeIndex(
                            this.props.previewProgramStartTime,
                            this.props.pageData[this.props.previewChannelIndex + 1].programs
                        );
                        const gridVals = {
                            previewChannelIndex: this.props.previewChannelIndex + 1,
                            previewProgramIndex: closestIndex,
                        };
                        this.props.onGridNavigate('downProgram', gridVals);
                    } else {
                        //page change
                        this.pageDown(false);
                    }
                }
                break;
            case 'ArrowUp':
                if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen) {
                    //Up
                    // if (activeRowIndex > 0 && !this.props.pageTransition) {//condition when it flips the page up
                    //     this.updateRowIndexesAndRatios(activeRowIndex - 1);
                    // } else if (activeRowIndex === 0 && this.props.guideAnimationsOff) {
                    //     this.updateRowIndexesAndRatios(currentPageData.length - 1);
                    // }
                    if (this.props.previewChannelIndex > 0) {
                        ///////up
                        const closestIndex = this.closestTimeIndex(
                            this.props.previewProgramStartTime,
                            this.props.pageData[this.props.previewChannelIndex - 1].programs
                        );
                        const gridVals = {
                            previewChannelIndex: this.props.previewChannelIndex - 1,
                            previewProgramIndex: closestIndex,
                        };
                        this.props.onGridNavigate('upProgram', gridVals);
                    } else {
                        //page change
                        this.pageUp(false);
                    }
                }
                break;
            case 'ArrowLeft':
                if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen) {
                    if (this.props.previewProgramIndex === 0 && this.props.horizontalPageIndex > -36) {
                        //left Page

                        this.pageLeft(false);
                        //this.props.guideAnimationsEnabled && this.triggerPageAnimation(this.state.windowWidth * -1, 0);
                    } else if (this.props.pageData[this.props.previewChannelIndex].programs[this.props.previewProgramIndex - 1]) {
                        const nextProgram = this.props.pageData[this.props.previewChannelIndex].programs[this.props.previewProgramIndex - 1];
                        //const programRatio = this.getProgramRatio(nextProgram, false);
                        // return {
                        //     previewProgramStartTime: nextProgram.unix_start_time,
                        //     //previewProgramRatioCount: this.props.previewProgramRatioCount - programRatio,
                        //     previewProgramIndex: this.props.previewProgramIndex - 1
                        // }
                        const gridVals = {
                            previewProgramStartTime: nextProgram.unix_start_time,
                            previewProgramIndex: this.props.previewProgramIndex - 1,
                        };
                        this.props.onGridNavigate('leftProgram', gridVals);
                    }
                }
                break;
            case 'ArrowRight':
                if (!this.props.isOptionsOpen && !this.props.isGuideSearchOpen) {
                    if (
                        this.props.pageData[this.props.previewChannelIndex].programs[this.props.previewProgramIndex].programRatiosCount >= 4 &&
                        this.props.horizontalPageIndex < 115
                    ) {
                        // < 119 for true last page. however page data is shortened from the end every 30 minutes, so leaving cushion for now

                        this.pageRight(false);
                        // this.setState((prevState) => {

                        //
                        //     return {
                        //         pageData: pd,
                        //         previewProgramIndex: 0,
                        //         previewProgramStartTime: pd[prevState.previewChannelIndex].programs[0].unix_start_time,
                        //         startTime: newStartTime
                        //     }
                        // })
                    } else if (this.props.pageData[this.props.previewChannelIndex].programs[this.props.previewProgramIndex + 1]) {
                        const nextProgram = this.props.pageData[this.props.previewChannelIndex].programs[this.props.previewProgramIndex + 1];
                        if (nextProgram) {
                            const gridVals = {
                                previewProgramStartTime: nextProgram.unix_start_time,
                                previewProgramIndex: this.props.previewProgramIndex + 1,
                            };
                            this.props.onGridNavigate('rightProgram', gridVals);
                            // this.setState((prevState) => {
                            //     const nextProgram = prevState.pageData[prevState.previewChannelIndex].programs[prevState.previewProgramIndex + 1];
                            //     //const programRatio = this.getProgramRatio(nextProgram, false);
                            //     return {
                            //         previewProgramStartTime: nextProgram.unix_start_time,
                            //         //previewProgramRatioCount: prevState.previewProgramRatioCount + programRatio,
                            //         previewProgramIndex: prevState.previewProgramIndex + 1
                            //     }
                            // })
                        }
                    }
                }
                break;
            case 'Enter':
                if (this.tempString.length > 0) {
                    if (!this.state.navUtilityOpen) {
                        this.tempString = '';
                        this.channelSelectorHelper();
                    }
                } else {
                    !this.props.isOptionsOpen ||
                        (this.props.isGuideSearchOpen && !this.state.navUtilityOpen && !isFullScreen && this.props.toggleOptionsMenu(true));
                }
                break;
            case 'Escape':
                this.props.isOptionsOpen && !this.props.isGuideSearchOpen && this.props.handleOptionsOverlayClick();
                this.state.navUtilityOpen && this.handleNavOverlayClick();
                break;
            case 'PageUp':
                !this.props.isOptionsOpen && !this.props.isGuideSearchOpen && this.pageUp(true);
                break;
            case 'PageDown':
                !this.props.isOptionsOpen && !this.props.isGuideSearchOpen && this.pageDown(true);
                break;
            default:
                break;
        }
    };

    openGuideSearch = () => {
        this.props.openGuideSearch();
    };

    handleProgramClick = (program) => {
        const gridVals = {
            previewChannelIndex: program.channelIndex,
            previewProgramIndex: program.programIndex,
        };
        this.props.onGridNavigate('upProgram', gridVals);
        // this.props.toggleOptionsMenu(true);
    };

    changingChannelUpOrDown = (channelInfo) => {
        if (channelInfo.programs && channelInfo.programs.length > 0) {
            //only executes when programs data is available
            // let currentTime = this.props.currentTime.unix()*1000;//current time in seconds
            FSProgramIndex(this.props.currentTime.unix() * 1000, channelInfo.programs).then((globalProgramIndex) => {
                this.props.onChangeChannel(channelInfo, globalProgramIndex);
            });
        }
    };

    changeDay = (isLeft) => {
        const canGoLeft = isLeft ? this.props.horizontalPageIndex - 12 > -36 : true;
        const canGoRight = !isLeft
            ? this.props.horizontalPageIndex + 12 < 120 && this.props.pageStartTime + 86400 < this.props.guideEndPagesTimes.guideLastPageTime
            : true;
        //const momentObj = moment();
        if (canGoLeft && canGoRight) {
            const newStartTime = isLeft ? this.props.pageStartTime - 86400 : this.props.pageStartTime + 86400; //day in past or future
            const newHorizPageIndex = isLeft ? this.props.horizontalPageIndex - 12 : this.props.horizontalPageIndex + 12;
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                newStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                newHorizPageIndex
            );
            const gridVals = {
                pageData: pd,
                previewProgramStartTime: pd[this.props.previewChannelIndex].programs[0].unix_start_time,
                pageStartTime: newStartTime,
                horizontalPageIndex: newHorizPageIndex,
                previewProgramIndex: 0,
                previewChannelIndex: this.props.previewChannelIndex,
                pageStartingIndex: this.props.pageStartingIndex,
            };
            this.props.onGridNavigate('init', gridVals);
        }
    };

    toggleNavUtility = (open) => {
        if (open) {
            this.setState({
                navUtilityOpen: true,
            });
        } else {
            this.setState({
                navUtilityOpen: false,
            });
        }
    };

    handleNavOverlayClick = () => {
        this.toggleNavUtility(false);
    };

    handleNavEnter = () => {
        this.toggleNavUtility(false);
        this.tempString = '';
        this.channelSelectorHelper();
    };

    handleFullScreen = () => {
        window.globalVideoRef && window.globalVideoRef.requestFullscreen();
        this.toggleNavUtility(false);
        this.setState({ tempChannelHolder: '' });
        this.tempString = '';
    };

    handleLastChannelJump = () => {
        this.changingChannelUpOrDown(this.state.lastChannel);
        this.toggleNavUtility(false);
        this.setState({ tempChannelHolder: '' });
        this.tempString = '';
    };

    ///////////////////////////////////////////////////////////////////////////

    render() {
        const { pageData, horizontalPageIndex, pageStartTime } = this.props;
        const { tempChannelHolder } = this.state;
        const guideMarkerRatio = this.props.currentTime.minute() >= 30 ? this.props.currentTime.minute() - 30 : this.props.currentTime.minute();
        // const pastCheckTime = this.props.channelsStartTime + 7200;
        //const pageDate = moment(pageStartTime).format('ddd M/DD');
        //console.log('asdfasdfasdfasdfasdfasdf',pageStartTime);
        const pStartTime = moment.unix(pageStartTime);

        if (pageData) {
            return (
                <>
                    {this.state.navUtilityOpen && (
                        <div onClick={this.handleNavOverlayClick} className={css(styles.OptionsOverLay)}>
                            <NavUtility
                                setChannel={this.setChannel}
                                handleNavEnter={this.handleNavEnter}
                                handleFullScreen={this.handleFullScreen}
                                changeDay={this.changeDay}
                                handleLastChannelJump={this.handleLastChannelJump}
                            />
                        </div>
                    )}
                    {horizontalPageIndex >= 0 ? (
                        <div className={css(styles.ScheduleGridContainer)}>
                            <ScheduleTimesAndDates
                                pageStartTime={pStartTime}
                                horizontalPageIndex={horizontalPageIndex}
                                guideMarkerRatio={guideMarkerRatio}
                                tempChannelHolder={tempChannelHolder}
                            />
                            <div className={css(styles.Main)}>
                                {
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row', borderRight: '.01em solid rgba(230,230,230, 0.2)' }}>
                                            <div className={css(styles.ChannelsAndScheduleGrid)}>
                                                {
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {pageData.map((program, i) => {
                                                            let logo = '';
                                                            // program.station_logo && program.station_logo.forEach((cur) => {
                                                            //     if (logo === '') {
                                                            //         if (cur.includes('_DARK')) {
                                                            //             logo = cur;
                                                            //         } else if (cur.includes('white') && !cur.includes('2400')) {
                                                            //             logo = cur;
                                                            //         }
                                                            //     }
                                                            // })

                                                            // if (program.station_logo) {
                                                            //     for (let i = 0; i < program.station_logo.length; i++) {

                                                            //     }
                                                            // }

                                                            if (program.rovi_custom_channel_logo) {
                                                                logo = program.rovi_custom_channel_logo;
                                                            } else {
                                                                logo = program.station_logo;
                                                            }
                                                            if (logo === '') {
                                                                // if the preferred logos are unavailable, just take the first one from station_logo and if there are 0 take the first from generic, if not ''.
                                                                logo =
                                                                    program.station_logo && program.station_logo[0]
                                                                        ? program.station_logo[0]
                                                                        : program.generic_station_logo && program.generic_station_logo[0]
                                                                        ? program.generic_station_logo[0]
                                                                        : '';
                                                            }
                                                            return (
                                                                // <View key={i} style={[styles.programInfoContainer, { width: width / 6 , height: yScale(36), minHeight: xScale(30)}]}>
                                                                //     <Text style={[styles.textStyle, this.props.selectedChannel === program.custom_channel_number && { fontFamily: 'OpenSans-Regular', color: 'white' }]}>{program.custom_channel_number}</Text>
                                                                //     <View style={styles.qualityBox}>
                                                                //         {/* <Text style={styles.qualityText}>{channel.quality}</Text>*/}
                                                                //         <Text style={styles.qualityText}>HD</Text>
                                                                //     </View>
                                                                //     <Image style={{ height: yScale(30), width: xScale(90) }} resizeMode={"contain"}  source={{ uri: logo !== '' ? logo : null }} />
                                                                // </View>
                                                                <div key={i} className={css(styles.ChannelContainer)}>
                                                                    <div className={css(styles.ChannelNumContainerPresent)}>
                                                                        <div className={css(styles.ChannelNumber)}>{program.custom_channel_number}</div>
                                                                    </div>
                                                                    <div className={css(styles.LogoContainer)}>
                                                                        {logo !== '' ? (
                                                                            <img className={css(styles.Logo)} src={logo} alt="Station Logo"></img>
                                                                        ) : (
                                                                            <div>{program.custom_name ? program.custom_name : ''}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                }
                                                {
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 10, gridColumn: '2' }}>
                                                        {this.props.pageData.map((rowData, index) => {
                                                            return (
                                                                <ScheduleRow
                                                                    handleProgramClick={this.handleProgramClick}
                                                                    key={index}
                                                                    rowIndex={index}
                                                                    rowData={rowData}
                                                                    gridRows={this.props.gridRows}
                                                                    previewProgramIndex={this.props.previewProgramIndex}
                                                                    isChannelRowActive={this.props.previewChannelIndex === index}
                                                                    horizontalPageIndex={horizontalPageIndex}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={css(styles.GuideNavContainer)}>
                                            {/* <div className={css(styles.GuideNavText)}>Navigate by page: </div> */}
                                            <FontAwesomeIcon
                                                color={'rgba(0,131,159,255)'}
                                                icon="search"
                                                onClick={() => this.openGuideSearch()}
                                                className={css(styles.Arrow)}
                                            />
                                            <img
                                                onClick={() => this.pageLeft(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_left.svg')}
                                                alt="arrow left"
                                            />
                                            <img
                                                onClick={() => this.pageDown(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_down.svg')}
                                                alt="arrow down"
                                            />
                                            <img
                                                onClick={() => this.pageUp(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_up.svg')}
                                                alt="arrow up"
                                            />
                                            <img
                                                onClick={() => this.pageRight(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_right.svg')}
                                                alt="arrow right"
                                            />
                                            <img
                                                onClick={() => this.toggleNavUtility(true)}
                                                className={css(styles.NavUtilityIcon)}
                                                src={require('../../assets/SC_SVGs/ic_hints_buger.svg')}
                                                alt="select"
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    ) : (
                        //past data
                        <div className={css(styles.ScheduleGridContainerPastTime)}>
                            <ScheduleTimesAndDates
                                pageStartTime={pStartTime}
                                horizontalPageIndex={horizontalPageIndex}
                                guideMarkerRatio={guideMarkerRatio}
                                tempChannelHolder={tempChannelHolder}
                            />
                            <div className={css(styles.Main)}>
                                {
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row', borderLeft: '0.01em solid rgba(230, 230, 230, 0.2)' }}>
                                            <div className={css(styles.ChannelAndScheduleGridPastTime)}>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 10 }}>
                                                    {this.props.pageData.map((rowData, index) => {
                                                        return (
                                                            <ScheduleRow
                                                                handleProgramClick={this.handleProgramClick}
                                                                key={index}
                                                                rowIndex={index}
                                                                rowData={rowData}
                                                                gridRows={this.props.gridRows}
                                                                previewProgramIndex={this.props.previewProgramIndex}
                                                                isChannelRowActive={this.props.previewChannelIndex === index}
                                                                horizontalPageIndex={horizontalPageIndex}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                {
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {pageData.map((program, i) => {
                                                            let logo = '';
                                                            if (program.rovi_custom_channel_logo) {
                                                                logo = program.rovi_custom_channel_logo;
                                                            } else {
                                                                logo = program.station_logo;
                                                            }
                                                            if (logo === '') {
                                                                // if the preferred logos are unavailable, just take the first one from station_logo and if there are 0 take the first from generic, if not ''.
                                                                logo =
                                                                    program.station_logo && program.station_logo[0]
                                                                        ? program.station_logo[0]
                                                                        : program.generic_station_logo && program.generic_station_logo[0]
                                                                        ? program.generic_station_logo[0]
                                                                        : '';
                                                            }
                                                            return (
                                                                // <View key={i} style={[styles.programInfoContainer, { width: width / 6 , height: yScale(36), minHeight: xScale(30)}]}>
                                                                //     <Text style={[styles.textStyle, this.props.selectedChannel === program.custom_channel_number && { fontFamily: 'OpenSans-Regular', color: 'white' }]}>{program.custom_channel_number}</Text>
                                                                //     <View style={styles.qualityBox}>
                                                                //         {/* <Text style={styles.qualityText}>{channel.quality}</Text>*/}
                                                                //         <Text style={styles.qualityText}>HD</Text>
                                                                //     </View>
                                                                //     <Image style={{ height: yScale(30), width: xScale(90) }} resizeMode={"contain"}  source={{ uri: logo !== '' ? logo : null }} />
                                                                // </View>
                                                                <div
                                                                    key={i}
                                                                    className={css(styles.ChannelContainer)}
                                                                    style={{
                                                                        borderLeft: '.01em solid rgba(230,230,230, 0.2)',
                                                                    }}>
                                                                    <div className={css(styles.ChannelNumContainerPast)}>
                                                                        <span className={css(styles.ChannelNumber)}>{program.custom_channel_number}</span>
                                                                    </div>
                                                                    <div className={css(styles.LogoContainerPast)}>
                                                                        {logo !== '' ? (
                                                                            <img className={css(styles.Logo)} src={logo} alt="Station Logo"></img>
                                                                        ) : (
                                                                            <div>{program.custom_name ? program.custom_name : ''}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={css(styles.GuideNavContainer)}>
                                            {/* <div className={css(styles.GuideNavText)}>Navigate by page: </div> */}
                                            <FontAwesomeIcon
                                                color={'rgba(0,131,159,255)'}
                                                icon="search"
                                                onClick={() => this.openGuideSearch()}
                                                className={css(styles.Arrow)}
                                            />
                                            <img
                                                onClick={() => this.pageLeft(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_left.svg')}
                                                alt="arrow left"
                                            />
                                            <img
                                                onClick={() => this.pageDown(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_down.svg')}
                                                alt="arrow down"
                                            />
                                            <img
                                                onClick={() => this.pageUp(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_up.svg')}
                                                alt="arrow up"
                                            />
                                            <img
                                                onClick={() => this.pageRight(true)}
                                                className={css(styles.Arrow)}
                                                src={require('../../assets/SC_SVGs/ic_small_arrow_right.svg')}
                                                alt="arrow right"
                                            />
                                            <img
                                                onClick={() => this.toggleNavUtility(true)}
                                                className={css(styles.NavUtilityIcon)}
                                                src={require('../../assets/SC_SVGs/ic_hints_buger.svg')}
                                                alt="select"
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            return null;
        }
    }
}

const styles = StyleSheet.create({
    ScheduleGridContainer: {
        display: 'grid',
        gridTemplateRows: '2.5em 22em',
        width: '100%',
    },
    ScheduleGridContainerPastTime: {
        display: 'grid',
        gridTemplateRows: '2.5em 22em',
        width: '100%',
    },
    Main: {
        // '@media screen and (min-width: 1240px)': {
        // maxWidth: '90vw',
        // },
        // '@media screen and (max-width: 1239px)': {
        // minWidth: '1129px',
        // maxWidth: '56em'
        // }
        width: '90vw',
        minWidth: '56em',
    },
    ChannelsAndScheduleGrid: {
        display: 'grid',
        gridTemplateColumns: '15% 85%',
        width: '100%',
    },
    ChannelAndScheduleGridPastTime: {
        display: 'grid',
        gridTemplateColumns: '85% 15%',
        width: '100%',
    },
    ChannelContainer: {
        width: '100%',
        height: '3.1em',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#dbdbdb',
        fontSize: '1em',
    },
    ChannelNumContainerPresent: {
        left: 0,
        display: 'flex',
        flex: 1,
    },
    ChannelNumContainerPast: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    ChannelNumber: {
        fontSize: '1.2em',
    },
    LogoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flex: 2,
    },
    LogoContainerPast: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 2,
    },
    Logo: {
        maxHeight: '2.5em',
    },
    GuideNavContainer: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        paddingTop: '1em',
    },
    ProgramTitle: {
        color: '#dbdbdb',
        fontSize: '1em',
        whiteSpace: 'nowrap',
        marginLeft: '.3em',
    },
    Arrow: {
        //margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        color: 'white',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
        margin: '0 2em',
    },
    NavUtilityIcon: {
        padding: '.2em .5em',
        fontSize: '1.6em',
        color: 'white',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
        margin: '0 2em',
    },
    OptionsOverLay: {
        //really needs to be moved to guide to be styled right..
        position: 'absolute',
        //top: '81px',
        bottom: 0,
        left: 0,
        width: '100%',
        minWidth: '66em',
        height: 'calc(100%)',
        //height: '100%',
        zIndex: 3,
        backgroundColor: 'rgba(1,1,1,0.7)',
    },
});
