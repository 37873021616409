import React from 'react';
import { connect } from 'react-redux';
import { toggleSubtitle, setPlayerSourceOpened, updateNumTracks, setDeviceLimitReached, refreshSotalToken } from '../../../actions/main';

import { changeProgramBlockedAndFullscreen, changeIsPinPopupFSOpen, changeParentalControlBlockActive } from '../../../actions/menu';

import { restartCompleted, startRestartProgram } from '../../../actions/guide';

import WebPlayer from '../WebPlayer';

const WebPlayerContainer = (props) => {
    return <WebPlayer {...props} />;
};

const mapStateToProps = (state) => ({
    isSubtitleToggled: state.main.isSubtitleToggled,
    isPlayerSourceOpened: state.main.isPlayerSourceOpened,
    isDeviceLimitReached: state.main.isDeviceLimitReached,
    isSotalRefreshLoading: state.main.isSotalRefreshLoading,
    currentChannel: state.main.currentChannel,
    parentalControlsEnabled: state.menu.parentalControlsEnabled,
    currentRatingLimits: state.menu.currentRatingLimits,
    globalProgramIndex: state.main.globalProgramIndex,
    isProgramBlockedAndFullscreen: state.menu.isProgramBlockedAndFullscreen,
    isPinPopupFSOpen: state.menu.isPinPopupFSOpen,
    keyMap: state.main.keyMap,
    pinNumber: state.menu.pinNumber,
    isParentalControlBlockActive: state.menu.isParentalControlBlockActive,
    isFullScreen: state.main.isFullScreen,
    restartLoading: state.guide.restartLoading,
    restartFailed: state.guide.restartFailed,
    subscriberData: state.login.subscriberData,
    restartProgram: state.guide.restartProgram,
    restartChannel: state.guide.restartChannel,
    lockedChannels: state.menu.lockedChannels,
    restartStreamInfo: state.guide.restartStreamInfo,
    restartProgramInProgress: state.guide.restartProgramInProgress,
    triggerLiveChannelReload: state.main.triggerLiveChannelReload,
    pageData: state.guide.pageData,
    previewProgramIndex: state.guide.previewProgramIndex,
    previewChannelIndex: state.guide.previewChannelIndex,
});

export const mapDispatchToProps = (dispatch) => ({
    onToggleSubtitle: (isSubtitleToggled) => dispatch(toggleSubtitle(isSubtitleToggled)),
    onSetPlayerSourceOpened: (isPlayerSourceOpened) => dispatch(setPlayerSourceOpened(isPlayerSourceOpened)),
    onSetDeviceLimitReached: (isDeviceLimitReached) => dispatch(setDeviceLimitReached(isDeviceLimitReached)),
    onStartRestartProgram: (program, scAssetId, channel) => dispatch(startRestartProgram(program, scAssetId, channel)),
    //onChangeProgramBlockedAndFullscreen: (isProgramBlockedAndFullscreen) => dispatch(changeProgramBlockedAndFullscreen(isProgramBlockedAndFullscreen)),
    //onChangeIsPinPopupFSOpen: (isPinPopupFSOpen) => dispatch(changeIsPinPopupFSOpen(isPinPopupFSOpen)),
    //onChangeParentalControlBlockActive: (isParentalControlBlockActive) => dispatch(changeParentalControlBlockActive(isParentalControlBlockActive)),
    onRestartCompleted: () => dispatch(restartCompleted()),
    onUpdateNumTracks: (debugNumOfTracks) => dispatch(updateNumTracks(debugNumOfTracks)),
    //onRefreshSotalToken: () => dispatch(refreshSotalToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebPlayerContainer);
