import React, { Component } from 'react';
import { closestChNumber } from '../../utils/ClosestChNumber';
import { FSProgramIndex } from '../../utils/FSProgramIndex';
import moment from 'moment';
import WebPlayer from '../Player/Container/WebPlayerContainer';
import { StyleSheet, css } from 'aphrodite';
//import bugsnag from 'lib/bugsnag';

export default class VideoPlayerWithBars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempChannelHolder: '',
            channelNumber: null,
            isRecordedSingle: false,
            isRecordedSeries: false,
            selectedAssetInfo: null,
            infoBarFlipper: true,
            isMovie: false,
            starRating: 0,
            fsRecFlag: false,
            navUtility: false,
            fullScreenForSecond: false,
        };
        this.keyInputInterval = null;
        this.tempString = '';
    }

    componentDidMount = () => {
        // bugsnag.leaveBreadcrumb("<FullScreen /> DidMount", {
        //     type: 'navigation',
        //     component: "<FullScreen />",
        //   })

        let currentTime = this.props.currentTime.unix() * 1000; //in ms
        FSProgramIndex(currentTime, this.props.currentChannel.programs).then((globalProgramIndex) => {
            this.props.onChangeChannel(this.props.currentChannel, globalProgramIndex);
        });
        // console.log("component did mount i videoplayerwithbars: ", this.props);
    };

    componentWillUnmount = () => {
        this.mountDelayTimeout && clearTimeout(this.mountDelayTimeout);
        this.interval && clearInterval(this.interval);
        this.props.onChannelBarInfo(false);
        this.props.onFullScreenInfoOpen(false); //we need it for Guide
        this._fullScreenEventEmitter && this._fullScreenEventEmitter.remove();
    };

    logicToChangeTheChannel = (selectedChannel) => {
        //logic to change station by channel number
        this.interval && clearInterval(this.interval);
        if (selectedChannel) {
            //let's see if that will fix the propblem
            let startIndex = this.props.channelList.indexOf(selectedChannel.toString());
            let channelInfo = this.props.channels[startIndex];
            this.setState({ tempChannelHolder: '' }, () => {
                this.tempString = '';
                let currentTime = this.props.currentTime.unix() * 1000; //in seconds
                FSProgramIndex(currentTime, channelInfo.programs).then((globalProgramIndex) => {
                    this.props.onChangeChannel(channelInfo, globalProgramIndex);
                });
            });
        }
    };

    setChannel = (keyCode) => {
        if (this.tempString.length < 5) {
            this.keyInputInterval && clearInterval(this.keyInputInterval);
            this.tempString += this.numberPad[keyCode];
            this.setState(
                {
                    channelNumber: parseInt(this.tempString),
                    tempChannelHolder: this.tempString,
                },
                () => {
                    this.keyInputInterval = setInterval(
                        () => {
                            clearInterval(this.keyInputInterval);
                            this.tempString = ''; //clearing temp string for another number
                            //the following logic is to determing our channel
                            if (this.state.tempChannelHolder) {
                                this.channelSelectorHelper(); //finalized channel
                            }
                        },
                        this.props.boxModel === 'Aminos' ? 4000 : 6000
                    ); //after 2 seconds it has to find the program
                }
            );
        }
    };

    channelSelectorHelper = () => {
        let tempChannelHolder = parseInt(this.state.tempChannelHolder);
        let ourChannel;
        if (this.props.channelList && this.props.channelList.length > 0) {
            //ensures that data is loaded prior
            if (this.props.channelList.includes(this.state.tempChannelHolder)) {
                ourChannel = tempChannelHolder;
            } else if (
                tempChannelHolder > parseInt(this.props.channelList[0]) &&
                tempChannelHolder < parseInt(this.props.channelList[this.props.channelList.length - 1])
            ) {
                ourChannel = closestChNumber(this.props.channelList, tempChannelHolder);
            } else if (tempChannelHolder < parseInt(this.props.channelList[0])) {
                ourChannel = this.props.channelList[0];
            } else if (tempChannelHolder > parseInt(this.props.channelList[this.props.channelList.length - 1])) {
                ourChannel = this.props.channelList[this.props.channelList.length - 1];
            }
            //now let's handle the channelChange
            this.setState({ channelNumber: ourChannel }, () => {
                this.logicToChangeTheChannel(ourChannel);
            });
        }
        return ourChannel;
    };

    isNavOpen = () => {
        return this.state.navUtility;
    };

    _fullScreenListener = (keyEvent) => {
        //this works only for number keys
        // console.log(this.props.alertEAS);
        const programUnavailable = this.props.currentChannel.programs[this.props.globalProgramIndex].show_type === '';
        if (!this.props.alertEAS) {
            const { keyMap, boxModel } = this.props;
            if (!this.state.navUtility) {
                if (this.props.isFullScreen && this.numberPad[keyEvent.keyCode] && !this.props.connTimeOut) {
                    //activate it only if this is full screen
                    this.recalculateProgramIndex();
                    this.setChannel(keyEvent.keyCode);
                } else if (this.props.isFullScreen && keyEvent.keyCode === keyMap.select && boxModel !== 'Amazon') {
                    // console.log("pressing OK", this.state);
                    this.keyInputInterval && clearInterval(this.keyInputInterval);
                    this.recalculateProgramIndex();
                    this.tempString = '';
                    if (this.state.tempChannelHolder.length === 0 && !this.props.isChannelBarInfoOpen) {
                        //open info just by clicking OK button
                        !this.props.isFullScreenInfoOpen && this.props.onChannelBarInfo(true);
                    } else if (this.state.tempChannelHolder.length > 0 && !this.props.isChannelBarInfoOpen && this.props.isFullScreenInfoOpen) {
                        //on OK when ch entered and
                        this.setState({ tempChannelHolder: '' });
                    } else if (this.state.tempChannelHolder && this.state.tempChannelHolder.length > 0) {
                        this.channelSelectorHelper();
                    }
                } else if (this.props.isFullScreen && (keyEvent.keyCode === keyMap.channelUp || keyEvent.keyCode === keyMap.channelDown)) {
                    if (this.keyInputInterval) {
                        this.setState({ tempChannelHolder: '', channelNumber: null }, () => {
                            this.tempString = '';
                            clearInterval(this.keyInputInterval); //clear inputted channel if Ch up or Ch down
                        });
                    }
                } else if (this.props.isFullScreen && boxModel === 'Amazon' && (keyEvent.keyCode === keyMap.up || keyEvent.keyCode === keyMap.down)) {
                    if (!this.props.isRecordingScreenOpen && !this.props.isTicketScreenOpen) {
                        //ensure recording options and
                        if (this.keyInputInterval) {
                            this.setState({ tempChannelHolder: '', channelNumber: null }, () => {
                                this.tempString = '';
                                clearInterval(this.keyInputInterval); //clear inputted channel if Ch up or Ch down
                            });
                        }
                        keyEvent.keyCode === keyMap.up ? this.props.channelUp() : this.props.channelDown();
                    }
                } else if (this.props.isFullScreen && (keyEvent.keyCode === keyMap.info || (keyEvent.keyCode === keyMap.select && boxModel === 'Amazon'))) {
                    // on amazon, pressing ok brings up info options while in fullscreen
                    //logic to pull or shutdown options with record selected on extended remote control
                    this.props.isChannelBarInfoOpen && this.props.onChannelBarInfo(false);
                    if (!this.props.isFullScreenInfoOpen) {
                        //this logic triggers info on/off
                        this.props.onFullScreenInfoOpen(true);
                    }
                } else if (!this.props.lightweightVersion && !programUnavailable && this.props.isFullScreen && keyEvent.keyCode === keyMap.record) {
                    //to record on the Full Screen
                    //first close all the menus that are open => these should be done in respective components
                    //at that check if any of the bars are open and possibly delay opening Record Dialog for 150 ms
                    // if(this.props.isChannelBarInfoOpen || this.props.isFullScreenInfoOpen){
                    //     console.log("start timer for 150 ms to close the forementioned options");//optional
                    // }
                    this.checkingOrdersAndAssets(); //to recalculate options on opening Record Menu in Full Screen
                    this.props.onFullScreenOptionsOpen(true);
                } else if (!this.props.connTimeOut && !this.props.isOptionsOpen && keyEvent.keyCode === keyMap.back && this.props.currentPage === 'Guide') {
                    //Back
                    if (
                        this.props.isFullScreen &&
                        !this.props.isChannelBarInfoOpen &&
                        !this.props.isFullScreenInfoOpen &&
                        !this.props.isRecordingScreenOpen &&
                        !this.props.isTicketScreenOpen &&
                        !this.props.isFullScreenOptionsOpen
                    ) {
                        this.props.onToggleFullScreen(false);
                    }
                } else if (
                    keyEvent.keyCode === keyMap.back ||
                    (boxModel === 'Amazon' && keyEvent.keyCode === keyMap.back && this.props.currentPage !== 'Guide')
                ) {
                    //fullscreen Live TV from anywhere on Amino, with fire this is available with options button if anywhere except in the guide
                    if (
                        this.props.isFullScreen &&
                        !this.state.navUtility &&
                        !this.props.isChannelBarInfoOpen &&
                        !this.props.isFullScreenInfoOpen &&
                        !this.props.isRecordingScreenOpen &&
                        !this.props.isTicketScreenOpen &&
                        !this.props.isFullScreenOptionsOpen
                    ) {
                        //comment 2 lines out if Ed wants to keep FullScreenInfo status
                        this.props.isChannelBarInfoOpen && this.props.onChannelBarInfo(false);
                        this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(false);
                        this.props.onToggleFullScreen(false);
                        // this.resetAnimated();//probably do not need it anymore
                    }
                } else if (boxModel === 'Aminos' && keyEvent.keyCode === keyMap.subtitle && this.props.isPlayerSourceOpened) {
                    this.toggleSubtitle();
                    !this.props.isFullScreenInfoOpen && this.props.onChannelBarInfo(true); //toggle info bar
                }
            } else if (this.state.navUtility && boxModel === 'Amazon' && keyEvent.keyCode === keyMap.back) {
                // back if the navUtility is open
                this.closeNavUtility();
            }
            if (
                this.props.isFullScreen &&
                keyEvent.keyCode === keyMap.options &&
                !this.props.isRecordingScreenOpen &&
                !this.props.isTicketScreenOpen &&
                !this.props.isFullScreenInfoOpen &&
                this.state.fullScreenForSecond
            ) {
                this.setState((prevState) => {
                    return { navUtility: !prevState.navUtility };
                });
            }
        }
    };

    checkingOrdersAndAssets = () => {
        let series = false;
        let matchedAsset = false;
        if (
            this.props.currentChannel.programs[this.props.globalProgramIndex].show_type !== 'MO' &&
            this.props.currentChannel.programs[this.props.globalProgramIndex].series_id !== '' &&
            this.props.orderData &&
            this.props.orderData[0]
        ) {
            //case when this is not a movie
            this.state.isMovie && this.setState({ isMovie: false, starRating: 0 });
            for (let i = 0; i < this.props.orderData.length; i++) {
                if (this.props.orderData[i].series_id === this.props.currentChannel.programs[this.props.globalProgramIndex].series_id.toString()) {
                    series = true;
                    matchedAsset = true;
                    this.setState({
                        isRecordedSingle: false,
                        isRecordedSeries: true,
                        selectedAssetInfo: this.props.currentChannel.programs[this.props.globalProgramIndex],
                        isMovie: false,
                        starRating: 0,
                    });
                    break;
                }
            }
        } else if (
            (this.props.currentChannel.programs[this.props.globalProgramIndex].show_type === 'MO' ||
                this.props.currentChannel.programs[this.props.globalProgramIndex].show_type === 'OT') &&
            this.props.currentChannel.programs[this.props.globalProgramIndex].series_id === ''
        ) {
            //case when movie is detected
            this.setState({
                isMovie: true,
                selectedAssetInfo: this.props.currentChannel,
                starRating: Number(this.props.currentChannel.programs[this.props.globalProgramIndex].star_rating),
            });
        } else {
            // everything else? currently channel7
            this.state.isMovie &&
                this.setState({
                    isMovie: false,
                    starRating: 0,
                });
        }

        if (!series) {
            this.props.assetData.forEach((obj) => {
                if (obj.program_id === this.props.currentChannel.programs[this.props.globalProgramIndex].program_id.toString()) {
                    if (obj.type === 'single') {
                        matchedAsset = true;
                        this.setState({
                            isRecordedSingle: true,
                            isRecordedSeries: false,
                            selectedAssetInfo: obj,
                        });
                    }
                }
            });
        }

        if (!matchedAsset) {
            this.setState({
                isRecordedSingle: false,
                isRecordedSeries: false,
                selectedAssetInfo: null,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.currentChannel !== this.props.currentChannel ||
            this.props.isFullScreenInfoOpen !== prevProps.isFullScreenInfoOpen ||
            this.props.isFullScreen !== prevProps.isFullScreen
        ) {
            //condition when to recalculate condition of orders and assets
            this.checkingOrdersAndAssets();
        }

        if (prevProps.isFullScreen === false && this.props.isFullScreen === true) {
            this.timer && clearTimeout(this.timer);
            this.timer = setTimeout(() => this.setState({ fullScreenForSecond: true }), 300);
            this.recalculateProgramIndex();
        }
        if (prevProps.isFullScreen === true && this.props.isFullScreen === false) {
            this.setState({ fullScreenForSecond: false });
            this.timer && clearTimeout(this.timer);
        }

        if (
            (!prevProps.isFullScreen && this.props.isFullScreen) ||
            ((prevProps.currentChannel.custom_channel_number !== this.props.currentChannel.custom_channel_number ||
                prevState.channelNumber !== this.state.channelNumber ||
                prevState.tempChannelHolder !== this.state.tempChannelHolder) &&
                !this.props.isFullScreenInfoOpen)
        ) {
            //this condition is to open infoBar on Full Screen
            this.setState({ channelNumber: this.props.currentChannel.custom_channel_number });
            !this.props.isFullScreenInfoOpen && !this.props.isChannelBarInfoOpen && this.props.onChannelBarInfo(true);
            this.setState(function (prevState) {
                return {
                    infoBarFlipper: !prevState.infoBarFlipper,
                };
            });
        }
        !this.props.isFullScreen && prevProps.isFullScreen && this.props.onFullScreenOptionsOpen(false); // close it anytime going from Full screen to small screen
    }

    toggleSubtitle = () => {
        this.props.onToggleSubtitle(!this.props.isSubtitleToggled);
        // StorageService.setItem('isSubtitleToggled', JSON.stringify(this.props.isSubtitleToggled)); //sets stored subtitle option - off for now (player may isn't ready to set subtitle right away)
    };

    closeFSOptionsMenu = (action) => {
        // console.log("action: ", action);
        switch (action) {
            case 'dismiss':
            case 'cancel':
                this.props.onFullScreenOptionsOpen(false);
                this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false); //just in case
                this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(false); //we need it for Guide
                this.props.isChannelBarInfoOpen && this.props.onChannelBarInfo(false);
                break;
            case 'openOptions':
                this.props.isFullScreenOptionsOpen && this.setState({ fsRecFlag: true }, () => this.props.onFullScreenOptionsOpen(false));
                !this.state.isRecordedSeries && !this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(true);
                this.state.isRecordedSeries && !this.props.isTicketScreenOpen && this.props.toggleTicketScreen(true);
                break;
            case 'createTicket':
                this.props.isFullScreenOptionsOpen && this.setState({ fsRecFlag: true }, () => this.props.onFullScreenOptionsOpen(false));
                !this.state.isRecordedSeries && !this.props.isTicketScreenOpen && this.props.toggleTicketScreen(true);
        }
    };

    reopenFSOptionsMenu = () => {
        this.setState({ fsRecFlag: false }, () => this.props.onFullScreenOptionsOpen(true));
    };

    navUtilityEnter = () => {
        if (this.state.tempChannelHolder !== '') {
            this.channelSelectorHelper();
            this.setState({ navUtility: false, tempChannelHolder: '' });
            this.tempString = '';
        }
    };

    closeNavUtility = () => {
        this.setState({ navUtility: false, tempChannelHolder: '' });
        this.tempString = '';
    };

    openFullScreenRecordingOptions = () => {
        this.checkingOrdersAndAssets(); //to recalculate options on opening Record Menu in Full Screen
        this.props.onFullScreenOptionsOpen(true);
    };

    recalculateProgramIndex = () => {
        let currentTime = this.props.currentTime.unix() * 1000; //in ms
        FSProgramIndex(currentTime, this.props.currentChannel.programs).then((globalProgramIndex) => {
            // console.log("globalProgramIndex: ", globalProgramIndex);
            this.props.onSetGlobalProgramIndex(globalProgramIndex);
        });
    };

    render() {
        const { globalProgramIndex, currentChannel, liveTV } = this.props;
        // let channelInfo = currentChannel;

        let chNumberName = currentChannel.custom_channel_number + ' ' + currentChannel.custom_name;
        let showStart = currentChannel.programs[globalProgramIndex].unix_start_time;
        let day = `${moment(showStart).format('ddd M/D LT')}`;
        let dayNumberName = `${day} ${chNumberName}`;

        return (
            <>
                {
                    <div className={css(styles.Container)}>
                        {/* <SkitterVideoContainer style={{ flex: 1 }} paused={!isPlaying} source={{ uri: liveTV }} /> */}
                        <WebPlayer isRecordingStream={false} source={{ uri: liveTV }} />
                    </div>
                }
            </>
        );
    }
}

const styles = StyleSheet.create({
    Container: {
        height: '100%',
        //width: '100%'
    },
});

// const styles = StyleSheet.create({
//     mainContainer: {
//         backgroundColor: '#0f0f0f',
//         position: 'absolute',
//         top: 0,
//         right: 0,
//         elevation: 0
//     },
// })
