import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { JumpBackIcon, FullScreenIcon, PrevDayIcon, NextDayIcon, GoIcon, NumIcon } from '../Common/NavUtilityIcons';

const NavUtility = (props) => {
    const { handleNavEnter, setChannel, handleFullScreen, changeDay, handleLastChannelJump } = props;

    const getNumButton = (num) => {
        const textID = '_' + num;
        const gID = 'ic_numpad_0' + num;
        return (
            <div key={num} className={css(styles.ButtonContainer)}>
                <button className={css(styles.Button)} onClick={() => setChannel(num)}>
                    <NumIcon num={num} />
                </button>
            </div>
        );
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className={css(styles.Main)}>
            <div className={css(styles.KeyRow)}>{['1', '2', '3'].map((x) => getNumButton(x))}</div>
            <div className={css(styles.KeyRow)}>{['4', '5', '6'].map((x) => getNumButton(x))}</div>
            <div className={css(styles.KeyRow)}>{['7', '8', '9'].map((x) => getNumButton(x))}</div>
            <div className={css(styles.KeyRow)}>
                <div className={css(styles.ButtonContainer)}>
                    <button
                        className={css(styles.Button)}
                        onClick={(e) => {
                            handleLastChannelJump();
                        }}>
                        <JumpBackIcon />
                    </button>
                </div>
                {getNumButton('0')}
                <div className={css(styles.ButtonContainer)}>
                    <button
                        className={css(styles.Button)}
                        onClick={(e) => {
                            handleFullScreen();
                        }}>
                        <FullScreenIcon />
                    </button>
                </div>
            </div>
            <div className={css(styles.KeyRow)}>
                <div className={css(styles.ButtonContainer)}>
                    <button
                        className={css(styles.Button)}
                        onClick={(e) => {
                            changeDay(true);
                        }}>
                        <PrevDayIcon />
                    </button>
                </div>
                <div className={css(styles.ButtonContainer)}>
                    <button
                        className={css(styles.Button)}
                        onClick={(e) => {
                            changeDay(false);
                        }}>
                        <NextDayIcon />
                    </button>
                </div>
                <div className={css(styles.ButtonContainer)}>
                    <button
                        className={css(styles.Button)}
                        onClick={(e) => {
                            handleNavEnter();
                        }}>
                        <GoIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NavUtility;

const styles = StyleSheet.create({
    Main: {
        //alignSelf:'flex-end',
        minHeight: '8em',
        position: 'absolute',
        left: '29.59em',
        top: '2.8em',
        //backgroundColor: '#202020',
        width: '16em',
        height: '30em',
        zIndex: '3',
        // color: 'white',
        display: 'flex',
        flexDirection: 'column',
    },
    KeyRow: {
        borderColor: 'yellow',
        //borderStyle: 'solid',
        //borderWidth: '3px',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        //margin: '1em 2em',
        //border: '2px solid yellow',
    },
    ButtonContainer: {
        display: 'flex',
        flex: 1,
        borderColor: 'yellow',
        border: 'none',
        // border: `2px solid white`,
        alignItems: 'center',
        justifyContent: 'center',
    },
    Button: {
        // borderStyle: 'none',
        ':focus': {
            //removes accessibility in chrome
            outline: 0,
        },
        backgroundColor: 'transparent',
        borderRadius: '100%',
        border: 'none',
        fontSize: '1em',
        transition: 'all 120ms 0ms ease-in-out',
        ':hover': {
            transform: 'scale(1.3)',
        },
        ':active': {
            transform: 'scale(1)',
        },
    },
});

// ExitButton: {
//     margin: '0 .2em',
//     padding: '.2em .5em',
//     fontSize: '1.6em',
//     right: 0,
//     position: 'absolute',
//     ':hover': {
//         cursor: 'pointer',
//         backgroundColor: '#606060',
//         borderRadius: '3em',
//         pointer: 'arrow',
//     },
// },
