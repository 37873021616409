import React, { Component } from 'react';
//import shaka from 'shaka-player'
import { StyleSheet, css } from 'aphrodite';
import { getSotalLiveStream, getSotalRecording } from '../../services/SotalCloudService';
import { errorHandler } from '../../utils/ErrorHandler';
import { FSProgramIndex } from '../../utils/FSProgramIndex';
import ReactLoading from 'react-loading';
import './shaka.css';
import moment from 'moment';
import muxjs from 'mux.js';
import SeasonMyTicket from '../Guide/Containers/SeasonMyTicketContainer';
import '../../controls.css';
import { update } from 'lodash';
var youbora = require('youboralib');
const shaka = require('shaka-player/dist/shaka-player.ui.js');
//import * as shaka from '../../shaka-player/dist/shaka-player.ui.js'; // dist/shaka-player/dist/shaka-player.ui.js');
// var manifestUri = 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd'; //Trick Mode
// var manifestUri = 'https://bitmovin-a.akamaihd.net/content/art-of-motion_drm/mpds/11331.mpd'; //Widevine DRM
//var manifestUri = 'https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd'; //WebVTT
export default class WebPlayer extends Component {
    constructor(props) {
        super(props);

        //Creating reference to store video component on DOM
        this.videoComponent = React.createRef();

        //Creating reference to store video container on DOM
        this.videoContainer = React.createRef();

        this.state = {
            fullscreen: false,
            isPlaying: true,
            liveUrl: '',
            liveDrm: '',
            drmUrl: '',
            streamUrl: null,
            sourceError: false,
            noSourceUriError: false,
            showIcon: false,
            optionsOpen: false,
            currentProgram: null,
            displayChannel: {},
            restartPosition: 0,
            // resets retryAttempts only when a new program is started
            retryAttempts: 0,
            maxRetryAttempts: 5,
            cancelToken: '',
        };
        this.timeoutToken = '';
        this.videoPosition = 0;
        this.player = null;
        this.clearRetryTimer = '';
        window.muxjs = muxjs;
    }

    createPlaceholders({ container }) {
        const uiContainer = document.createElement('div');
        container.appendChild(uiContainer);

        const videoElement = document.createElement('video');
        videoElement.style.maxWidth = '100%';
        uiContainer.appendChild(videoElement);

        return {
            uiContainer,
            videoElement,
        };
    }

    componentDidMount() {
        /**
         * Creates a container for the UI and a video element.
         * @param {HTMLDivElement} container
         * @returns {{uiContainer: HTMLDivElement, videoElement: HTMLVideoElement}}
         */
        this.setState(() => {
            if (this.props.recording) {
                return {
                    currentProgram: this.props.recording,
                };
            } else {
                return {
                    currentProgram: this.props.currentChannel.programs[this.props.globalProgramIndex],
                    displayChannel: this.props.currentChannel,
                };
            }
        });
        const video = this.videoComponent.current;
        const videoContainer = this.videoContainer.current;
        this.player = new shaka.Player(this.videoComponent.current);
        window.plugin.setAdapter(new youbora.adapters.Shaka(this.player));
        video.addEventListener('pause', () => {
            setTimeout(() => {
                if (!this.state.isPlaying) {
                    this.setState(
                        {
                            showIcon: true,
                        },
                        () => {
                            clearTimeout(this.timeoutToken);
                            this.timeoutToken = setTimeout(() => {
                                this.setState({ showIcon: false });
                            }, 7000);
                        }
                    );
                } else {
                    clearTimeout(this.timeoutToken);
                    this.timeoutToken = setTimeout(() => {
                        this.setState({ showIcon: false });
                    }, 500);
                }
            }, 0);
            this.updateVideoPlaying(false);
        });
        video.addEventListener('play', () => {
            setTimeout(() => {
                if (!this.state.isPlaying) {
                    this.setState(
                        {
                            showIcon: true,
                        },
                        () => {
                            clearTimeout(this.timeoutToken);
                            this.timeoutToken = setTimeout(() => {
                                this.setState({ showIcon: false });
                            }, 7000);
                        }
                    );
                } else {
                    clearTimeout(this.timeoutToken);
                    this.timeoutToken = setTimeout(() => {
                        this.setState({ showIcon: false });
                    }, 500);
                }
            }, 0);
            this.updateVideoPlaying(true);
        });
        videoContainer.addEventListener('fullscreenchange', this.updateFullScreen);
        window.plugin.enable();
        window.plugin.fireInit();

        this.ui = new shaka.ui.Overlay(this.player, videoContainer, video);
        this.controls = this.ui.getControls();
        this.uiLoader();
        this.player.configure({
            preferredTextLanguage: 'el',
            streaming: {
                bufferBehind: 100000,
            },
        });

        // Install built-in polyfills to patch browser incompatibilities.
        shaka.polyfill.installAll();

        window.globalVideoRef = this.videoComponent.current;

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
            if (!this.props.isDeviceLimitReached) {
                // Everything looks good!
                this.fetchSotalStream(true);
                //this.initPlayer();
            } else {
                console.log('device limit reached');
            }
        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }

        // setTimeout(() => {
        //     this.getWrongStream()
        // }, 30000)
    }

    initPlayer = () => {
        const { liveUrl, liveDrm } = this.state;
        console.log('drm state', this.state);
        // const video = document.getElementById('video');
        // const ui = this.refs.video['ui'];
        // const controls = ui.getControls();
        // const player = controls.getPlayer();
        //widvine server configuration
        this.setDrm(liveDrm);
        // Listen for error events.
        this.player.addEventListener('error', this.onErrorEvent);
        // this.player.addEventListener('buffering', this.onBufferingEvent);
        // this.player.addEventListener('onstatechange', this.onStateChange);
        // this.player.addEventListener('loaded', this.onLoaded);
        // this.player.addEventListener('drmsessionupdate', this.onDRMSessionUpdate);
        // this.player.addEventListener('largegap', this.onLargeGap)
        // Try to load a manifest.
        // This is an asynchronous process.
        // if (this.props.source.uri) {
        //     manifestUri = this.state.sotalURL;
        // }
        //console.log(this.state.sotalURL);
        //window.plugin.fireInit();
        this.player
            .load(liveUrl)
            .then(() => {
                //window.plugin.setAdapter(new youbora.adapters.Shaka(this.player));
                // setTimeout(() =>{
                this.setYouboraData();

                //     this.player.trickPlay(2.5);
                // }, 5000);
                // setTimeout(() =>{
                //     this.player.trickPlay(-2.5);
                // }, 10000);
                // setTimeout(() => {
                //     //console.log("set text visiblity");
                //     this.player.addTextTrack('https://example.com/foo.vtt', 'en', 'caption', 'text/vtt');

                // this.props.isRecordingStream && this.controls.toggleFullScreen();
                if (this.props.isRecordingStream) {
                    this.controls.toggleFullScreen();
                    this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000);
                }
                // this.videoComponent.current.requestFullscreen().catch((err) => {
                //     //console.log('can not launch fullscreen');
                // });
                // }, 10000);
                //This runs if the asynchronous load is successful.
                //console.log('The video has now been loaded!');
            })
            .catch(this.onError); // onError is executed if the asynchronous load fails.
    };

    // onLargeGap = (currentTime, gapSize) => {
    //     console.log("** large gap",currentTime, gapSize)
    // }

    // onDRMSessionUpdate = () => {
    //     console.log("** drmsessionupdate accepted license resposne")
    // }

    // onLoaded = () => {
    //     console.log("** loaded")
    // }

    // onBufferingEvent = (buffering) => {
    //     console.log("** on buffering event", buffering)
    // }

    // onStateChange = (state) => {
    //     console.log("** on state change", state)
    // }

    fetchSotalStream = (isInitial) => {
        const { isRecordingStream, recording, restartProgramInProgress } = this.props;
        this.setState({ fetchingSotalStream: true }, () => {
            if (!isRecordingStream && !restartProgramInProgress) {
                // window.plugin.fireInit();
                // console.log('window.plugin.fireInit()');
                getSotalLiveStream(this.props.currentChannel.sotal_cloud_channel_asset_id)
                    .then((res) => {
                        console.log('fetchSotalStream res', res);
                        //if(this.mounted){ //prevent asynchronous setstate on unmounted
                        this.setState({ fetchingSotalStream: false, fetchStreamSuccessful: true, liveUrl: res.streamURL, liveDrm: res.drmLicenseURL }, () => {
                            if (isInitial) {
                                this.initPlayer();
                            } else {
                                this.getCorrectStream();
                            }
                        });
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                errorMessage: 'No Stream Available',
                                noSourceUriError: true,
                                sourceError: false,
                                fetchingSotalStream: false,
                                fetchStreamSuccessful: false,
                                liveUrl: '',
                                liveDrm: '',
                            },
                            () => {
                                //this.props.isPauseLiveActive && this.props.onUpdatePauseLiveActive(false);
                                errorHandler(e, 'get sotal live stream', this.props.subscriberData.subDeviceInfo);
                            }
                        );
                    });
            } else if (restartProgramInProgress) {
                this.getCorrectStream();
            } else {
                // cdvr
                window.plugin.enable();
                window.plugin.fireInit();
                getSotalRecording(this.props.recording.sc_id)
                    .then((res) => {
                        //get stream at recording program name for now
                        //if(this.mounted){ //prevent asynchronous setstate on unmounted
                        if (res && res.streamURL) {
                            this.setState(
                                {
                                    fetchingSotalStream: false,
                                    fetchStreamSuccessful: true,
                                    liveUrl: res.streamURL,
                                    liveDrm: res.drmLicenseURL,
                                },
                                () => {
                                    this.props.isPauseLiveActive && this.props.onUpdatePauseLiveActive(false);
                                    this.initPlayer();
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    errorMessage: 'No Stream Available',
                                    noSourceUriError: true,
                                    sourceError: false,
                                    fetchingSotalStream: false,
                                    fetchStreamSuccessful: false,
                                    liveUrl: '',
                                    liveDrm: '',
                                },
                                () => {
                                    //this.props.isPauseLiveActive && this.props.onUpdatePauseLiveActive(false);
                                    let error = new Error('invlaid sotal recording response');
                                    error.extraFields = {
                                        invalid_response: res,
                                        recording_sc_id: this.props.recording.sc_id,
                                    };
                                    //TODO: add in subscriber data for error handler
                                    // errorHandler(error, 'get sotal recording stream', this.props.subscriberData.subDeviceInfo);
                                }
                            );
                        }
                        //}
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                errorMessage: 'No Stream Available',
                                noSourceUriError: true,
                                sourceError: false,
                                fetchingSotalStream: false,
                                fetchStreamSuccessful: false,
                                liveUrl: '',
                                liveDrm: '',
                            },
                            () => {
                                //this.props.isPauseLiveActive && this.props.onUpdatePauseLiveActive(false);
                                //TODO: add in subscriber data for error handler
                                // errorHandler(e, 'get sotal recording stream', this.props.subscriberData.subDeviceInfo);
                            }
                        );
                    });
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {
        // this.videoComponent.current.pause()
        if (prevState.currentProgram?.schedule_id !== this.state.currentProgram?.schedule_id && this.state.restartPosition) {
            this.setState({ restartPosition: 0 });
        }

        if (this.props.currentChannel !== prevProps.currentChannel) {
            this.setState({ retryAttempts: 0, restartPosition: 0 });
        }

        // if (prevProps.restartProgramInProgress !== this.props.restartProgramInProgress) {
        //     if (this.props.restartProgramInProgress) {
        //         this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000)
        //     } else {
        //         clearInterval(this.clearVideoInterval)
        //     }
        // }

        const { isRecordingStream, restartProgramInProgress } = this.props;
        if (!isRecordingStream) {
            if (
                (prevProps.currentChannel.sotal_cloud_channel_asset_id !== this.props.currentChannel.sotal_cloud_channel_asset_id || //new channel
                    (prevProps.triggerLiveChannelReload !== this.props.triggerLiveChannelReload && !this.props.restartProgramInProgress) ||
                    (this.props.restartStreamInfo.streamURL && this.props.restartStreamInfo.streamURL !== prevProps.restartStreamInfo.streamURL) || //new restart stream
                    (prevProps.currentChannel.sotal_cloud_channel_asset_id === this.props.currentChannel.sotal_cloud_channel_asset_id && //restart -> live, on same channel
                        !restartProgramInProgress &&
                        prevProps.restartProgramInProgress)) &&
                !this.props.isDeviceLimitReached
            ) {
                this.fetchSotalStream(); //chan change                //!this.props.restartProgramInProgress &&
            } else if (!this.props.isDeviceLimitReached && prevProps.isDeviceLimitReached) {
                if (restartProgramInProgress) {
                    this.props.onStartRestartProgram(this.props.restartProgram, this.props.restartProgram.restartResource);
                } else {
                    this.fetchSotalStream();
                }
            }
        } else {
            if (!this.props.isDeviceLimitReached && prevProps.isDeviceLimitReached) {
                this.fetchSotalStream(true);
            }
        }
        if (
            prevProps.globalProgramIndex !== this.props.globalProgramIndex ||
            JSON.stringify(this.props.restartProgram) !== JSON.stringify(prevProps.restartProgram)
        ) {
            this.setState(() => {
                if (this.props.restartProgramInProgress) {
                    return {
                        // resets retryAttempts only when a new program is started
                        currentProgram: this.props.restartProgram,
                        displayChannel: this.props.restartChannel,
                        retryAttempts: 0,
                        restartPosition: 0,
                    };
                }
                if (this.props.recording) {
                    return {
                        currentProgram: this.props.recording,
                        retryAttempts: 0,
                        restartPosition: 0,
                    };
                } else {
                    return {
                        currentProgram: this.props.currentChannel.programs[this.props.globalProgramIndex],
                        displayChannel: this.props.currentChannel,
                        retryAttempts: 0,
                        // restartPosition: 0,
                    };
                }
            });
        }
    }

    // getWrongStream() {
    //     console.log("## getting correct stream")
    //     //checks for special stream cases first
    //     const { restartStreamInfo, restartProgramInProgress, restartProgram, isRecordingStream } = this.props;
    //     const { liveUrl, liveDrm, fetchingSotalStream, fetchStreamSuccessful } = this.state;
    //     let url = '';
    //     let drmUrl = '';
    //     // if (restartProgramInProgress) {
    //     //     this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000);

    //     //     url = restartStreamInfo.streamURL;
    //     //     drmUrl = restartStreamInfo.drmLicenseURL;
    //     //     // } else if (isPauseLiveActive) {
    //     //     //     url = this.props.source; //needed?
    //     // } else if (fetchingSotalStream) {
    //     //     url = null;
    //     //     drmUrl = null;
    //     // } else if (fetchStreamSuccessful) {
    //     //     if (isRecordingStream) {
    //     //         this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000);
    //     //     }
    //     //     url = liveUrl;
    //     //     drmUrl = liveDrm;
    //     // }

    //     if (url !== this.state.streamUrl) {
    //         window.plugin.fireStop();
    //         window.plugin.disable();
    //         this.player.unload().then(() => {
    //             window.plugin.enable();
    //             window.plugin.fireInit();
    //             this.setState({ streamUrl: url, drmUrl: drmUrl });
    //             this.setDrm(drmUrl);
    //             console.log("## hits here")
    //             this.player
    //                 .load(url)
    //                 .then(() => {
    //                     console.log("## hits after load")
    //                     this.setYouboraData();
    //                     console.log("## setting retry timer to blank string")
    //                     this.clearRetryTimer = ""
    //                     // if (this.props.restartProgramInProgress || isRecordingStream) {
    //                     //     this.player.getMediaElement().currentTime = this.state.restartPosition + 5 * this.state.retryAttempts;
    //                     // }
    //                     // resets retryAttempts only when a new program is started (in component did update)
    //                     this.setState({ sourceError: false, noSourceUriError: false });
    //                 })
    //                 .catch((e) => {
    //                     console.log("## catches error")
    //                     this.onError(e)});
    //         });
    //     } else {
    //         return null;
    //     }

    //     // if(restartProgramInProgress){
    //     //     console.log('***A', restartURL);
    //     //     return restartURL;
    //     // }else if(isPauseLiveActive){
    //     //     return this.props.source;
    //     // }else if(fetchingSotalStream){
    //     //     return null;
    //     // }else if (fetchStreamSuccessful){
    //     //     return sotalURL;
    //     // }
    //     // return this.props.source; //default
    // }

    getCorrectStream() {
        //checks for special stream cases first
        const { restartStreamInfo, restartProgramInProgress, restartProgram, isRecordingStream } = this.props;
        const { liveUrl, liveDrm, fetchingSotalStream, fetchStreamSuccessful } = this.state;
        let url = 'd';
        let drmUrl = 'd';

        if (restartProgramInProgress) {
            this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000);

            url = restartStreamInfo.streamURL;
            drmUrl = restartStreamInfo.drmLicenseURL;
            // } else if (isPauseLiveActive) {
            //     url = this.props.source; //needed?
        } else if (fetchingSotalStream) {
            url = null;
            drmUrl = null;
        } else if (fetchStreamSuccessful) {
            if (isRecordingStream) {
                this.clearVideoInterval = setInterval(this.saveVideoProgress, 1000);
            }
            url = liveUrl;
            drmUrl = liveDrm;
        }

        if (url !== this.state.streamUrl) {
            window.plugin.fireStop();
            window.plugin.disable();
            this.player.unload().then(() => {
                window.plugin.enable();
                window.plugin.fireInit();
                this.setState({ streamUrl: url, drmUrl: drmUrl });
                this.setDrm(drmUrl);
                this.player
                    .load(url)
                    .then(() => {
                        this.setYouboraData();
                        this.clearRetryTimer = '';
                        if (this.props.restartProgramInProgress || isRecordingStream) {
                            this.player.getMediaElement().currentTime = this.state.restartPosition + 5 * this.state.retryAttempts;
                        }
                        // resets retryAttempts only when a new program is started (in component did update)
                        this.setState({ sourceError: false, noSourceUriError: false });
                    })
                    .catch((e) => {
                        this.clearRetryTimer = '';
                        this.onError(e);
                    });
            });
        } else {
            return null;
        }

        // if(restartProgramInProgress){
        //     console.log('***A', restartURL);
        //     return restartURL;
        // }else if(isPauseLiveActive){
        //     return this.props.source;
        // }else if(fetchingSotalStream){
        //     return null;
        // }else if (fetchStreamSuccessful){
        //     return sotalURL;
        // }
        // return this.props.source; //default
    }

    onErrorEvent = (event) => {
        // Extract the window.shaka.util.Error object from the event.
        if (this.props.restartProgramInProgress || this.props.recording) {
            this.setState({
                restartPosition: this.videoComponent.current.currentTime,
            });
        }

        this.onError(event.detail);
    };

    setDrm = (url) => {
        // console.log('drm url', url);
        this.player.configure({
            //clockSyncUri: 'https://skitter-origin002.sotalcloud.com/',
            drm: {
                servers: {
                    'com.widevine.alpha': url,
                    //'com.widevine.alpha': 'https://cloud.noisypeak.com/wvproxy/proxy?provider=skitter&content_id=303030',
                    // 'com.microsoft.playready': 'https://foo.bar/drm/playready'
                },
                advanced: {
                    'com.widevine.alpha': {
                        videoRobustness: 'SW_SECURE_CRYPTO',
                        audioRobustness: 'SW_SECURE_CRYPTO',
                    },
                },
            },
        });
    };

    // componentDidUpdate() {
    //     console.log(this.state.sotalURL);
    // }

    onError = (error) => {
        clearInterval(this.clearVideoInterval);

        //source error
        console.log('onError');
        console.error('Error code ------------------------------', error.code, 'object', error);
        ///send bugsnag
        let e = new Error('Shaka source error' + error.code);
        e.extraFields = { liveUrl: this.state.liveUrl, sourceError: error };
        errorHandler(e, 'Shaka source error' + error.code, null);
        let codeString = error.code ? error.code.toString() : '';

        let extendedCode = codeString + (error.data && error.data[1] ? ' - ' + error.data[1] : ''); //includes http status code, if available

        if (error.data && error.data[0]?.data && error.data[0]?.data[1] && error.data && error.data[0]?.data[1] === 412) {
            this.exitFullscreenVideo();
            window.plugin.fireStop();
            window.plugin.disable();
            this.player.unload();
            this.props.onSetDeviceLimitReached(true);
        } else if (error.data && error.data[1] && error.data[1] === 401) {
            //console.log('getting 401'); //ignoring 401s from shaka and only handling the 412s
        } else {
            this.exitFullscreenVideo();
            console.log('error being set :', error);
            this.setState({ sourceError: true, errorCode: extendedCode, noSourceUriError: false });
            // resets retryAttempts only when a new program is started
            if (this.clearRetryTimer === '') {
                if (this.state.retryAttempts < this.state.maxRetryAttempts) {
                    this.clearRetryTimer = setTimeout(() => {
                        this.setState({ retryAttempts: this.state.retryAttempts + 1, streamUrl: null }, this.getCorrectStream);
                    }, 3000);
                }
            }
        }
    };

    saveVideoProgress = () => {
        if (this.player) {
            if (this.videoComponent.current.currentTime !== 0) {
                // to avoid restting the time
                this.setState({
                    restartPosition: this.videoComponent.current.currentTime,
                });
            }
        }
    };

    uiLoader = () => {
        const ui = this.videoComponent.current['ui'];
        console.log('currentconfig', ui.config);
        const config = {
            seekBarColors: {
                base: 'rgba(255,255,255, 0.35)',
                buffered: 'rgba(255,255,255, 0.6)',
                played: 'rgba(255,255,255, 0.8)',
            },
            volumeBarColors: {
                base: 'rgba(255,255,255, 0.6)',
                level: 'rgba(255,255,255, 0.8)',
            },
            overflowMenuButtons: ['captions', 'quality', 'language', 'picture_in_picture', 'airplay'],
        };

        if (this.props.isRecordingStream) {
            config.overflowMenuButtons.push('playback_rate');
        }

        ui.configure(config);

        //const controls = ui.getControls();
    };
    exitFullscreenVideo = () => {
        if (window.document.fullscreenElement?.className.includes('shaka-video-container')) {
            //exit fullscreen video
            window.document.exitFullscreen();
        }
    };

    playerCleanup = () => {
        window.globalVideoRef = null;
        window.plugin.fireStop();
        window.plugin.disable();

        if (this.player) {
            this.player.destroy();
            this.player = null;
        }
        if (this.ui) {
            this.ui = null;
        }
    };

    recalculateProgramIndex = () => {
        let currentTime = this.props.currentTime.unix() * 1000; //in ms
        FSProgramIndex(currentTime, this.props.currentChannel.programs).then((globalProgramIndex) => {
            // console.log("globalProgramIndex: ", globalProgramIndex);
            this.props.onSetGlobalProgramIndex(globalProgramIndex);
        });
    };

    componentWillUnmount() {
        clearInterval(this.state.cancelToken);
        this.playerCleanup();
    }

    togglePlayPause = (e) => {
        // e.stopPropagation();
        //this.player.current && this.player.current.requestPictureInPicture();
        if (this.state.isPlaying) {
            this.videoComponent.current && this.videoComponent.current.pause();
        } else {
            this.videoComponent.current && this.videoComponent.current.play();
        }
    };

    updateFullScreen = () => {
        if (window.document.fullscreenElement?.className.includes('shaka-video-container')) {
            this.setState({
                fullscreen: true,
            });
        } else {
            this.setState({
                fullscreen: false,
            });
        }
    };

    updateVideoPlaying = (isPlaying) => {
        this.setState({ isPlaying });
    };

    handleMouseClick = () => {
        setTimeout(() => {
            if (!this.state.isPlaying) {
                this.setState(
                    {
                        showIcon: true,
                    },
                    () => {
                        clearTimeout(this.timeoutToken);
                        this.timeoutToken = setTimeout(() => {
                            this.setState({ showIcon: false });
                        }, 7000);
                    }
                );
            } else {
                clearTimeout(this.timeoutToken);
                this.timeoutToken = setTimeout(() => {
                    this.setState({ showIcon: false });
                }, 500);
            }
        }, 0);
        this.togglePlayPause();
    };

    getTime = (currentProgram) => {
        if (currentProgram.sc_id) {
            return `${moment.unix(currentProgram.unix_start_time).format('hh:mm A')} - ${moment.unix(currentProgram.unix_end_time).format('hh:mm A')}`;
        } else if (currentProgram.unix_start_time) {
            return `${moment(Number(currentProgram.unix_start_time)).format('hh:mma').toUpperCase()} - ${moment(Number(currentProgram.unix_end_time))
                .format('hh:mma')
                .toUpperCase()}`;
        } else {
            return '';
        }
    };

    setYouboraData = () => {
        const { restartProgramInProgress, currentChannel, globalProgramIndex, isRecordingStream, recording } = this.props; //debugTrackInfo, debugNumOfTracks, isPauseLiveActive
        const { fetchStreamSuccessful } = this.state;
        let programData = '';
        let streamType = '';
        let programChannel = ''; //using callsign as channel
        if (restartProgramInProgress) {
            //////// rstv
            programData = this.props.restartProgram;
            streamType = 'restart';
            //} else if (isPauseLiveActive) {////////pause live
            //      programData = '';
        } else if (fetchStreamSuccessful && !isRecordingStream) {
            //////// live
            let selectedGuideProgram = currentChannel.programs[globalProgramIndex];
            //console.log('selectedGuideProgram', selectedGuideProgram);
            if (selectedGuideProgram) {
                programChannel = currentChannel.custom_name; // setting channel when on live/pause-live
                programData = selectedGuideProgram;
                // if (isPauseLiveActive) {
                //     streamType = "pause-live"
                // } else {
                streamType = 'live';
                //}
            }
        } else if (isRecordingStream) {
            //////// cdvr
            //console.log('isRecordingStream === true in player!!! recording:', recording);
            let recordingWithTimestamp = { ...recording };
            recordingWithTimestamp.playbackTimestamp = this.videoPosition;
            programData = recordingWithTimestamp;
            if (programData.unix_start_time && programData.unix_end_time) {
                //reformatting for cdvr
                programData.unix_start_time = +programData.unix_start_time * 1000;
                programData.unix_end_time = +programData.unix_end_time * 1000;
            }
            streamType = 'cdvr';
        }

        if (programData !== '') {
            programData.streamType = streamType;
            programData.channel = programChannel;
            // if (debugTrackInfo)
            //     programData = {...programData, ...debugTrackInfo};
            // if (debugNumOfTracks)
            //     programData = {...programData, ...debugNumOfTracks};
            // if (debugBitrateInfo)
            //     programData = {...programData, ...debugBitrateInfo};
        }

        //window.plugin.setAdapter(new youbora.adapters.Shaka(this.player));
        //window.plugin.fireInit();
        const youboraOptions = {
            'content.isLive': streamType === 'live',
            'content.title': programData.long_title ? programData.long_title : '',
            'content.duration': programData.unix_end_time && programData.unix_start_time ? (programData.unix_end_time - programData.unix_start_time) / 1000 : 0,
            'content.program': programData.schedule_id ? programData.schedule_id.toString() : '',
            'content.playbackType': programData.streamType ? programData.streamType : '',
            'content.genre': programData.category ? programData.category : '',
            'content.season': programData.se_name ? programData.se_name : '',
            'content.id': programData.schedule_id ? programData.schedule_id.toString() : '',
            'content.customDimension.1': this.props.subscriberData && this.props.subscriberData.telco_name ? this.props.subscriberData.telco_name : '',
            'content.customDimension.2': programData.source_id ? programData.source_id : '',
            'content.channel': programData.channel ? programData.channel : '',
            username: this.props.subscriberData && this.props.subscriberData.id ? this.props.subscriberData.id.toString() : '',
            'content.metadata': {
                genres: programData.genres && programData.genres.length > 0 ? programData.genres.join(' ') : '',
                programStartFormatted: programData.start_formated ? programData.start_formated : '',
                programEpNumber: programData.ep_number ? programData.ep_number : '',
                programPid: programData.program_id ? programData.program_id : '',
                programRating: programData.program_rating ? programData.program_rating : '',
                programReleaseYear: programData.release_year ? programData.release_year : '',
                programSeriesId: programData.series_id ? programData.series_id : '',
                programShowType: programData.show_type ? programData.show_type : '',
                programEpName: programData.ep_name ? programData.ep_name : '',
                programTvRating: programData.tv_rating ? programData.tv_rating : '',
                programStreamType: programData.streamType ? programData.streamType : '',
            },
        };
        window.plugin.setOptions(youboraOptions);
        //return programData;
    };

    render() {
        let currentProgram = this.state.currentProgram;
        let chanLogo;

        const airingtime = currentProgram && this.getTime(currentProgram);

        if (currentProgram && currentProgram.channelLogos) {
            let channelLogos = currentProgram.channelLogos[0].logos;

            for (let g = 0; g < channelLogos.length; g++) {
                // if (channelLogos[g].file_url.includes('white') && !channelLogos[g].file_url.includes('2400')) {
                //     chanLogo = channelLogos[g].file_url;
                //     break;
                // }
                if (channelLogos[g].file_url.includes('white') && !channelLogos[g].file_url.includes('2400') && !channelLogos[g].file_url.includes('_4')) {
                    chanLogo = channelLogos[g].file_url;
                    break;
                }
            }
        } else if (this.state.displayChannel) {
            chanLogo = this.state.displayChannel.station_logo;
        } else {
            chanLogo = '';
        }
        let channelName = currentProgram && currentProgram.channel ? currentProgram.channel : '';
        try {
            if (chanLogo === undefined) {
                channelName = '';
            } else if (channelName.includes('HD')) {
                channelName = channelName.split('HD')[0];
            } else if (channelName.includes('(')) {
                channelName = channelName.split('(')[0];
            }
        } catch (e) {
            console.log(e);
            channelName = '';
        }

        return (
            <div className={css(styles.mainContainer)}>
                {/* { !(this.state.sourceError || this.state.noSourceUriError || this.props.isDeviceLimitReached) && */}
                <div
                    onClick={this.handleMouseClick}
                    style={{
                        zIndex: this.state.sourceError || this.state.noSourceUriError || this.props.isDeviceLimitReached ? -1 : 0,
                        height: '99.6%',
                    }}
                    className={'shaka-player-container'}
                    id={'videoPlayer'}
                    ref={this.videoContainer}>
                    {/* should have condition to check  if there's error's before displaying video... do have something^..  */}
                    <video
                        id="videoId"
                        className={css(styles.videoStyles)}
                        ref={this.videoComponent}
                        //poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
                        controls={false}
                        autoPlay={!this.props.isDeviceLimitReached}
                    />
                    {currentProgram &&
                        (this.state.fullscreen ? (
                            <>
                                {/* <img className={css(styles.myLogo)} src={require('../../assets/SC_SVGs/ic_small_logo.svg')} /> */}
                                <div
                                    className={css(styles.fullVideoOverlay)}
                                    style={this.state.showIcon ? { visibility: 'visible', opacity: 1 } : { visibility: 'hidden', opacity: 0 }}>
                                    <div className={css(styles.channelInfo)}>
                                        {chanLogo && (
                                            <img
                                                alt="channel logo"
                                                className={css(styles.channelLogo)}
                                                src={chanLogo ? chanLogo : 'currentChannel.station_logo'}
                                            />
                                        )}
                                        <div className={css(styles.timeSlot)}>
                                            <div style={{ marginRight: '.5em', fontWeight: 'bold' }}>{airingtime}</div>
                                        </div>
                                        <div className={css(styles.channelDetails)}>
                                            <div style={{ marginRight: currentProgram.sc_id ? 0 : '.5em', fontWeight: 'bold' }}>
                                                {/* shows channel number if on guide */}
                                                {this.state.displayChannel && !currentProgram.sc_id
                                                    ? `${this.state.displayChannel.custom_channel_number} HD `
                                                    : ''}
                                            </div>
                                            <div>{this.state.displayChannel ? this.state.displayChannel.ch_name : channelName}</div>
                                        </div>
                                    </div>
                                    <div className={css(styles.episodeName)}>{currentProgram.long_title}</div>
                                    <div className={css(styles.descriptionContainer)}>
                                        <h3>
                                            {currentProgram.episode_name ? currentProgram.episode_name : currentProgram.ep_name ? currentProgram.ep_name : ''}
                                        </h3>
                                        {currentProgram.description
                                            ? currentProgram.description
                                            : currentProgram.program_desc
                                            ? currentProgram.program_desc
                                            : 'no details available'}
                                    </div>
                                    <div className={css(styles.optionsContainer)}>
                                        <button className={css(styles.button)} onClick={this.togglePlayPause}>
                                            {this.state.isPlaying ? 'Stop' : 'Play'}
                                        </button>
                                        {/* <button
                                            className={css(styles.button)}
                                            onClick={() => {
                                                this.setState({ optionsOpen: true });
                                            }}>
                                            Options
                                        </button> */}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                            // <img className={css(styles.myLogo)} src={require('../../assets/SC_SVGs/ic_small_logo.svg')} />
                        ))}
                </div>
                {/* {this.state.optionsOpen &&  
                    // <SeasonMyTicket
                    // channelInfo={this.state.currentChannel}
                    // programIndex={this.props.programIndex}
                    // postRecording={this.props.postRecording}
                    // // selectedProgram={this.state.selectedProgram}
                    // channelsList={this.props.channelsList}
                    // isLoadingChannels={this.props.isLoadingChannels}
                    // // isRecordedSeries={selectedProgram.recordedSeries}
                    // selectedAssetInfo={this.state.selectedAssetInfo}
                    // onOptionClose={this.props.onOptionClose}
                    // isCurrentlyMyShows={false}
                    // />
                // */}
                {this.props.isDeviceLimitReached &&
                    (this.props.isSotalRefreshLoading ? (
                        <div className={css(styles.ErrorBackground)}>
                            <div className={css(styles.LoadingContainer)}>
                                <div>Retrying</div>
                                <ReactLoading type={'spinningBubbles'} color={'#ffffff'} height={'2em'} width={'2em'} />
                            </div>
                        </div>
                    ) : (
                        <div className={css(styles.ErrorBackground)}>
                            <div className={css(styles.ErrorContainer)}>You have reached your device limit of {this.props.subscriberData.device_limit}</div>
                            <div
                                onClick={() => {
                                    // this.props.onRefreshSotalToken();
                                    if (this.props.restartProgramInProgress || this.props.recording) {
                                        this.videoComponent.current.currentTime = this.state.restartPosition;
                                    }
                                    this.props.onSetDeviceLimitReached(false);
                                }}
                                className={css(styles.limitRetryButton)}>
                                Force retry
                            </div>
                        </div>
                    ))}
                {this.state.sourceError && !this.props.isDeviceLimitReached && (
                    <div className={css(styles.ErrorBackground)}>
                        <div
                            className={css(
                                styles.ErrorContainer
                            )}>{`error : ${this.state.errorCode} Retry Attempt: ${this.state.retryAttempts} out of ${this.state.maxRetryAttempts}`}</div>
                    </div>
                )}
                {this.state.noSourceUriError && !this.props.isDeviceLimitReached && (
                    <div className={css(styles.UnavailableErrorContainer)}>{this.state.errorMessage}</div>
                )}
            </div>
        );
    }
}

const styles = StyleSheet.create({
    mainContainer: {
        position: 'relative',
        height: '100%',
        width: '100%', //preserving 16:9 ratio
        backgroundColor: 'black',
        // display: 'flex',
        // justifyContent: 'center',
        // textAlign: 'center',
        marginLeft: 'auto',
    },
    videoStyles: {
        // position: 'absolute',
        height: '100%',
        width: '100%',
    },
    videoStylesz: {
        // position: 'absolute',
        //width: '100%',
        //display: 'hidden',
        height: '99.6%',
    },
    ErrorContainer: {
        //position: 'relative',
        //display: 'flex',
        //backgroundColor: '#333',
        //top: '40%',
        //left: '32%', //'calc(100vh - 81px),
        color: 'white',
        borderRadius: '.5em',
        padding: '1em',
        maxWidth: '80%',
        height: '1.5em',
        //alignSelf: 'center'
    },
    limitRetryButton: {
        //position: 'relative',
        //display: 'flex',
        backgroundColor: '#222',
        //top: '40%',
        //left: '32%', //'calc(100vh - 81px),
        color: 'white',
        borderRadius: '10em',
        padding: '.2em 1.5em',
        maxWidth: '80%',
        height: '1.5em',
        border: '0.1em solid white',
        marginTop: '1em',
        //alignSelf: 'center'
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#606060',
        },
    },
    ErrorBackground: {
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',
        height: '100%',
        //textAlign: 'center',
        //border: '1px solid yellow',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        //alignContent: 'center',
        alignItems: 'center',
        //flex: 1,
    },
    LoadingContainer: {
        backgroundColor: '#333',
        //top: '40%',
        //left: '32%', //'calc(100vh - 81px),
        color: 'white',
        borderRadius: '.5em',
        padding: '1em',
        width: '8em',
        height: '1.5em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    UnavailableErrorContainer: {
        position: 'absolute',
        //display: 'flex',
        backgroundColor: '#333',
        top: '40%',
        //alignSelf: 'center',
        left: '28%',
        color: 'white',
        borderRadius: '.5em',
        padding: '1em',
    },
    UIStyles: {},
    iconStyle: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        height: '15%',
        width: '15%',
        color: 'rgb(15,82,186, 0.8)',
    },
    videoOverlay: {
        position: 'absolute',
        // width: "1.5em",
        color: 'white',
        left: '2em',
        bottom: '3em',
    },
    fullVideoOverlay: {
        cursor: 'pointer',
        zIndex: '5',
        position: 'absolute',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        // width: "1.5em",
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(0,0,0,0.65)',
        background: 'linear-gradient(to top, rgba(0,0,0,.99) 0%, rgba(0,0,0,0.01) 100%)',
        // boxShadow: '0 0 2em 5em rgba(0,0,0,0.8)',
        pointerEvents: 'none',
        color: 'white',
        paddingBottom: '8em',
        bottom: '0em',
        paddingLeft: '3em',
        // visibility: 'visible',
        // opacity: '1',
        transition: ' all 0.6s ease',
    },
    hidden: {
        visibility: 'hidden',
        opacity: '0',
        transition: 'all 0.6s ease-out',
    },
    myLogo: {
        position: 'absolute',
        width: '1.5em',
        right: '1em',
        top: '1em',
    },
    button: {
        ':focus': {
            outline: 'none',
        },
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(220,220,220)',
            color: '#000',
        },
        backgroundColor: 'transparent',
        color: '#fff',
        border: '1px solid #fff',
        padding: '.3em',
        appearance: 'none',
        display: 'inline',
        width: '12em',
        height: '100%',
        fontWeight: '900',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    optionsContainer: {
        marginTop: '1em',
        height: '2em',
        textAlign: 'initial',
        display: 'flex',
        pointerEvents: 'auto',
    },
    episodeName: {
        fontSize: '3em',
    },
    descriptionContainer: {
        visibility: 'visible',
        opacity: '1',
        transition: 'all 0.6s ease',
        width: '55%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        fontSize: '1em',
        maxHeight: '12em',
        whiteSpace: 'textwrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    channelInfo: {
        width: '25em',
        display: 'grid',
        // alignItems: 'flex-end',
        gridTemplateColumns: '1fr 4fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: "'logo timeSlot' 'logo channelInfo'",
        whiteSpace: 'nowrap',
        // overflow: "hidden",
        textOverflow: 'ellipsis',
    },
    channelDetails: {
        alignItems: 'flex-start',
        display: 'flex',
        fontSize: '1.5em',
        gridArea: 'channelInfo',
        marginLeft: '2%',
    },
    channelLogo: {
        gridArea: 'logo',
    },
    timeSlot: {
        marginLeft: '2%',
        gridArea: 'timeSlot',
        display: 'flex',
        alignItems: 'flex-end',
    },
});
