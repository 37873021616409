export const TOGGLE_OPTIONS_POPUP = 'TOGGLE_OPTIONS_POPUP';

export const SET_HORIZONTAL_PAGE = 'SET_HORIZONTAL_PAGE';
export const NEXT_HORIZONTAL_PAGE = 'NEXT_HORIZONTAL_PAGE';
export const PREVIOUS_HORIZONTAL_PAGE = 'PREVIOUS_HORIZONTAL_PAGE';

export const SET_PROGRAM_INDEX = 'SET_PROGRAM_INDEX';
export const SET_PROGRAM_INDEX_COUNTER = 'SET_PROGRAM_INDEX_COUNTER';
export const SET_SELECTED_CHANNEL_INDEX = 'SET_SELECTED_CHANNEL_INDEX';
export const SET_PREVIOUS_CHANNEL_INDEX = 'SET_PREVIOUS_CHANNEL_INDEX';
export const TOGGLE_PROGRAM_INDEX_COUNTER = 'TOGGLE_PROGRAM_INDEX_COUNTER';
export const CHANGE_PROGRAM_START = 'CHANGE_PROGRAM_START';
export const CHANGE_ACTIVE_ROW_INDEX = 'CHANGE_ACTIVE_ROW_INDEX';
export const TOGGLE_RECORDING_SCREEN = 'TOGGLE_RECORDING_SCREEN';
export const IS_RECORDING_SCREEN = 'IS_RECORDING_SCREEN';
export const TOGGLE_RECORDING_TOAST = 'TOGGLE_RECORDING_TOAST';
export const TOGGLE_TICKET_SCREEN = 'TOGGLE_TICKET_SCREEN';
export const IS_CREATING_TICKET_SCREEN = 'IS_CREATING_TICKET_SCREEN';
export const TOGGLE_TICKET_TOAST = 'TOGGLE_TICKET_TOAST';
export const TOGGLE_OPTIONS_MENU = 'TOGGLE_OPTIONS_MENU';
export const TOGGLE_GUIDE_ANIMATIONS = 'TOGGLE_GUIDE_ANIMATIONS';

export const SET_RESTART_IN_PROGRESS = 'SET_RESTART_IN_PROGRESS';
export const SET_RESTART_STREAM = 'SET_RESTART_STREAM';
export const SET_RESTART_CHANNEL = 'SET_RESTART_CHANNEL';
export const CLEAR_RESTART = 'CLEAR_RESTART';
export const SET_RESTART_LOADING = 'SET_RESTART_LOADING';
export const RESTART_COMPLETED_TOAST_SET = 'RESTART_COMPLETED_TOAST_SET';
export const UPDATE_CATCHUP_LIVE_EVENTS = 'UPDATE_CATCHUP_LIVE_EVENTS';
export const SET_SEARCH_MAP = 'SET_SEARCH_MAP';

// Recording Types
export const POST_RECORD_BEGIN = 'POST_RECORD_BEGIN';
export const POST_RECORD_SUCCESSFUL = 'POST_RECORD_SUCCESSFUL';
export const POST_RECORD_ERROR = 'POST_RECORD_ERROR';

export const UPDATE_RECORD_BEGIN = 'UPDATE_RECORD_BEGIN';
export const UPDATE_RECORD_SUCCESSFUL = 'UPDATE_RECORD_SUCCESSFUL';
export const UPDATE_RECORD_ERROR = 'UPDATE_RECORD_ERROR';

export const DELETE_RECORD_BEGIN = 'DELETE_RECORD_BEGIN';
export const DELETE_RECORD_SUCCESSFUL = 'DELETE_RECORD_SUCCESSFUL';
export const DELETE_RECORD_ERROR = 'DELETE_RECORD_ERROR';
export const CANCEL_RECORD_ERROR = 'CANCEL_RECORD_ERROR';

// Page Updates
export const UPDATE_PAGE_DATA = 'UPDATE_PAGE_DATA';
export const UPDATE_NEXT_PAGE_DATA = 'UPDATE_NEXT_PAGE_DATA';
export const TRANSITION_NEW_PAGE_IN = 'TRANSITION_NEW_PAGE_IN';
export const UPDATE_CHANNEL_INDEXES = 'UPDATE_CHANNEL_INDEXES';
export const UPDATE_CHANNEL_START_END_TIMES = 'UPDATE_CHANNEL_START_END_TIMES';
export const UPDATE_ANIMATED_VALUE = 'UPDATE_ANIMATED_VALUE';

//refactored grid logic types:
export const PAGE_INIT = 'PAGE_INIT';
export const PAGE_CHANGE_RIGHT = 'PAGE_CHANGE_RIGHT';
export const PROGRAM_CHANGE_RIGHT = 'PROGRAM_CHANGE_RIGHT';
export const PAGE_CHANGE_LEFT = 'PAGE_CHANGE_LEFT';
export const PROGRAM_CHANGE_LEFT = 'PROGRAM_CHANGE_LEFT';
export const PROGRAM_CHANGE_UP = 'PROGRAM_CHANGE_UP';
export const PAGE_CHANGE_UP = 'PAGE_CHANGE_UP';
export const PROGRAM_CHANGE_DOWN = 'PROGRAM_CHANGE_DOWN';
export const PAGE_CHANGE_DOWN = 'PAGE_CHANGE_DOWN';

//ChannelsList Types
export const GET_CHANNELS_BEGIN = 'GET_CHANNELS_BEGIN';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const UPDATE_CHANNELS_LIST = 'UPDATE_CHANNELS_LIST';
export const GET_CHANNELS_FAILURE = 'GET_CHANNELS_FAILURE';

//Cancel MyTicket
export const TOGGLE_CANCEL_TICKET_SCREEN = 'TOGGLE_CANCEL_TICKET_SCREEN';
export const IS_CANCELLING_TICKET_SCREEN = 'IS_CANCELLING_TICKET_SCREEN';
// export const TOGGLE_CANCEL_TICKET_TOAST = 'TOGGLE_CANCEL_TICKET_TOAST';

//INFO BARS
export const CHANNEL_BAR_INFO_STATE = 'CHANNEL_BAR_INFO_STATE';
export const TOGGLE_FULLSCREEN_INFO = 'TOGGLE_FULLSCREEN_INFO';

//Cancel Order
export const CANCEL_ORDER_BEGIN = 'CANCEL_ORDER_BEGIN';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';
