//import * as Keychain from 'react-native-keychain';//secure info goes here
// import { bugSnagNotifierSetup } from './BugSnagService'; //bug snag service
import { makingErrorObject } from '../utils/ErrorHandler';
import { loginAxios } from '../utils/AxiosInstances';
import axios from 'axios';
//import { NativeModules } from 'react-native';
//const { NetworkInf } = NativeModules;

export let isDRMEnabled = false; //change it if DRM is enabled.

let errorObject = {};
let retryCount = 0; //I am using external variable just in case if we'll comeback to axios 0.19 as it does not let to modify error.response.config
let requestCopy = {}; //only used for Network Error
let loginTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export var branding_url = null;
export let subbranding_url = null;
export var telco_email = null;
export var favTelcoLimit = null;

export const GetToken = (subscriberData, subDeviceInfo) => {
    //gets token

    // let body = {
    //     ...subscriberData,
    //service_name: "web_platform" //this will be used instead once set up
    // serial_number: subDeviceInfo.extraDeviceInfo.serialNumber,
    // board: subDeviceInfo.extraDeviceInfo.board,
    // brand: subDeviceInfo.extraDeviceInfo.brand,
    // model: subDeviceInfo.extraDeviceInfo.model,
    // version: subDeviceInfo.extraDeviceInfo.versionRelease,
    // manufacturer: subDeviceInfo.extraDeviceInfo.manufacturer,
    // mac_address: subDeviceInfo.mac_address,
    // version_incremental: subDeviceInfo.extraDeviceInfo.incremental,
    // version_sdk_number: subDeviceInfo.extraDeviceInfo.sdkNumber.toString(),
    // display: subDeviceInfo.extraDeviceInfo.display
    //}
    // ,serial_number:"G090P0098164090C",board:"p212",brand:"Amazon",model:"AFTA",version:"7.1.2",manufacturer:"Amazon",mac_address:"B0:FC:0D:68:17:7D",version_incremental:"0002852679044",version_sdk_number:"25",display:"NS6265"
    let body;
    if (subscriberData.password) {
        body = { ...subscriberData };
    } else {
        body = { email: subscriberData.email, password: subscriberData.tempPw };
    }
    return loginAxios.post('/api/login', body, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
    });
};

export const GetSotalToken = (subscriberData, subDeviceInfo) => {
    //gets token

    let body = { ...subscriberData };
    return loginAxios.post(
        '/api/login', //using this for now. backend will add call that will just return new sc token
        body,
        {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
        }
    );
};

export const GetSubscriberData = () => {
    //gets subscriber data
    return loginAxios.get('/api/user', {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
    });
};

//interceptors
loginAxios.interceptors.request.use(
    (request) => {
        request.retries = 4;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        // if (retryCount === 2){//just for testing - needs to change in GetSubscriberData /api/users...
        //     request.url = "http://sms-ingress.sms.10.250.250.46.xip.io/api/user"
        // }
        // console.log("login request success: ", request)
        loginTimeOut = setTimeout(() => {
            cancel && cancel('Network Error'); //TO DO - change it to timeout error
        }, 100000); //if original timeout does not work
        //console.log(request);
        return request;
    },
    (error) => {
        return error;
    }
);

loginAxios.interceptors.response.use(
    (response) => {
        retryCount = 0;
        requestCopy = {};
        if (response.data.user) {
            // let user = response.data.user;
            //bugSnagNotifierSetup(user);
        }
        loginTimeOut && clearTimeout(loginTimeOut);
        cancel && (cancel = null);
        //console.log("loginAxios response: ", response)
        return response;
    },
    (error) => {
        //console.log(error, error.message, error.config)
        loginTimeOut && clearTimeout(loginTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy; //if Network Error - it has no config
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 || (error.response.status === 404 && retryCount > 0))
        ) {
            //auth error!!! Kick them out!
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (false) {
            //some logic to handle other codes
        }
        // Check if we've maxed out the total number of retries
        if (retryCount >= config.retries) {
            // Reject with the error
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Increase the retry count
        retryCount += 1;
        //console.log(retryCount, config.retryDelay * retryCount);
        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function () {
            return loginAxios(config);
        });
    }
);

//rest of methods
export const checkingIfLoginNeeded = async () => {
    //checks if login was saved from before
    return false; //await Keychain.getGenericPassword();
};

export const gatheringDeviceInfo = async () => {
    //building subscriber login object
    let deviceInfo = {};
    //checking the username/accountID and password/pin
    const serial_number = new Promise((resolve, reject) => {
        //some convoluted stuff with promises
        // NetworkInf.getSerialNumber(serial => {
        //    if(serial){
        //     resolve(serial);
        //    }
        // })
    });
    const box_model = boxModel();
    const mac_address = new Promise((resolve, reject) => {
        //some convoluted stuff with promises
        // NetworkInf.getMac(mac => {
        //    if(mac){
        //     resolve(mac);
        //    }
        // })
    });
    const extraDeviceInfo = new Promise((resolve, reject) => {
        // NetworkInf.readDevice(device => {
        //     resolve(device);
        // });
    });
    deviceInfo['js_version'] = '1.1';
    deviceInfo['device_type'] = await box_model; //not async function
    deviceInfo['serial_number'] = await serial_number;
    deviceInfo['mac_address'] = await mac_address;
    deviceInfo['extraDeviceInfo'] = await extraDeviceInfo;
    return await deviceInfo;
};

export const StoringInSecureStorage = async (username, password) => {
    //await Keychain.resetGenericPassword();//clearing storage
    // Store the credentials
    return false; //await Keychain.setGenericPassword(username, password);
};

export const boxModel = () => {
    //detecting type of the box
    return new Promise((resolve) => {
        // NetworkInf.getBox(device => {
        // let boxModel = device.boxModel || null;
        // let brand = device.brand || null;
        // if(brand === "Amino" && (boxModel === "Amigo7" || boxModel ==="Kamai7B") || brand === "Amlogic"){
        //     resolve("Aminos");// full remote functionality
        // }else if(brand === "Xiaomi" && (boxModel === "MIBOX3")){
        //     resolve("Xiaomi");//limited remote functionality
        // }else if(brand === "Amazon"){
        //     resolve("Amazon"); //uncomment to use SkitterVideo for Amazon Firestick
        // }
        // resolve(null);//at least basic functionality
        // })
    });
};

export const clearStorage = async () => {
    //to do - remove
    return false; //await Keychain.resetGenericPassword();
};

export const telcoBrandSetter = (subData) => {
    //setting the image for telco if available
    if (subData.subbranding_enabled === 'yes') {
        branding_url = subData.branding_url;
        if (subData.subbranding_url) {
            subbranding_url = subData.subbranding_url;
        } else {
            subbranding_url = branding_url;
        }
    } else if (subData.subbranding_enabled === 'no') {
        resetBrandToEpic();
    }

    if (subData.telco_name === 'Swayzee') {
        isDRMEnabled = true;
    }
};

export const resetBrandToEpic = () => {
    //to reset brand to epic on Logout
    branding_url = null;
    subbranding_url = null;

    //for now - update later
    isDRMEnabled = false;
};

export const telcoEmailSetter = (subData) => {
    if (subData.telco_email !== '') {
        telco_email = subData.telco_email;
    } else {
        telco_email = '';
    }
};

export const favTelcoLimitGetter = (subData) => {
    if (subData.telco_favorite_limit !== '') {
        favTelcoLimit = subData.telco_favorite_limit;
    } else {
        favTelcoLimit = '';
    }
};

// export const resetLoginServices = () => {
//     branding_url = null;
//     subbranding_url = null;
//     telco_email = null;
//     favTelcoLimit = null;
//     //body = null;
// };
