import { GET_LOGIN_BEGIN, GET_LOGIN_SUCCESS, GET_LOGIN_FAILED, ON_LOGIN_NEEDED, LOGIN_COMPLETED, TOGGLE_RESET_NOTICE } from '../types/login';

const initialState = {
    isErrorOnLogin: false,
    errorMessageOnLogin: '',
    originalErrorResponse: {},
    subscriberData: {},
    isLoginSuccess: false,
    isLoadingOnLogin: false,
    loginSuccessMessage: '',
    subDeviceInfo: {},
    isResetNoticeSet: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_RESET_NOTICE:
            return {
                ...state,
                isResetNoticeSet: action.isResetNoticeSet,
            };
        case GET_LOGIN_BEGIN:
            return {
                ...state,
                isErrorOnLogin: false,
                errorMessageOnLogin: '',
                subscriberData: {},
                isLoadingOnLogin: true,
            };
        case GET_LOGIN_SUCCESS:
            return {
                ...state,
                subscriberData: action.subData,
                errorMessageOnLogin: '',
                isLoginSuccess: true,
            };
        case GET_LOGIN_FAILED:
            return {
                ...state,
                isErrorOnLogin: true,
                originalErrorResponse: action.originalErrorResponse,
                errorMessageOnLogin: action.error,
                isLoadingOnLogin: false,
                isLoginSuccess: false,
            };
        case ON_LOGIN_NEEDED:
            return {
                isErrorOnLogin: false,
                errorMessageOnLogin: '',
                subscriberData: {},
                isLoginSuccess: false,
                isLoadingOnLogin: false,
                loginSuccessMessage: '',
            };
        case LOGIN_COMPLETED:
            return {
                ...state,
                isLoadingOnLogin: false,
            };
        default:
            return state;
    }
};
