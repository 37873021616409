import {
    CHANGE_CHANNEL,
    CHANGE_PAGE,
    CHANGE_PREVIOUS_PAGE,
    TOGGLE_FULLSCREEN,
    TOGGLE_SUBTITLE,
    SET_CHANNEL_LIST,
    SET_CHANNELS,
    PLAY_LIVE_TV,
    PAUSE_LIVE_TV,
    RESUME_LIVE_TV,
    STOP_LIVE_TV,
    SUSPEND_LIVE_TV,
    FETCH_CHANNELS,
    FETCH_CHANNELS_SUCCESSFUL,
    FETCH_CHANNELS_ERROR,
    FETCH_PAST_CHANNELS,
    FETCH_PAST_CHANNELS_SUCCESSFUL,
    FETCH_PAST_CHANNELS_ERROR,
    HIDE_MINI_PLAYER,
    TOGGLE_HALF_HOUR_LOAD,
    TOGGLE_FULLSCREEN_LIVE,
    SAVE_BOX_MODEL,
    PROGRESS_BAR_INDEX,
    IS_PAUSED,
    SAVE_DEVICE_INFO,
    UPDATE_FETCHING_TEXT,
    TOGGLE_PLAYING_RECORDING,
    UPDATE_ALL_CHANNELS,
    UPDATING_CHANNEL_DATA,
    // UPDATE_ERROR_RECORDING_TEXT,
    UPDATE_GUIDE_END_PAGES_TIMES,
    TOGGLE_FULLSCREEN_OPTIONS,
    CONNECTIVITY_CHANGE,
    CONNECTIVITY_STATUS,
    ON_LOGOUT_BEGIN,
    ON_LOGOUT_SUCCESS,
    ON_LOGOUT_ERROR,
    PREPARE_FOR_NEXT_LOGOUT_ATTEMPT,
    UPDATE_PREVIOUS_ALERTS_ARR,
    ALERT_EAS,
    CLEAR_EAS,
    AUTH_ERROR,
    GENERAL_ERROR,
    GENERAL_ERROR_RESET,
    SET_LIGHTWEIGHT_VERSION,
    SET_GLOBAL_PROGRAM_INDEX,
    SET_LOGIN_READY,
    SET_PLAYER_SOURCE_OPENED,
    SET_DEVICE_LIMIT_REACHED,
    TOGGLE_SOTAL_REFRESH_LOADING,
    UPDATE_ORDER_DATA_LOADING,
    TRIGGER_LIVE_CHANNEL_RELOAD,
    TOGGLE_INACTIVATION_MESSAGE,
    SET_NUMBER_OF_PAGES,
} from '../types/main';
// import { DrawerActions } from 'react-navigation';

const initialState = {
    isLoginReady: false,
    lightweightVersion: true,
    isFetchingSubscriberData: false,
    isLoadingChannels: false,
    currentPage: 'Login',
    previousPage: 'Home',
    currentChannel: undefined,
    isFullScreen: false,
    isSubtitleToggled: false,
    channels: undefined,
    pastChannels: undefined,
    channelList: undefined,
    isPlayerShowing: false,
    error: undefined,
    isPlaying: false, //I think the initial state must be false
    prevStateIsPlaying: false,
    prevPlayerState: false,
    subscriberData: {},
    halfHourLoad: false,
    isPlayingRecording: false,
    isFullscreenLive: false,
    boxModel: '',
    prBarIndex: 4, //selecting button play
    isPaused: false,
    DeviceInfo: null,
    isErrorWindowOpen: false,
    //default error message,
    isUpdatingChannelData: false,
    requestStatusCode: 0,
    errorRecordingText: 'The requested order could not be found! Please try making a new request.',
    globalProgramIndex: 0,
    pausedChannel: 0,
    guideEndPagesTimes: {
        guideFirstPageTime: 0,
        guideLastPageTime: 0,
    },
    isFullScreenOptionsOpen: false,
    conType: false,
    isConnected: false,
    connTimeOut: false,
    isLogOutSuccess: false,
    isLogOutErrorMessage: '',
    isLogoutInProgress: false,
    keyMap: {},
    gotGuideData: false,
    fetchGuideDataError: undefined,
    previousAlertsArr: [], //array of previously triggered alertheaders(id for alerts)
    alertEAS: false,
    messageEAS: '',
    urlEAS: '',
    authError: false, //error status code
    generalError: false,
    responseError: null,
    showDvrControls: true, //TO DO add logic to toggle this prop to show controls on small remote control
    isPlayerSourceOpened: false,
    firstFullLoadComplete: false,
    isSotalRefreshLoading: false,
    number_of_pages: 0,
    triggerLiveChannelReload: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TRIGGER_LIVE_CHANNEL_RELOAD:
            return {
                ...state,
                triggerLiveChannelReload: !state.triggerLiveChannelReload,
            };
        case TOGGLE_INACTIVATION_MESSAGE:
            return {
                ...state,
                isInactivationMessageSet: action.isInactivationMessageSet,
            };
        case SET_LOGIN_READY:
            return {
                ...state,
                isLoginReady: true,
            };
        case SET_LIGHTWEIGHT_VERSION:
            return {
                ...state,
                lightweightVersion: !action.cdvrAccess,
            };
        case TOGGLE_FULLSCREEN_LIVE:
            return {
                ...state,
                isFullscreenLive: action.isFullscreenLive,
            };
        case TOGGLE_PLAYING_RECORDING:
            return {
                ...state,
                isPlayingRecording: action.isPlayingRecording,
            };
        case CHANGE_CHANNEL:
            return {
                ...state,
                currentChannel: action.channel,
                globalProgramIndex: action.globalProgramIndex,
            };
        case CHANGE_PAGE:
            const previousPage = state.currentPage;
            return {
                ...state,
                currentPage: action.page,
                previousPage: previousPage,
            };
        case CHANGE_PREVIOUS_PAGE:
            return {
                ...state,
                previousPage: action.previousPage,
            };
        case TOGGLE_FULLSCREEN:
            // console.log("toggle full screen")
            return {
                ...state,
                isFullScreen: action.isFullScreen,
            };
        case TOGGLE_SUBTITLE:
            // console.log("toggle subtitle", action.isSubtitleToggled);
            return {
                ...state,
                isSubtitleToggled: action.isSubtitleToggled,
            };
        case SET_CHANNEL_LIST: //////////////////////////////////////what missing from ch list???
            return {
                ...state,
                channelList: action.channelList,
            };
        case SET_CHANNELS:
            return {
                ...state,
                channels: action.channels,
            };
        case PLAY_LIVE_TV:
            return {
                ...state,
                isPlayerShowing: true,
                isPlaying: true,
            };
        case PAUSE_LIVE_TV:
            return {
                ...state,
                isPlaying: false,
                pausedChannel: action.pausedChannel,
            };
        case RESUME_LIVE_TV:
            return {
                ...state,
                isPlaying: true,
            };
        case STOP_LIVE_TV:
            return {
                ...state,
                isPlayerShowing: false,
                isPlaying: false,
            };
        case SUSPEND_LIVE_TV:
            return {
                ...state,
                prevStateIsPlaying: action.isPlaying,
                prevPlayerState: action.isPlayerShowing,
            };
        case UPDATE_FETCHING_TEXT:
            return {
                ...state,
                fetchingOnLoginText: action.updatedText,
            };
        case FETCH_CHANNELS:
            return {
                ...state,
                isLoadingChannels: true,
                fetchGuideDataError: undefined,
                gotGuideData: false,
            };
        case FETCH_CHANNELS_SUCCESSFUL:
            return {
                ...state,
                isLoadingChannels: false,
                channels: action.channels,
                gotGuideData: true,
                channelList: action.channelList,
                currentChannel: action.channels[0],
            };
        case FETCH_CHANNELS_ERROR:
            return {
                ...state,
                isLoadingChannels: false,
                fetchGuideDataError: action.fetchGuideDataError,
                gotGuideData: false,
            };
        case FETCH_PAST_CHANNELS:
            return {
                ...state,
                isLoadingChannels: true,
            };
        case FETCH_PAST_CHANNELS_SUCCESSFUL:
            return {
                ...state,
                isLoadingChannels: false,
                pastChannels: action.pastChannels,
            };
        case FETCH_PAST_CHANNELS_ERROR:
            return {
                ...state,
                isLoadingChannels: false,
                error: action.error,
            };
        case UPDATE_ALL_CHANNELS:
            return {
                ...state,
                channels: action.channels,
                channelList: action.channelList,
                firstFullLoadComplete: true,
            };
        case UPDATING_CHANNEL_DATA:
            return {
                ...state,
                isUpdatingChannelData: action.isUpdatingChannelData,
            };
        case HIDE_MINI_PLAYER:
            return {
                ...state,
                isPlayerShowing: false,
            };
        case TOGGLE_HALF_HOUR_LOAD:
            return {
                ...state,
                halfHourLoad: action.isLoaded,
            };
        case SAVE_BOX_MODEL:
            return {
                ...state,
                boxModel: action.boxModel,
                keyMap: action.keyMap,
            };
        case PROGRESS_BAR_INDEX:
            return {
                ...state,
                prBarIndex: action.prBarIndex,
            };
        case IS_PAUSED:
            return {
                ...state,
                isPaused: action.isPaused,
            };
        case SAVE_DEVICE_INFO:
            return {
                ...state,
                DeviceInfo: action.DeviceInfo,
            };
        // case UPDATE_ERROR_RECORDING_TEXT:
        //     return {
        //         ...state,
        //         errorRecordingText: action.updatedErrorRecText
        //     }
        case UPDATE_GUIDE_END_PAGES_TIMES:
            return {
                ...state,
                guideEndPagesTimes: action.guideEndPagesTimes,
            };
        case TOGGLE_FULLSCREEN_OPTIONS:
            return {
                ...state,
                isFullScreenOptionsOpen: action.isFullScreenOptionsOpen,
            };
        case CONNECTIVITY_CHANGE:
            return {
                ...state,
                conType: action.conType,
                isConnected: action.isConnected,
            };
        case CONNECTIVITY_STATUS:
            return {
                ...state,
                connTimeOut: action.connTimeOut,
            };
        case ON_LOGOUT_BEGIN:
            return {
                ...state,
                isLogOutSuccess: false,
                logoutErrorMessage: '',
                isLogoutInProgress: true,
            };
        case ON_LOGOUT_SUCCESS:
            return {
                ...state,
                isLogOutSuccess: true,
                logoutErrorMessage: '',
                isLogoutInProgress: false,
            };
        case ON_LOGOUT_ERROR:
            return {
                ...state,
                isLogOutSuccess: false,
                logoutErrorMessage: action.error,
                isLogoutInProgress: false,
            };
        case PREPARE_FOR_NEXT_LOGOUT_ATTEMPT:
            return {
                ...state,
                isLogOutSuccess: false,
                logoutErrorMessage: '',
                isLogoutInProgress: false,
            };
        case AUTH_ERROR:
            return {
                ...state,
                authError: true,
                responseError: action.error, //message for error
            };
        case GENERAL_ERROR:
            return {
                ...state,
                generalError: true,
                responseError: action.error,
            };
        case GENERAL_ERROR_RESET:
            return {
                ...state,
                generalError: false,
                responseError: action.resetObject.message,
            };
        case UPDATE_PREVIOUS_ALERTS_ARR:
            return {
                ...state,
                previousAlertsArr: action.alertsArr,
            };
        case ALERT_EAS:
            return {
                ...state,
                alertEAS: true,
                messageEAS: action.pusherResponse.message,
                urlEAS: action.pusherResponse.hls_path,
            };
        case CLEAR_EAS:
            return {
                ...state,
                alertEAS: false,
                messageEAS: '',
                urlEAS: '',
            };
        case SET_GLOBAL_PROGRAM_INDEX:
            return {
                ...state,
                globalProgramIndex: action.globalProgramIndex,
            };
        case SET_PLAYER_SOURCE_OPENED:
            return {
                ...state,
                isPlayerSourceOpened: action.isPlayerSourceOpened,
            };
        case SET_DEVICE_LIMIT_REACHED:
            return {
                ...state,
                isDeviceLimitReached: action.isDeviceLimitReached,
            };
        case TOGGLE_SOTAL_REFRESH_LOADING:
            return {
                ...state,
                isSotalRefreshLoading: action.isSotalRefreshLoading,
            };
        case UPDATE_ORDER_DATA_LOADING:
            return {
                ...state,
                isOrderDataLoading: action.isLoading,
            };
        case SET_NUMBER_OF_PAGES:
            return {
                ...state,
                number_of_pages: action.number_of_pages,
            };
        default:
            return state;
    }
};
