export const FSProgramIndex = async (currentTime, arr) => {
    if (arr.length > 0) {
        let closestNumber = 0;
        let mid;
        let lo = 0;
        let hi = arr.length - 1;
        //grab the closest index out the row through binary search
        while (hi - lo > 1) {
            //create mid-point with lo and hi values
            mid = Math.floor((lo + hi) / 2);
            if (arr[mid].unix_start_time < currentTime) {
                //move lo up to middle if our midpoint is less than the unix time we're looking for
                lo = mid;
            } else {
                //else move hi to midpoint
                hi = mid;
            }
        }

        //set number to lo or hi depending on which is closer
        if (currentTime - arr[lo].unix_start_time <= arr[hi].unix_start_time - currentTime) {
            closestNumber = lo;
        } else {
            closestNumber = hi;
        }

        // check if page start time is between the start and end times of the current, next or previous program
        if (arr[closestNumber] && (currentTime - arr[closestNumber].unix_start_time) * (currentTime - arr[closestNumber].unix_end_time) <= 0)
            return await closestNumber;
        else if (arr[closestNumber + 1] && (currentTime - arr[closestNumber + 1].unix_start_time) * (currentTime - arr[closestNumber + 1].unix_end_time) <= 0) {
            return (await closestNumber) + 1;
        } else if (
            arr[closestNumber - 1] &&
            (currentTime - arr[closestNumber - 1].unix_start_time) * (currentTime - arr[closestNumber - 1].unix_end_time) <= 0
        ) {
            return (await closestNumber) - 1;
        } else {
            return await closestNumber;
        }
    } else {
        return 0;
    }
};
