import React from 'react';
import { StyleSheet, css } from 'aphrodite';

function AccountSettings(props) {
    return (
        <>
            <div className={css(styles.RowContainer)}>
                <div className={css(styles.RowLabel)}>{props.label}</div>
                <div className={css(styles.RowData)}>{props.data}</div>
            </div>
        </>
    );
}

// <InfoRow label='Subscriber name' value={subscriberData.first_name + " " + subscriberData.last_name} />
// <InfoRow label='Subscriber email' value={subscriberData.email} />
// <InfoRow label='Account created on' value={subscriberData.created_at} />
// {subscriberData.address && <InfoRow label='Registered address' value={subscriberData.address} />}

export default AccountSettings;

const styles = StyleSheet.create({
    RowContainer: {
        display: 'flex',
        //   border: '1px solid red',
        justifyContent: 'space-between',
        marginTop: '1em',
    },
    RowLabel: {
        display: 'flex',
        position: 'relative',
    },
    RowData: {
        display: 'flex',
        position: 'relative',
    },
});
