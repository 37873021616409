export default function getKeyMapping(boxModel) {
    let buttonPress = {};

    buttonPress.left = 21;
    buttonPress.right = 22;
    buttonPress.up = 19;
    buttonPress.down = 20;
    buttonPress.select = 23;
    buttonPress.back = 4;
    buttonPress.info = 165;
    buttonPress.rewind = 89; // temporarily using rewind as options button so we dont have to see the dev menu
    buttonPress.ff = 90; // fast-forward
    buttonPress.pp = 85; // play/pause

    if (boxModel === 'Amazon') {
        buttonPress.options = 82; //this also triggers the devmenu to open in develop mode, but still works
    }

    if (boxModel === 'Aminos') {
        buttonPress.search = 84;
        buttonPress.stop = 86;
        buttonPress.record = 130;
        buttonPress.red = 1183;
        buttonPress.green = 1184;
        buttonPress.yellow = 1185;
        buttonPress.blue = 1186;
        buttonPress.channelUp = 166;
        buttonPress.channelDown = 167;
        buttonPress.num1 = 8;
        buttonPress.num2 = 9;
        buttonPress.num3 = 10;
        buttonPress.num4 = 11;
        buttonPress.num5 = 12;
        buttonPress.num6 = 13;
        buttonPress.num7 = 14;
        buttonPress.num8 = 15;
        buttonPress.num9 = 16;
        buttonPress.num0 = 7;
        buttonPress.subtitle = 175;
    }

    return buttonPress;
}
//import device type here
//import boxModel from '../reducers/main';
//console.log();
