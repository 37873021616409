import {
    // Main Menu
    CHANGE_MAIN_MENU_INDEX,
    CHANGE_MAIN_MENU_ITEM_INDEX,
    TOGGLE_SUBMENU,
    CHANGE_SELECTED_ROW_INDEX,
    // Parental Controls
    TOGGLE_PARENTAL_CONTROLS,
    TOGGLE_HIDE_ADULT_CONTENT,
    TOGGLE_PURCHASE_CONTROLS,
    CHANGE_PIN_NUMBER,
    CHANGE_CONFIRM_PIN_NUMBER,
    CHANGE_LOCKED_CHANNELS,
    CHANGE_RATING_LIMITS,

    // Lock Channels
    LOCK_CHANNEL,
    LOCK_ALL_CHANNELS,
    UNLOCK_ALL_CHANNELS,
} from '../types/menu';

import { PARENTAL_CONTROL_MENU_ITEMS } from '../components/Menu/Common/MenuConstants';
// import { bindActionCreators } from 'redux';

// May need to seperate into multiple reducers
const initialState = {
    // Main Menu
    mainMenuIndex: 0,
    mainMenuItemIndex: 0,
    // Parental Controls
    options: PARENTAL_CONTROL_MENU_ITEMS,
    parentalControlsEnabled: false,
    hideAdultContentEnabled: true,
    purchaseControlsEnabled: true,
    isPinPopupShowing: false,
    selectedPinPopupIndex: 0,
    selectedParentalControlsOptionIndex: 0,
    pinNumber: undefined,
    confirmPinNumber: undefined,
    lockedChannels: [],
    ratingLimits: {
        tvShowRating: 'Allow all except Adult',
        movieRating: 'Allow all except Adult',
        unratedTvShow: 'Block all',
        unratedMovie: 'Block all',
    },
    selectedParentalControlText: 'Off',
    selectedHideAdultContentText: 'On',
    selectedSetRatingLimitsText: 'Allow all except Adult',
    selectedLockChannelsText: 'All unlocked',
    selectedPurchaseControlsText: 'On',
    // Others (TODO)
    carouselItemIndex: 0,
    accountSystemInfoRowIndex: 0,
    copyRightsPage: 0,
    tipTroubleShootingRowIndex: 0,
    isSubmenuEnabled: false,
    selectedRowIndex: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Main Menu
        case CHANGE_MAIN_MENU_INDEX:
            return {
                ...state,
                mainMenuIndex: action.menuIndex,
                mainMenuItemIndex: 0,
            };
        case CHANGE_MAIN_MENU_ITEM_INDEX:
            return {
                ...state,
                mainMenuItemIndex: action.itemIndex,
            };
        // Parental Controls
        case TOGGLE_PARENTAL_CONTROLS:
            return {
                ...state,
                parentalControlsEnabled: action.parentalControlsEnabled,
                options: state.options.map((option, i) => (i === 0 ? { ...option, selectedOption: action.parentalControlsEnabled ? 'On' : 'Off' } : option)),
            };
        case TOGGLE_HIDE_ADULT_CONTENT:
            return {
                ...state,
                hideAdultContentEnabled: action.hideAdultContentEnabled,
                options: state.options.map((option, i) => (i === 1 ? { ...option, selectedOption: action.hideAdultContentEnabled ? 'On' : 'Off' } : option)),
            };
        case TOGGLE_PURCHASE_CONTROLS:
            return {
                ...state,
                purchaseControlsEnabled: action.purchaseControlsEnabled,
                options: state.options.map((option, i) => (i === 4 ? { ...option, selectedOption: action.purchaseControlsEnabled ? 'On' : 'Off' } : option)),
            };
        case CHANGE_PIN_NUMBER:
            return {
                ...state,
                pinNumber: action.pinNumber,
            };
        case CHANGE_CONFIRM_PIN_NUMBER:
            return {
                ...state,
                confirmPinNumber: action.confirmPinNumber,
            };
        case CHANGE_RATING_LIMITS:
            const selectedOption = action.ratingLimits.tvShowRating
                ? action.ratingLimits.tvShowRating === state.ratingLimits.movieRating
                    ? action.ratingLimits.tvShowRating
                    : `${action.ratingLimits.tvShowRating}, ${state.ratingLimits.movieRating}`
                : action.ratingLimits.movieRating === state.ratingLimits.tvShowRating
                ? action.ratingLimits.movieRating
                : `${state.ratingLimits.tvShowRating}, ${action.ratingLimits.movieRating}`;
            return {
                ...state,
                ratingLimits: { ...state.ratingLimits, ...action.ratingLimits },
                options: state.options.map((option, i) =>
                    i === 2
                        ? {
                              ...option,
                              selectedOption: selectedOption.length > 22 ? `${selectedOption.slice(0, 22)}...` : selectedOption,
                          }
                        : option
                ),
            };

        // Lock Channels
        case CHANGE_LOCKED_CHANNELS:
            return {
                ...state,
                lockedChannels: action.lockedChannels,
                options: state.options.map((option, i) =>
                    i === 3
                        ? {
                              ...option,
                              selectedOption:
                                  action.lockedChannels.length === 0
                                      ? 'All unlocked'
                                      : action.lockedChannels.length === action.channels.length
                                      ? 'All locked'
                                      : `${action.lockedChannels.length} locked`,
                          }
                        : option
                ),
            };
        case LOCK_CHANNEL:
            return state.lockedChannels.includes(action.channel)
                ? {
                      ...state,
                      lockedChannels: state.lockedChannels.filter((lockedChannel) => lockedChannel !== action.channel),
                      options: state.options.map((option, i) =>
                          i === 3
                              ? {
                                    ...option,
                                    selectedOption: state.lockedChannels.length === 0 ? 'All unlocked' : `${state.lockedChannels.length - 1} locked`,
                                }
                              : option
                      ),
                  }
                : {
                      ...state,
                      lockedChannels: state.lockedChannels.concat([action.channel]),
                      options: state.options.map((option, i) =>
                          i === 3
                              ? {
                                    ...option,
                                    selectedOption:
                                        state.lockedChannels.length === action.channels.length ? 'All locked' : `${state.lockedChannels.length + 1} locked`,
                                }
                              : option
                      ),
                  };
        case LOCK_ALL_CHANNELS:
            return {
                ...state,
                options: state.options.map((option, i) => (i === 3 ? { ...option, selectedOption: 'All locked' } : option)),
                lockedChannels: action.channels.map((channel) => channel.custom_channel_number),
            };
        case UNLOCK_ALL_CHANNELS:
            return {
                ...state,
                options: state.options.map((option, i) => (i === 3 ? { ...option, selectedOption: 'All unlocked' } : option)),
                lockedChannels: [],
            };
        case TOGGLE_SUBMENU:
            return {
                ...state,
                isSubmenuEnabled: action.isSubmenuEnabled,
            };
        case CHANGE_SELECTED_ROW_INDEX:
            return {
                ...state,
                selectedRowIndex: action.indx,
            };
        default:
            return state;
    }
};
