export const CARD_MENU_TOGGLE = 'CARD_MENU_TOGGLE';
export const TOGGLE_CARDS = 'TOGGLE_CARDS';
export const TOGGLE_RECORDING_LIST_PAGE = 'TOGGLE_RECORDING_LIST_PAGE';
export const TOGGLE_FULLSCREEN_VIDEO = 'TOGGLE_FULLSCREEN_VIDEO';
export const TOGGLE_SINGLE_RECORDING_PAGE = 'TOGGLE_SINGLE_RECORDING_PAGE';
export const MOVE_LEFT = 'MOVE_LEFT';
export const MOVE_RIGHT = 'MOVE_RIGHT';
export const UPDATE_CARDS = 'UPDATE_CARDS';
export const GET_ASSETS_BEGIN = 'GET_ASSETS_BEGIN';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';
export const UPDATE_ASSET_DATA = 'UPDATE_ASSET_DATA';
export const UPDATE_ORDER_DATA = 'UPDATE_ORDER_DATA';
export const CHANGE_TITLES_INDEX = 'CHANGE_TITLES_INDEX';
export const GET_IMAGES_BEGIN = 'GET_IMAGES_BEGIN';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const TOGGLE_SORT_BY_SEASON = 'TOGGLE_SORT_BY_SEASON';
export const FILTER_SHOW_LIST = 'FILTER_SHOW_LIST';
export const FILTER_CARD_LIST = 'FILTER_CARD_LIST';
export const CHANGE_MENU_TITLE = 'CHANGE_MENU_TITLE';
export const SERIES_VIEW_ENABLED = 'SERIES_VIEW_ENABLED';

//for upcoming shows
export const ON_UPCOMING_MENU = 'ON_UPCOMING_MENU';
export const UPDATE_UPCOMING_DATA = 'UPDATE_UPCOMING_DATA';
export const UPDATE_UPCOMING_LOADING = 'UPDATE_UPCOMING_LOADING';

// export const TOGGLE_CANCEL_TICKET_SCREEN = 'TOGGLE_CANCEL_TICKET_SCREEN';
// export const IS_CANCELLING_TICKET_SCREEN = 'IS_CANCELLING_TICKET_SCREEN';
// export const TOGGLE_CANCEL_TICKET_TOAST = 'TOGGLE_CANCEL_TICKET_TOAST';

// SELECTED RECORDINGS
export const CHANGE_SELECTED_INDEX = 'CHANGE_SELECTED_INDEX';

//to toggle blue button in the right place
export const ON_ALL_SENUMS_AVAILABLE = 'ON_ALL_SENUMS_AVAILABLE';

// SOTAL CLOUD RECORDINGS RETRIEVAL
export const GET_SC_BEGIN = 'GET_SC_BEGIN';
export const GET_SC_SUCCESS = 'GET_SC_SUCCESS';
export const GET_SC_FAILURE = 'GET_SC_FAILURE';
