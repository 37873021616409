import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoPlayerWithBarsContainer from './Containers/VideoPlayerWithBarsContainer';
import moment from 'moment';
import { toggleOptionsMenu } from '../../actions/guide';
import { FSProgramIndex } from '../../utils/FSProgramIndex';

// TODO: figure out if I should Take everything from data and remove header, description, etc
const Title = (props) => {
    if (props.pageData && props.pageData[props.previewChannelIndex] && props.pageData[props.previewChannelIndex].programs[props.previewProgramIndex]) {
        const removeSeason = (seasonName) => {
            //function returns the number from Season
            let regEx = /\d+/;
            if (regEx.test(seasonName)) {
                //if digits present - then extract the digits
                return `${seasonName}`.match(regEx)[0];
            }
            return '';
        };

        const { pageData, previewProgramIndex, previewChannelIndex } = props;
        const selectedProgram = pageData[previewChannelIndex].programs[previewProgramIndex];
        const selectedChannel = pageData[previewChannelIndex];
        const isCustomGuide = selectedChannel.type === 'local' && selectedChannel.custom_guide === true;
        const header = isCustomGuide ? selectedChannel.guide_title : selectedProgram.long_title ? selectedProgram.long_title : 'Program Data Unavailable';
        const rating = selectedProgram.tv_rating;
        const dayDate = `${moment(selectedProgram.unix_start_time).format('ddd M/DD').toUpperCase()}`;
        const restartAvailable = selectedProgram.restartAvailable;
        const airingtime = isCustomGuide
            ? ''
            : `${moment(selectedProgram.unix_start_time).format('hh:mma').toUpperCase()} - ${moment(selectedProgram.unix_end_time)
                  .format('hh:mma')
                  .toUpperCase()}`;
        const defaultDescription = 'No Description Available';
        const isPlaceholder = selectedProgram.program_id === 'placeholder';

        const liveProgram =
            +selectedProgram.unix_start_time / 1000 < props.currentTime.unix() && +selectedProgram.unix_end_time / 1000 > props.currentTime.unix();

        const restartProgram =
            +selectedProgram.unix_start_time / 1000 < props.currentTime.unix() && +selectedProgram.unix_end_time / 1000 < props.currentTime.unix();

        let description = isCustomGuide
            ? selectedChannel.guide_desc
            : isPlaceholder
            ? ''
            : selectedProgram.program_desc !== ''
            ? selectedProgram.program_desc
            : defaultDescription;
        let ep_name = selectedProgram.ep_name ? selectedProgram.ep_name : null; //" '" + selectedProgram.ep_name + "' - "
        // Change description string based on program type
        let seText;
        if (selectedProgram.show_type === 'SE' && (selectedProgram.se_number || selectedProgram.ep_number)) {
            if (selectedProgram.se_number) {
                seText = `S${selectedProgram.se_number} E${selectedProgram.ep_number}`; // + description;
            } else {
                seText = `S${removeSeason(selectedProgram.se_name)} E${selectedProgram.ep_number}`; // + description;
            }
        }

        let genre = '';

        selectedProgram.genres &&
            selectedProgram.genres.forEach((cur, i, arr) => {
                if (i + 1 < arr.length) {
                    genre = genre.concat(`${cur},`);
                } else {
                    genre = genre.concat(`${cur}`);
                }
            });
        let logo = '';
        if (selectedChannel.rovi_custom_channel_logo) {
            logo = selectedChannel.rovi_custom_channel_logo;
        } else {
            logo = selectedChannel.station_logo;
        }

        if (logo === '') {
            // if the preferred logos are unavailable, just take the first one from station_logo and if there are 0 take the first from generic, if not ''.
            logo =
                selectedChannel.station_logo && selectedChannel.station_logo[0]
                    ? selectedChannel.station_logo[0]
                    : selectedChannel.generic_station_logo && selectedChannel.generic_station_logo[0]
                    ? selectedChannel.generic_station_logo[0]
                    : '';
        }

        const closeOptions = () => {
            // Animated.timing(
            //     this.state.bounceValue,
            //     {
            //         toValue: yScale(240),
            //         duration: 150,
            //         useNativeDriver: true
            //     }
            // ).start(
            props.onOptionClose(true);
            //);
        };

        const restartResource =
            props.eventListWithCatchup[
                props.eventListWithCatchup.findIndex((cur) => {
                    return cur.ext_id === selectedProgram.schedule_id;
                })
            ];

        const launchRestartProgram = () => {
            if (props.restartProgramInProgress && props.restartProgram.schedule_id === selectedProgram.schedule_id) {
            } else {
                if (props.isDeviceLimitReached) {
                    //still getting/setting restart, will not actually play until user hits force retry
                    if (restartResource) {
                        props.onClearRestart();
                        props.onSetPendingRestartProgram(selectedProgram, restartResource, selectedChannel);
                    }
                    // closeOptions();
                } else {
                    if (restartResource) {
                        props.onClearRestart();
                        window.plugin.fireInit();
                        props.onStartRestartProgram(selectedProgram, restartResource, selectedChannel);
                    }
                    // closeOptions();
                    //props.onToggleFullScreen(true);
                }
            }
        };

        const currentChannel = async (chNumber) => {
            let ch = props.channels.filter((val, i) => {
                return val.custom_channel_number === chNumber;
            });
            return (await (ch && ch[0])) ? ch[0] : null;
        };

        const channelSelection = () => {
            props.restartProgramInProgress && props.onClearRestart();
            let chNumber = selectedChannel.custom_channel_number;
            currentChannel(chNumber).then((ourChannel) => {
                FSProgramIndex(moment().unix() * 1000, ourChannel.programs).then((globalProgramIndex) => {
                    props.onChangeChannel(ourChannel, globalProgramIndex);
                    //props.onToggleFullScreen(true);
                    props.resetGrid();
                });
            });
        };

        //console.log(program, program.station_logo[0], program.generic_station_logo[0], logo);
        //if (logo === '' && data.station_logo) { // if the preferred logos are unavailable, just take the first one from station_logo and if there are 0 take the first from generic, if not ''.
        //data.station_logo[0] ? data.station_logo[0] :data.generic_station_logo && data.generic_station_logo[0] ? logo = data.generic_station_logo[0] : '';
        //}

        const ratingFormatter = (rating) => {
            let formatted = '';
            let firstTwo = rating.slice(0, 2);
            if (firstTwo === 'TV') {
                formatted = `${firstTwo}-${rating.slice(2)}`;
            } else {
                formatted = rating;
            }
            return formatted;
        };

        return (
            <div className={css(styles.Main)}>
                <div className={css(styles.fullVideoOverlay)} style={{ visibility: 'visible', opacity: 1 }}>
                    <div className={css(styles.channelInfo)}>
                        {logo && (
                            <div className={css(styles.logoContainer)}>
                                <img className={css(styles.channelLogo)} src={logo ? logo : 'currentChannel.station_logo'} />
                            </div>
                        )}
                        <div className={css(styles.timeSlot)}>
                            {dayDate && <div className={css(styles.DayDate)}>{dayDate}</div>}
                            <div style={{ fontSize: '1em', marginRight: '.5em', fontWeight: 'bold' }}>{airingtime}</div>
                        </div>
                        <div className={css(styles.channelDetails)}>
                            <div style={{ marginRight: selectedProgram.sc_id ? 0 : '.5em', fontWeight: 'bold' }}>
                                {`${selectedChannel.custom_channel_number}`}
                            </div>
                            <div>{selectedChannel.custom_name ? selectedChannel.custom_name : ''}</div>
                            {rating ? (
                                <>
                                    <div className={css(styles.RatingBox)}>
                                        <div className={css(styles.Rating)}>{ratingFormatter(rating)}</div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>

                    <div className={css(styles.programInfoContainer)}>
                        <div className={css(styles.episodeName)}>
                            {selectedProgram.long_title}
                            {seText && <small className={css(styles.seText)}>{seText}</small>}
                        </div>
                        <div className={css(styles.descriptionContainer)}>
                            {selectedProgram.ep_name && (
                                <>
                                    <h2 style={{ margin: '0' }}>
                                        {selectedProgram.ep_name}
                                        {
                                            selectedProgram.release_year && (
                                                <small className={css(styles.FirstAired)}>First aired: {selectedProgram.release_year}</small>
                                            ) //ellipsizeMode='tail' style={ styles.firstAiredText }
                                        }
                                    </h2>
                                </>
                            )}
                            {selectedProgram.description
                                ? selectedProgram.description
                                : selectedProgram.program_desc
                                ? selectedProgram.program_desc
                                : 'no details available'}
                            {genre && (
                                <>
                                    <div className={css(styles.Genre)}>{fixCaseAndSpace(genre)}</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={css(styles.optionsContainer)}>
                        <button className={css(styles.button)} onClick={restartProgram ? launchRestartProgram : channelSelection}>
                            {restartProgram ? 'Restart Program' : !liveProgram ? 'Watch Live TV' : 'Watch Now'}
                        </button>
                        <button
                            className={css(styles.button)}
                            style={{ marginc: '.5em' }}
                            onClick={() => {
                                props.toggleOptionsMenu(true);
                            }}>
                            Options
                        </button>
                    </div>
                </div>
                {/* <div className={css(styles.Content)}>
                    <div className={css(styles.ContentRel)}>
                        {selectedProgram.seriesExcluded && (
                            <div className={css(styles.TitleContainer)}>
                                <FontAwesomeIcon color={'rgba(0,131,159,255)'} icon="info-circle" />;
                                <div className={css(styles.excludedDisclaimer)}>
                                    This program will not be recorded. Modify recording options if you would like to include it
                                </div>
                            </div>
                        )}
                        {logo !== '' && <img className={css(styles.Logo)} src={logo} alt="Logo" />}
                        <div className={css(styles.BelowDescriptionContainer)}>
                            {dayDate && <div className={css(styles.DayDate)}>{dayDate}</div>}
                            {airingtime && <div className={css(styles.AiringTime)}>{airingtime}</div>}
                            {rating ? (
                                <>
                                    <div className={css(styles.RatingBox)}>
                                        <div className={css(styles.Rating)}>{ratingFormatter(rating)}</div>
                                    </div>
                                </>
                            ) : null}
                            <div className={css(styles.Callsign)}>{selectedChannel.custom_name ? selectedChannel.custom_name : ''}</div>
                        </div>
                        <div className={css(styles.TitleContainer)}>
                            <div className={css(styles.Title)}>{header !== '' ? header : 'Program Data Unavailable'}</div>
                            {restartAvailable && (
                                <img className={css(styles.SeriesCircleContainer)} src={require('../../assets/SC_SVGs/ic_catch_up.svg')} alt="catch up icon" />
                            )}
                        </div>
                        {seText && (
                            <div className={css(styles.TitleContainer)}>
                                <div className={css(styles.BelowTitle)}>{seText}</div>
                            </div>
                        )}
                        {ep_name && (
                            <div className={css(styles.TitleContainer)}>
                                <div className={css(styles.EpName)}>{ep_name}</div>
                            </div>
                        )}
                        {
                            selectedProgram.release_year && <div className={css(styles.FirstAired)}>First aired: {selectedProgram.release_year}</div> //ellipsizeMode='tail' style={ styles.firstAiredText }
                        }
                        {
                            <div className={css(styles.DescriptionContainer)}>
                                {
                                    //trimIndex > 0 ?
                                    <div className={css(styles.Description)}>{description}</div>
                                }
                                {genre && (
                                    <>
                                        {/* <div className={css(styles.DateTime)} >{ fixCaseAndSpace(genre)}</div>
                                        <div className={css(styles.Genre)}>{fixCaseAndSpace(genre)}</div>
                                    </>
                                )}
                            </div>
                        }
                    </div> */}
                <div className={css(styles.ReservedForMiniPlayer)}>
                    <VideoPlayerWithBarsContainer
                        // channelUp={this.channelUp}
                        // channelDown={this.channelDown}
                        //liveTV={liveTV}
                        currentTime={props.currentTime}
                        isProgressBarOpen={false}
                        closePB={false}
                        //screenSize={screenSize}
                        //onVideoBufferEnd={this.onVideoBufferEnd}
                        //keyMap={this.props.keyMap}
                        //alertEAS={alertEAS}
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const styles = StyleSheet.create({
    Main: {
        flex: 5,
        display: 'flex',
        maxHeight: '12em',
        marginBottom: '.25em',
    },
    Content: {
        flex: 2,
    },
    ContentRel: {
        //////////to allow overflow
        position: 'relative',
        //display: 'block',
        paddingLeft: '.5em',
        paddingTop: '.5em',
        height: '12em',
        // borderColor: 'yellow',
        // borderStyle: 'solid',
        // borderWidth: '.1em',
        overflowX: 'hidden',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
            width: '6px',
        },
    },
    ReservedForMiniPlayer: {
        //width: '28em',
        position:'absolute',
        right: '.1em',
        alignItems: 'flex-end',
        height: '12.5em',
        width: '22.22em', //preserving 16:9 ratio
    },
    TitleContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    Title: {
        color: 'white',
        fontSize: '1.65em',
        fontWeight: '300',
        marginRight: '.2em',
        //fontFamily: 'Roboto'
    },
    BelowTitle: {
        color: 'white',
        fontSize: '1.2em',
        fontWeight: '300',
    },
    EpName: {
        color: 'white',
        fontSize: '1.2em',
        fontWeight: '500',
    },
    Logo: {
        position: 'absolute',
        width: '5em',
        marginLeft: '30em',
        //marginTop: '0.5em'//'calc(81px + 1.5vw)'
    },
    FirstAired: {
        color: 'white',
        fontSize: '.8em',
        fontWeight: '300',
        marginLeft: '.5em',
        bottom: '.2em',
    },
    Callsign: {
        // position: 'absolute',
        color: '#fff',
        fontSize: '1.1em',
        marginLeft: '1em', //'50%',
        // marginTop: '0.5em', //'calc(81px + 2.1em)'
        marginTop: '.2em',
        fontWeight: '500',
    },
    DescriptionContainer: {
        width: '33em',
        //maxHeight: '5em',
        overflow: 'hidden',
    },
    Description: {
        color: 'white',
        fontSize: '1.1em',
        marginTop: '0.2em',
        fontWeight: '300',
    },
    Genre: {
        // color: 'white',
        fontSize: '1.1em',
        marginTop: '0.2em',
        color: '#888888',
        fontWeight: '300',
    },
    DayDate: {
        color: 'white',
        fontSize: '1em',
        marginTop: '.2em',
        whiteSpace: 'nowrap',
        fontWeight: '500',
        marginRight: '.3em',
    },
    AiringTime: {
        color: 'white',
        fontSize: '.75em',
        marginTop: '.2em',
        whiteSpace: 'nowrap',
        fontWeight: '300',
    },
    BelowDescriptionContainer: {
        // borderColor: 'magenta',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        display: 'flex',
        flexDirection: 'row',
        //position: 'absolute',
        maxWidth: '100%',
        overflow: 'hidden',
        alignItems: 'center',
    },
    PipeDivider: {
        color: 'white',
        fontSize: '1.2em',
        marginTop: '.1em',
        marginLeft: '.7em',
        marginRight: '.7em',
        fontWeight: '300',
    },
    RatingBox: {
        //backgroundColor: '#909090',
        borderColor: '#AAAAAA',
        borderStyle: 'solid',
        borderWidth: '.01em',
        borderRadius: '.35em',
        marginTop: '.1em',
        marginLeft: '.5em',
    },
    Rating: {
        padding: ' 0em .2em 0em .2em',
        color: '#AAAAAA',
        fontSize: '.75em',
        // marginTop: '.1em',
        whiteSpace: 'nowrap',
    },
    excludedDisclaimer: {
        color: 'rgba(0,131,159,255)',
        fontSize: '.9em',
        // marginTop: '0.2em',
        marginBottom: '0.3em',
        fontWeight: '300',
    },
    fullVideoOverlay: {
        width: '60%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        // width: "1.5em",
        // backgroundColor: 'rgba(0,0,0,0.65)',
        // boxShadow: '0 0 2em 5em rgba(0,0,0,0.8)',
        color: 'white',
        bottom: '0em',
        paddingRight: '2em',

        // visibility: 'visible',
        // opacity: '1',
    },
    channelInfo: {
        width: '100%',
        display: 'grid',
        height: '2em',
        // alignItems: 'flex-end',
        gridTemplateColumns: 'auto 4fr 1fr',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: "'logo timeSlot options' 'logo channelInfo options'",
        whiteSpace: 'nowrap',
        // overflow: "hidden",
        textOverflow: 'ellipsis',
        marginBottom: '1em',
    },
    channelDetails: {
        alignItems: 'flex-start',
        display: 'flex',
        fontSize: '1em',
        gridArea: 'channelInfo',
        marginLeft: '2%',
    },
    channelLogo: {
        width: '5em',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '5em',
        gridArea: 'logo',
    },
    timeSlot: {
        marginLeft: '2%',
        marginBottom: '.1em',
        fontSize: '.75em',
        gridArea: 'timeSlot',
        display: 'flex',
        alignItems: 'flex-end',
    },
    button: {
        ':focus': {
            outline: 'none',
        },
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(220,220,220)',
            color: '#000',
        },
        backgroundColor: 'transparent',
        color: '#fff',
        border: '1px solid #fff',
        padding: '.3em',
        appearance: 'none',
        display: 'inline',
        width: '12em',
        height: '100%',
        fontWeight: '900',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    optionsContainer: {
        gridArea: 'options',
        // marginBottom: '.5em',
        height: '1.75em',
        textAlign: 'initial',
        display: 'flex',
        // flexDirection: 'column',
        pointerEvents: 'auto',
    },
    episodeName: {
        fontSize: '1.5em',
        marginTop: '.2em',
        marginBottom: '.05em',
    },
    programInfoContainer: {
        height: '7.75em',
    },
    descriptionContainer: {
        visibility: 'visible',
        opacity: '1',
        width: '100%',
        height: 'auto',
        // minHeight: '5em',
        maxHeight: '6em',
        marginBottom: '.25em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontSize: '.9em',
        whiteSpace: 'textwrap',
        overflow: 'scroll',
        textOverflow: 'ellipsis',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
        overflowX: 'auto',
        '::-webkit-scrollbar': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    seText: {
        marginLeft: '.25em',
        fontSize: '.5em',
    },
});

function fixCaseAndSpace(str) {
    if (str) {
        str = str
            .split(',')
            .map((cur) => cur[0].toUpperCase() + cur.slice(1, cur.length))
            .join(', ');
        if (str.indexOf(' ') > -1) {
            str = str
                .split(' ')
                .map((cur) => cur[0].toUpperCase() + cur.slice(1, cur.length))
                .join(' ');
        }
        if (str.indexOf('/') > -1) {
            str = str
                .split('/')
                .map((cur) => cur[0].toUpperCase() + cur.slice(1, cur.length))
                .join('/');
        }
        return str;
    } else {
        return null;
    }
}

export default Title;
