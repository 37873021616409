import { restartLiveEventsAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';

let errorObject = {};
let retryCount = 0;
let requestCopy = {}; //only used for Network Error
let resetTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export const getCatchupLiveEvents = () => {
    //gets all available catchup streams
    return restartLiveEventsAxios
        .get(`/live-events?catchup[eq]=1`, {
            // add this for specific channel: &live.id[in]=${scAssetId}`,{
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
        })
        .then((res) => {
            // On return this should update redux
            if (res.data && res.data.data && res.data.data.length > 0) {
            } else {
                return new Promise.reject({
                    status: 'restart service error: getCatchupLiveEvents',
                    message: 'No Live Events Available',
                });
            }
            return res.data.data;
        });
    // }).then((res) => {
    //     if (res && res.data && res.data.data && res.data.data.length > 0) {
    //         //const YMDToFind = program.start_formated.split(' ')[0];
    //         //const TimeToFind = program.start_formated.split(' ')[1];
    //         const resIndex = res.data.data.findIndex((cur) => cur.ext_id === program.schedule_id);
    //         //res.data.data.findIndex((cur) => cur.start_time.includes(YMDToFind) && cur.start_time.includes(TimeToFind))
    //         const resource = resIndex && resIndex > -1 && res.data.data[resIndex] //condition to find by times: (cur) => cur.start_time.includes(YMDToFind) && cur.start_time.includes(TimeToFind)
    //         if (resource && resource.id) {
    //             return restartLiveEventsAxios.get(`live-events/${resource.id}/catchup-url`,{
    //                 cancelToken: new CancelToken(function executor(c) {
    //                     cancel = c;
    //                 })
    //             }).then((res) => {
    //                 return res.data.data;
    //             })
    //         } else {
    //             return new Promise.reject({status: "restart service error", message : "resource ID not found"});
    //         }
    //     } else {
    //         return new Promise.reject({status: "restart service error", message : "No events returned"});
    //     }
    // })
};

//interceptors
restartLiveEventsAxios.interceptors.request.use(
    (request) => {
        request.retries = 0;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        resetTimeOut && clearTimeout(resetTimeOut);
        resetTimeOut = setTimeout(() => {
            cancel && cancel('Network Error');
        }, 15000); //if original timeout does not work
        //console.log("live event list request: ", request)
        return request;
    },
    (error) => {
        return error;
    }
);

restartLiveEventsAxios.interceptors.response.use(
    (response) => {
        retryCount = 0;
        requestCopy = {};
        resetTimeOut && clearTimeout(resetTimeOut);
        cancel && (cancel = null); //null the cance function for successful req
        //console.log(" live event list response: ", response);
        return response;
    },
    (error) => {
        // console.log(error.message, error.response)
        resetTimeOut && clearTimeout(resetTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 || error.response.status === 404) &&
            retryCount > 0
        ) {
            //auth error!!! Kick them out!
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Check if we've maxed out the total number of retries
        if (retryCount >= config.retries) {
            // Reject with the error
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Increase the retry count
        retryCount += 1;
        // console.log(retryCount, config.retryDelay * retryCount);

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return restartLiveEventsAxios(config);
        });
    }
);
