import React from 'react';
import LoginPage from './components/Login/Containers/LoginPageContainer';
import AppContainer from './AppContainer';

const WebNavigator = (props) => {
    const { isLoadingOnLogin, isLoginSuccess, authError, generalError } = props;
    //console.log(isLoadingOnLogin && isLoginSuccess)
    return (
        <>
            {!isLoadingOnLogin && isLoginSuccess && !authError ? ( //&& !generalError
                <AppContainer />
            ) : (
                <LoginPage />
            )}
        </>
    );
};

export default WebNavigator;
