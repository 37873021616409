import React from 'react';
import { connect } from 'react-redux';

import { getChannels, toggleTicketScreen, deleteRecording } from '../../../actions/guide';
import { updateAssetData } from '../../../actions/myshows';
import OptionsMyShows from '../MyShowsSeriesView/OptionsMyShows';

const OptionsMyShowsContainer = (props) => <OptionsMyShows {...props} />;

const mapStateToProps = (state) => ({
    isTicketScreenOpen: state.guide.isTicketScreenOpen,
    currentPage: state.main.currentPage,
    channelsList: state.guide.channelsList,
    assetData: state.myshows.assetData,
});

export const mapDispatchToProps = (dispatch) => ({
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    getChannels: (series_id, source_id) => dispatch(getChannels(series_id, source_id)),
    deleteRecording: (type, id) => dispatch(deleteRecording(type, id)),
    onUpdateAssetData: (assetData) => dispatch(updateAssetData(assetData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsMyShowsContainer);
