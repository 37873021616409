import React from 'react';
import { connect } from 'react-redux';
import {
    changePage,
    changeChannel,
    playLiveTV,
    pauseLiveTV,
    resumeLiveTV,
    stopLiveTV,
    hideMiniPLayer,
    toggleSubtitle,
    toggleFullScreen,
    changeProgressBarIndex,
    isPaused,
} from '../../../actions/main';
import {
    setProgramIndex,
    setSelectedChannelIndex,
    setPreviousChannelIndex,
    toggleRecordingScreen,
    changeActiveRowIndex,
    setProgramIndexCounter,
    setHorizontalPage,
    changeProgramStart,
    updatePageData,
    updateNextPageData,
    nextHorizontalPage,
    previousHorizontalPage,
    updatePageTransition,
    updateChannelIndexes,
    updateChannelStartEndTimes,
    updateAnimatedValue,
    toggleTicketScreen,
    updatePageChannels,
    onChannelBarInfo,
    onFullScreenInfoOpen,
    toggleOptionsMenu,
    toggleRecordingToast,
    gridNavigate,
} from '../../../actions/guide';

import { changeStateSpeed } from '../../../actions/progressbar';

import Guide from '../Guide';
import { updateOnUpcomingMenu } from '../../../actions/myshows';

// const GuideContainer = (props) => <Guide {...props} />;

const mapStateToProps = (state) => {
    return {
        eventListWithCatchup: state.guide.eventListWithCatchup,
        lightweightVersion: state.main.lightweightVersion,
        keyMap: state.main.keyMap,
        boxModel: state.main.boxModel,
        currentPage: state.main.currentPage,
        currentChannel: state.main.currentChannel,
        channels: state.main.channels,
        channelList: state.main.channelList,
        isPlayerShowing: state.main.isPlayerShowing,
        isPlaying: state.main.isPlaying,
        isSubtitleToggled: state.main.isSubtitleToggled,
        isFullScreen: state.main.isFullScreen,
        programIndex: state.guide.programIndex,
        horizontalPage: state.guide.horizontalPage,
        selectedChannelIndex: state.guide.selectedChannelIndex,
        previousChannelIndex: state.guide.previousChannelIndex,
        programIndexCounter: state.guide.programIndexCounter,
        isProgramIndexCounterSet: state.guide.isProgramIndexCounterSet,
        isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
        // allRecordings : state.guide.allRecordings,
        currentProgressState: state.progressbar.currentProgressState,
        isPostingOrder: state.guide.isPostingOrder,
        storedStartTime: state.guide.storedStartTime,
        activeRowIndex: state.guide.activeRowIndex,
        currentPageData: state.guide.currentPageData,
        pageTransition: state.guide.pageTransition,
        nextPageData: state.guide.nextPageData,
        pageData: state.guide.pageData,
        channelsCurrentStartIndex: state.guide.channelsCurrentStartIndex,
        channelsCurrentEndIndex: state.guide.channelsCurrentEndIndex,
        channelsStartTime: state.guide.channelsStartTime,
        channelsEndTime: state.guide.channelsEndTime,
        pageAnimatedValue: state.guide.pageAnimatedValue,
        isTicketScreenOpen: state.guide.isTicketScreenOpen,
        channelsList: state.guide.channelsList,
        isLoadingChannels: state.guide.isLoadingChannels,
        prBarIndex: state.main.prBarIndex,
        previousPage: state.main.previousPage,
        isChannelBarInfoOpen: state.guide.isChannelBarInfoOpen,
        isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
        pausedChannel: state.main.pausedChannel,
        guideEndPagesTimes: state.main.guideEndPagesTimes,
        isOptionsOpen: state.guide.isOptionsOpen,
        isFullScreenOptionsOpen: state.main.isFullScreenOptionsOpen,
        connTimeOut: state.main.connTimeOut,
        authError: state.main.authError,
        generalError: state.main.generalError,
        subscriberData: state.login.subscriberData,
        guideAnimationsOff: state.guide.guideAnimationsOff,
        isDeletingOrder: state.guide.isDeletingOrder,
        isOrderRequestSuccessful: state.guide.isOrderRequestSuccessful,
        isOrderRequestCompleted: state.guide.isOrderRequestCompleted,
        toastOrderMessage: state.guide.toastOrderMessage,
        isRecordingToastOpen: state.guide.isRecordingToastOpen,
        searchMap: state.guide.searchMap,
        gridRows: state.guide.gridRows,
        assetDataProgramIDLookup: state.myshows.assetDataProgramIDLookup,
        orderData: state.myshows.orderData,
        isCancelTicketScreenOpen: state.myshows.isCancelTicketScreenOpen,
        onUpcomingMenu: state.myshows.onUpcomingMenu,
        // adding for resetGrid relocation to guide
        pageStartingIndex: state.guide.pageStartingIndex, //index in all channels that starts the preview grid
        allChannels: state.main.channels,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onChangePage: (page) => dispatch(changePage(page)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    onPlayLiveTV: () => dispatch(playLiveTV()),
    onPauseLiveTV: () => dispatch(pauseLiveTV()),
    onResumeLiveTV: () => dispatch(resumeLiveTV()),
    onStopLiveTV: () => dispatch(stopLiveTV()),
    onHideMiniPlayer: () => dispatch(hideMiniPLayer()),
    onToggleSubtitle: (isSubtitleToggled) => dispatch(toggleSubtitle(isSubtitleToggled)),
    onToggleFullScreen: (isFullScreen) => dispatch(toggleFullScreen(isFullScreen)),
    onSetSelectedChannelIndex: (selectedChannelIndex) => dispatch(setSelectedChannelIndex(selectedChannelIndex)),
    onSetPreviousChannelIndex: (previousChannelIndex) => dispatch(setPreviousChannelIndex(previousChannelIndex)),
    onSetProgramIndex: (programIndex) => dispatch(setProgramIndex(programIndex)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    toggleRecordingToast: (isOpen) => dispatch(toggleRecordingToast(isOpen)),
    onChangeStateSpeed: (speed) => dispatch(changeStateSpeed(speed)),
    onChangeActiveRowIndex: (newRowIndex) => dispatch(changeActiveRowIndex(newRowIndex)),
    onSetProgramIndexCounter: (programIndexCounter) => dispatch(setProgramIndexCounter(programIndexCounter)),
    onSetHorizontalPage: (page) => dispatch(setHorizontalPage(page)),
    onChangeProgramStart: (newStartTime) => dispatch(changeProgramStart(newStartTime)),
    onUpdatePageData: (payload) => dispatch(updatePageData(payload)),
    onNextHorizontalPage: () => dispatch(nextHorizontalPage()),
    onPreviousHorizontalPage: () => dispatch(previousHorizontalPage()),
    onUpdatePageTransition: (transitionActive) => dispatch(updatePageTransition(transitionActive)),
    onUpdateNextPageData: (payload) => dispatch(updateNextPageData(payload)),
    onUpdateChannelIndexes: (beginningIndex, endingIndex) => dispatch(updateChannelIndexes(beginningIndex, endingIndex)),
    onUpdateChannelStartEndTimes: (startTime, endTime) => dispatch(updateChannelStartEndTimes(startTime, endTime)),
    onUpdateAnimatedValue: (animatedXY) => dispatch(updateAnimatedValue(animatedXY)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    onUpdatePageChannels: (startChannelIndex, endChannelIndex, channels, startTime, endTime) =>
        dispatch(updatePageChannels(startChannelIndex, endChannelIndex, channels, startTime, endTime)),
    changeProgressBarIndex: (value) => dispatch(changeProgressBarIndex(value)),
    isPaused: (status) => dispatch(isPaused(status)),
    onChannelBarInfo: (isChannelBarInfoOpen) => dispatch(onChannelBarInfo(isChannelBarInfoOpen)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
    toggleOptionsMenu: (isOptionsOpen) => dispatch(toggleOptionsMenu(isOptionsOpen)),
    onGridNavigate: (direction, extraData) => dispatch(gridNavigate(direction, extraData)),
    updateOnUpcomingMenu: (onUpcomingMenu) => dispatch(updateOnUpcomingMenu(onUpcomingMenu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
