import React, { Component } from 'react';
import moment from 'moment';
// import RecordingToast from './components/Guide/RecordingToast';
import { FSProgramIndex } from './utils/FSProgramIndex';
import { clearTokenInHeader } from './utils/AxiosInstances';
import { disconnectFromPusherChannel } from './actions/main';
import { BrowserRouter as Router, Route, NavLink, Redirect } from 'react-router-dom';
import MyShowsContainer from './components/MyShows/Containers/MyShowsContainer';
import SettingsContainer from './components/Settings/Containers/SettingsContainer';
import GuideContainer from './components/Guide/Containers/GuideContainer';
import { StyleSheet, css } from 'aphrodite';

export default class AppMain extends Component {
    constructor() {
        super();
        this.state = {
            videoOff: false,
            currentTime: moment(),
            currentPage: 'Home',
            previousPage: 'Home',
            guideStartUnix: 0,
            unlockErrorWindowCode: '',
            updateChannelTime: moment().valueOf() + 60000,
            fadingRedButton: false,
            //fadeAnimation: new Animated.Value(1),
            isConnected: false,
            //appState: AppState.currentState,
            // testTest: false,
            delayClearEAS: false,
            countdownEAS: false,
            conType: null,
            isUpdatingIndex: false,
        };
        this.connLostTimer = null;
        this.initialLoginRun = false;
        this.netInfo = null;
    }

    componentDidMount = () => {
        document.addEventListener('keyup', this._handleKeyPress);
        this.props.onUpdateRestartAvailablity();
        this.initialGuideTimer(); //starts the guid time
        this.setGuideUpdateTime(); //updates the guide for every 30 min
        // Time
        this.timeInterval = setInterval(() => {
            this.setState({
                currentTime: moment(),
            });
        }, 15000);

        // StorageService.getItem('isSubtitleOn').then(res => res && this.props.onToggleSubtitle(JSON.parse(res)));//this is how it remembers if Subtitles On/Off
        const guideStart = moment()
            .subtract(moment().minute() % 30, 'minutes')
            .subtract(moment().second(), 'seconds')
            .subtract(4440, 'minutes')
            .unix();
        const guideEndTime = moment()
            .subtract(moment().minute() % 30, 'minutes')
            .subtract(moment().second(), 'seconds')
            .add(14400, 'minutes')
            .unix();
        this.props.onLoadChannels(guideStart, guideEndTime, true);
    };

    _handleKeyPress = (event) => {
        switch (event.key) {
            case ' ':
                this.setState((prevstate) => ({ videoOff: !prevstate.videoOff }));
                break;
            default:
                break;
        }
    };

    initialGuideTimer = () => {
        //Needed to show time in Guide correctly
        const guideStartTime = moment()
            .subtract(moment().minute() % 30, 'minutes')
            .subtract(moment().second(), 'seconds')
            .unix();
        this.setState({
            guideStartUnix: guideStartTime,
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        // TODO: Update logic to reload guide data every 30 minutes.
        // if (this.state.currentTime.minute() % 30 === 0 && this.state.currentTime.seconds() < 15 && this.props.halfHourLoad === false) {//only every 30 min
        //     const guideStart = moment().subtract(moment().minute() % 30, 'minutes').subtract(moment().second(), 'seconds').unix();
        //     this.setState({
        //         guideStartUnix: guideStart
        //     });
        //     this.props.onHalfHourEpgUpdate(this.props.channels);
        //     this.props.onToggleHalfHourLoad(true);
        //     let subData = this.props.subscriberData.subDeviceInfo;
        //     // bugsnag.notify(new Error("appMain 30 min update"), function(report) {
        //     //     report.metadata = {
        //     //         subData: {"subData": subData}
        //     //     };
        //     // })
        //     setTimeout(() => {
        //         this.props.onToggleHalfHourLoad(false);
        //     }, 60000);

        // }
        if (this.state.currentTime !== prevState.currentTime && !this.state.isUpdatingIndex) {
            this.setState({ isUpdatingIndex: true });
            let thisChannel = this.props.currentChannel.custom_channel_number; //current channel info
            let currentChannelIndex = this.props.channelList.indexOf(thisChannel); //info of current channel
            let channelInfo = this.props.channels[currentChannelIndex];
            let currentTime = this.state.currentTime.unix() * 1000; //in seconds
            FSProgramIndex(currentTime, channelInfo.programs).then((globalProgramIndex) => {
                this.props.onChangeChannel(channelInfo, globalProgramIndex);
            });
            this.setState({ isUpdatingIndex: false });
        }

        if (this.state.currentTime.valueOf() > this.state.updateChannelTime && !this.props.isUpdatingChannelData) {
            //every 6 hours update
            //let subData = this.props.subscriberData.subDeviceInfo;
            this.setGuideUpdateTime();
            const guideStart = moment()
                .subtract(moment().minute() % 30, 'minutes')
                .subtract(moment().second(), 'seconds')
                .subtract(4440, 'minutes')
                .unix();
            const guideEndTime = moment()
                .subtract(moment().minute() % 30, 'minutes')
                .subtract(moment().second(), 'seconds')
                .add(14400, 'minutes')
                .unix();
            this.props.onLoadChannels(guideStart, guideEndTime);
        }

        if (prevProps.isFullScreen && !this.props.isFullScreen) {
            this.resetAnimated(); //reset fading timer or the button would disappear
        } else if (!prevProps.isFullScreen && this.props.isFullScreen) {
            this.fadeRedButtonTimer && clearTimeout(this.fadeRedButtonTimer);
            this.fadeRedButtonTimer = setInterval(() => {
                this.setState({ fadingRedButton: true }, () => clearTimeout(this.fadeRedButtonTimer));
            }, 8000);
        }

        //if(!prevProps.authError && this.props.authError){
        // // console.log("auth error occured")
        // let subData = this.props.subscriberData.subDeviceInfo;
        // // bugsnag.notify(new Error("authentication problem after login"), function(report) {
        // //     report.metadata = {
        // //         subData: {"subData": subData}
        // //     };
        // // })
        // this.statusCodeTimer = setTimeout(()=>{
        //     this._sessionCleanUp();
        //     this.props.resetAppState();
        //     this.props.rootNavigation.rootNavigation.navigate('Login'); //not yet
        // }, 10000)
        // console.log('AUTH_ERROR!!! (UNHANDLED)')
        //}

        if (prevProps.alertEAS === false && this.props.alertEAS === true) {
            this.setState({ delayClearEAS: true, countdownEAS: true });
            this.easTimer = setTimeout(() => this.setState({ countdownEAS: false }), 30000); //once timer is up, user can exit the eas message
        }

        if (prevProps.alertEAS === true && this.props.alertEAS === false) {
            this.delayTimerEAS = setTimeout(() => this.setState({ delayClearEAS: false, countdownEAS: false }), 300); //this delay prevents back press event from triggering twice
        }
    };

    channelUp = () => {
        let thisChannel = this.props.currentChannel.custom_channel_number; //current channel info
        let currentChannelIndex = this.props.channelList.indexOf(thisChannel); //info of current channel
        if (currentChannelIndex < this.props.channelList.length - 1) {
            let nextChannelFullInfo = this.props.channels[currentChannelIndex + 1];
            this.changingChannelUpOrDown(nextChannelFullInfo);
        } else if (currentChannelIndex === this.props.channelList.length - 1) {
            //go to the beginning of array
            let channelFullInfo = this.props.channels[0];
            this.changingChannelUpOrDown(channelFullInfo);
        }
        !this.props.isPlaying && this.props.onResumeLiveTV(); //restart the video if paused
    };

    channelDown = () => {
        let thisChannel = this.props.currentChannel.custom_channel_number; //current channel info
        let currentChannelIndex = this.props.channelList.indexOf(thisChannel); //info of current channel
        if (currentChannelIndex > 0 && currentChannelIndex < this.props.channelList.length) {
            let nextChannelFullInfo = this.props.channels[currentChannelIndex - 1];
            this.changingChannelUpOrDown(nextChannelFullInfo);
        } else if (currentChannelIndex === 0) {
            //go to the beginning of array
            let channelFullInfo = this.props.channels[this.props.channelList.length - 1];
            this.changingChannelUpOrDown(channelFullInfo);
        }
        !this.props.isPlaying && this.props.onResumeLiveTV(); //restart the video if paused
    };

    _listenerFunction = (keyEvent) => {
        const {
            isPlayerShowing,
            isFullScreen,
            onPlayLiveTV,
            onStopLiveTV,
            onToggleFullscreen,
            isPlayingRecording,
            isRecordingScreenOpen,
            isTicketScreenOpen,
            currentChannel,
            isFullScreenOptionsOpen,
            connTimeOut,
            keyMap,
            boxModel,
            authError,
            generalError,
        } = this.props; // channelList, channels, onPauseLiveTV, onResumeLiveTV, isPlaying
        if (!connTimeOut && this.state.isConnected && !this.state.delayClearEAS && !authError && !generalError) {
            //we may need to add the error here
            if (!isPlayingRecording) {
                if (keyEvent.keyCode === keyMap.pp && currentChannel && currentChannel.custom_channel_number) {
                    // Play/Pause
                    if (isPlayerShowing) {
                        //we store paused channel so we can compare it after in Guide (from Guide to FS)
                        // isPlaying ? onPauseLiveTV(currentChannel.custom_channel_number) : onResumeLiveTV();  //*Note Disabled for now..
                    } else {
                        onPlayLiveTV();
                    }
                }
                if (keyEvent.keyCode === keyMap.red || (boxModel === 'Amazon' && keyEvent.keyCode === keyMap.options && this.props.currentPage !== 'Guide')) {
                    //fullscreen Live TV from anywhere on Amino, with fire this is available with options button if anywhere except in the guide
                    if (!isFullScreen && isPlayerShowing) {
                        /////
                        onToggleFullscreen(true);
                    } else if (isFullScreen && boxModel !== 'Amazon') {
                        //close only if this is big remote on Amino
                        //comment 2 lines out if Ed wants to keep FullScreenInfo status
                        this.props.isChannelBarInfoOpen && this.props.onChannelBarInfo(false);
                        this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(false);
                        onToggleFullscreen(false);
                        // this.resetAnimated();//probably do not need it anymore
                    }
                }

                if (keyEvent.keyCode === keyMap.stop) {
                    // Stop
                    isFullScreen && onToggleFullscreen(false);
                    onStopLiveTV();
                }
            }
            if (keyEvent.keyCode === keyMap.back && this.props.isFullScreen && this.props.isPlayerShowing && this.props.currentPage !== 'Guide') {
                //we'll handle that from Guide directly, otherwise can not handle gradual exit
                if (
                    !this.props.isChannelBarInfoOpen &&
                    !this.props.isFullScreenInfoOpen &&
                    !isRecordingScreenOpen &&
                    !isTicketScreenOpen &&
                    !isFullScreenOptionsOpen
                ) {
                    //that allows for gradual closing
                    onToggleFullscreen(false);
                    this.resetAnimated();
                }
            }
            //channel UP an channel Down changing logic
            if (isFullScreen && !isRecordingScreenOpen && !isTicketScreenOpen && !isFullScreenOptionsOpen) {
                if (keyEvent.keyCode === keyMap.channelUp) {
                    //UP on big remote control
                    this.channelUp();
                } else if (keyEvent.keyCode === keyMap.channelDown) {
                    //Down on remote control(remove 93 - Zoomtack)
                    this.channelDown();
                }
            }
            //end of channel UP an channel Down changing logic
        } else if (!generalError && keyEvent.keyCode === keyMap.select) {
            //should only work when No Connection Detected...
            // let message = "Opening Network Settings...";
            // NetworkInf.openWifiSettings();
        } else if (keyEvent.keyCode === keyMap.back && this.state.delayClearEAS && !this.state.countdownEAS) {
            this.clearEAS();
        }
    };

    changingChannelUpOrDown = (channelInfo) => {
        if (channelInfo.programs && channelInfo.programs.length > 0) {
            //only executes when programs data is available
            let currentTime = this.state.currentTime.unix() * 1000; //in seconds
            FSProgramIndex(currentTime, channelInfo.programs).then((globalProgramIndex) => {
                this.props.onChangeChannel(channelInfo, globalProgramIndex);
            });
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.timeInterval);
        this.statusCodeTimer = null;
    };

    setPreviousPage = (previousPage) => {
        this.setState({ previousPage });
    };

    setCurrentPage = (currentPage) => {
        this.setState({ currentPage });
    };

    //Update the channel data randomly every 5-6 hours
    setGuideUpdateTime = () => {
        const FIVE_HOURS_FROM_CURRENT_MS = moment().valueOf() + 18000000;
        const SIX_HOURS_FROM_CURRENT_MS = moment().valueOf() + 21600000;
        let randomTime = Math.floor(Math.random() * (SIX_HOURS_FROM_CURRENT_MS - FIVE_HOURS_FROM_CURRENT_MS + 1)) + FIVE_HOURS_FROM_CURRENT_MS;
        this.setState({
            updateChannelTime: randomTime,
        });
    };

    onVideoBufferEnd = () => {
        this.connLostTimer && clearTimeout(this.connLostTimer);
        //this.props.onConnLossInterval(true);//just was triggering it - as Error on Buffer would mean lost connection...
    };

    clearEAS = () => {
        this.props.clearEAS();
    };

    closeDropdowns = (e) => {
        //TODO: !important. Add NavDropOpen state to redux, so we can close from anywhere with this function
        this.setState({ isNavDropOpen: false });
        //e.stopPropagation();
    };

    render() {
        // const smallScreen = { height: '100%', width: '100%' }; //that gives us 16x9 aspect ratio //const smallScreen = {height: yScale(260), width: xScale(462)};
        // const screenSize = smallScreen; //isFullScreen ? fullScreen : smallScreen; //being calculated correctly!!!
        // const liveTV = currentChannel && currentChannel.stream_info && currentChannel.stream_info.path ? currentChannel.stream_info.path : null;
        // let offSwitch = !isFullScreen ? !isTicketScreenOpen : true; //solve the problem of layering with player and options
        //const alertURL = this.props.urlEAS.replace('s3','http');
        // const alertEAS = this.state.delayClearEAS;

        const { currentTime, guideStartUnix } = this.state;
        return (
            <div onClick={this.closeDropdowns} className={css(styles.Main)}>
                {/* {this.props.isRecordingToastOpen && ( 
                    <RecordingToast
                        isPostingOrder={this.props.isPostingOrder}
                        toggleRecordingToast={this.props.toggleRecordingToast}
                        isUpdatingOrder={this.props.isUpdatingOrder}
                        isDeletingOrder={this.props.isDeletingOrder}
                        toastOrderMessage={this.props.toastOrderMessage}
                        isOrderRequestSuccessful={this.props.isOrderRequestSuccessful}
                        isOrderRequestCompleted={this.props.isOrderRequestCompleted}
                    />
                )} */}
                <Router>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return !this.props.lightweightVersion ? <Redirect to="/myshows" /> : <Redirect to="/guide" />;
                        }}
                    />
                    {<Route path="/myshows" component={MyShowsContainer} />}
                    <Route path="/guide" render={() => <GuideContainer currentTime={currentTime} guideStartUnix={guideStartUnix} />} />
                    <Route path="/settings" component={SettingsContainer} />
                </Router>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    Main: {
        backgroundColor: '#050505',
        position: 'relative',
        //overflow: 'hidden',
        fontSize: '17px',
    },
});
