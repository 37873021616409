import React from 'react';
import { connect } from 'react-redux';

import {
    toggleRecordingScreen,
    toggleRecordingToast,
    //clearOrderMessages,
    toggleCancelTicketScreen,
    isCancelTicketScreenOpen,
    deleteRecording,
} from '../../../actions/guide';
import CancelMyTicket from '../CancelMyTicket';

const CancelMyTicketContainer = (props) => (
    <CancelMyTicket
        toggleCancelTicketScreen={props.toggleCancelTicketScreen}
        isCancelTicketScreenOpen={props.isCancelTicketScreenOpen}
        deleteRecording={props.deleteRecording}
        isRecordingScreenOpen={props.isRecordingScreenOpen}
        toggleRecordingScreen={props.toggleRecordingScreen}
        createOrderMessage={props.createOrderMessage}
        isPostingOrder={props.isPostingOrder}
        toggleRecordingToast={props.toggleRecordingToast}
        updateOrderMessage={props.updateOrderMessage}
        deleteOrderMessage={props.deleteOrderMessage}
        isUpdatingOrder={props.isUpdatingOrder}
        isDeletingOrder={props.isDeletingOrder}
        updateOrderStatus={props.updateOrderStatus}
        deleteOrderStatus={props.deleteOrderStatus}
        //    clearOrderMessages={props.clearOrderMessages}
        recording={props.recording}
        cancel={props.cancel}
        connTimeOut={props.connTimeOut}
        keyMap={props.keyMap}
        isFullScreen={props.isFullScreen}
        onOptionClose={props.onOptionClose}
    />
);

const mapStateToProps = (state) => ({
    isFullScreen: state.main.isFullScreen,
    keyMap: state.main.keyMap,
    isCancelTicketScreenOpen: state.guide.isCancelTicketScreenOpen,
    postOrderStatus: state.guide.postOrderStatus,
    createOrderMessage: state.guide.createOrderMessage,
    isPostingOrder: state.guide.isPostingOrder,
    updateOrderMessage: state.guide.updateOrderMessage,
    deleteOrderMessage: state.guide.deleteOrderMessage,
    isUpdatingOrder: state.guide.isUpdatingOrder,
    isDeletingOrder: state.guide.isDeletingOrder,
    updateOrderStatus: state.guide.updateOrderStatus,
    deleteOrderStatus: state.guide.deleteOrderStatus,
    connTimeOut: state.main.connTimeOut,
    subscriberID: state.login.subscriberData.id,
});

export const mapDispatchToProps = (dispatch) => ({
    toggleCancelTicketScreen: (recVar) => dispatch(toggleCancelTicketScreen(recVar)),
    deleteRecording: (type, id) => dispatch(deleteRecording(type, id)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    toggleRecordingToast: (isOpen) => dispatch(toggleRecordingToast(isOpen)),
    // clearOrderMessages: () => dispatch(clearOrderMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelMyTicketContainer);
