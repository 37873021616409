import React from 'react';
import { connect } from 'react-redux';
import MainSettings from '../MainSettings';

// import {
//     loginSequence,
//     onLoginDataReady,
//     onLoginNeeded
// } from '../../../actions/login';
// import {
//     changePage,
//     resetAppState,
//     saveBoxModel
//  } from '../../../actions/main';
// import Blank from '../../Common/Blank';

const SettingsContainer = (props) => {
    return <MainSettings subscriberData={props.subscriberData} />;
};

const mapStateToProps = (state) => ({
    subscriberData: state.login.subscriberData,
});

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
