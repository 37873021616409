import React, { useCallback } from 'react';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScheduleRow = (props) => {
    const createRecordingIcon = useCallback((recordedSeries, recordedSingle, sc_recording_state, seriesExcluded, adjIcons) => {
        if (seriesExcluded) {
            return <img className={css(styles.SeriesCircleContainer, adjIcons)} src={require('../../assets/SC_SVGs/ic_excluded.svg')} />;
        } else if (sc_recording_state) {
            if (recordedSeries) {
                /* SERIES RECORDING */
                if (sc_recording_state === 'recorded') {
                    //done
                    return (
                        // <div className={css(styles.SeriesCircleContainer)}>
                        //     <div className={css(styles.SingleRecordingCircleDone)} />
                        //     <div className={css(styles.SeriesRecordingCircleDone)} />
                        //     <div className={css(styles.SeriesRecordingCircleDone)} />
                        // </div>
                        <img
                            className={css(styles.SeriesCircleContainer, adjIcons)}
                            src={require('../../assets/SC_SVGs/ic_recorded_filled.svg')}
                            title="scheduled series recording"
                        />
                    );
                } else if (sc_recording_state !== 'error') {
                    return (
                        // <div className={css(styles.SeriesCircleContainer)}>
                        //     <div className={css(styles.SingleRecordingCircle)} />
                        //     <div className={css(styles.SeriesRecordingCircle)} />
                        //     <div className={css(styles.SeriesRecordingCircle)} />
                        // </div>
                        <img
                            className={css(styles.SeriesCircleContainer, adjIcons)}
                            src={require('../../assets/SC_SVGs/ic_recorded.svg')}
                            title="scheduled series recording"
                        />
                    );
                } else if (sc_recording_state === 'error') {
                    return <FontAwesomeIcon className={css(styles.ExclamationCircle, adjIcons)} color={'rgba(225,0,0)'} icon="exclamation-circle" />;
                }
            } else if (recordedSingle) {
                /* SINGLE RECORDING */
                if (sc_recording_state === 'recorded') {
                    return (
                        <img
                            className={css(styles.SeriesCircleContainer, adjIcons)}
                            src={require('../../assets/SC_SVGs/ic_recorded_filled.svg')}
                            title="scheduled single recording"
                        />
                    ); //<div className={css(styles.SingleRecordingCircleDone)} />
                } else if (sc_recording_state !== 'error') {
                    return (
                        <img
                            className={css(styles.SeriesCircleContainer, adjIcons)}
                            src={require('../../assets/SC_SVGs/ic_recorded.svg')}
                            title="scheduled single recording"
                        />
                    ); //<div className={css(styles.SingleRecordingCircle)} />;
                } else if (sc_recording_state === 'error') {
                    return <FontAwesomeIcon className={css(styles.ExclamationCircle)} color={'rgba(225,0,0)'} icon="exclamation-circle" />;
                }
            }
        }
    }, []);

    return (
        <>
            <div className={css(styles.Row)} id={props.rowData.custom_channel_number}>
                {props.rowData.programs.map((program, i) => {
                    // console.log('program: ', program,'programRatio: ', program.programRatio)
                    const endTrimmed = +program.unix_end_time / 1000;
                    const finished = endTrimmed < moment().unix();
                    return (
                        <div
                            key={i}
                            onClick={() => props.handleProgramClick({ channelIndex: props.rowIndex, programIndex: i })}
                            className={css(
                                styles.ProgramContainer,
                                props.isChannelRowActive && props.previewProgramIndex === i && styles.SelectedProgramContainer
                            )}
                            style={{
                                borderColor: 'rgba(230,230,230, 0.2)',
                                flex: program.programRatio,
                                // width:
                                // props.isChannelRowActive && props.previewProgramIndex === i
                                //     ? `calc((${program.programRatio} * (60em / 5.2)))`
                                //     : `calc(${program.programRatio} * (60em / 5.2) )`,
                            }}>
                            <div className={css(!program.restartAvailable && finished ? styles.DullProgramTitle : styles.ProgramTitle)}>
                                <div
                                    className={css(
                                        styles.ProgramText,
                                        props.isChannelRowActive && props.previewProgramIndex === i ? styles.selectedProgramText : null
                                    )}>
                                    {
                                        // program.epi_title &&
                                        //     program.epi_title.length > this.getProgramRatio(program) * 21 ?
                                        //     program.epi_title.slice(0, this.getProgramRatio(program) * 21) :
                                        program.programTitle
                                        // this.state.isRecorded[i] ? <Text>{program.epi_title}{"  "}{<Icon key={i} name="circle" size={15} color= "red" />}</Text>
                                    }
                                </div>
                                {createRecordingIcon(
                                    program.recordedSeries,
                                    program.recordedSingle,
                                    program.sc_recording_state,
                                    program.seriesExcluded,
                                    props.isChannelRowActive && props.previewProgramIndex === i ? styles.adjIcons : null
                                )}
                            </div>
                        </div>
                    );
                    // } else {
                    //     return null;
                    // }
                })}
            </div>
        </>
    );
};

const styles = StyleSheet.create({
    Row: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    ProgramTitle: {
        boxSizing: 'border-box',
        color: '#dbdbdb',
        fontSize: '1.1em',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        // paddingTop: '.3em'
    },
    DullProgramTitle: {
        color: '#656565',
        fontSize: '1.1em',
        whiteSpace: 'nowrap',
        // marginLeft: '.3em',
        display: 'flex',
        flexDirection: 'column',
    },
    ProgramText: {
        boxSizing: 'border-box',
        height: 'auto',
        // position: 'relative',
        // top: '.3em',
        // left: '.3em',
        paddingLeft: '.3em',
        paddingTop: '.3em',
    },
    selectedProgramText: {
        boxSizing: 'border-box',
        height: 'auto',
        // position: 'relative',
        // top: '.3em',
        // left: '.3em',
        paddingLeft: '.21em',
        paddingTop: '.21em',
    },
    SingleRecordingCircle: {
        height: '.7em',
        width: '.7em',
        marginLeft: '.5em',
        borderRadius: '10em',
        backgroundColor: 'rgba(225,20,20)',
        marginBottom: '.15em',
        border: '.1em solid #303030',
    },
    SingleRecordingCircleDone: {
        //position: 'absolute',
        //right: 0,
        height: '.7em',
        width: '.7em',
        marginLeft: '.5em',
        borderRadius: '10em',
        backgroundColor: 'rgba(37,76,25,1)',
        border: '.1em solid #808080',
        marginBottom: '.15em',
    },
    SeriesRecordingCircle: {
        height: '.7em',
        width: '.7em',
        marginLeft: '-.3em',
        borderRadius: '10em',
        backgroundColor: 'rgba(225,20,20)',
        marginBottom: '.15em',
        border: '.1em solid #303030',
    },
    ExclamationCircle: {
        marginLeft: '.5em',
    },
    SeriesRecordingCircleDone: {
        height: '.7em',
        width: '.7em',
        marginLeft: '-.3em',
        borderRadius: '10em',
        backgroundColor: 'rgba(37,76,25,1)',
        border: '.1em solid #808080',
        marginBottom: '.15em',
    },
    SeriesCircleContainer: {
        //display: 'flex',
        //maxHeight: '1.3em',
        // marginLeft: '.4em',
        width: '2.5em',
        height: '1.4em',
        position: 'relative',
        //top: '0em',
        // position: 'absolute',
        // marginTop: '1.1em',
    },
    adjIcons: {
        left: '-.1em',
    },
    ProgramContainer: {
        boxSizing: 'border-box',
        height: '3.1em',
        display: 'flex',
        flexDirection: 'column',
        outline: '1px solid rgba(230,230,230, 0.2)',
        overflow: 'hidden',
        alignContent: 'center',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: '#808080',
            outline: '1px solid rgba(230,230,230,1)',
        },
    },
    SelectedProgramContainer: {
        outline: '1px solid rgba(230,230,230, 1)',
    },
});

// const styles = StyleSheet.create({
//     scheduleItemContainer: {
//         flexDirection: 'row',
//         height: yScale(36),
//         minHeight: yScale(30),
//     },
//     textStyle: {
//         color: '#dbdbdb',
//         fontFamily: 'OpenSans-Light',
//         fontSize: fontScale(19),
//         paddingBottom: yScale(2),
//     },
//     selectedTextStyle: {
//         color: 'rgb(240,240,240)',
//         fontFamily: 'OpenSans-Regular',
//         fontSize: fontScale(19),
//         paddingBottom: yScale(2),
//     },
//     programStyle: {
//         backgroundColor: 'rgba(0,0,0, 0.2)',
//         borderColor: 'rgba(230,230,230, 0.7)',
//         borderWidth: xScale(0.5),
//         alignItems: 'center',
//         paddingHorizontal: xScale(5),
//         flexDirection: 'row'
//     },
//     selectedProgramStyle: {
//         backgroundColor: 'rgba(120,120,120, 1)',
//         borderColor: 'rgba(230,230,230, 0.9)',
//         borderWidth: xScale(1),
//         alignItems: 'center',
//         paddingHorizontal: xScale(5),
//         flexDirection: 'row',
//         zIndex: -5
//     },
//     selectedProgramStyleWithNav: {
//         backgroundColor: 'rgba(120,120,120, .5)',
//         borderColor: 'rgba(230,230,230, 0.9)',
//         borderWidth: xScale(1),
//         alignItems: 'center',
//         paddingHorizontal: xScale(5),
//         flexDirection: 'row',
//         zIndex: -5
//     },
//     programInfoContainer: {
//         flexDirection: 'row',
//         backgroundColor: 'rgba(0,0,0, 0.2)',
//         borderColor: 'rgba(230,230,230, 0.7)',
//         borderWidth: xScale(0.5),
//         alignItems: 'center',
//         justifyContent: 'center',
//         // paddingHorizontal: 20
//     },
//     selectedProgramInfoContainer: {

//     },
//     qualityBox: {
//         borderRadius: xScale(3),
//         backgroundColor: '#303030',
//         paddingHorizontal: xScale(3),
//         marginHorizontal: xScale(5),
//         width: xScale(23),
//         height: yScale(15),
//         alignItems: 'center',
//         justifyContent: 'center'
//     },
//     qualityText: {
//         color: '#979797',
//         fontSize: fontScale(10)
//     }
// });

export default ScheduleRow;
