import React from 'react';
import { connect } from 'react-redux';
import {
    changePage,
    changeChannel,
    playLiveTV,
    pauseLiveTV,
    resumeLiveTV,
    stopLiveTV,
    hideMiniPLayer,
    toggleSubtitle,
    toggleFullScreen,
    changeProgressBarIndex,
    isPaused,
} from '../../../actions/main';
import {
    setProgramIndex,
    setSelectedChannelIndex,
    setPreviousChannelIndex,
    toggleRecordingScreen,
    changeActiveRowIndex,
    setProgramIndexCounter,
    setHorizontalPage,
    changeProgramStart,
    updatePageData,
    updateNextPageData,
    nextHorizontalPage,
    previousHorizontalPage,
    updatePageTransition,
    updateChannelIndexes,
    updateChannelStartEndTimes,
    updateAnimatedValue,
    toggleTicketScreen,
    updatePageChannels,
    onChannelBarInfo,
    onFullScreenInfoOpen,
    toggleOptionsMenu,
    //
    updateRestartAvailablity,
    gridNavigate,
    clearRestart,
} from '../../../actions/guide';

import Schedule from '../Schedule';

// const ScheduleContainer = (props) => <Schedule {...props} />;

const mapStateToProps = (state) => {
    return {
        eventListWithCatchup: state.guide.eventListWithCatchup,
        lightweightVersion: state.main.lightweightVersion,
        keyMap: state.main.keyMap,
        boxModel: state.main.boxModel,
        currentPage: state.main.currentPage,
        currentChannel: state.main.currentChannel,
        channels: state.main.channels,
        channelList: state.main.channelList,
        isPlayerShowing: state.main.isPlayerShowing,
        isPlaying: state.main.isPlaying,
        isSubtitleToggled: state.main.isSubtitleToggled,
        isFullScreen: state.main.isFullScreen,
        programIndex: state.guide.programIndex,
        horizontalPage: state.guide.horizontalPage,
        selectedChannelIndex: state.guide.selectedChannelIndex,
        previousChannelIndex: state.guide.previousChannelIndex,
        programIndexCounter: state.guide.programIndexCounter,
        isProgramIndexCounterSet: state.guide.isProgramIndexCounterSet,
        isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
        // allRecordings : state.guide.allRecordings,
        currentProgressState: state.progressbar.currentProgressState,
        isPostingOrder: state.guide.isPostingOrder,
        storedStartTime: state.guide.storedStartTime,
        activeRowIndex: state.guide.activeRowIndex,
        currentPageData: state.guide.currentPageData,
        pageTransition: state.guide.pageTransition,
        nextPageData: state.guide.nextPageData,
        channelsCurrentStartIndex: state.guide.channelsCurrentStartIndex,
        channelsCurrentEndIndex: state.guide.channelsCurrentEndIndex,
        channelsStartTime: state.guide.channelsStartTime,
        channelsEndTime: state.guide.channelsEndTime,
        pageAnimatedValue: state.guide.pageAnimatedValue,
        isTicketScreenOpen: state.guide.isTicketScreenOpen,
        channelsList: state.guide.channelsList,
        isLoadingChannels: state.guide.isLoadingChannels,
        prBarIndex: state.main.prBarIndex,
        previousPage: state.main.previousPage,
        isChannelBarInfoOpen: state.guide.isChannelBarInfoOpen,
        isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
        pausedChannel: state.main.pausedChannel,
        guideEndPagesTimes: state.main.guideEndPagesTimes,
        isOptionsOpen: state.guide.isOptionsOpen,
        isFullScreenOptionsOpen: state.main.isFullScreenOptionsOpen,
        connTimeOut: state.main.connTimeOut,
        authError: state.main.authError,
        generalError: state.main.generalError,
        subscriberData: state.login.subscriberData,
        guideAnimationsOff: state.guide.guideAnimationsOff,

        //////// STB APP UPDATED PROPS
        gridRows: state.guide.gridRows,
        //eventListWithCatchup: state.guide.eventListWithCatchup,
        assetDataProgramIDLookup: state.myshows.assetDataProgramIDLookup,
        orderData: state.myshows.orderData,
        //isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
        //isPostingOrder: state.guide.isPostingOrder,
        tempAnimationData: state.guide.tempAnimationData,
        pageData: state.guide.pageData,
        // activeChannelIndex: state.guide.activeChannelIndex, //channel selected to play video
        previewChannelIndex: state.guide.previewChannelIndex, //channel with focused/highlighted program the grid
        pageStartingIndex: state.guide.pageStartingIndex, //index in all channels that starts the preview grid
        previewProgramIndex: state.guide.previewProgramIndex, //index of focused program within the focused row
        previewProgramStartTime: state.guide.previewProgramStartTime,
        pageStartTime: state.guide.pageStartTime,
        horizontalPageIndex: state.guide.horizontalPageIndex,
        allChannels: state.main.channels,
        isSCLoading: state.myshows.isSCLoading,
        isOrderDataLoading: state.main.isOrderDataLoading,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onUpdateRestartAvailablity: () => dispatch(updateRestartAvailablity()),
    onChangePage: (page) => dispatch(changePage(page)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    onPlayLiveTV: () => dispatch(playLiveTV()),
    onPauseLiveTV: () => dispatch(pauseLiveTV()),
    onResumeLiveTV: () => dispatch(resumeLiveTV()),
    onStopLiveTV: () => dispatch(stopLiveTV()),
    onHideMiniPlayer: () => dispatch(hideMiniPLayer()),
    onToggleSubtitle: (isSubtitleToggled) => dispatch(toggleSubtitle(isSubtitleToggled)),
    onToggleFullScreen: (isFullScreen) => dispatch(toggleFullScreen(isFullScreen)),
    onSetSelectedChannelIndex: (selectedChannelIndex) => dispatch(setSelectedChannelIndex(selectedChannelIndex)),
    onSetPreviousChannelIndex: (previousChannelIndex) => dispatch(setPreviousChannelIndex(previousChannelIndex)),
    onSetProgramIndex: (programIndex) => dispatch(setProgramIndex(programIndex)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    onChangeActiveRowIndex: (newRowIndex) => dispatch(changeActiveRowIndex(newRowIndex)),
    onSetProgramIndexCounter: (programIndexCounter) => dispatch(setProgramIndexCounter(programIndexCounter)),
    onSetHorizontalPage: (page) => dispatch(setHorizontalPage(page)),
    onChangeProgramStart: (newStartTime) => dispatch(changeProgramStart(newStartTime)),
    onUpdatePageData: (payload) => dispatch(updatePageData(payload)),
    onNextHorizontalPage: () => dispatch(nextHorizontalPage()),
    onPreviousHorizontalPage: () => dispatch(previousHorizontalPage()),
    onUpdatePageTransition: (transitionActive) => dispatch(updatePageTransition(transitionActive)),
    onUpdateNextPageData: (payload) => dispatch(updateNextPageData(payload)),
    onUpdateChannelIndexes: (beginningIndex, endingIndex) => dispatch(updateChannelIndexes(beginningIndex, endingIndex)),
    onUpdateChannelStartEndTimes: (startTime, endTime) => dispatch(updateChannelStartEndTimes(startTime, endTime)),
    onUpdateAnimatedValue: (animatedXY) => dispatch(updateAnimatedValue(animatedXY)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    onUpdatePageChannels: (startChannelIndex, endChannelIndex, channels, startTime, endTime) =>
        dispatch(updatePageChannels(startChannelIndex, endChannelIndex, channels, startTime, endTime)),
    changeProgressBarIndex: (value) => dispatch(changeProgressBarIndex(value)),
    isPaused: (status) => dispatch(isPaused(status)),
    onChannelBarInfo: (isChannelBarInfoOpen) => dispatch(onChannelBarInfo(isChannelBarInfoOpen)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
    toggleOptionsMenu: (isOptionsOpen) => dispatch(toggleOptionsMenu(isOptionsOpen)),

    //////// STB APP UPDATED PROPS
    // onUpdateRestartAvailablity: () => dispatch(updateRestartAvailablity()),
    // onPlayLiveTV: () => dispatch(playLiveTV()),
    // onToggleFullScreen: (isFullScreen) => dispatch(toggleFullScreen(isFullScreen)),
    // onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    // toggleOptionsMenu: (isOptionsOpen) => dispatch(toggleOptionsMenu(isOptionsOpen)),
    onGridNavigate: (direction, extraData) => dispatch(gridNavigate(direction, extraData)),
    // onChangePage: (page) => dispatch(changePage(page)),
    onClearRestart: () => dispatch(clearRestart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
