import React, { Component } from 'react';
//import ReactPlayer from 'react-player'
import './Login.css';
import { StyleSheet, css } from 'aphrodite';
import transEpic from '../../assets/transEpic.png';
import { disconnectFromPusherChannel } from '../../actions/main';
import SignIn from './SignIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loginBackgroundIMG from '../../assets/bg_login.jpg';
import LoadingAnimation from './LoadingAnimation';

export default class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // username: "kyle.fratello@skittertv.com",
            // password: "123456",
            username: '',
            password: '',
            loginCompleted: false,
            displayUsernameError: false,
            displayPasswordError: false,
            usernameErrorText: '',
            passwordErrorText: '',
        };
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    resetDisplayError = () => {
        if (this.state.displayUsernameError || this.state.displayPasswordError) {
            this.setState({ displayPasswordError: false, displayUsernameError: false });
        }
    };

    handleChange = (event, field) => {
        this.setState({
            [field]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let willLogin = true;

        if (this.state.username.length <= 0) {
            willLogin = false;
            this.setState({
                displayUsernameError: true,
                usernameErrorText: 'The username field can not be empty',
            });
        }

        if (this.state.password.length <= 0) {
            willLogin = false;
            this.setState({
                displayPasswordError: true,
                passwordErrorText: 'The password field can not be empty',
            });
        }

        if (willLogin) {
            this.setState({ displayPasswordError: false, displayUsernameError: false });
            this.props.onLoginDataReady({ email: this.state.username, password: this.state.password });
        }
    };

    // componentDidMount = () => {
    // }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            !this.props.isErrorOnLogin &&
            this.props.isLoginSuccess &&
            !this.props.authError &&
            !this.props.fetchGuideDataError &&
            !this.props.generalError &&
            !this.props.getAssetsError &&
            !this.props.getImagesLoadError &&
            prevProps.isLoadingOnLogin &&
            !this.props.isLoadingOnLogin
        ) {
            this.setState({ progress: 1 });
            this.loginDismissTimer = setTimeout(this.loginDismiss, 100); //TO DO - to remove
            //console.log('A');
        }
        //console.log(this.props.isLoginSuccess, this.props.authError, this.props.generalError);

        if (this.props.isLoginSuccess && ((!prevProps.authError && this.props.authError) || (!prevProps.generalError && this.props.generalError))) {
            // console.log('error has occured - session reset');
            //console.log('B');
            this.statusCodeTimer = setTimeout(() => {
                this._sessionCleanUp();
            }, 3000);
        }
    };

    _sessionCleanUp = () => {
        //cleans up session and preparing for the next
        this.props.resetAppState();
        this.setState(
            {
                idx: 0,
                subHeader: 'Please input your Account ID',
                emptyusername: true,
                emptypassword: true,
                username: '',
                password: '',
                text: '',
                progress: 0,
            },
            () => {
                //clearTokenInHeader();
                // clearStorage(); //TO DO - to restore
                disconnectFromPusherChannel();
            }
        );
    };

    loginDismiss = () => {
        //this.setState({hideProgressBar : true})
        // console.log("go to main app"); //TO DO comment in this and uncomment out the action
        //in here we are reseting navigator and moving to Home screen, which supposed to become first in stack now
        // this.props.onChangePage("Home");
        // this.props.navigation.navigate("MainApp");
        console.log('LOGIN COMPLETE');
        //this.setState({loginCompleted: true})
    };

    render() {
        const {
            errorMessageOnLogin,
            isErrorOnLogin,
            isLoadingOnLogin,
            isLoginSuccess,
            fetchGuideDataError,
            getAssetsError,
            getImagesLoadError,
            originalErrorResponse,
        } = this.props;

        return (
            <div className={css(styles.Main)}>
                <div className={css(styles.LogoContainer)}>
                    <img className={css(styles.Logo)} src={transEpic} alt="Logo" />
                </div>
                {this.state.loginCompleted ? (
                    <p>Login successful, enjoy!</p>
                ) : isLoginSuccess && !isErrorOnLogin ? (
                    !fetchGuideDataError && !getAssetsError && !getImagesLoadError ? (
                        <div className={css(styles.loadingContainer)}>
                            <p>{this.props.isResetNoticeSet ? 'Channels need an update' : 'Loading guide and recordings'}</p>
                            <LoadingAnimation svgStyles={styles.loadingIcon} />
                        </div>
                    ) : (
                        <>
                            <p>{fetchGuideDataError ? fetchGuideDataError : getAssetsError ? getAssetsError : getImagesLoadError}</p>
                        </>
                    )
                ) : !isLoadingOnLogin && !isErrorOnLogin ? (
                    <SignIn
                        handleSubmit={this.handleSubmit}
                        handleChange={(e, field) => this.handleChange(e, field)}
                        userVal={this.state.username}
                        passVal={this.state.password}
                        displayUsernameError={this.state.displayUsernameError}
                        displayPasswordError={this.state.displayPasswordError}
                        usernameErrorText={this.state.usernameErrorText}
                        passwordErrorText={this.state.passwordErrorText}
                        resetDisplayError={this.resetDisplayError}
                    />
                ) : isErrorOnLogin ? (
                    errorMessageOnLogin === 'Invalid Platform' ? (
                        <div className={css(styles.errorContainer)}>
                            <FontAwesomeIcon className={css(styles.triangle)} size={'1x'} icon="exclamation-triangle" />
                            <p>
                                At this time, mobile devices are not supported on the EPIC platform. Please use a supported desktop browser (Firefox or Chrome)
                                to view your TV channels and recordings.
                            </p>
                        </div>
                    ) : (
                        <div className={css(styles.errorContainer)}>
                            <div className={css(styles.errorHeaderIconWrapper)}>
                                <FontAwesomeIcon className={css(styles.triangle)} size={'1x'} icon="exclamation-triangle" />
                                <h1 className={css(styles.errorHeader)}>Login Failed</h1>
                            </div>
                            {originalErrorResponse?.error?.response?.data?.message ? (
                                originalErrorResponse.error.response?.data?.errors ? (
                                    <>
                                        <p
                                            className={css(
                                                styles.errorText
                                            )}>{`Failed due to the following: ${originalErrorResponse.error.response.data.message}`}</p>
                                        <ul>
                                            {Object.keys(originalErrorResponse.error.response.data.errors).map((keyName, i) => (
                                                <li key={i}>
                                                    <p
                                                        className={css(
                                                            styles.errorText
                                                        )}>{`${keyName}: ${originalErrorResponse.error.response.data.errors[keyName]}`}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <p
                                        className={css(
                                            styles.errorText
                                        )}>{`Failed due to the following: ${originalErrorResponse.error.response.data.message}`}</p>
                                )
                            ) : (
                                <p className={css(styles.errorText)}>An unknown problem has occured, please check internet access before trying again.</p>
                            )}
                            {/* <input className={css(styles.submitInput)} type="submit" value="LOG IN" /> */}
                            <button className={css(styles.tryAgainButton)} onClick={this._sessionCleanUp}>
                                Try Again
                            </button>
                        </div>
                    )
                ) : (
                    <div className={css(styles.loadingContainer)}>
                        <p>{this.props.isResetNoticeSet ? 'Channels need an update' : 'Logging in'}</p>
                        <LoadingAnimation svgStyles={styles.loadingIcon} />
                    </div>
                )}
            </div>
        );
    }
}

// App {
//   text-align: center;
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

const styles = StyleSheet.create({
    Main: {
        '@media (max-width: 600px)': {
            paddingLeft: 0,
            alignItems: 'center',
        },
        // textAlign: 'center',
        // backgroundColor: '#000',
        // minHeight: '100vh',
        display: 'flex',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        paddingLeft: '10vw',
        overflow: 'hidden',
        fontSize: 'calc(12px + 2vmin)',
        color: 'white',
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.72)), url(${loginBackgroundIMG})`,
        backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        boxSizing: 'border-box',
        minWidth: '300px',
        minHeight: '700px',
    },
    Form: {
        width: '50%',
    },
    Login: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    LoginHeader: {
        color: '#61dafb',
        marginBottom: '30px',
        margin: '20px',
        fontSize: 'calc(12px + 2vmin)',
    },
    TextInput: {
        padding: '5px',
        backgroundColor: '#282c34',
        borderRadius: '4px',
        color: 'white',
        margin: '15px',
    },
    SubmitButton: {
        margin: '10px',
        backgroundColor: '#white',
        borderWidth: '3px',
        borderColor: 'white',
        color: '#282c34',
        padding: '5px',
    },
    LogoContainer: {
        marginTop: '20vh',
    },
    Logo: {
        width: 'calc(140px + 8vmin)',
    },
    triangle: {
        fontSize: '1.8em',
        color: 'yellow',
    },
    errorContainer: {
        '@media (max-width: 600px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        maxWidth: '80%',
    },
    errorHeaderIconWrapper: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorHeader: {
        marginLeft: '4vw',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '1.8em',
    },
    errorText: {
        '@media (max-width: 600px)': {
            textAlign: 'center',
        },
        fontFamily: 'Roboto',
        fontSize: '0.9em',
        fontWeight: '400',
    },
    tryAgainButton: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(220,220,220)',
            color: '#000',
        },
        ':focus': {
            outline: 'none',
        },
        appearance: 'none',
        backgroundColor: 'initial',
        borderColor: 'rgb(200,200,200)',
        borderStyle: 'solid',
        color: 'rgb(200,200,200)',
        padding: '1vh 2vw',
        transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
        marginTop: '5vh',
    },
    loadingIcon: {
        height: '15%',
        width: '15%',
    },
    loadingContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden',
        display: 'flex',
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
});
