import {
    CARD_MENU_TOGGLE,
    MOVE_LEFT,
    MOVE_RIGHT,
    UPDATE_CARDS,
    GET_ASSETS_BEGIN,
    GET_ASSETS_SUCCESS,
    GET_ASSETS_FAILURE,
    UPDATE_ASSET_DATA,
    UPDATE_ORDER_DATA,
    CHANGE_SELECTED_INDEX,
    CHANGE_TITLES_INDEX,
    GET_IMAGES_BEGIN,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAILURE,
    UPDATE_LOADING,
    TOGGLE_FULLSCREEN_VIDEO,
    TOGGLE_SINGLE_RECORDING_PAGE,
    TOGGLE_RECORDING_LIST_PAGE,
    TOGGLE_SORT_BY_SEASON,
    ON_ALL_SENUMS_AVAILABLE,
    FILTER_SHOW_LIST,
    FILTER_CARD_LIST,
    CHANGE_MENU_TITLE,
    GET_SC_BEGIN,
    GET_SC_SUCCESS,
    GET_SC_FAILURE,
    UPDATE_UPCOMING_DATA,
    ON_UPCOMING_MENU,
    //TOGGLE_SELECTED_RECORDING
} from '../types/myshows';

const initialState = {
    cardsEnabled: true,
    cardData: [], //Update later with images
    titlesIndex: 0,
    subscriberData: {},
    isLoadingAssets: false,
    isLoadingProgramData: false,
    getAssetsError: null,
    assetData: [],
    singleRecordingIndex: 0,
    isMyShowsRecordingListOpen: true,
    isShowRecordingInfoOpen: false,
    isFullScreenVideoOpen: false,
    isSortBySeason: true,
    allSENumsAvailable: false,
    showList: [],
    cardList: null,
    menuIndex: 0,
    isSelectedRecordingOpen: false,
    orderData: [],
    getImagesLoadError: null,
    globalShowList: [],
    assetDataProgramIDLookup: {},
    isSCLoading: false,
    upcomingData: [],
    onUpcomingMenu: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SORT_BY_SEASON:
            return {
                ...state,
                isSortBySeason: action.isSortBySeason,
            };
        case FILTER_SHOW_LIST:
            return {
                ...state,
                globalShowList: action.globalShowList,
                showList: action.showList,
            };
        case FILTER_CARD_LIST:
            return {
                ...state,
                cardList: action.cardList,
            };
        case CHANGE_MENU_TITLE:
            return {
                ...state,
                menuIndex: action.index,
            };
        case CARD_MENU_TOGGLE:
            return {
                ...state,
                cardsEnabled: action.cardsEnabled,
            };
        case TOGGLE_RECORDING_LIST_PAGE:
            return {
                ...state,
                isMyShowsRecordingListOpen: action.listPageToggle,
            };
        case TOGGLE_SINGLE_RECORDING_PAGE:
            return {
                ...state,
                isShowRecordingInfoOpen: action.singleToggle,
            };
        case TOGGLE_FULLSCREEN_VIDEO:
            return {
                ...state,
                isFullScreenVideoOpen: action.videoToggle,
            };
        case MOVE_LEFT:
            const lastItem = action.cardData[action.cardData.length - 1];
            const shiftedArrayLeft = [lastItem, ...action.cardData.slice(0, action.cardData.length - 1)];
            return {
                ...state,
                assetData: shiftedArrayLeft,
            };
        case MOVE_RIGHT:
            const firstItem = action.cardData[0];
            const shiftedArrayRight = [...action.cardData.slice(1), firstItem];
            return {
                ...state,
                assetData: shiftedArrayRight,
            };
        case CHANGE_TITLES_INDEX:
            return {
                ...state,
                titlesIndex: action.titlesIndex,
            };
        case UPDATE_CARDS:
            return {
                ...state,
                cardData: action.cardData,
            };
        case GET_ASSETS_BEGIN:
            return {
                ...state,
                isLoadingAssets: true, //for changing message on login screen
                getAssetsError: null,
            };
        case GET_ASSETS_SUCCESS:
            return {
                ...state,
                isLoadingAssets: false,
                subscriberData: action.products,
            };
        case GET_ASSETS_FAILURE:
            return {
                ...state,
                isLoadingAssets: false,
                getAssetsError: action.getAssetsError,
                subscriberData: [],
            };
        case UPDATE_ASSET_DATA:
            return {
                ...state,
                assetDataProgramIDLookup: action.assetDataProgramIDLookup,
                assetData: action.assetData,
                isLoadingAssets: false,
            };
        case ON_UPCOMING_MENU:
            return {
                ...state,
                onUpcomingMenu: action.onUpcomingMenu,
            };
        case UPDATE_UPCOMING_DATA:
            return {
                ...state,
                upcomingData: action.upcomingData,
            };
        case UPDATE_ORDER_DATA:
            return {
                ...state,
                orderData: action.orderData,
            };
        case UPDATE_ORDER_DATA:
            return {
                ...state,
                orderData: action.orderData,
            };
        case CHANGE_SELECTED_INDEX:
            return {
                ...state,
                singleRecordingIndex: action.singleRecordingIndex,
            };
        case UPDATE_LOADING:
            return {
                ...state,
                isLoadingAssets: action.isLoading,
            };
        case GET_IMAGES_BEGIN:
            return {
                ...state,
                isLoadingProgramData: true,
                getImagesLoadError: null,
            };
        case GET_IMAGES_SUCCESS:
            return {
                ...state,
                isLoadingProgramData: false,
                getImagesLoadError: null,
            };
        case GET_IMAGES_FAILURE:
            return {
                ...state,
                isLoadingProgramData: false,
                getImagesLoadError: action.error,
            };
        case ON_ALL_SENUMS_AVAILABLE:
            return {
                ...state,
                allSENumsAvailable: action.onAllSENumsAvailable,
            };
        case GET_SC_BEGIN:
            return {
                ...state,
                isSCLoading: true,
                isSCError: false,
            };
        case GET_SC_SUCCESS:
            return {
                ...state,
                isSCLoading: false,
                isSCError: false,
            };
        case GET_SC_FAILURE:
            return {
                ...state,
                isSCLoading: false,
                isSCError: true,
            };
        // case TOGGLE_SELECTED_RECORDING:
        //     return {
        //         ...state,
        //         isSelectedRecordingOpen: action.isSelectedRecordingOpen
        //     }
        default:
            return state;
    }
};
