import { channelsAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';

let errorObject = {};
let retryCount = 0;
let requestCopy = {}; //only used for Network Error
let channelTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export const getNumberOfPages = (start, end) => {
    return channelsAxios.get(`getguide/${start}/${end}?page=0&items_per_page=1&no_data=true`, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

export const getChannelsWithSeriesId = (series_id) => {
    return channelsAxios.get(`/getSeasonsChannels/${series_id}`, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

export const getChannelsWithSourceId = (source_id) => {
    //in case if channels array is empty on series
    return channelsAxios.get(`/getSources/${source_id}`, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

channelsAxios.interceptors.request.use(
    (request) => {
        // console.log("channels axios request", request);
        request.retries = 2;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        channelTimeOut = setTimeout(() => {
            cancel && cancel('Timeout'); //TO DO - change it to timeout error
        }, 21000); //if original timeout does not work
        return request;
    },
    (error) => {
        return error;
    }
);

channelsAxios.interceptors.response.use(
    (response) => {
        // console.log("channels axios response", response);
        retryCount = 0;
        requestCopy = {};
        channelTimeOut && clearTimeout(channelTimeOut);
        channelTimeOut = null;
        cancel && (cancel = null);
        return response;
    },
    (error) => {
        // console.log("channel error", error, error.message, error.config)
        channelTimeOut && clearTimeout(channelTimeOut);
        channelTimeOut = null;
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' || error.message !== 'Timeout' || error.message ? error.config : null;

        // If Network Error config does not exist or the retry option is not set, reject
        if (error && error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (((error && error.message === 'Network Error') || error.message === 'Timeout') && !config) {
            // console.log("Network Error or Timeout occured and no config from request", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (((error.response.status === 401 || error.response.status === 404 || error.response.status === 400) && retryCount > 0) ||
                error.response.status === 500)
        ) {
            //auth error!!! Kick them out! or 404 or 400
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (retryCount >= config.retries) {
            // Reject with the error
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        retryCount += 1;
        //console.log(retryCount, config.retryDelay * retryCount);

        let backoff = new Promise(function (resolve) {
            //handle exponential backoff
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return channelsAxios(config);
        });
    }
);
