export const closestChNumber = (arr, num) => {
    //TO DO - make more efficient search
    for (let i = 0; i < arr.length; i++) {
        if (parseInt(arr[i]) > num) {
            //stop and go to number arr(i-1)
            return arr[i - 1];
        }
    }
};

export const numberPad = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']; //{ 7: '0', 8: '1', 9: '2', 10: '3', 11: '4', 12: '5', 13: '6', 14: '7', 15: '8', 16: '9' }; //numberPad codes 0..9;

export const startRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];

export const stopRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];

export const allDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const singleShowTypes = ['MO', 'OT']; //TODO - add show types for single shows as needed

export const menuItems = ['Recordings', 'Cast', 'My Ticket Options'];
