import React from 'react';
import { connect } from 'react-redux';
// import Blank from '../MyShowsBlank';

import {
    cardMenuToggle,
    moveLeft,
    moveRight,
    updateCards,
    changeTitlesIndex,
    recordingListToggle,
    singleRecordingToggle,
    fullscreenVideoToggle,
    toggleSortBySeason,
    onAllSENumsAvailable,
    updateFilter,
    updateOnUpcomingMenu,
} from '../../../actions/myshows';

import { changePage, stopLiveTV, togglePlayingRecording } from '../../../actions/main';

import {
    deleteRecording,
    toggleRecordingScreen,
    toggleRecordingToast,
    //clearOrderMessages,
    toggleTicketScreen,
    toggleCancelTicketScreen,
    getChannels,
    clearRestart,
} from '../../../actions/guide';

import { updateShowInfo } from '../../../actions/showinfo';

import MyShows from '../MyShows';

const MyShowsContainer = (props) => {
    return <MyShows {...props} />;
};

const mapStateToProps = (state) => ({
    lightweightVersion: state.main.lightweightVersion,
    isFullScreen: state.main.isFullScreen,
    keyMap: state.main.keyMap,
    showList: state.myshows.showList,
    isSortBySeason: state.myshows.isSortBySeason,
    isTicketScreenOpen: state.guide.isTicketScreenOpen,
    isCancelTicketScreenOpen: state.guide.isCancelTicketScreenOpen,
    isFullscreenLive: state.main.isFullscreenLive,
    isPlayingRecording: state.main.isPlayingRecording,
    isPlayerShowing: state.main.isPlayerShowing,
    cardData: state.myshows.cardData,
    cardsEnabled: state.myshows.cardsEnabled,
    titlesIndex: state.myshows.titlesIndex,
    subscriberData: state.myshows.subscriberData,
    assetData: state.myshows.assetData,
    isLoadingAssets: state.myshows.isLoadingAssets,
    currentPage: state.main.currentPage,
    isMyShowsRecordingListOpen: state.myshows.isMyShowsRecordingListOpen,
    isShowRecordingInfoOpen: state.myshows.isShowRecordingInfoOpen,
    isFullScreenVideoOpen: state.myshows.isFullScreenVideoOpen,
    selectedRowIndex: state.showinfo.selectedRowIndex,
    showInfo: state.showinfo.showInfo,
    isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
    postOrderStatus: state.guide.postOrderStatus,
    currentProgressState: state.progressbar.currentProgressState,
    createOrderMessage: state.guide.createOrderMessage,
    deleteOrderMessage: state.guide.deleteOrderMessage,
    isUpdatingOrder: state.guide.isUpdatingOrder,
    deleteOrderStatus: state.guide.deleteOrderStatus,
    allSENumsAvailable: state.myshows.allSENumsAvailable,
    channelsList: state.guide.channelsList,
    isSelectedRecordingOpen: state.myshows.isSelectedRecordingOpen,
    connTimeOut: state.main.connTimeOut,
    boxModel: state.main.boxModel,
    authError: state.main.authError,
    generalError: state.main.generalError,
    globalShowList: state.myshows.globalShowList,
    isDeletingOrder: state.guide.isDeletingOrder,
    isPostingOrder: state.guide.isPostingOrder,
    isOrderRequestSuccessful: state.guide.isOrderRequestSuccessful,
    isOrderRequestCompleted: state.guide.isOrderRequestCompleted,
    toastOrderMessage: state.guide.toastOrderMessage,
    isRecordingToastOpen: state.guide.isRecordingToastOpen,
    upcomingData: state.myshows.upcomingData,
    isUpcomingLoading: state.myshows.isUpcomingLoading,
    onUpcomingMenu: state.myshows.onUpcomingMenu,
});

export const mapDispatchToProps = (dispatch) => ({
    updateFilter: (index) => dispatch(updateFilter(index)),
    toggleSortBySeason: (isOn) => dispatch(toggleSortBySeason(isOn)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    toggleCancelTicketScreen: (recVar) => dispatch(toggleCancelTicketScreen(recVar)),
    onStopLiveTV: () => dispatch(stopLiveTV()),
    onChangePage: (page) => dispatch(changePage(page)),
    onChangeTitlesIndex: (titlesIndex) => dispatch(changeTitlesIndex(titlesIndex)),
    onCardMenuToggle: (cardsEnabled) => dispatch(cardMenuToggle(cardsEnabled)),
    onRecordingListToggle: (listPageToggle) => dispatch(recordingListToggle(listPageToggle)),
    onSingleRecordingToggle: (singleToggle) => dispatch(singleRecordingToggle(singleToggle)),
    onFullscreenVideoToggle: (videoToggle) => dispatch(fullscreenVideoToggle(videoToggle)),
    onMoveRight: (cardData) => dispatch(moveRight(cardData)),
    onMoveLeft: (cardData) => dispatch(moveLeft(cardData)),
    onUpdateCards: (cardData) => dispatch(updateCards(cardData)),
    deleteRecording: (type, id) => dispatch(deleteRecording(type, id)),
    onUpdateShowInfo: (newInfo) => dispatch(updateShowInfo(newInfo)),
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    togglePlayingRecording: (isPlaying) => dispatch(togglePlayingRecording(isPlaying)),
    toggleRecordingToast: (isOpen) => dispatch(toggleRecordingToast(isOpen)),
    //clearOrderMessages: () => dispatch(clearOrderMessages()),
    onAllSENumsAvailable: (status) => dispatch(onAllSENumsAvailable(status)),
    //toggleSelectedRecording: (isSelectedRecordingOpen) => dispatch(toggleSelectedRecording(isSelectedRecordingOpen)),
    getChannels: (series_id, source_id) => dispatch(getChannels(series_id, source_id)),
    onClearRestart: () => dispatch(clearRestart()),
    updateOnUpcomingMenu: (onUpcomingMenu) => dispatch(updateOnUpcomingMenu(onUpcomingMenu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyShowsContainer);
