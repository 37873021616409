//import { Animated } from 'react-native';

import {
    TOGGLE_FULLSCREEN_INFO,
    TOGGLE_OPTIONS_POPUP,
    SET_HORIZONTAL_PAGE,
    NEXT_HORIZONTAL_PAGE,
    PREVIOUS_HORIZONTAL_PAGE,
    SET_PROGRAM_INDEX,
    SET_SELECTED_CHANNEL_INDEX,
    SET_PREVIOUS_CHANNEL_INDEX,
    SET_PROGRAM_INDEX_COUNTER,
    TOGGLE_PROGRAM_INDEX_COUNTER,
    TOGGLE_RECORDING_SCREEN,
    IS_RECORDING_SCREEN,
    POST_RECORD_BEGIN,
    POST_RECORD_SUCCESSFUL,
    POST_RECORD_ERROR,
    TOGGLE_RECORDING_TOAST,
    UPDATE_RECORD_BEGIN,
    UPDATE_RECORD_SUCCESSFUL,
    UPDATE_RECORD_ERROR,
    DELETE_RECORD_BEGIN,
    DELETE_RECORD_SUCCESSFUL,
    DELETE_RECORD_ERROR,
    CANCEL_RECORD_ERROR,
    CHANGE_PROGRAM_START,
    CHANGE_ACTIVE_ROW_INDEX,
    UPDATE_PAGE_DATA,
    UPDATE_NEXT_PAGE_DATA,
    TRANSITION_NEW_PAGE_IN,
    UPDATE_CHANNEL_INDEXES,
    UPDATE_CHANNEL_START_END_TIMES,
    UPDATE_ANIMATED_VALUE,
    TOGGLE_TICKET_SCREEN,
    IS_CREATING_TICKET_SCREEN,
    GET_CHANNELS_BEGIN,
    GET_CHANNELS_SUCCESS,
    UPDATE_CHANNELS_LIST,
    GET_CHANNELS_FAILURE,
    TOGGLE_CANCEL_TICKET_SCREEN,
    IS_CANCELLING_TICKET_SCREEN,
    CHANNEL_BAR_INFO_STATE,
    CANCEL_ORDER_BEGIN,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    TOGGLE_OPTIONS_MENU,
    // POST_ORDER_ERROR,
    TOGGLE_GUIDE_ANIMATIONS,
    SET_RESTART_IN_PROGRESS,
    SET_RESTART_STREAM,
    CLEAR_RESTART,
    SET_RESTART_LOADING,
    // RESTART_COMPLETED_TOAST_SET,
    UPDATE_CATCHUP_LIVE_EVENTS,
    PAGE_INIT,
    PAGE_CHANGE_RIGHT,
    PROGRAM_CHANGE_RIGHT,
    PAGE_CHANGE_LEFT,
    PROGRAM_CHANGE_LEFT,
    PROGRAM_CHANGE_UP,
    PAGE_CHANGE_UP,
    PROGRAM_CHANGE_DOWN,
    PAGE_CHANGE_DOWN,
    SET_SEARCH_MAP,
} from '../types/guide';

const initialState = {
    tempAnimationData: null,
    pageData: null,
    activeChannelIndex: 0, //channel selected to play video
    previewChannelIndex: 0, //channel with focused/highlighted program the grid
    pageStartingIndex: 0, //index in all channels that starts the preview grid
    previewProgramIndex: 0, //index of focused program within the focused row
    previewProgramStartTime: null,
    pageStartTime: null,
    gridRows: 6,
    // activeRowIndex: 0,
    // storedStartTime: 0,
    // programIndex: 0,
    // programIndexCounter: 0,
    // isProgramIndexCounterSet: false,
    // selectedChannelIndex: 0,
    // previousChannelIndex: 0,
    // isOptionsPopupOpen: false,
    // horizontalPage: 0,
    isRecordingScreenOpen: false,
    error: undefined,
    isPostingOrder: false,
    // allRecordings: [],
    toastOrderMessage: '',
    isRecordingToastOpen: false,
    isUpdatingOrder: false,
    isDeletingOrder: false,
    currentPageData: [],
    nextPageData: [],
    pageTransition: false,
    channelsCurrentStartIndex: 0,
    channelsCurrentEndIndex: 6,
    channelsStartTime: 0,
    channelsEndTime: 0,
    // pageAnimatedValue: new Animated.ValueXY({ x: 0, y: 0}),
    isLoadingChannels: true,
    channelsList: {},
    isTicketScreenOpen: false,
    isCancelTicketScreenOpen: false,
    isOrderRequestCompleted: false,
    isOrderRequestSuccessful: false,
    isChannelBarInfoOpen: false,
    isCancelingOrder: false,
    isCancelRequestSuccessful: false,
    isFullScreenInfoOpen: false,
    isOptionsOpen: false,
    guideAnimationsOff: true,
    restartProgramInProgress: false,
    restartStreamInfo: { streamURL: '', drmLicenseURL: '' },
    restartChannel: null,
    restartProgram: null,
    restartLoading: false,
    restartFailed: false,
    eventListWithCatchup: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_MAP:
            return {
                ...state,
                searchMap: action.searchMap,
            };
        case PAGE_INIT:
            return {
                ...state,
                pageData: action.pageData,
                previewProgramStartTime: action.previewProgramStartTime,
                pageStartTime: action.pageStartTime,
                horizontalPageIndex: action.horizontalPageIndex,
                previewChannelIndex: action.previewChannelIndex,
                previewProgramIndex: action.previewProgramIndex,
                pageStartingIndex: action.pageStartingIndex,
            };
        case PAGE_CHANGE_RIGHT:
            return {
                ...state,
                tempAnimationData: action.tempAnimationData,
                pageData: action.pageData,
                previewProgramIndex: action.previewProgramIndex,
                previewProgramStartTime: action.previewProgramStartTime,
                pageStartTime: action.pageStartTime,
                horizontalPageIndex: action.horizontalPageIndex,
            };
        case PROGRAM_CHANGE_RIGHT:
            return {
                ...state,
                previewProgramStartTime: action.previewProgramStartTime,
                previewProgramIndex: action.previewProgramIndex,
            };
        case PAGE_CHANGE_LEFT:
            return {
                ...state,
                tempAnimationData: action.tempAnimationData,
                pageData: action.pageData,
                previewProgramIndex: action.previewProgramIndex,
                previewProgramStartTime: action.previewProgramStartTime,
                pageStartTime: action.pageStartTime,
                horizontalPageIndex: action.horizontalPageIndex,
            };
        case PROGRAM_CHANGE_LEFT:
            return {
                ...state,
                type: PROGRAM_CHANGE_LEFT,
                previewProgramStartTime: action.previewProgramStartTime,
                previewProgramIndex: action.previewProgramIndex,
            };
        case PROGRAM_CHANGE_UP:
            return {
                ...state,
                previewProgramIndex: action.previewProgramIndex,
                previewChannelIndex: action.previewChannelIndex,
            };
        case PAGE_CHANGE_UP:
            return {
                ...state,
                tempAnimationData: action.tempAnimationData,
                previewChannelIndex: action.previewChannelIndex,
                pageStartingIndex: action.pageStartingIndex,
                pageData: action.pageData,
                previewProgramIndex: action.previewProgramIndex,
            };
        case PROGRAM_CHANGE_DOWN:
            return {
                ...state,
                previewProgramIndex: action.previewProgramIndex,
                previewChannelIndex: action.previewChannelIndex,
            };
        case PAGE_CHANGE_DOWN:
            return {
                ...state,
                tempAnimationData: action.tempAnimationData,
                previewChannelIndex: action.previewChannelIndex,
                pageStartingIndex: action.pageStartingIndex,
                pageData: action.pageData,
                previewProgramIndex: action.previewProgramIndex,
            };
        case UPDATE_CATCHUP_LIVE_EVENTS:
            return {
                ...state,
                eventListWithCatchup: action.eventListWithCatchup,
            };
        case CLEAR_RESTART:
            // console.log('restart cleared')
            return {
                ...state,
                restartProgramInProgress: false,
                restartStreamInfo: { streamURL: '', drmLicenseURL: '' },
                restartProgram: null,
                restartChannel: null,
                restartLoading: false,
                restartFailed: false,
            };
        case SET_RESTART_IN_PROGRESS: //set immediately after user selects a RSTV program
            // console.log('restart in progress')
            return {
                ...state,
                restartProgramInProgress: true,
                restartProgram: action.program,
                restartChannel: action.channel,
                restartLoading: true,
                restartFailed: false,
                restartStreamInfo: { streamURL: '', drmLicenseURL: '' },
            };
        case SET_RESTART_STREAM: //this sets the url from null after the stream is fetched and returned from the service
            // console.log('restart stream is set to :',action.restartURL)
            return {
                ...state,
                restartStreamInfo: action.restartStreamInfo,
                restartLoading: false,
            };
        case SET_RESTART_LOADING:
            return {
                ...state,
                restartLoading: action.isLoading,
                restartFailed: action.isFailure,
            };
        case TOGGLE_GUIDE_ANIMATIONS:
            return {
                ...state,
                guideAnimationsOff: action.isOff,
            };
        case TOGGLE_FULLSCREEN_INFO:
            return {
                ...state,
                isFullScreenInfoOpen: action.isFullScreenInfoOpen,
            };
        case TOGGLE_RECORDING_SCREEN:
            return {
                ...state,
                isRecordingScreenOpen: action.isRecordingScreenOpen,
            };
        case IS_RECORDING_SCREEN:
            return {
                ...state,
                isRecordingScreenOpen: action.isRecordingScreenOpen,
            };
        case SET_PROGRAM_INDEX:
            return {
                ...state,
                programIndex: action.programIndex,
            };
        case SET_PROGRAM_INDEX_COUNTER:
            return {
                ...state,
                programIndexCounter: action.programIndexCounter,
            };
        case SET_SELECTED_CHANNEL_INDEX:
            return {
                ...state,
                selectedChannelIndex: action.selectedChannelIndex,
            };
        case SET_PREVIOUS_CHANNEL_INDEX:
            return {
                ...state,
                previousChannelIndex: action.previousChannelIndex,
            };
        case CHANGE_PROGRAM_START:
            return {
                ...state,
                storedStartTime: action.newStartTime,
            };
        case CHANGE_ACTIVE_ROW_INDEX:
            return {
                ...state,
                activeRowIndex: action.newRowIndex,
            };
        case TOGGLE_OPTIONS_POPUP:
            return {
                ...state,
                isOptionsPopupOpen: !state.isOptionsPopupOpen,
            };
        case TOGGLE_PROGRAM_INDEX_COUNTER:
            return {
                ...state,
                isProgramIndexCounterSet: action.isProgramIndexCounterSet,
            };
        case SET_HORIZONTAL_PAGE:
            return {
                ...state,
                horizontalPage: action.page,
            };
        case NEXT_HORIZONTAL_PAGE:
            return {
                ...state,
                horizontalPage: state.horizontalPage + 1,
            };
        case PREVIOUS_HORIZONTAL_PAGE:
            return {
                ...state,
                // horizontalPage: state.horizontalPage > 0 ? state.horizontalPage - 1 : 0
                horizontalPage: state.horizontalPage - 1,
            };
        //POST Recording
        case POST_RECORD_BEGIN:
            return {
                ...state,
                isRecordingToastOpen: true,
                isPostingOrder: true,
                isOrderRequestCompleted: false,
                toastOrderMessage: 'Processing',
            };
        case POST_RECORD_SUCCESSFUL:
            return {
                ...state,
                isPostingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: true,
                toastOrderMessage: 'Recording(s) queued',
            };
        case POST_RECORD_ERROR:
            return {
                ...state,
                isPostingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: false,
                error: action.error,
                toastOrderMessage: 'There was an error in creating your recording(s)',
            };
        //UPDATE Recording
        case UPDATE_RECORD_BEGIN:
            return {
                ...state,
                isRecordingToastOpen: true,
                isUpdatingOrder: true,
                isOrderRequestCompleted: false,
                toastOrderMessage: 'Updating recording(s)...',
            };
        case UPDATE_RECORD_SUCCESSFUL:
            return {
                ...state,
                isUpdatingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: true,
                toastOrderMessage: 'The recording(s) has been successfully updated.',
            };
        case UPDATE_RECORD_ERROR:
            return {
                ...state,
                isUpdatingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: false,
                error: action.error,
                toastOrderMessage: 'There was an error in updating your recording(s).',
            };
        //DELETE Recording
        case DELETE_RECORD_BEGIN:
            return {
                ...state,
                isRecordingToastOpen: true,
                isDeletingOrder: true,
                isOrderRequestCompleted: false,
                toastOrderMessage: 'Deleting recording(s)...',
            };
        case DELETE_RECORD_SUCCESSFUL:
            return {
                ...state,
                isDeletingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: true,
            };
        case DELETE_RECORD_ERROR:
            return {
                ...state,
                isDeletingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: false,
                error: action.error,
                toastOrderMessage: 'There was an error in deleting your recording(s).',
            };
        case CANCEL_RECORD_ERROR:
            return {
                ...state,
                isDeletingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: false,
                error: action.error,
                toastOrderMessage: 'There was an error in canceling your recording(s).',
            };
        case TOGGLE_RECORDING_TOAST:
            return {
                ...state,
                isRecordingToastOpen: action.isOpen,
            };
        case TOGGLE_TICKET_SCREEN:
            return {
                ...state,
                isTicketScreenOpen: action.isTicketScreenOpen,
            };
        case IS_CREATING_TICKET_SCREEN:
            return {
                ...state,
                isTicketScreenOpen: action.isTicketScreenOpen,
            };
        case TOGGLE_CANCEL_TICKET_SCREEN:
            return {
                ...state,
                isCancelTicketScreenOpen: action.isCancelTicketScreenOpen,
            };
        case IS_CANCELLING_TICKET_SCREEN:
            return {
                ...state,
                isCancelTicketScreenOpen: action.isCancelTicketScreenOpen,
            };
        case UPDATE_PAGE_DATA:
            return {
                ...state,
                currentPageData: action.payload,
            };
        case UPDATE_NEXT_PAGE_DATA:
            return {
                ...state,
                nextPageData: action.payload,
            };
        case TRANSITION_NEW_PAGE_IN:
            return {
                ...state,
                pageTransition: action.transitionActive,
            };
        case UPDATE_CHANNEL_INDEXES:
            return {
                ...state,
                channelsCurrentStartIndex: action.beginningIndex,
                channelsCurrentEndIndex: action.endingIndex,
            };
        case UPDATE_CHANNEL_START_END_TIMES:
            return {
                ...state,
                channelsStartTime: action.startTime,
                channelsEndTime: action.endTime,
            };
        case CANCEL_ORDER_BEGIN:
            return {
                ...state,
                isRecordingToastOpen: true,
                isCancelingOrder: true,
                isOrderRequestCompleted: false,
                toastOrderMessage: 'Canceling recording(s).',
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                isCancelingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: true,
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                isCancelingOrder: false,
                isOrderRequestCompleted: true,
                isOrderRequestSuccessful: false,
                error: action.error,
                toastOrderMessage: 'There was an error in canceling your recording(s).',
            };
        case UPDATE_ANIMATED_VALUE:
            return {
                ...state,
                pageAnimatedValue: action.animatedXY,
            };
        case CHANNEL_BAR_INFO_STATE:
            return {
                ...state,
                isChannelBarInfoOpen: action.isChannelBarInfoOpen,
            };
        case GET_CHANNELS_BEGIN:
            return {
                ...state,
                channelsList: {},
            };
        case GET_CHANNELS_SUCCESS:
            return {
                ...state,
                isLoadingChannels: true,
                error: null,
            };
        case UPDATE_CHANNELS_LIST:
            return {
                ...state,
                channelsList: action.channelsList,
            };
        case GET_CHANNELS_FAILURE:
            return {
                ...state,
                isLoadingChannels: false,
                error: action.error,
                channelsList: {},
            };
        case TOGGLE_OPTIONS_MENU:
            return {
                ...state,
                isOptionsOpen: action.isOptionsOpen,
            };
        default:
            return state;
    }
};
