import React, { Component } from 'react';
//import { Animated, DeviceEventEmitter, StyleSheet, Text, View, Divider } from 'react-native';
// import { Divider } from 'react-native-elements'
//import Icon from 'react-native-vector-icons/FontAwesome';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompassCalibrationOutlined } from '@material-ui/icons';

// export const Item = ({ text, isSelected }) => {
//     return (
//         <>
//         </>
//         // <View style={{ flexDirection: 'row' }}>
//         //     <View style={isSelected ? styles.itemSelected : styles.item}>
//         //         <Text style={isSelected ? styles.itemSelectedText : styles.itemText}>{text}</Text>
//         //     </View>
//         // </View>
//     );
// }

// export const CustomItem = ({ text, option, isSelected }) => {
//     return (
//         // <View style={{ flexDirection: 'row' }}>
//         //     <Text style={styles.customItemText}>{text}</Text>
//         //     <View style={{ flex: 7.5, flexDirection: 'row' }}>
//         //         <Icon name="caret-left" size={xScale(25)} color="#D3D3D3" style={{ marginLeft: xScale(5), marginRight: xScale(7), paddingVertical: xScale(9) }}></Icon>
//         //         <View style={isSelected ? styles.customItemSelected : styles.customItem}>
//         //             <Text style={isSelected ? styles.customTextSelected : styles.customText}>{option}</Text>
//         //         </View>
//         //         <Icon name="caret-right" size={xScale(25)} color="#D3D3D3" style={{ marginLeft: xScale(7), paddingVertical: xScale(9), marginRight: xScale(20) }}></Icon>
//         //     </View>
//         // </View>
//         <>
//         </>
//     );
// }

export const Item = ({ text, isSelected, onSelection, disabled }) => {
    return (
        <>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onSelection();
                }}
                className={css([styles.OptionItem, disabled && styles.disabledText])}>
                {text}
            </div>
        </>
    );
};

export const CustomItem = ({ text, option, isSelected, optionIndex, changeOptionIndex, disabled }) => {
    return (
        <>
            <div className={css(styles.CustomItemContainer)}>
                <div className={disabled ? css(styles.disabledText) : css(styles.CustomItemText)}>{text}</div>
                <div className={css(styles.CustomItemOptionContainer)}>
                    <button className={css([styles.customItemArrow, disabled && styles.disabledButton])} onClick={() => changeOptionIndex(true)}>
                        <img className={css(styles.ArrowIcon)} src={require('../../assets/SC_SVGs/ic_small_arrow_left.svg')} />
                    </button>
                    <div className={disabled ? css(styles.disabledCustomItemOption) : css(styles.CustomItemOption)}>{option}</div>
                    <button className={css([styles.customItemArrow, disabled && styles.disabledButton])} onClick={() => changeOptionIndex(false)}>
                        <img className={css(styles.ArrowIcon)} src={require('../../assets/SC_SVGs/ic_small_arrow_right.svg')} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default class seasonMyTicket extends Component {
    seasonIndex = 0;
    recIndex = 0;
    chanIndex = 0;
    startIndex = 0;
    stopIndex = 0;
    startFromIndex = 0;
    // seasonValues = [];
    recValues = [];
    // chanValues = [];
    getHD = '0';
    //keepMaxValues = [];
    startRecValues = [];
    stopRecValues = [];
    constructor() {
        super();
        this.state = {
            //isTicketCreated: false,
            orderInfo: {},
            selectedIndex: 0,
            //bounceValue: new Animated.Value(yScale(600)),
            selectedOrderId: '',
            //seriesEnabled: true,
            seasonIndex: 0,
        };
        // this.seasonValues = ['1', '2', '3', '4', '5', '6', '7', "New", ]; //in place of these numbers will be the seasons available for the particular show
        this.recValues = ['New & Reruns', 'Reruns', 'New Only'];
        // this.chanValues = ["All", '1', '2', '3', '4', '5', '6', '7']; //in place of these numbers will be the channel source id's
        //this.keepMaxValues = ['All', '1', '2', '3', '4', '5', '10', '25'];
        this.startRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];
        this.stopRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];
        this.startFromValues = ['1'];
        this.startSeason = '';
        this.rec = '';
        this.chan = '';
        this.vidQual = '';
        this.rent = '';
        this.text0 = '';
        this.text1 = '';
        this.text2 = '';
        this.infoPopulated = false;
    }

    componentDidUpdate = (prevProps) => {
        if ((!prevProps.authError && this.props.authError) || (!prevProps.generalError && this.props.generalError)) {
            this.closeOptions('dismiss');
        }
        if (!prevProps.channelsList.seasonNames && this.props.channelsList.seasonNames && this.selectedOrder) {
            const { tempSeasonList } = this.props.channelsList;
            //let selectedOrderId = this.props.selectedAssetInfo.order_id;
            this.seasonIndex =
                tempSeasonList.findIndex((item) => item.season_number === this.selectedOrder.start_from) > -1 &&
                tempSeasonList.findIndex((item) => item.season_number === this.selectedOrder.start_from) < tempSeasonList.length - 1
                    ? tempSeasonList.findIndex((item) => item.season_number === this.selectedOrder.start_from)
                    : 0;
            //this.chanIndex = tempChannelList.findIndex(item => item.channel === this.selectedOrder.channel) > -1 ? tempChannelList.findIndex(item => item.channel == this.selectedOrder.channel): null;
            //this.recIndex = this.recValues.indexOf(this.selectedOrder.record_state) > -1 ? this.recValues.indexOf(this.selectedOrder.record_state) : null;
            this.recIndex =
                this.recValues.indexOf(this.selectedOrder.record_state) > -1
                    ? this.recValues.indexOf(this.selectedOrder.record_state)
                    : this.selectedOrder.record_state === 'New'
                    ? this.recValues.indexOf('New Only')
                    : 0;
            this.startIndex =
                this.startRecValues.indexOf(this.selectedOrder.start_recording) > -1 ? this.startRecValues.indexOf(this.selectedOrder.start_recording) : null;
            this.stopIndex =
                this.stopRecValues.indexOf(this.selectedOrder.stop_recording) > -1 ? this.stopRecValues.indexOf(this.selectedOrder.stop_recording) : null;
            this.setState({
                selectedOrderId: this.selectedOrder.order_id,
                //seriesEnabled: this.selectedOrder.enabled //series order exists but is set to disabled
            });
        }
        if (!prevProps.channelsList.tempChannelList && this.props.channelsList.tempChannelList && this.selectedOrder && this.selectedOrder.channel !== 'All') {
            this.props.channelsList.tempChannelList.forEach((value) => {
                if (value.channel.toString() === this.selectedOrder.channel.toString()) {
                    this.chanIndex = this.props.channelsList.channelNames.indexOf(value.full_name);
                }
            });
        }
    };

    componentDidMount = () => {
        if (this.props.isRecordedSeries) {
            this.selectedOrder = null;
            this.props.orderData.forEach((cur, i) => {
                //why do we do that?
                if (cur?.order_type === 'series' && this.props.selectedAssetInfo?.series_id === cur?.series_id) {
                    //check for series added
                    this.selectedOrder = cur;
                    if (this.selectedOrder.source_id) {
                        this.selectedOrder.channelName = this.props.allChannels[
                            this.props.allChannels.findIndex((x) => x.source_id === +this.selectedOrder.source_id)
                        ]?.ch_name;
                    } else this.selectedOrder.channelName = ' ';
                }
            });
        }
    };

    callAction() {
        //this will either trigger request to create a myticket order or update myticket order with arguments passed in.
        if (this.props.isRecordedSeries) {
            this.props.updateRecording.apply(null, arguments);
        } else {
            this.props.postRecording.apply(null, arguments);
        }
    }

    componentWillUnmount() {
        this._optionsEmitter && this._optionsEmitter.remove();
        this._optionsEmitter = null;
    }

    closeOptions(nextAction) {
        this.actionOnSelection(nextAction);
    }

    actionOnSelection = (nextAction) => {
        switch (nextAction) {
            case 'cancel':
                if (this.props.fsRecFlag && this.props.isFullScreen) {
                    this.props.reopenFSOptionsMenu(true);
                    this.props.isFullScreen && this.props.toggleTicketScreen(false);
                    break;
                }
                this.props.currentPage === 'Guide' && !this.props.isFullScreen && this.props.onOptionClose(false); //open options
                this.props.isFullScreen && !this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(true);
                this.props.currentPage === 'MyShows' && !this.props.isFullScreen && this.props.onOptionMyShowsClose() && this.props.toggleTicketScreen(false);
                this.props.currentPage === 'Home' && !this.props.isFullScreen && this.props.toggleTicketScreen(false);
                this.props.isFullScreen && this.props.toggleTicketScreen(false);
                break;
            case 'createTicketWithTheseOptions':
                this.props.toggleTicketScreen(!this.props.isTicketScreenOpen);
                // this.props.isFullScreen && this.props.onFullScreenInfoOpen(true);
                break;
            case 'dismiss':
                !this.props.isFullScreen && this.props.isOptionOpen && this.props.onOptionClose(false); //open options
                this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false);
                break;
            case 'update':
                const { tempSeasonList, tempChannelList, channelNames } = this.props.channelsList; //this.props.channelsList = channelObject from actions/guide
                if (tempSeasonList && tempChannelList) {
                    let startFromData = tempSeasonList[this.seasonIndex].season_number;
                    let recData = this.recValues[this.recIndex];

                    if (recData === 'New Only') {
                        recData = 'New';
                    } else if (this.recValues[this.recIndex] === 'New & Reruns') {
                        recData = 'Both';
                    }

                    !this.props.isPostingOrder &&
                        this.callAction(
                            'series',
                            this.props.isRecordedSeries ? this.state.selectedOrderId : this.props.selectedProgram.schedule_id,
                            this.props.subscriberID,
                            this.startIndex === 0 ? 0 : this.startRecValues[this.startIndex],
                            this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex],
                            startFromData,
                            // this.props.channelsList.channelNames[this.chanIndex], //*Will be channel source_ids in the future or "All"
                            // this.chanIndex === 0 ? channelNames[this.chanIndex] : tempChannelList[this.chanIndex - 1].channel,
                            this.props.channelInfo ? this.props.channelInfo.source_id : this.props.selectedAssetInfo.source_id,
                            this.getHD, //this.keepMaxValues[this.keepMaxIndex],
                            recData,
                            '0'
                        ); //last value is for is_start_from_year
                    this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false);
                }
                this.props.isCurrentlyMyShows && this.props.onOptionMyShowsClose();
                break;
            case 'cancelSeries':
                this.props.toggleCancelTicketScreen(true);
            default:
                break;
        }
    };

    handleChangeOptionIndex = (isDecrement, option) => {
        switch (option) {
            // case "timeChannel": //has not been implemented yet. Exists as placeholder in mobile app
            //     if (isDecrement) {

            //     } else {

            //     }
            //     this.setState({ selectedIndex : this.state.selectedIndex})
            //     break;
            case 'startRec':
                if (isDecrement) {
                    this.startIndex > 0 ? (this.startIndex = this.startIndex - 1) : (this.startIndex = this.startRecValues.length - 1);
                } else {
                    this.startIndex < this.startRecValues.length - 1 ? (this.startIndex = this.startIndex + 1) : (this.startIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            case 'stopRec':
                if (isDecrement) {
                    this.stopIndex > 0 ? (this.stopIndex = this.stopIndex - 1) : (this.stopIndex = this.stopRecValues.length - 1);
                } else {
                    this.stopIndex < this.stopRecValues.length - 1 ? (this.stopIndex = this.stopIndex + 1) : (this.stopIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            case 'startSeason':
                if (isDecrement) {
                    this.seasonIndex > 0 ? (this.seasonIndex -= 1) : (this.seasonIndex = this.props.channelsList.seasonNames.length - 1);
                } else {
                    this.seasonIndex < this.props.channelsList.seasonNames.length - 1 ? (this.seasonIndex += 1) : (this.seasonIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            case 'chan':
                if (isDecrement) {
                    this.chanIndex > 0 ? (this.chanIndex -= 1) : (this.chanIndex = this.props.channelsList.channelNames.length - 1);
                } else {
                    this.chanIndex < this.props.channelsList.channelNames.length - 1 ? (this.chanIndex += 1) : (this.chanIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            case 'record':
                if (isDecrement) {
                    this.recIndex > 0 ? (this.recIndex -= 1) : (this.recIndex = this.recValues.length - 1);
                } else {
                    this.recIndex < this.recValues.length - 1 ? (this.recIndex += 1) : (this.recIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            default:
                break;
        }
    };

    render() {
        //const { height, width } = Dimensions.get('window');
        this.startSeason = '';
        this.rec = '';
        this.chan = '';
        this.vidQual = '';
        this.rent = '';
        this.text0 = '';
        this.text1 = '';
        this.text2 = '';

        if (this.props.channelsList && this.props.channelsList.seasonNames && this.props.channelsList.channelNames) {
            this.startSeason = this.props.channelsList.seasonNames[this.seasonIndex];
            this.startSeason = this.recValues[this.recIndex] === 'New Only' ? 'New Episodes Only' : this.props.channelsList.seasonNames[this.seasonIndex];
            //this.rec = this.seasonIndex === this.props.channelsList.seasonNames.length-1 ? <Text style={styles.inactiveText}>New</Text> : this.recValues[this.recIndex]

            //this.chan = this.props.channelsList.channelNames[this.chanIndex];
            this.chan =
                this.selectedOrder && this.selectedOrder.channelName ? this.selectedOrder.channelName : this.props.channelsList.channelNames[this.chanIndex]; //selectedAssetInfo && selectedAssetInfo.ch_full_name ? selectedAssetInfo.ch_full_name : channelInfo  && channelInfo.ch_name ? channelInfo.ch_name : "";

            //this.vidQual = this.chanIndex === this.props.channelsList.channelNames.length-1 ? "Prefer HD" : <Text style={styles.inactiveText}>Prefer HD</Text>
        }

        const disabled = !this.props.channelsList.tempSeasonList;

        this.recordNewOnly = false;

        if (this.startSeason === 'New Episodes Only') {
            this.recordNewOnly = true;
        }

        this.rec = this.recValues[this.recIndex];

        //let rent = <Text style={styles.inactiveText}>Don&#39;t include</Text>

        let minOrMinsStartRec = this.startRecValues[this.startIndex] === '1' ? ' minute ' : ' minutes ';
        let minOrMinsStopRec = this.stopRecValues[this.stopIndex] === '1' ? ' minute ' : ' minutes ';

        let startRec = this.startIndex === 0 ? 'On time' : this.startRecValues[this.startIndex] + minOrMinsStartRec + 'early';
        let stopRec = this.stopIndex === 0 ? 'On time' : this.stopRecValues[this.stopIndex] + minOrMinsStopRec + 'after';

        this.text0 = !this.props.isRecordedSeries ? 'Create' : 'Update';
        this.text1 = !this.props.isRecordedSeries ? 'Exit' : 'Exit';
        this.text2 = this.props.isRecordedSeries ? 'Cancel Series Recording' : false;

        this.infoPopulated =
            this.props.channelsList && this.props.channelsList.channelNames && Array.isArray(this.props.channelsList.channelNames) ? true : false;

        return (
            // <View style={{ position: 'absolute', height, width, backgroundColor: 'rgba(155, 155, 155, 0.5)', elevation: 1 }}>
            //     <Animated.View style={[styles.optionsContainer, { transform: [{'translate':[0,0,9]}] }, { transform: [{ translateY: this.state.bounceValue }] }]}>
            //         <View style={styles.optionsHeader}>
            //             <Text style={{ fontFamily: 'OpenSans-Regular', color: '#1299C5', fontSize: fontScale(26) }}>MyTicket Options</Text>
            //         </View>
            //         <View style={styles.itemListContainer}>
            //             <Item text={this.text0} isSelected={!this.infoPopulated ? false : this.state.selectedIndex === 0} />
            //             <Item text={this.text1} isSelected={this.state.selectedIndex === 1} />
            //             1<CustomItem text='Include:' option='Recordings only' isSelected={this.state.selectedIndex === 2} />
            //             2<CustomItem text='Start from:' option={this.startSeason} isSelected={this.state.selectedIndex === 3} />
            //             3<CustomItem text='Rent or buy:' option={rent}  />
            //             <View style={{ borderBottomWidth: 0.4, borderColor: '#404040', width: '100%', paddingTop: 15, marginBottom: 10 }}></View>
            //             4{this.props.channelsList.seasonNames && this.state.seasonIndex === this.props.channelsList.seasonNames.length-1 ? <CustomItem text='Record:' option={this.rec} /> : <CustomItem text='Record:' option={this.rec} isSelected={this.state.selectedIndex === 4} /> }
            //             5{this.props.channelsList.seasonNames && this.state.seasonIndex === this.props.channelsList.seasonNames.length-1 ? <CustomItem text='Channel:' option={this.chan} isSelected={this.state.selectedIndex === 4} /> : <CustomItem text='Channel:' option={this.chan} isSelected={this.state.selectedIndex === 5} /> }
            //             {/* <CustomItem text='Channel:' option={chan} isSelected={this.state.selectedIndex === 5} /> *}
            //             {/* <CustomItem text='Keep at most:' option={keepMax} isSelected={this.state.selectedIndex === 6} />
            //             <CustomItem text='Keep until:' option='Space needed' isSelected={this.state.selectedIndex === 7} /> */}
            //             6<CustomItem text='Video quality:' option={this.vidQual} />
            //             7{this.props.channelsList.seasonNames && this.state.seasonIndex === this.props.channelsList.seasonNames.length-1 ? <CustomItem text='Start recording:'
            //                 option={startRec}
            //                 isSelected={this.state.selectedIndex === 5} /> : <CustomItem text='Start recording:'
            //                 option={startRec}
            //                 isSelected={this.state.selectedIndex === 6} />}

            //             8{this.props.channelsList.seasonNames && this.state.seasonIndex === this.props.channelsList.seasonNames.length-1 ? <CustomItem text='Stop recording:'
            //                 option={stopRec}
            //                 isSelected={this.state.selectedIndex === 6} /> : <CustomItem text='Stop recording:'
            //                 option={stopRec}
            //                 isSelected={this.state.selectedIndex === 7} />}

            //         </View>
            //     </Animated.View>
            // </View>
            <>
                {
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={css(styles.Container)}>
                        <FontAwesomeIcon onClick={this.props.onOptionClose} className={css(styles.ExitButton)} icon="times" />
                        <div className={css(styles.Header)}>
                            <div className={css(styles.HeaderText)}>{'Series Recording Options'}</div>
                        </div>
                        <div className={css(styles.Options)}>
                            <Item text={this.text0} disabled={disabled} onSelection={() => this.closeOptions('update')} />
                            {this.text2 && <Item text={this.text2} onSelection={() => this.closeOptions('cancelSeries')} />}
                            <Item
                                text={this.text1}
                                onSelection={() => {
                                    this.props.isCurrentlyMyShows && this.props.onOptionMyShowsClose();
                                    this.props.toggleTicketScreen(false);
                                }}
                            />
                            {/* <CustomItem
                                disabled={disabled}
                                text={'Include:'}
                                option={'Recordings only'}
                                changeOptionIndex={(isDecrement) => {
                                    console.log('placeholder');
                                }}
                            /> */}
                            <CustomItem
                                disabled={this.recordNewOnly}
                                text="Start from:"
                                option={this.startSeason}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'startSeason');
                                }} //left off here, with this handler/index change
                            />
                            {/* <CustomItem
                                disabled={disabled}
                                text="Rent or buy:"
                                option={`Don't include`}
                                changeOptionIndex={(isDecrement) => {
                                    console.log('placeholder');
                                }}
                            /> */}
                            <CustomItem
                                disabled={false}
                                text="Record:"
                                option={this.rec}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'record');
                                }}
                            />
                            <CustomItem
                                disabled={true}
                                text="Channel:"
                                option={this.chan}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'chan');
                                }}
                            />
                            {/* <CustomItem
                                disabled={disabled}
                                text="Video quality:"
                                option={this.vidQual}
                                changeOptionIndex={(isDecrement) => {
                                    console.log('placeholder');
                                }}
                            /> */}
                            <CustomItem
                                disabled={disabled}
                                text="Start recording:"
                                option={startRec}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'startRec');
                                }}
                            />
                            <CustomItem
                                disabled={disabled}
                                text="Stop recording:"
                                option={stopRec}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'stopRec');
                                }}
                            />
                        </div>
                    </div>
                }
            </>
        );
    }
}

const styles = StyleSheet.create({
    Container: {
        //alignSelf:'flex-end',
        // width: '20em',
        minHeight: '8em',
        position: 'absolute',
        // position: '-webkit-sticky',
        // position: 'sticky',
        //alignSelf: 'flex-end',
        //display: 'flex',
        //flexDirection: 'column',
        backgroundColor: '#202020',
        //justifyContent: 'center',
        //alignItems: 'flex-start',
        //paddingLeft: '4em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'white',
        //borderRadius: '5px',
        //marginTop:'10em',
        //paddingLeft: '4em',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        //marginTop: 'auto',
        marginBottom: '3em',
        //padding: '0 1em'
        width: '25em',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    OptionItem: {
        // cursor: 'pointer',
        // //margin: '3px',
        // //padding: '4px',
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        padding: '.6em',
    },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        // color: '#61dafb',
        //fontSize:'1.3em',
        // padding: '.1em',
        marginLeft: '4em',
        //borderColor: 'red',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        width: '17em',
    },
    HeaderText: {
        fontSize: '1.3em',
    },
    CustomItemContainer: {
        //margin: '3px',
        //padding: '4px',
        padding: '.6em',
        display: 'flex',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    // CustomItemText: {

    // },
    CustomItemOption: {
        width: '9em',
        textAlign: 'center',
    },
    disabledCustomItemOption: {
        width: '9em',
        textAlign: 'center',
        color: '#808080',
    },
    customItemArrow: {
        backgroundColor: '#202020',
        cursor: 'pointer',
        margin: '0 1em',
        //borderRadius: '.3em',
        width: '1.3em',
        //textDecoration: 'none',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.02em',
        fontSize: '1.2em',
        color: 'white',
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
        },
    },
    CustomItemOptionContainer: {
        marginLeft: 'auto',
        display: 'flex',
    },
    disabledText: {
        color: '#808080',
    },
    disabledButton: {
        visibility: 'hidden',
        // backgroundColor: '#505050',
        // ':hover': {
        //     backgroundColor: '#505050',
        // },
    },
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
    customItemArrow: {
        backgroundColor: '#202020',
        cursor: 'pointer',
        margin: '0 1em',
        //borderRadius: '.3em',
        width: '1.3em',
        height: '1.2em',
        //textDecoration: 'none',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.02em',
        fontSize: '1.2em',
        color: 'white',
        padding: 0,
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
        },
        overflow: 'hidden',
    },
    // SubHeader: {
    //     marginTop: '2em',
    //     color: 'white',
    //     margin: '1em 2em',
    //     padding: '.1em'
    // }
    ArrowIcon: {
        width: '1.1em',
    },
});
// const styles = StyleSheet.create({
//     optionsContainer: {
//         position: 'absolute',
//         width: xScale(870),
//         height: yScale(620),
//         bottom: 0,
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignSelf: 'center',
//         backgroundColor: '#1B1B1B'
//     },
//     optionsHeader: {
//         flex: 0.7,
//         backgroundColor: '#1B1B1B',
//         alignSelf: 'stretch',
//         paddingHorizontal: xScale(50),
//         justifyContent: 'flex-end',
//         paddingBottom: xScale(15)
//     },
//     itemListContainer: {
//         flex: 8,
//         backgroundColor: '#171717',
//         alignSelf: 'stretch',
//         paddingHorizontal: xScale(40),
//         paddingVertical: yScale(10),
//     },
//     customItemText: {
//         fontFamily: 'OpenSans-Light',
//         color: '#9B9B9B',
//         fontSize: fontScale(22),
//         flex: 4,
//         marginLeft: xScale(30),
//         paddingVertical: xScale(3)
//     },
//     customText: {
//         fontFamily: 'OpenSans-Light',
//         color: '#9B9B9B',
//         fontSize: fontScale(22)
//     },
//     customTextSelected: {
//         fontFamily: 'OpenSans-Light',
//         fontWeight: '600',
//         color: '#fff',
//         fontSize: fontScale(22),
//     },
//     customItem: {
//         flex: 1,
//         borderRadius: xScale(2),
//         paddingHorizontal: xScale(12),
//         paddingVertical: yScale(4),
//     },
//     customItemSelected: {
//         flex: 1,
//         borderRadius: xScale(2),
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(3),
//         borderRadius: xScale(2),
//         borderColor: '#fff',
//         backgroundColor: '#5a5a5a',
//         borderWidth: xScale(2)
//     },
//     itemText: {
//         fontFamily: 'OpenSans-Regular',
//         color: '#9B9B9B',
//         fontSize: fontScale(22),
//     },
//     itemSelectedText: {
//         fontFamily: 'OpenSans-Regular',
//         fontWeight: '600',
//         color: '#fff',
//         fontSize: fontScale(22),
//     },
//     item: {
//         flex: 1,
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(6),//accounting for borderWidth selected
//     },
//     itemSelected: {
//         flex: 1,
//         justifyContent: 'center',
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(4),
//         borderRadius: xScale(2),
//         borderColor: '#fff',
//         backgroundColor: '#5a5a5a',
//         borderWidth: xScale(2)
//     },
//     inactiveText: {
//         color: '#404040'
//     },
// });
