import moment from 'moment';
import Pusher from 'pusher-js';

import {
    CHANGE_CHANNEL,
    CHANGE_PAGE,
    CHANGE_PREVIOUS_PAGE,
    TOGGLE_FULLSCREEN,
    TOGGLE_SUBTITLE,
    SET_CHANNEL_LIST,
    SET_CHANNELS,
    FETCH_CHANNELS,
    FETCH_CHANNELS_SUCCESSFUL,
    FETCH_CHANNELS_ERROR,
    PLAY_LIVE_TV,
    PAUSE_LIVE_TV,
    RESUME_LIVE_TV,
    STOP_LIVE_TV,
    HIDE_MINI_PLAYER,
    TOGGLE_HALF_HOUR_LOAD,
    TOGGLE_PLAYING_RECORDING,
    SAVE_BOX_MODEL,
    PROGRESS_BAR_INDEX,
    IS_PAUSED,
    SAVE_DEVICE_INFO,
    UPDATE_FETCHING_TEXT,
    UPDATE_ALL_CHANNELS,
    UPDATING_CHANNEL_DATA,
    UPDATE_GUIDE_END_PAGES_TIMES,
    TOGGLE_FULLSCREEN_OPTIONS,
    CONNECTIVITY_CHANGE,
    CONNECTIVITY_STATUS,
    ON_LOGOUT_BEGIN,
    ON_LOGOUT_SUCCESS,
    ON_LOGOUT_ERROR,
    PREPARE_FOR_NEXT_LOGOUT_ATTEMPT,
    RESET_APP_STATE,
    SUSPEND_LIVE_TV,
    UPDATE_PREVIOUS_ALERTS_ARR,
    ALERT_EAS,
    CLEAR_EAS,
    AUTH_ERROR,
    GENERAL_ERROR,
    GENERAL_ERROR_RESET,
    SET_LIGHTWEIGHT_VERSION,
    SET_GLOBAL_PROGRAM_INDEX,
    SET_PLAYER_SOURCE_OPENED,
    SET_DEVICE_LIMIT_REACHED,
    TOGGLE_SOTAL_REFRESH_LOADING,
    SET_OVERSCAN,
    UPDATE_PUSHER_STATE,
    TOGGLE_VIDEO_DEBUG_OPTION,
    UPDATE_NUM_TRACKS,
    UPDATE_ORDER_DATA_LOADING,
    RESET_AUTH_ERROR,
    RETURN_TO_LIVE_AFTER_EAS,
    TOGGLE_TOOL_TIPS,
    TOGGLE_INACTIVATION_MESSAGE,
    TRIGGER_LIVE_CHANNEL_RELOAD,
    SET_NUMBER_OF_PAGES,
    SET_LOGIN_READY,
} from '../types/main';
import { getChannelPrograms } from '../services/ChannelsLoad';
import { getNumberOfPages } from '../services/ChannelService';
import { loginCompleted, onLoginDataReady, toggleResetNotice } from './login';
// import { disconnectFromPusherChannel } from '../actions/main';
import {
    updateAssetData,
    updateOrderData,
    updateLoading,
    filterCardList,
    filterShowList,
    completedRecordingsFilter,
    getSCBegin,
    getSCSuccess,
    getSCFailure,
    getUpcomingAssets,
    updateUpcomingLoading,
    updateUpcomingData,
} from '../actions/myshows';
import { getRecordingImages, checkImages, checkImagesBG } from '../services/RecordingImages';
import { updateChannelStartEndTimes, clearRestart, setSearchMap } from './guide';
import { onLogOutApiCall } from '../services/LogoutService';
import { GetSotalToken } from '../services/LoginServices';
import getKeyMapping from '../utils/DeviceKeyMap';
import { errorHandler } from '../utils/ErrorHandler';
import { FSProgramIndex } from '../utils/FSProgramIndex';
//import { resetBrandToEpic, favTelcoLimitSetter, clearStorage } from "../services/LoginServices";
//import NavigationService from '../utils/NavigationService';
import { getChannelMapping, setChannelMapping } from '../services/SotalCloudService';
// import { resetServices } from '../services/ResetAllServices';
import { setScTokenInHeader, clearTokenInHeader } from '../utils/AxiosInstances';
import { removeSlashes } from './myshows';

let pusherCDVR;
//let pusherEAS;
let pusherChannelCDVR;
let countyChannelEAS;
let stateChannelEAS;
let nationalChannelEAS;
let pusherAdminChannel;

export const togglePlayingRecording = (isPlayingRecording) => {
    return {
        type: TOGGLE_PLAYING_RECORDING,
        isPlayingRecording,
    };
};

export const changeChannel = (channel, globalProgramIndex) => {
    return {
        type: CHANGE_CHANNEL,
        channel,
        globalProgramIndex,
    };
};

export const setGlobalProgramIndex = (globalProgramIndex) => ({
    type: SET_GLOBAL_PROGRAM_INDEX,
    globalProgramIndex,
});

export const changePage = (page) => ({
    type: CHANGE_PAGE,
    page,
});

export const changePreviousPage = (previousPage) => ({
    type: CHANGE_PREVIOUS_PAGE,
    previousPage,
});

export const toggleFullScreen = (isFullScreen) => ({
    type: TOGGLE_FULLSCREEN,
    isFullScreen,
});

export const toggleSubtitle = (isSubtitleToggled) => ({
    type: TOGGLE_SUBTITLE,
    isSubtitleToggled,
});

export const setChannelList = (channelList) => ({
    type: SET_CHANNEL_LIST,
    channelList,
});

export const setChannels = (channels) => ({
    type: SET_CHANNELS,
    channels,
});

export const playLiveTV = () => ({
    type: PLAY_LIVE_TV,
});

export const pauseLiveTV = (pausedChannel) => ({
    type: PAUSE_LIVE_TV,
    pausedChannel,
});

export const resumeLiveTV = () => ({
    type: RESUME_LIVE_TV,
});

export const stopLiveTV = () => ({
    type: STOP_LIVE_TV,
});

export const suspendLiveTV = (isPlayerShowing, isPlaying) => ({
    type: SUSPEND_LIVE_TV,
    isPlayerShowing,
    isPlaying,
});

export const hideMiniPLayer = () => ({
    type: HIDE_MINI_PLAYER,
});

export const toggleToolTips = (isToolTipsEnabled) => {
    return {
        type: TOGGLE_TOOL_TIPS,
        isToolTipsEnabled,
    };
};

export const setLoginReady = () => ({
    type: SET_LOGIN_READY,
});

//Load Future Channels On Login
export const pusherChannelSetup = (subData) => {
    return (dispatch, getState) => {
        // dispatch(updatePusherState("connected"));//remove it - only use it for testing
        const cdvrBase = subData.services.cdvr;
        const pusherOptions = {
            cluster: 'mt1',
            authEndpoint: `https://${cdvrBase}/api/broadcasting/auth`,
            encrypted: true,
            activityTimeout: 60000,
            forceTLS: true,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: subData.token,
                },
            },
        };
        pusherCDVR = new Pusher('581d8d85a11f3b0bd7a6', pusherOptions);
        pusherCDVR.logToConsole = true;
        pusherChannelCDVR = pusherCDVR.subscribe(`private-cdvr.${subData.id}`); //where subData.id - is a socket ID
        //Bind pusherCDVR events
        pusherOrderCreated(pusherChannelCDVR, getState, dispatch);
        pusherOrderDeleted(pusherChannelCDVR, getState, dispatch);
        pusherOrderUpdated(pusherChannelCDVR, getState, dispatch);
        pusherAssetCreated(pusherChannelCDVR, getState, dispatch);
        pusherAssetDeleted(pusherChannelCDVR, getState, dispatch);
        pusherCancelMyTicket(pusherChannelCDVR, getState, dispatch);
        pusherOrderCreateFailed(pusherChannelCDVR, getState, dispatch);
        pusherSinglesToSeries(pusherChannelCDVR, getState, dispatch); //use this pusher channel if "new_logic" property detected

        pusherAdminChannel = pusherCDVR.subscribe(`sms.${subData.id}`);
        pusherAdminEvent(pusherAdminChannel, getState, dispatch, subData);

        // pusherEAS = new Pusher('5fb45afb6dc26783a474', pusherOptions);
        // pusherEAS.logToConsole = true;

        // console.log("subData: ", subData);

        // if (!subData.fips_code) {
        // subData.fips_code = '13136';
        // }
        //else {
        //    subData.fips_code = subData.fips_code.toString();
        // }
        // console.log("subData: ", subData)
        const stateFip = subData.fips_code.toString().slice(subData.fips_code.toString().length - 5, subData.fips_code.toString().length - 3);

        countyChannelEAS = pusherCDVR.subscribe(`sms.0${subData.fips_code}`); //${subData.fips_code}
        stateChannelEAS = pusherCDVR.subscribe(`sms.0${stateFip}000`);
        nationalChannelEAS = pusherCDVR.subscribe(`sms.000000`);

        //bind PusherEAS events
        pusherAlertEAS(countyChannelEAS, getState, dispatch, subData);
        pusherAlertEAS(stateChannelEAS, getState, dispatch, subData);
        pusherAlertEAS(nationalChannelEAS, getState, dispatch, subData);

        //event listeners
        pusherCDVR.connection.bind('state_change', function (state) {
            if (state.current === 'connecting') {
                this.pusherTimer = setTimeout(() => {
                    dispatch(updatePusherState('unavailable'));
                }, 12000); //12 seconds waiting for pusher to connect, might be too long and we can make 9 secs or less
            }
            if (state.current === 'connected') {
                clearTimeout(this.pusherTimer);
                this.pusherTimer = null;
            }
            // console.log("pusher status: ", state)
            dispatch(updatePusherState(state.current));
        });
        // pusherCDVR.connection.bind( 'error', function( error ) {
        //     console.log("some sort of pusherError: ", error);
        // });
        // pusherCDVR.connection.bind( 'connected', function( event ) {
        //     console.log("I am connected to pusherCDVR: ", event);
        // });
    };
};

const setNumberOfPages = (number_of_pages) => {
    return {
        type: SET_NUMBER_OF_PAGES,
        number_of_pages,
    };
};

//trying this out to set channel number for MSSV
export const channelsArrayForMSSV = new Map();

const channelsHashMapBuilder = (channelArray) => {
    channelArray.forEach((channel) => {
        if (!channelsArrayForMSSV.has(channel.source_id)) {
            channelsArrayForMSSV.set(channel.source_id, channel.custom_channel_number);
        }
    });
};
export const getPrograms = (guideStartTime, guideEndTime, subData) => {
    return (dispatch, getState) => {
        let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
        // let gridRows = getState().guide.gridRows;
        dispatch(fetchGuideDataBegin());
        return getNumberOfPages(guideStartTime, guideEndTime) //gets number of pages for guide load
            .then((result) => {
                let favoriteChannel = getState().guide.favoriteChannel;
                dispatch(setNumberOfPages(result.data.number_of_pages)); //result.data.number_of_pages
                return getChannelPrograms(guideStartTime, guideEndTime, subData, result.data.number_of_pages, true, favoriteChannel) //initial channel load
                    .then((chans) => {
                        // guide data
                        if (Array.isArray(chans) && chans.length > 0) {
                            const disabled_channels = getState().login?.subscriberData?.disabled_channels;
                            let channels = checkForPrograms(chans, disabled_channels); //filter out channels that do not have programs
                            channelsHashMapBuilder(channels);
                            let channelList = channelsList(channels);
                            // set initial global index
                            for (let i = 0; i < channels.length; i++) {
                                //make sure we have this in <loadChannels>
                                // channels[i].ch_name = (subData.callSignToName[channels[i].custom_name])?.name;//first version - uncomment in login.js
                                channels[i].ch_name = subData.callSignToName[channels[i].custom_name];
                            }

                            let currentTime = moment().unix() * 1000; //in seconds
                            FSProgramIndex(currentTime, channels[0].programs).then((globalProgramIndex) => {
                                dispatch(setGlobalProgramIndex(globalProgramIndex));
                            });
                            //end of initial global index setup
                            const initialChannelIndex = favoriteChannel && favoriteChannel !== '0' ? +favoriteChannel : 0;
                            dispatch(fetchGuideDataSuccess(channels, channelList, initialChannelIndex));
                            //getLockedChannelData(dispatch); //compare and update locked channels info for parental controls
                            const startTime = moment()
                                .subtract(moment().minute() % 30, 'minutes')
                                .subtract(moment().second(), 'seconds')
                                .unix();
                            const endTime = moment()
                                .subtract(moment().minute() % 30, 'minutes')
                                .add(120, 'minutes')
                                .unix();
                            dispatch(updateChannelStartEndTimes(startTime, endTime));
                            // console.log('about to call updatePageChannels() to set initial page programs. channels : ', channels);

                            //page data wont be updated here
                            // let tempData = dispatch(updatePageChannels(0, gridRows, channels, startTime, endTime));//first ten channels 0-9(10 non-inclusive)
                            // dispatch(updateNextPageData(tempData));
                            // dispatch(updatePageData(tempData));
                            createMapForSearch(channels, dispatch);
                            return channels;
                        }
                    })
                    .then((response) => {
                        //get assets data
                        // dispatch(setLightweightVersion(subData.cdvrAccess)); //already setting it up in login.js
                        dispatch(pusherChannelSetup(subData));
                        dispatch(loginCompleted());
                        //dispatch((subData));//for normal production flow
                        // dispatch(updatePusherState("connected"));//use for testing only - simulates successful pusher connection
                    });
            })
            .catch((error) => {
                //TO DO - add bugSnag for error reporting
                console.log('There was a problem fetching Guide Data. ', error);
                let errorObject = errorHandler(error, 'getPrograms from main.js', subDeviceInfo); //variable upfront only for console.log
                dispatch(errorIntercept(errorObject));
                dispatch(fetchGuideDataError(errorObject.message));
            });
    };
};

//Load channels after user logged in
export const loadChannels = (guideStartTime, guideEndTime, fullLoadAfterLogin) => {
    return (dispatch, getState) => {
        let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
        const guideEndPagesTimes = {
            guideFirstPageTime: fullLoadAfterLogin ? guideStartTime : guideStartTime + 7200,
            guideLastPageTime: fullLoadAfterLogin ? guideEndTime : guideEndTime - 7200, //should it be plus here???
        };
        dispatch(updateGuideEndPagesTimes(guideEndPagesTimes));
        dispatch(updatingChannelData(true));
        let subData = getState().login.subscriberData;
        dispatch(fetchGuideDataBegin());
        return getChannelPrograms(guideStartTime, guideEndTime, subData, getState().main.number_of_pages) // session is equal to the session token
            .then((chans) => {
                const disabled_channels = getState().login?.subscriberData?.disabled_channels;
                let channels = checkForPrograms(chans, disabled_channels); //filter out channels that do not have programs
                let channelList = channelsList(channels);
                for (let i = 0; i < channels.length; i++) {
                    // channels[i].ch_name = (subData.callSignToName[channels[i].custom_name])?.name;//first version - uncomment in login.js
                    channels[i].ch_name = subData.callSignToName[channels[i].custom_name];
                }
                let startIndex = channelList.indexOf(getState().main.currentChannel?.custom_channel_number.toString()); //index of channel in array
                dispatch(updateAllChannels(channels, channelList, channels[startIndex])); //changes properties in fetchGuideDataSuccess
                dispatch(updatingChannelData(false));
                createMapForSearch(channels, dispatch);
                //we need to update current channel here
            })
            .catch((error) => {
                // console.log("load channels error: ", error);
                let errorObject = errorHandler(error, 'loadChannels from main.js', subDeviceInfo);
                dispatch(errorIntercept(errorObject));
                dispatch(fetchGuideDataError(error));
                dispatch(updatingChannelData(false));
            });
    };
};

//Update guide page every 30 minutes
//**Also used to reset from background
// export const halfHourEpgUpdate = (channels, regular, liveStart, liveEnd) => {//regular=true represents 30 min update, regular=flase - guide refresh when App comes back from background
//     // console.log("half an hour update...");
//     return (dispatch, getState) => {
//         let pageData;
//         const guideState = getState().guide;
//         const channelsCurrentStartIndex = guideState.channelsCurrentStartIndex;
//         const channelsCurrentEndIndex = guideState.channelsCurrentEndIndex;
//         const channelsStartTime = guideState.channelsStartTime;
//         const channelsEndTime = guideState.channelsEndTime;
//         //if(regular){
//             pageData = dispatch(updatePageChannels(channelsCurrentStartIndex, channelsCurrentEndIndex, channels, channelsStartTime + 1800, channelsEndTime + 1800));
//             dispatch(updateChannelStartEndTimes(channelsStartTime + 1800, channelsEndTime + 1800));
//             dispatch(updatePageData(pageData));
//             dispatch(updateNextPageData(pageData));
//         // }else{
//         //     //dispatch(triggerProgramRatioUpdate(false)); //update only triggers on true later
//         //     pageData = dispatch(updatePageChannels(channelsCurrentStartIndex, channelsCurrentEndIndex, channels, liveStart, liveEnd));
//         //     dispatch(updatePageData(pageData));
//         //     dispatch(updateNextPageData(pageData));
//         //     dispatch(updateChannelStartEndTimes(liveStart, liveEnd));
//         //     dispatch(setHorizontalPage(0));
//         //     dispatch(setProgramIndex(0));
//         //     //dispatch(changeProgramStart(pageData[0].programs[0].unix_start_time));
//         //     //dispatch(triggerProgramRatioUpdate(true)); //triggers get ratio methods in Schedule Container, might be better to move those methods to a redux file. works as-is.
//         // }
//     }
// }

export const fetchGuideDataBegin = () => ({
    //new
    type: FETCH_CHANNELS,
});

export const fetchGuideDataSuccess = (channels, channelList, initialChannelIndex) => ({
    type: FETCH_CHANNELS_SUCCESSFUL,
    channels,
    channelList,
    initialChannelIndex,
});

export const fetchGuideDataError = (fetchGuideDataError) => ({
    type: FETCH_CHANNELS_ERROR,
    fetchGuideDataError,
});

export const updateAllChannels = (channels, channelList, currentChannel) => ({
    type: UPDATE_ALL_CHANNELS,
    channels,
    channelList,
    currentChannel,
});

export const setLightweightVersion = (cdvrAccess) => ({
    type: SET_LIGHTWEIGHT_VERSION,
    cdvrAccess,
});

// Update subscriber
export const toggleHalfHourLoad = (isLoaded) => ({
    type: TOGGLE_HALF_HOUR_LOAD,
    isLoaded,
});

export const saveBoxModel = (boxModel) => ({
    type: SAVE_BOX_MODEL,
    boxModel,
    keyMap: getKeyMapping(boxModel),
});

export const changeProgressBarIndex = (value) => ({
    type: PROGRESS_BAR_INDEX,
    prBarIndex: value,
});

export const isPaused = (status) => ({
    type: IS_PAUSED,
    isPaused: status,
});

export const saveDeviceInfo = (DeviceInfo) => ({
    type: SAVE_DEVICE_INFO,
    DeviceInfo,
});

export const updateFetchingText = (updatedText) => ({
    type: UPDATE_FETCHING_TEXT,
    updatedText,
});

export const updatingChannelData = (isUpdatingChannelData) => ({
    type: UPDATING_CHANNEL_DATA,
    isUpdatingChannelData,
});

export const updateGuideEndPagesTimes = (guideEndPagesTimes) => ({
    type: UPDATE_GUIDE_END_PAGES_TIMES,
    guideEndPagesTimes,
});

export const toggleFullScreenOptions = (isFullScreenOptionsOpen) => ({
    type: TOGGLE_FULLSCREEN_OPTIONS,
    isFullScreenOptionsOpen,
});

export const updateOrderDataLoading = (isLoading) => ({
    type: UPDATE_ORDER_DATA_LOADING,
    isLoading,
});

export const returnToLiveAfterEas = (shouldReturnToLive) => ({
    type: RETURN_TO_LIVE_AFTER_EAS,
    shouldReturnToLive,
});

export const triggerLiveChannelReload = () => ({
    type: TRIGGER_LIVE_CHANNEL_RELOAD,
});

const _sessionCleanUp = () => {
    return (dispatch, getState) => {
        console.log('_sessionCleanUp * * *');
        //return (dispatch, getState) => {
        //console.log("running session cleanup")
        clearTokenInHeader();
        disconnectFromPusherChannel();
        dispatch(resetAppState());
    };
};

const resetLogin = (subData) => {
    return (dispatch, getState) => {
        //let subData = getState().login.subscriberData;
        dispatch(onLoginDataReady(subData));
    };
};

//***PUSHER EVENTS

const pusherAdminEvent = (pusherChannel, getState, dispatch, subData) => {
    pusherChannel.bind('active_changed', (pusherData) => {
        adminEventQue.newEvent('active_changed'); //will need to store all pusherdata in que until execution???
    });
    pusherChannel.bind('package_changed', (pusherData) => {
        adminEventQue.newEvent('package_changed');
    });
    pusherChannel.bind('channel_changed', (pusherData) => {
        adminEventQue.newEvent('channel_changed', pusherData);
    });
    pusherChannel.bind('offering_changed', (pusherData) => {
        adminEventQue.newEvent('offering_changed');
    });

    function randomNumber(min, max) {
        return +(Math.random() * (max - min) + min).toFixed(3);
    }

    let adminEventQue = {
        eventArr: [],
        newEvent: function (event, pusherData) {
            this.eventArr.push(event);
            if (this.queTimer) {
                clearTimeout(this.queTimer);
                this.queTimer = null;
            }
            this.queTimer = setTimeout(() => {
                this.executeEvent(pusherData);
                this.queTimer && clearTimeout(this.queTimer);
                this.queTimer = null;
                this.eventArr = [];
            }, 10000);
        },
        executeEvent: function (pusherData) {
            //logic for choosing the right event
            if (this.eventArr.includes('active_changed')) {
                adminActiveChanged();
            } else if (this.eventArr.includes('offering_changed') || this.eventArr.includes('package_changed')) {
                setTimeout(
                    () => {
                        adminGroupChange();
                    },
                    randomNumber(0, 40) // rand range of time before app will reload. Used in case many clients are reloaded, endpoint will not be called all at once.
                );
            } else if (this.eventArr.includes('channel_changed')) {
                //need to check new channel mapping and see if currently playing channel has been updated. if so trigger new get stream call
                let liveStreamMapping = getChannelMapping();
                let chanToUpdateIndex = subData.channels.findIndex((chan) => chan.id === pusherData.channel_id);
                if (chanToUpdateIndex > -1) {
                    let resource;
                    for (let j = 0; j < pusherData.resources.length; j++) {
                        if (pusherData.resources[j].type === 'dash-live') {
                            resource = pusherData.resources[j];
                        }
                    }
                    let new_id = resource.id;
                    let sotalAsset = subData.channels[chanToUpdateIndex].sotal_cloud_channel_asset.sotal_cloud_channel_asset_id;
                    liveStreamMapping[liveStreamMapping.findIndex((cur) => cur.sc_asset_id === sotalAsset)].sc_resource_id = new_id;
                    setChannelMapping(liveStreamMapping);
                    setTimeout(() => {
                        if (getState().main.currentChannel.sotal_cloud_channel_asset_id === sotalAsset) {
                            //in the case that the channel that changed is being viewed by user
                            dispatch(triggerLiveChannelReload());
                        }
                    }, 10000); //extra time needed so the resource will be available from sc
                }
            }
        },
    };

    const adminActiveChanged = () => {
        dispatch(_sessionCleanUp());
        //just going to keep it simple and log the user out. they will see the inactive error when they try to log in again
    };

    const adminGroupChange = () => {
        //offering or package changed event
        console.log('offering_changed');
        let subData = getState().login.subscriberData;
        dispatch(_sessionCleanUp());
        dispatch(resetLogin(subData));
        dispatch(toggleResetNotice(true));
        // resetServices();
        // disconnectFromPusherChannel();
        // clearTokenInHeader();
        // // dispatch(changePage("Login"));
        // // NavigationService.navigate('Login');
        // // NavigationService.resetMainNavigator();
    };
};

//     const adminActiveChanged = () => {
//         // const resetToLogin = () => { //needs new logic for web
//         //     clearStorage()
//         //     .then(res=> {
//         //         resetServices();
//         //         disconnectFromPusherChannel();//dispatch(disconnectFromPusherChannel());
//         //         clearTokenInHeader();
//         //         dispatch(changePage("Login"));
//         //         NavigationService.navigate('Login');
//         //         NavigationService.resetMainNavigator();
//         //         dispatch(inactivationNotice())
//         //     });
//         // }
//         // //console.log("pusher active changed triggered!!! pusherData: ",pusherData);
//         // dispatch(onLogout())
//         // .then(result => {
//         //     if(result?.status === 200){
//         //         resetToLogin();
//         //     } else {
//         //         throw new Error("logout after active_changed failed") //still returning to login screen anyway
//         //     }
//         // }).catch(err => {
//         //     resetToLogin();
//         // })
//     };

//     const adminGroupChange = () => {
//         //offering or package changed event //needs updates for web
//         // resetServices();
//         // disconnectFromPusherChannel();
//         // clearTokenInHeader();
//         // dispatch(changePage("Login"));
//         // NavigationService.navigate('Login');
//         // NavigationService.resetMainNavigator();
//         // dispatch(toggleResetNotice(true));
//     };
// };

//---------Pusher Order Create
const pusherOrderCreated = (pusherChannel, getState, dispatch) => {
    pusherChannel.bind('OrderCreated', (pusherData) => {
        console.log('order created: ', pusherData);
        try {
            if (getState().login.subscriberData.hasOwnProperty('new_logic') && getState().login.subscriberData.new_logic) {
                console.log('execute new logic');
                !getState().myshows.isSCLoading && dispatch(getSCBegin());
                let newGlobalShowList = [...getState().myshows.globalShowList];
                let assetsToUpdate = [];
                let assetData = [];
                let sc_rec_state = {};
                //group assets that need to be modified and add them to newAssets list
                // console.log("asset Data: ", [...getState().myshows.assetData]);
                if ('assets' in pusherData) {
                    //if we already have this asset in the list - single out it and modify (series to single, etc)
                    pusherData.assets.forEach((asset) => {
                        assetData = [...getState().myshows.assetData].filter((ourAsset) => {
                            if (asset.toString() === ourAsset.asset_id.toString()) {
                                assetsToUpdate.push(ourAsset); //exploit side effect and populate newAssets list
                                //preserve sc_recording_state - build a map {asset => sc_recording_state}
                                sc_rec_state[ourAsset.asset_id] = ourAsset.sc_recording_state;
                            }
                            return !pusherData.assets.includes(parseInt(ourAsset.asset_id)); //return everything that is not our asset
                        });
                    });
                }

                // console.log("assetsToUpdate: ", assetsToUpdate);
                // console.log("sc_rec_state: ", sc_rec_state)
                if (assetsToUpdate.length > 0 && assetsToUpdate[0].order_id) {
                    //modify if assets found
                    console.log('first option - when modifying existing assets');
                    // dispatch(getSCBegin());
                    if ('order' in pusherData) {
                        for (let x = 0; x < assetsToUpdate.length; x++) {
                            //really happens double when dealing with existing shows
                            assetsToUpdate[x].order_id = pusherData.order.id.toString();
                            assetsToUpdate[x].type = pusherData.order.type;
                            assetsToUpdate[x].sc_recording_state = sc_rec_state[assetsToUpdate[x].asset_id] || 'scheduled'; //needed to manage dots and checkmarks in Guide
                        }
                    }

                    for (let i = 0; i < newGlobalShowList.length; i++) {
                        //update global show list, along with assetData list
                        for (let z = 0; z < pusherData.assets.length; z++) {
                            if (newGlobalShowList[i].asset_id === pusherData.assets[z].toString()) {
                                newGlobalShowList[i].type = pusherData.order.type;
                                newGlobalShowList[i].order_id = pusherData.order.id.toString();
                            }
                        }
                    }

                    //I need to add logic that if there are more pusherData.assets than in assets to update
                    //create an asset that is not in the list - happens when more assets need to be created
                } else {
                    //creating a new asset
                    // console.log("second option - when new asset is being added to assetData list");
                    const scheduleList = [];
                    if ('order' in pusherData) {
                        //do we need this stuff?
                        console.log('option 1');
                        if (assetsToUpdate.length > 0) {
                            console.log('option 2');
                            for (let b = 0; b < assetsToUpdate.length; b++) {
                                assetsToUpdate[b].order_id = pusherData.order.id.toString(); //set id
                                assetsToUpdate[b].type = pusherData.order.type; //set the type
                                //extract schedule ids, so we can populate missing information
                                scheduleList.push(parseInt(assetsToUpdate[b].schedule_id));
                            }
                        } else if ('assets' in pusherData && 'order' in pusherData) {
                            //should work just fine for single recording
                            console.log('option 3');
                            for (let g = 0; g < pusherData.assets.length; g++) {
                                let newAsset = {};
                                newAsset.order_id = pusherData.order.id.toString(); //set id
                                newAsset.type = pusherData.order.type; //set the type
                                newAsset.asset_id = pusherData.assets[g].toString();
                                //?? do we need a source here as well ?? - or is it only related to channel
                                assetsToUpdate.push(newAsset);
                            }
                        }
                        //we do not need to update global MyShows global list here - because it will be updated with SC update
                    }
                }
                assetData = [...assetData, ...assetsToUpdate];
                //update all lists
                if (assetData && assetData.length > 0) {
                    dispatch(updateAssetData(assetData));
                }
                dispatch(filterShowList(newGlobalShowList));
                // dispatch(filterCardList(newGlobalShowList));
                //create new order for these modified assets - look like we got everything that what we get from pusher
                const newOrder = {
                    order_id: pusherData.order.id.toString(),
                    order_type: pusherData.order.type,
                    subscriber_id: pusherData.order.user_id.toString(),
                    start_recording: pusherData.order.start_recording.toString(),
                    stop_recording: pusherData.order.stop_recording.toString(),
                    start_from: pusherData.order.start_from ? pusherData.order.start_from.toString() : '0', //redo that with better condition
                    channel: pusherData.order.channel,
                    get_hd: pusherData.order.get_hd ? pusherData.order.get_hd : 0,
                    record_state: pusherData.order.record_state,
                    is_start_from_year: pusherData.order.is_start_from_year,
                    series_id: pusherData.order.series_id ? pusherData.order.series_id.toString() : null,
                    source_id: pusherData.order.source_id ? pusherData.order.source_id.toString() : null,
                };

                // append newOrder to orderData
                const orderData = [...getState().myshows.orderData, newOrder]; //add new order to order list
                // console.log("order Data: ", orderData);
                dispatch(updateOrderData(orderData));
                getState().myshows.isSCLoading && dispatch(getSCSuccess());
                dispatch(updateLoading(false));
                //sort of like in JAVA, not sure if this is needed for JS
                sc_rec_state = null;
                newGlobalShowList = null;
                assetsToUpdate = null;
                assetData = null;
            } else {
                console.log('execute old logic');
                let orderData = [...getState().myshows.orderData];
                let assetData = [...getState().myshows.assetData];
                //Merge Fix Question**
                //subdeviceinfo used in .catch() below
                let subDeviceInfo = getState().login.subscriberData.subDeviceInfo; //for error handling
                //remove moved assets/orders from assetData to prevent duplicates
                if ('moved_assets' in pusherData) {
                    pusherData.moved_assets.forEach((cur) => {
                        assetData = assetData.filter((asset) => {
                            return asset.asset_id.toString() !== cur.asset.toString();
                        });
                        orderData = orderData.filter((order) => {
                            return order.order_id.toString() !== cur.order_from.toString();
                        });
                    });
                }

                const newAssets = [];
                const scheduleList = [];
                if (pusherData.assets) {
                    // console.log("first case: ")
                    for (let i = 0; i < pusherData.assets.length; i++) {
                        newAssets.push({
                            program_name: removeSlashes(pusherData.assets[i].program_name),
                            schedule_id: pusherData.assets[i].schedule_id.toString(),
                            program_id: pusherData.assets[i].program_id.toString(),
                            asset_id: pusherData.assets[i].id.toString(),
                            starts_at: pusherData.assets[i].starts_at,
                            ends_at: pusherData.assets[i].ends_at,
                            order_id: pusherData.order.id.toString(),
                            show_type: '',
                            description: '',
                            backgroundImage: '',
                            cardImage: '', //we can get card image //TO DO
                            start_recording: pusherData.order.start_recording.toString(),
                            stop_recording: pusherData.order.stop_recording.toString(),
                            type: pusherData.order.type,
                            source_id: pusherData.assets[i].source_id.toString(),
                            id: i, //should it be zero
                            season_number: '',
                            episode_number: '',
                            episode_name: '',
                            series_id: pusherData.assets[i].series_id.toString(),
                            series_description: '',
                            release_year: '',
                            tv_rating: '',
                            start_formated: '',
                            end_formated: '',
                            long_title: '',
                            sc_id: pusherData.assets[i].sc_id,
                        });
                        // console.log("newAssets: ", newAssets);
                        scheduleList.push(pusherData.assets[i].schedule_id);
                    }
                    getRecordingImages(scheduleList, dispatch)
                        .then((data) => {
                            console.log('data: ', data);
                            for (let i = 0; i < newAssets.length; i++) {
                                //here we are mapping the returned get-schedule data to the existing newAssets
                                let sch = newAssets[i].schedule_id;
                                if (data[sch]) {
                                    checkImages(data[sch], newAssets[i]);
                                    checkImagesBG(data[sch], newAssets[i]);
                                    newAssets[i].description = data[sch].program_desc;
                                    newAssets[i].show_type = data[sch].show_type;
                                    newAssets[i].cast = data[sch].cast;
                                    // newAssets[i].cast = data[sch].cast;
                                    newAssets[i].tv_rating = data[sch].tv_rating;
                                    newAssets[i].episode_name = data[sch].ep_name;
                                    newAssets[i].season_number = data[sch].se_number;
                                    newAssets[i].episode_number = data[sch].ep_number;
                                    newAssets[i].release_year = data[sch].release_year;
                                    newAssets[i].start_formated = data[sch].start_formated;
                                    newAssets[i].end_formated = data[sch].end_formated;
                                    newAssets[i].unix_start_time = data[sch].unix_start_time;
                                    newAssets[i].unix_end_time = data[sch].unix_end_time;
                                    newAssets[i].long_title = data[sch].long_title;
                                    newAssets[i].genre = data[sch].category;
                                    newAssets[i].group_id = data[sch].group_id;
                                }
                            }

                            assetData.push(...newAssets);
                            dispatch(updateAssetData(assetData));
                            dispatch(completedRecordingsFilter(assetData)); //should do it through AppMain assetsCheck()

                            const newOrder = {
                                order_id: pusherData.order.id.toString(),
                                order_type: pusherData.order.type,
                                subscriber_id: pusherData.order.user_id.toString(),
                                start_recording: pusherData.order.start_recording.toString(),
                                stop_recording: pusherData.order.stop_recording.toString(),
                                start_from: pusherData.order.start_from ? pusherData.order.start_from.toString() : null, //redo that with better condition
                                channel: pusherData.order.channel,
                                get_hd: pusherData.order.get_hd ? pusherData.order.get_hd : 0, //TO DO - do we event need it?
                                record_state: pusherData.order.record_state,
                                is_start_from_year: pusherData.order.is_start_from_year,
                                series_id: pusherData.order.series_id ? pusherData.order.series_id.toString() : null,
                                source_id: pusherData.order.source_id ? pusherData.order.source_id.toString() : null,
                            };
                            orderData.push(newOrder);
                            dispatch(updateOrderData(orderData));
                            dispatch(updateLoading(false));
                        })
                        //Merge Fix Question**
                        //catch got deleted in the commit comparison, but it's still being useful?
                        .catch((error) => {
                            //added this for error handling on call
                            // console.log("getSchedule error", error);
                            let errorObject = errorHandler(error, 'getRecordedImages in PusherOrderCreated', subDeviceInfo); //variable upfront only for console.log
                            dispatch(errorIntercept(errorObject));
                            dispatch(updateLoading(false));
                        });
                } else {
                    //Assets = null(no assets), so just update order list
                    // console.log("last else case")
                    const newOrder = {
                        order_id: pusherData.order.id.toString(),
                        order_type: pusherData.order.type,
                        subscriber_id: pusherData.order.user_id.toString(),
                        start_recording: pusherData.order.start_recording.toString(),
                        stop_recording: pusherData.order.stop_recording.toString(),
                        start_from: pusherData.order.start_from ? pusherData.order.start_from.toString() : null, //redo that with better condition
                        channel: pusherData.order.channel,
                        get_hd: pusherData.order.get_hd ? pusherData.order.get_hd : 0,
                        record_state: pusherData.order.record_state,
                        is_start_from_year: pusherData.order.is_start_from_year,
                        series_id: pusherData.order.series_id ? pusherData.order.series_id.toString() : null,
                        source_id: pusherData.order.source_id ? pusherData.order.source_id.toString() : null,
                    };

                    orderData.push(newOrder);
                    dispatch(updateOrderData(orderData));
                    dispatch(updateLoading(false));
                    dispatch(getSCSuccess());
                }
            }
        } catch (error) {
            console.log('pusherOrderCreated error: ', error);
            let errorObject = errorHandler(error, 'pusherOrderCreated', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
            dispatch(getSCFailure());
        }
    });
};

const pusherOrderCreateFailed = (pusherChannel, getState, dispatch) => {
    pusherChannel.bind('OrderCreateJobFailed', (pusherData) => {
        //console.log("Order Creation Failed, Failed Order:", pusherData);
        const error = {
            type: 'pusherOrderCreateFailed',
            programScheduleId: pusherData.schedule_id,
        };
        let errorObject = errorHandler(error, 'OrderCreateJobFailed', getState().login.subscriberData.subDeviceInfo);
        dispatch(errorIntercept(errorObject));
        dispatch(updateLoading(false));
    });
};

//---------Pusher Order Update
const pusherOrderUpdated = (pusherChannel, getState, dispatch) => {
    pusherChannel.bind('OrderUpdated', (pusherData) => {
        console.log('order updated: ', pusherData);
        try {
            dispatch(updateOrderDataLoading(true));
            const orderData = [...getState().myshows.orderData];
            const newOrderData = {
                order_id: pusherData.order.id.toString(),
                order_type: pusherData.order.type,
                subscriber_id: pusherData.order.user_id.toString(),
                start_recording: pusherData.order.start_recording.toString(),
                stop_recording: pusherData.order.stop_recording.toString(),
                start_from: pusherData.order.start_from ? pusherData.order.start_from.toString() : null,
                channel: pusherData.order.channel,
                get_hd: pusherData.order.get_hd ? pusherData.order.get_hd : 0,
                record_state: pusherData.order.record_state,
                is_start_from_year: pusherData.order.is_start_from_year,
                series_id: pusherData.order.series_id ? pusherData.order.series_id.toString() : null,
                source_id: pusherData.order.source_id ? pusherData.order.source_id.toString() : null,
            };
            const updateIndex = orderData.findIndex((data) => data.order_id.toString() === newOrderData.order_id.toString());
            orderData[updateIndex] = newOrderData;
            dispatch(updateOrderData(orderData));
            dispatch(updateLoading(false));
            dispatch(updateOrderDataLoading(false));
        } catch (error) {
            console.log('OrderUpdated error: ', error);
            let errorObject = errorHandler(error, 'OrderUpdated', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
            dispatch(updateOrderDataLoading(false));
        }
    });
};
//---------Pusher Order Delete
const pusherOrderDeleted = (pusherChannel, getState, dispatch) => {
    pusherChannel.bind('OrderDeleted', (pusherData) => {
        console.log('OrderDeleted: ', pusherData);
        try {
            dispatch(getSCBegin());
            const orderData = [...getState().myshows.orderData];
            const onUpcomingMenu = getState().myshows.onUpcomingMenu;
            // console.log("orderData: ", orderData);
            //Update Order data list
            // Conditional checks for very rare occurence that a delete pusher event gets triggered while a user is on the upcomingmenu index in Myshows
            // this is to prevent any discrepencies in the list from causing errors.
            if (!onUpcomingMenu) {
                const upcomingData = [...getState().myshows.upcomingData];
                const newUpcomingData = upcomingData.filter((asset) => asset.order_id.toString() !== pusherData.order.id.toString());
                dispatch(updateUpcomingData(newUpcomingData));
            }
            const newOrderData = orderData.filter((order) => order.order_id.toString() !== pusherData.order.id.toString()); //.id.toString());
            // console.log("newOrderData: ", newOrderData);
            dispatch(updateOrderData(newOrderData));
            dispatch(updateLoading(false));
            setTimeout(() => {
                dispatch(getSCSuccess());
            }, 150); //to be sure that Redux has time to update
        } catch (error) {
            dispatch(getSCFailure());
            dispatch(updateUpcomingLoading(false));
            console.log('OrderDeleted error: ', error);
            let errorObject = errorHandler(error, 'OrderDeleted', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
        }
    });
};

//---------Pusher Asset Create
//Runs on single asset creation
const pusherAssetCreated = (pusherChannel, getState, dispatch) => {
    let pushAsset;
    let assetData;
    let newAsset;
    pusherChannel.bind('AssetCreated', (pusherData) => {
        //console.log('pusher Asset Created', pusherData);
        try {
            if (getState().login.subscriberData.hasOwnProperty('new_logic') && getState().login.subscriberData.new_logic) {
                !getState().myshows.isSCLoading && dispatch(getSCBegin());
                //2.create new asset
                newAsset = {
                    program_name: removeSlashes(pusherData.asset.program_name),
                    schedule_id: pusherData.asset.schedule_id,
                    program_id: pusherData.asset.program_id.toString(),
                    asset_id: pusherData.asset.id.toString(),
                    starts_at: pusherData.asset.starts_at,
                    ends_at: pusherData.asset.ends_at,
                    // order_id: pusherData.order.id.toString(),//needs to be added in createOrder
                    show_type: '',
                    description: '',
                    backgroundImage: '',
                    cardImage: '', //we can get card image //TO DO
                    start_recording: pusherData.asset.start_recording.toString(),
                    stop_recording: pusherData.asset.stop_recording.toString(),
                    // type: pusherData.order.type,//will be added inside createOrder
                    source_id: pusherData.asset.source_id.toString(),
                    id: 0, //should it be zero
                    season_number: '',
                    episode_number: '',
                    episode_name: '',
                    series_id: pusherData.asset.series_id.toString(),
                    series_description: '',
                    release_year: '',
                    tv_rating: '',
                    start_formated: '',
                    end_formated: '',
                    long_title: '',
                    sc_id: pusherData.asset.sc_id,
                    genre: pusherData.asset.category,
                    expires_at: pusherData.asset.expires_at,
                    expires_unix: pusherData.asset.expires_unix.toString(),
                    user_id: pusherData.asset.user_id.toString(),
                    created_at: pusherData.asset.created_at,
                    favorite: pusherData.asset.favorite,
                    // updated_at: pusherData.asset.updated_at, //I do not think we need this property
                    sc_recording_state: 'scheduled',
                };

                pushAsset = true;
                assetData = [...getState().myshows.assetData];
                //case 1 - if order_id and type already present in asset - modify it with above information
                for (let j = 0; j < assetData.length; j++) {
                    if (newAsset.asset_id === assetData[j].asset_id) {
                        //if we found match - add properties
                        //we found match so we need to add properties - update it backwards
                        console.log('match found - modifying: ', assetData[j]);
                        pushAsset = false;
                        assetData[j] = { ...assetData[j], ...newAsset }; //merge properties from both Objs together
                    }
                }

                if (pushAsset) {
                    //this is the case when order did not fire up yet and asset needs to be added to assetData
                    assetData.push(newAsset);
                }
                // console.log("before updating assetData: ", assetData);
                dispatch(updateAssetData(assetData));
                dispatch(updateLoading(false));
                getState().myshows.isSCLoading && dispatch(getSCSuccess());
            }
            //for old logic - do nothing
        } catch (error) {
            console.log('AssetCreated error: ', error);
            let errorObject = errorHandler(error, 'AssetCreated', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
            dispatch(getSCFailure());
        }
    });
};

//---------Pusher Asset Delete
//Runs on single asset deletion
const pusherAssetDeleted = (pusherChannel, getState, dispatch) => {
    pusherChannel.bind('AssetDeleted', (pusherData) => {
        //console.log('pusher AssetDeleted: ', pusherData);
        try {
            const assetData = [...getState().myshows.assetData];
            const globalShowList = [...getState().myshows.globalShowList];
            // console.log("assetData: ", assetData);
            // console.log("globalShowList: ", globalShowList);
            const newAssetData = assetData.filter((asset) => asset.asset_id.toString() !== pusherData.asset.id.toString());
            dispatch(updateAssetData(newAssetData)); // Update Asset data list, as asset is was removed in local assetData list
            //update globalShowList
            const newGlobalShowList = globalShowList.filter((asset) => asset.asset_id.toString() !== pusherData.asset.id.toString());
            // console.log("newGlobalShowList", newGlobalShowList);
            dispatch(filterShowList(newGlobalShowList));
            // dispatch(filterCardList(newGlobalShowList));
            dispatch(updateLoading(false));
        } catch (error) {
            console.log('AssetDeleted error: ', error);
            let errorObject = errorHandler(error, 'AssetDeleted', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
        }
    });
};

const pusherCancelMyTicket = (pusherChannel, getState, dispatch) => {
    //TO DO - add logic for Alex's asset_id fix when ready

    pusherChannel.bind('SeriesMorphedToSingles', (pusherData) => {
        //get_hd does not come here
        console.log('SeriesMorphedToSingles:', pusherData);
        try {
            //this event should not respond when new_logic is true
            if (getState().login.subscriberData.hasOwnProperty('new_logic') && getState().login.subscriberData.new_logic) {
                console.log('new logic in place'); //do nothing - this channel does not exist in new logic
            } else {
                console.log('execute old logic');
                let orderData = [...getState().myshows.orderData];
                let assetData = [...getState().myshows.assetData];
                let globalShowList = [...getState().myshows.globalShowList];
                let newSingleOrder;
                // let orderDataSeriesId = [];
                if (pusherData.response && 'new_singles' in pusherData.response) {
                    let assetsToModify = assetData.filter((value) => {
                        //1.filter out assets with pusherData.response.old_series
                        return value.order_id.toString() === pusherData.response.old_series.toString(); //using 2 strings to normalize
                    });
                    assetData = assetData.filter((asset) => {
                        return asset.order_id.toString() !== pusherData.response.old_series.toString(); //using  toString() to normalize
                    });

                    //showlist must be handled similarly
                    let showListAssetsToModify = globalShowList.filter((value) => {
                        //1.filter out assets with pusherData.response.old_series
                        return value.order_id.toString() === pusherData.response.old_series.toString(); //using 2 strings to normalize
                    });
                    globalShowList = globalShowList.filter((asset) => {
                        return asset.order_id.toString() !== pusherData.response.old_series.toString(); //using  toString() to normalize
                    });

                    // orderDataSeriesId = orderData.filter((order) => {//to extract series id from the order
                    //     return order.order_id.toString() === pusherData.response.old_series.toString();
                    // }); //possibly not needed
                    orderData = orderData.filter((order) => {
                        //filtering out order that is being cancelled
                        return order.order_id.toString() !== pusherData.response.old_series.toString();
                    });
                    for (let i = 0; i < pusherData.response.new_singles.length; i++) {
                        newSingleOrder = {
                            //creating new order for orderData list
                            order_id: pusherData.response.new_singles[i].id.toString(),
                            order_type: pusherData.response.new_singles[i].type,
                            subscriber_id: pusherData.response.new_singles[i].user_id.toString(),
                            start_recording: pusherData.response.new_singles[i].start_recording.toString(),
                            stop_recording: pusherData.response.new_singles[i].stop_recording.toString(),
                            start_from: null,
                            channel: null,
                            get_hd: 0, //possibly should be null
                            record_state: null,
                            is_start_from_year: null,
                            series_id: null,
                            //commented out per Alex - all these fields should be null for single recordings
                            // series_id: orderDataSeriesId && orderDataSeriesId[0] && orderDataSeriesId[0].series_id,
                            // start_from: orderDataSeriesId && orderDataSeriesId[0] && orderDataSeriesId[0].start_from,
                            // channel: orderDataSeriesId && orderDataSeriesId[0] && orderDataSeriesId[0].channel,
                            // get_hd: pusherData.response.new_singles[i].get_hd ? pusherData.response.new_singles[i].get_hd : 0,
                            // record_state: orderDataSeriesId && orderDataSeriesId[0] && orderDataSeriesId[0].record_state,
                            // is_start_from_year: orderDataSeriesId && orderDataSeriesId[0] && orderDataSeriesId[0].is_start_from_year,
                        };
                        orderData = [...orderData, newSingleOrder]; //adding this new order (former series) to orderData
                        //we probably have to do the same for global ShowList or do we?
                    }

                    //next, we need to update assets order_id and type
                    //2.modify assetsToModify type and order id
                    for (let z = 0; z < assetsToModify.length; z++) {
                        //quantity of assetsToModify should be the same as new_singles
                        for (let y = 0; y < pusherData.response.new_singles.length; y++) {
                            // to match asset in new singles to order
                            if (assetsToModify[z].asset_id.toString() === pusherData.response.new_singles[y].asset_ids[0].toString()) {
                                assetsToModify[z].type = pusherData.response.new_singles[y].type;
                                assetsToModify[z].order_id = pusherData.response.new_singles[y].id.toString();
                            }
                        }
                    }
                    assetData.push(...assetsToModify);
                    //same for showlist
                    for (let z = 0; z < showListAssetsToModify.length; z++) {
                        //quantity of assetsToModify should be the same as new_singles
                        for (let y = 0; y < pusherData.response.new_singles.length; y++) {
                            // to match asset in new singles to order
                            if (showListAssetsToModify[z].asset_id.toString() === pusherData.response.new_singles[y].asset_ids[0].toString()) {
                                showListAssetsToModify[z].type = pusherData.response.new_singles[y].type;
                                showListAssetsToModify[z].order_id = pusherData.response.new_singles[y].id.toString();
                            }
                        }
                    }
                    globalShowList.push(...showListAssetsToModify);
                }
                // console.log("assetData: ", assetData);
                // console.log("orderData: ", orderData);
                dispatch(updateAssetData(assetData));
                dispatch(updateOrderData(orderData));
                dispatch(filterShowList(globalShowList));
                dispatch(updateLoading(false));
                newSingleOrder = null;
            }
        } catch (error) {
            console.log('SeriesMorphedToSingles error: ', error);
            let errorObject = errorHandler(error, 'SeriesMorphedToSingles', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
        }
    });
};

// AssetMovedFromSingleToSeries
const pusherSinglesToSeries = (pusherChannel, getState, dispatch) => {
    //rename it - to create MyTicket
    pusherChannel.bind('AssetMovedFromSingleToSeries', (pusherData) => {
        console.log('AssetMovedFromSingleToSeries', pusherData);
        try {
            !getState().myshows.isSCLoading && dispatch(getSCBegin());
            let assetData = [...getState().myshows.assetData];
            //deleting single orders that are being converted to series
            let updatedOrderData = [...getState().myshows.orderData].filter((value) => {
                //updatedOrderData
                return value.order_id.toString() !== pusherData.response.order_from.toString();
            });
            // console.log("orderData: ", [...getState().myshows.orderData]);
            // console.log("updated order data: ", updatedOrderData)
            dispatch(updateOrderData(updatedOrderData));

            //updating assets id and type
            let updatedAssetData = assetData.map((value) => {
                if (value.asset_id.toString() === pusherData.response.asset.toString()) {
                    let assetToModify = { ...value };
                    assetToModify.order_id = pusherData.response.order_to.toString();
                    assetToModify.type = 'series';
                    return assetToModify;
                }
                return value;
            });
            // console.log("assetData: ", assetData);
            // console.log("updatedAssetData: ", updatedAssetData);
            dispatch(updateAssetData(updatedAssetData));

            //update global showList
            let newGlobalShowList = [...getState().myshows.globalShowList];
            for (let i = 0; i < newGlobalShowList.length; i++) {
                //update global show list
                if (newGlobalShowList[i].asset_id === pusherData.response.asset.toString()) {
                    newGlobalShowList[i].type = 'series';
                    newGlobalShowList[i].order_id = pusherData.response.order_to.toString();
                }
            }

            //update all lists
            dispatch(filterShowList(newGlobalShowList));
            getState().myshows.isSCLoading &&
                setTimeout(() => {
                    dispatch(getSCSuccess());
                }, 150); //to be sure that Redux has time to update
            // dispatch(filterCardList(newGlobalShowList));
        } catch (error) {
            console.log('AssetMovedFromSingleToSeries error: ', error);
            let errorObject = errorHandler(error, 'AssetMovedFromSingleToSeries', getState().login.subscriberData);
            dispatch(errorIntercept(errorObject));
            dispatch(updateLoading(false));
            dispatch(getSCFailure());
        }
    });
};

const pusherAlertEAS = (pusherChannel, getState, dispatch, subData) => {
    pusherChannel.bind('fips', (pusherData) => {
        // console.log("pusherData: ", pusherData)
        if (subData && subData?.telco_id?.toString() === pusherData?.response?.telco_id?.toString()) {
            //only fireup when teloco-ids match
            // console.log('EAS EVENT FROM PUSHER!', JSON.stringify(pusherData));
            const previousAlertsArr = [...getState().main.previousAlertsArr];
            // console.log(previousAlertsArr);
            //check to see if the alertheader exists in the previousAlert arr. If it already exists, do nothing, otherwise add it to the array and trigger alert in app.
            if (false && previousAlertsArr?.includes(pusherData.response.easheader)) {
                // nothing happens
                // console.log('Duplicate EAS event');
            } else {
                if (getState().main.isPlaying) {
                    dispatch(returnToLiveAfterEas(true));
                    dispatch(stopLiveTV());
                }
                if (getState().main.isPlayingRecording) {
                    dispatch(togglePlayingRecording(false));
                }

                if (getState().guide.restartProgramInProgress) {
                    dispatch(clearRestart()); //clears restart and we go back to liveTV
                }

                if (getState().main.isFullScreen) {
                    dispatch(toggleFullScreen(false));
                }

                dispatch(updatePreviousAlertsArr([...previousAlertsArr, pusherData.response.easheader]));
                dispatch(alertEAS(pusherData.response));
            }
        }
    });
};

export const dispatchErrorHandler = (error, sentFrom, user, hideUserErrorMessage) => {
    //allows for error handler to be called from outside actions; when dispatch is not available
    return (dispatch, getState) => {
        let errorObject = errorHandler(error, sentFrom, user);
        if (!hideUserErrorMessage) {
            dispatch(errorIntercept(errorObject));
        }
    };
};

export const connectivityChange = (conType, isConnected) => ({
    type: CONNECTIVITY_CHANGE,
    isConnected,
    conType,
});

export const connLossInterval = (connTimeOut) => ({
    type: CONNECTIVITY_STATUS,
    connTimeOut,
});

export const inactivationNotice = () => {
    return (dispatch, getState) => {
        console.log('inactivationNotice called', this);
        dispatch(toggleInactivationMessage(true));
        setTimeout(() => {
            //this.messageDisplayTimer =
            dispatch(toggleInactivationMessage(false));
        }, 10000);
    };
};

export const toggleInactivationMessage = (isInactivationMessageSet) => ({
    type: TOGGLE_INACTIVATION_MESSAGE,
    isInactivationMessageSet,
});

//logout
export const onLogout = () => {
    return (dispatch, getState) => {
        let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
        dispatch(onLogoutBegin()); //preparation before Logout
        return onLogOutApiCall(subDeviceInfo) // actual API call for Logout
            .then((resolution) => {
                dispatch(onLogoutSuccess());
                return resolution;
            })
            .catch((error) => {
                let errorObject = errorHandler(error, 'onLogout', subDeviceInfo); //variable upfront only for console.log
                dispatch(onLogoutError(errorObject.message)); //case if token was not received
            });
    };
};

export const setDeviceLimitReached = (isDeviceLimitReached) => ({
    type: SET_DEVICE_LIMIT_REACHED,
    isDeviceLimitReached,
});

export const toggleSotalRefreshLoading = (isSotalRefreshLoading) => ({
    type: TOGGLE_SOTAL_REFRESH_LOADING,
    isSotalRefreshLoading,
});

// export const refreshSotalToken = () => {
//     return (dispatch, getState) => {
//         dispatch(toggleSotalRefreshLoading(true));
//         let subData = getState().login.subscriberData;
//         let creds = {
//             email: subData.user_name,
//             password: subData.tempPw,
//         };
//         return GetSotalToken(creds)
//             .then((response) => {
//                 // dispatch refresh for success or fail
//                 setScTokenInHeader(response.data.sotal_cloud_token);
//                 dispatch(toggleSotalRefreshLoading(false));
//                 dispatch(setDeviceLimitReached(false));
//             })
//             .catch((error) => {
//                 dispatch(toggleSotalRefreshLoading(false));
//             });
//     };
// };

//logout stuff

export const onLogoutBegin = () => ({
    type: ON_LOGOUT_BEGIN,
});

export const onLogoutSuccess = () => ({
    type: ON_LOGOUT_SUCCESS,
});

export const onLogoutError = (error) => ({
    type: ON_LOGOUT_ERROR,
    error,
});

export const prepareForNextLogoutAttempt = () => ({
    type: PREPARE_FOR_NEXT_LOGOUT_ATTEMPT,
});

export const resetAppState = () => ({
    type: RESET_APP_STATE,
});

const channelsList = (channels) => {
    //creating it here instead of reducer
    return channels.map((channel) => channel.custom_channel_number);
};

const checkForPrograms = (chans, disabled_channels) => {
    //checking for programs provided from backend on every channel

    let filteredChannels = chans && chans.length > 0 ? [...chans] : [];
    if (filteredChannels.length > 0 && disabled_channels && disabled_channels.length > 0) {
        filteredChannels = checkTempRestrictions(chans, disabled_channels);
    }
    //this portion is for selecting logo from array of icons
    if (filteredChannels && filteredChannels.length > 0) {
        let tempLogosHolder = [];
        for (let i = 0; i < filteredChannels.length; i++) {
            if (filteredChannels[i].station_logo && filteredChannels[i].station_logo.length > 0) {
                // if(filteredChannels[i].custom_channel_number === "701"){//temp - to inspect logos for specific channel
                //     console.log(filteredChannels[i].station_logo)
                // }
                tempLogosHolder = [...filteredChannels[i].station_logo];
                filteredChannels[i].station_logo = '';
                for (let g = 0; g < tempLogosHolder.length; g++) {
                    if (tempLogosHolder[g].includes('white') && !tempLogosHolder[g].includes('2400') && tempLogosHolder[g].includes('_4')) {
                        filteredChannels[i].station_logo = tempLogosHolder[g];
                        break;
                    }
                    if (tempLogosHolder[g].includes('white') && !tempLogosHolder[g].includes('2400')) {
                        filteredChannels[i].station_logo = tempLogosHolder[g];
                        break;
                    }
                    // if (tempLogosHolder[g].includes('_DARK')) {
                    //     filteredChannels[i].station_logo = tempLogosHolder[g];
                    //     break;
                    // }
                }
            } else {
                //if station logo array is empty
                filteredChannels[i].station_logo = '';
            }
            // console.log("custom channel number: ", filteredChannels[i].custom_channel_number," what goes into list: ", filteredChannels[i].station_logo);
        }
    }
    return filteredChannels;
};

export const errorIntercept = (error) => {
    //in case if auth error occured
    if (error && error.status && error.status === '401') {
        return {
            type: AUTH_ERROR,
            error: error.message,
        };
    } else {
        return {
            type: GENERAL_ERROR,
            error: error.message,
        };
    }
};

export const resetAuthError = () => ({
    type: RESET_AUTH_ERROR,
});

export const onGeneralErrorReset = (resetObject) => ({
    type: GENERAL_ERROR_RESET,
    resetObject: resetObject,
});

export const disconnectFromPusherChannel = () => {
    //unsubscribing from pusher channel
    if (pusherCDVR) {
        pusherChannelCDVR && pusherCDVR.unsubscribe(pusherChannelCDVR);
        countyChannelEAS && pusherCDVR.unsubscribe(countyChannelEAS);
        stateChannelEAS && pusherCDVR.unsubscribe(stateChannelEAS);
        nationalChannelEAS && pusherCDVR.unsubscribe(nationalChannelEAS);
        pusherAdminChannel && pusherCDVR.unsubscribe(pusherAdminChannel);
        pusherCDVR.disconnect();
    }
    // if (pusherEAS){
    //     // console.log("disconnect from pusherChannelEAS");
    //     pusherEAS.disconnect();
    // }
};

export const updatePreviousAlertsArr = (alertsArr) => ({
    type: UPDATE_PREVIOUS_ALERTS_ARR,
    alertsArr,
});

export const alertEAS = (pusherResponse) => ({
    type: ALERT_EAS,
    pusherResponse,
});

export const clearEAS = () => ({
    type: CLEAR_EAS,
});

export const setPlayerSourceOpened = (isPlayerSourceOpened) => ({
    type: SET_PLAYER_SOURCE_OPENED,
    isPlayerSourceOpened,
});

export const overscanAdjust = (status, action) => ({
    type: SET_OVERSCAN,
    setOverscan: status,
    action,
});

export const updatePusherState = (pusherState) => ({
    type: UPDATE_PUSHER_STATE,
    pusherState,
});

export const toggleVideoDebugOption = (isVideoDebugEnabled) => ({
    type: TOGGLE_VIDEO_DEBUG_OPTION,
    isVideoDebugEnabled,
});

export const updateNumTracks = (debugNumOfTracks) => ({
    type: UPDATE_NUM_TRACKS,
    debugNumOfTracks,
});

const createMapForSearch = (channels, dispatch) => {
    //building the Map {program name => to other stuff}
    let ourObj = {};
    try {
        //remove toUpperCase()- it's added to compensate for naming issue with backend, same show may be with few Upper/Lower case
        for (let i = 0; i < channels.length; i++) {
            if (channels[i].ch_name && channels[i].programs.length) {
                // console.log("programs for channel : " + this.props.channels[i].ch_name, this.props.channels[i].programs.length);
                for (let j = 0; j < channels[i].programs.length; j++) {
                    if (channels[i].programs[j].program_id !== 'placeholder') {
                        if (ourObj[channels[i].programs[j].long_title.toUpperCase()]) {
                            //already in our datastructure - check for episode name and add
                            //ourObj[this.props.channels[i].programs[j].long_title].length -1 - have to look at last object to see how many episodes are there
                            if (
                                !ourObj[channels[i].programs[j].long_title.toUpperCase()][
                                    ourObj[channels[i].programs[j].long_title.toUpperCase()].length - 1
                                ].ep_names?.includes(channels[i].programs[j].ep_name)
                            ) {
                                ourObj[channels[i].programs[j].long_title.toUpperCase()] = [
                                    ...ourObj[channels[i].programs[j].long_title.toUpperCase()],
                                    {
                                        ch_name: channels[i].ch_name || '',
                                        ch_number: channels[i].custom_channel_number || '',
                                        ep_name: channels[i].programs[j].ep_name || '',
                                        begins_at: channels[i].programs[j].start_formated || '', //start_formatted
                                        unix_start_time: channels[i].programs[j].unix_start_time || '',
                                        program_description: channels[i].programs[j].program_desc || '',
                                        ep_names: channels[i].programs[j]?.ep_name
                                            ? [
                                                  ...ourObj[channels[i].programs[j].long_title.toUpperCase()][
                                                      ourObj[channels[i].programs[j].long_title.toUpperCase()].length - 1
                                                  ].ep_names,
                                                  channels[i].programs[j].ep_name,
                                              ]
                                            : ourObj[channels[i].programs[j].long_title.toUpperCase()][
                                                  ourObj[channels[i].programs[j].long_title.toUpperCase()].length - 1
                                              ].ep_names,
                                        ep_number: channels[i].programs[j]?.ep_number || '',
                                        se_number: channels[i].programs[j]?.se_number || '',
                                        se_name: channels[i].programs[j]?.se_name || '',
                                        show_name: channels[i].programs[j]?.long_title || '',
                                        station_logo: channels[i].station_logo || '',
                                        show_type: channels[i].programs[j]?.show_type || '',
                                        release_year: channels[i].programs[j]?.release_year || '',
                                        show_name: channels[i].programs[j]?.long_title || '',
                                        schedule_id: channels[i].programs[j]?.schedule_id || '',
                                        id: i,
                                    },
                                ];
                            }
                        } else {
                            //not in our data structure
                            ourObj[channels[i].programs[j].long_title.toUpperCase()] = [
                                {
                                    ch_name: channels[i]?.ch_name || '',
                                    ch_number: channels[i]?.custom_channel_number || '',
                                    ep_name: channels[i].programs[j]?.ep_name || '',
                                    begins_at: channels[i].programs[j]?.start_formated || '', //start_formatted
                                    unix_start_time: channels[i].programs[j]?.unix_start_time || '',
                                    program_description: channels[i].programs[j].program_desc || '',
                                    ep_names: channels[i].programs[j]?.ep_name ? [channels[i].programs[j].ep_name] : [], //this sort of condition ensures that we have a list even if no ep_name
                                    ep_number: channels[i].programs[j]?.ep_number || '',
                                    se_number: channels[i].programs[j]?.se_number || '',
                                    se_name: channels[i].programs[j]?.se_name || '',
                                    show_name: channels[i].programs[j]?.long_title || '',
                                    station_logo: channels[i].station_logo || '',
                                    show_type: channels[i].programs[j]?.show_type || '',
                                    release_year: channels[i].programs[j]?.release_year || '',
                                    show_name: channels[i].programs[j]?.long_title || '',
                                    schedule_id: channels[i].programs[j]?.schedule_id || '',
                                    id: i,
                                },
                            ];
                        }
                    }
                }
            }
        }
        // console.log("ourObj: ", ourObj);
        dispatch(setSearchMap(ourObj));
    } catch (error) {
        console.log('error: ', error);
    }
};

//works on assumption that disabled_channels list's elements are always string
function checkTempRestrictions(chans, disabled_channels) {
    return chans.filter((value) => {
        return !disabled_channels.includes(value.source_id.toString());
    });
}
