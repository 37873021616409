import React, { Component } from 'react';
//import { Animated, ActivityIndicator, Dimensions, StyleSheet, Text, View } from 'react-native';
//import Icon from 'react-native-vector-icons/FontAwesome';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
import { StyleSheet, css } from 'aphrodite';

export default class RecordingToast extends Component {
    constructor() {
        super();
        this.state = {
            animateOut: false,
            //bounceValue: new Animated.Value(yScale(150))
        };
    }

    componentDidMount() {
        this.mounted = true;
        // Animated.spring(
        //     this.state.bounceValue,
        //     {
        //         toValue: 0,
        //         velocity: 3,
        //         tension: 2,
        //         friction: 8,
        //         useNativeDriver: true
        //     }
        // ).start();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOrderRequestCompleted !== this.props.isOrderRequestCompleted && this.props.isOrderRequestCompleted === true) {
            this.timer = setTimeout(() => {
                if (this.mounted) {
                    this.closeOptions();
                }
            }, 3000);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this._optionsEmitter && this._optionsEmitter.remove();
        this.props.toggleRecordingToast(false);
    }

    closeOptions = () => {
        // Animated.timing(//to close it quick - changed to timing
        //     this.state.bounceValue,
        //     {
        //         toValue: yScale(150),
        //         duration: 150,
        //         useNativeDriver: true
        //     }
        // ).start(() => {
        this.setState({ animateOut: true }, () => {
            setTimeout(() => this.props.toggleRecordingToast(false), 1000);
        });
        //});
    };

    render() {
        //const { height, width } = Dimensions.get('window');
        const { toastOrderMessage } = this.props;
        return (
            // <View style={{ position: 'absolute', elevation: 3, height, width, backgroundColor: 'rgba(155, 155, 155, 0.2)' }}>
            //       <Animated.View style={[styles.optionsContainer, { transform: [{ translateY: this.state.bounceValue }] }]}>
            //         <View style={styles.optionsHeader}>
            //             {(isPostingOrder || isUpdatingOrder || isDeletingOrder) &&
            //                 <View style={{backgroundColor: "rgba(99,99,99,0.2)", height: yScale(70), width: xScale(50), alignItems: 'center', justifyContent: 'center'}}>
            //                     <ActivityIndicator animating={true} size={'small'}/>
            //                 </View>
            //             }
            //             {isOrderRequestCompleted && isOrderRequestSuccessful &&
            //                 <View style={{backgroundColor: "rgba(99,99,99,0.2)", height: '100%', width: xScale(50), alignItems: 'center', justifyContent: 'center'}}>
            //                     <Icon name="check" size={xScale(18)} color="#fff"></Icon>
            //                 </View>
            //             }
            //             {isOrderRequestCompleted && !isOrderRequestSuccessful &&
            //                 <View style={{backgroundColor: "rgba(99,99,99,0.2)", height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            //                     <Icon name="ban" size={xScale(26)} color="#fff" style={{ marginHorizontal: xScale(12) }}></Icon>
            //                 </View>
            //             }
            //             <Text style={{ fontFamily: 'OpenSans-Light', color: '#9B9B9B', fontSize: fontScale(18), paddingLeft: xScale(15) }}>{toastOrderMessage}</Text>
            //         </View>
            //     </Animated.View>
            // </View>
            <>
                <div className={css([styles.Main, styles.animateToastIn, this.state.animateOut && styles.animateToastOut])}>
                    {toastOrderMessage ? toastOrderMessage : 'placeholder'}
                </div>
            </>
        );
    }
}

const translateKeyframesIn = {
    '0%': {
        transform: 'translateX(30em)',
    },
    '100%': {
        transform: 'translateX(0)',
    },
};

const translateKeyframesOut = {
    '0%': {
        transform: 'translateX(0)',
    },
    '100%': {
        transform: 'translateX(30em)',
    },
};

const styles = StyleSheet.create({
    Main: {
        position: 'absolute',
        display: 'flex',
        alignContent: 'stretch',
        backgroundColor: '#171717',
        alignItems: 'center',
        //width: '10em',
        height: '4em',
        top: 'calc(81px + 30em)',
        right: '8em', //8em
        color: 'white',
        padding: '0 4em',
        zIndex: 100,
    },
    animateToastIn: {
        animationName: translateKeyframesIn,
        animationDuration: '1s',
        animationIterationCount: 'none',
    },
    animateToastOut: {
        animationName: translateKeyframesOut,
        animationDuration: '1s',
        animationIterationCount: 'none',
    },
});

// const styles = StyleSheet.create({
//     optionsContainer: {
//         position: 'absolute',
//         width: xScale(350),
//         height: yScale(70),
//         bottom: yScale(20),
//         right:0,
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignSelf: 'center',
//     },
//     optionsHeader: {
//         flex: 1,
//         flexDirection: 'row',
//         backgroundColor: '#171717',
//         alignSelf: 'stretch',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//     },
// });
