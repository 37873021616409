import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

const LeftArrow = ({ arrowSVGStyles }) => (
    <svg className={css(arrowSVGStyles)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="ic_small_arrow_left_selected" transform="translate(4966 -582) rotate(180)">
            <rect id="Rectangle_993" data-name="Rectangle 993" width="32" height="32" transform="translate(4934 -614)" fill="none" />
            <path
                id="Path_1356"
                data-name="Path 1356"
                d="M5068-505l15.068,9.1L5068-486.8Z"
                transform="translate(-123.712 -102.258)"
                fill="none"
                stroke="#ddd"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

const RightArrow = ({ arrowSVGStyles }) => (
    <svg className={css(arrowSVGStyles)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="ic_small_arrow_right_selected" transform="translate(-4934 614)">
            <rect id="Rectangle_993" data-name="Rectangle 993" width="32" height="32" transform="translate(4934 -614)" fill="none" />
            <path
                id="Path_1356"
                data-name="Path 1356"
                d="M5068-505l15.068,9.1L5068-486.8Z"
                transform="translate(-123.712 -102.258)"
                fill="none"
                stroke="#ddd"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

/*Component for lists in the series view. */
const SeriesViewList = ({ isSeasonList, seasonSortedArray, seasonIndex, listData, activeIndex, handleListItemClick }) => {
    const [seasonTranslateVal, setSeasonTranslateVal] = useState(0);
    const [episodeTranslateVal, setEpisodeTranslateVal] = useState(0);

    const pageSize = 8;
    const totalSeasonPages = Math.ceil(seasonSortedArray.length / pageSize - 1); //zero-based pagination
    const totalEpisodePages = Math.ceil(seasonSortedArray[seasonIndex].episodes.length / pageSize - 1); //zero-based pagination

    const showLeftArrow = isSeasonList ? seasonTranslateVal !== 0 : episodeTranslateVal !== 0;
    const showRightArrow = isSeasonList ? seasonTranslateVal !== totalSeasonPages : episodeTranslateVal !== totalEpisodePages;
    const translateStyle = { transform: `translateX(${isSeasonList ? seasonTranslateVal * 100 * -1 : episodeTranslateVal * 100 * -1}%)` };

    useEffect(() => {
        //update episode translate val every time season index changes
        if (episodeTranslateVal !== 0) {
            setEpisodeTranslateVal(0);
        }
    }, [seasonIndex, listData]);

    const handleArrowClick = (isSeasonClick, isLeftArrowClick) => {
        // const { seasonSortedArray, seasonIndex } = this.state;

        if (isLeftArrowClick) {
            //pressed left arrow
            if (isSeasonClick) {
                setSeasonTranslateVal((prevSeasonTranslateVal) => {
                    if (prevSeasonTranslateVal === 0) {
                        return null;
                    } else {
                        return prevSeasonTranslateVal - 1;
                    }
                });
            } else {
                setEpisodeTranslateVal((prevEpisodeTranslateVal) => {
                    if (prevEpisodeTranslateVal === 0) {
                        return null;
                    } else {
                        return prevEpisodeTranslateVal - 1;
                    }
                });
            }
        } else {
            //pressed right arrow
            if (isSeasonClick) {
                setSeasonTranslateVal((prevSeasonTranslateVal) => {
                    if (prevSeasonTranslateVal === totalSeasonPages) {
                        return null;
                    } else {
                        return prevSeasonTranslateVal + 1;
                    }
                });
            } else {
                setEpisodeTranslateVal((prevEpisodeTranslateVal) => {
                    if (prevEpisodeTranslateVal === totalEpisodePages) {
                        return null;
                    } else {
                        return prevEpisodeTranslateVal + 1;
                    }
                });
            }
        }
    };

    return (
        <>
            <div className={css(styles.arrowsListWrapper)}>
                <div
                    className={css(styles.arrowContainer, styles.arrowContainerLeft, !showLeftArrow ? styles.hideArrowContainer : null)}
                    onClick={() => {
                        showLeftArrow && (isSeasonList ? handleArrowClick(true, true) : handleArrowClick(false, true));
                    }}>
                    <LeftArrow arrowSVGStyles={styles.arrow} />
                </div>
                <ul className={css(styles.list, isSeasonList ? styles.seasonList : null)}>
                    <div className={css(styles.listItemContainer)} style={translateStyle}>
                        {listData.map((cur, i) => (
                            <li
                                className={css(styles.seasonEpisodeStyles, i === activeIndex ? styles.activeSeasonEpisodeStyle : null)}
                                key={i}
                                onClick={() => handleListItemClick(i)}>
                                {isSeasonList ? cur.season : cur.episode_number.length > 0 ? `E${cur.episode_number}` : cur.episode_placeholder}
                            </li>
                        ))}
                    </div>
                </ul>
                <div
                    className={css(styles.arrowContainer, styles.arrowContainerRight, !showRightArrow ? styles.hideArrowContainer : null)}
                    onClick={() => {
                        showRightArrow && (isSeasonList ? handleArrowClick(true, false) : handleArrowClick(false, false));
                    }}>
                    <RightArrow arrowSVGStyles={styles.arrow} />
                </div>
            </div>
        </>
    );
};

const styles = StyleSheet.create({
    arrowsListWrapper: {
        position: 'relative',
    },
    arrowContainer: {
        ':hover': {
            cursor: 'pointer',
        },
        ':hover > svg': {
            color: '#000',
            backgroundColor: '#00B59C',
            borderRadius: '50%',
        },
        ':hover > svg path': {
            stroke: 'black',
        },
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        minWidth: '2%',
        width: '4%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hideArrowContainer: {
        ':hover': {
            cursor: 'initial',
        },
        opacity: 0,
    },
    arrowContainerLeft: {
        left: 0,
    },
    arrowContainerRight: {
        right: 0,
    },
    arrow: {
        height: '90%',
        width: '70%',
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        padding: 0,
        textAlign: 'initial',
        listStyleType: 'none',
        boxSizing: 'border-box',
        overflow: 'hidden',
        position: 'relative',
        marginLeft: '4%',
        marginRight: '4%',
    },
    seasonList: {
        marginBottom: '1%',
    },
    listItemContainer: {
        width: '100%',
        whiteSpace: 'nowrap',
        transition: 'transform 300ms',
        // backgroundColor: "blue"
    },
    seasonEpisodeStyles: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            color: '#000',
            borderRadius: '12% / 50%',
        },
        border: '1px solid transparent',
        borderRadius: '12% / 50%',
        boxSizing: 'border-box',
        display: 'inline-block',
        padding: '0.2em 0.3em',
        textAlign: 'center',
        //calculate (container width) / 8 items
        width: 'calc(100% / 8)',
        //calculate (container width) / 8 items
        minWidth: 'calc(100% / 8)',
        transition: ' background-color 150ms, color 250ms',
        fontSize: '.9em',
    },
    activeSeasonEpisodeStyle: {
        fontWeight: '700',
        border: '1px solid #fff',
        borderRadius: '12% / 50%',
    },
});

export default SeriesViewList;
