import React from 'react';
import { connect } from 'react-redux';
// import {
//     changeChannel,
//     toggleFullScreen,
// } from '../../../actions/main';
import { toggleOptionsMenu, startRestartProgram, clearRestart, setPendingRestartProgram } from '../../../actions/guide';

import { changeChannel } from '../../../actions/main';

import Title from '../Title';

const TitleContainer = (props) => <Title {...props} />;

const mapStateToProps = (state) => {
    return {
        pageData: state.guide.pageData,
        previewProgramIndex: state.guide.previewProgramIndex,
        previewChannelIndex: state.guide.previewChannelIndex,
        restartProgramInProgress: state.guide.restartProgramInProgress,
        restartProgram: state.guide.restartProgram,
        eventListWithCatchup: state.guide.eventListWithCatchup,
        isDeviceLimitReached: state.main.isDeviceLimitReached,
        channels: state.main.channels,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    toggleOptionsMenu: (isOptionsMenuOpen) => dispatch(toggleOptionsMenu(isOptionsMenuOpen)),
    onClearRestart: () => dispatch(clearRestart()),
    onSetPendingRestartProgram: (program, scAssetId, channel) => dispatch(setPendingRestartProgram(program, scAssetId, channel)),
    onStartRestartProgram: (program, scAssetId, channel) => dispatch(startRestartProgram(program, scAssetId, channel)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleContainer);
