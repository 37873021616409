import { postOrderAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';

let errorObject = {};
let body = {};
let retryCount = 0;
let requestCopy = {}; //only used for Network Error
let postOrderTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export const postRecordingDVR = (
    order_type,
    schedule_id,
    subscriber_id,
    start_recording,
    stop_recording,
    start_from,
    channel,
    get_hd,
    record_state,
    is_start_from_year
) => {
    // postOrderAxios.defaults.headers.common['Authorization'] = ``;//just for testing
    body = {
        order_type: order_type,
        schedule_id: schedule_id.toString(),
        subscriber_id: subscriber_id.toString(),
        start_recording: start_recording.toString(),
        stop_recording: stop_recording.toString(),
        channel: channel && channel.toString(), //channel
        start_from: start_from,
        get_hd: get_hd,
        record_state: record_state,
        is_start_from_year: is_start_from_year,
        keep_at_most: 'All',
        keep_until: 'UntilDelete',
    };
    return postOrderAxios.post('/api/orders', body, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

// start_from: "New" or a number according to new API
export const updateRecordingDVR = (
    order_type,
    order_id,
    subscriber_id,
    start_recording,
    stop_recording,
    start_from,
    channel,
    get_hd,
    record_state,
    is_start_from_year
) => {
    body = {
        type: order_type,
        subscriber_id: subscriber_id.toString(),
        start_recording: start_recording.toString(),
        stop_recording: stop_recording.toString(),
        channel: channel && channel.toString(),
        start_from: start_from,
        record_state: record_state,
        is_start_from_year: is_start_from_year,
    };
    // console.log("update: ", body);
    return postOrderAxios.put(`/api/orders/${order_id}`, body, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

export const deleteRecordingDVR = (type, id) => {
    //if the type is single, then the order will be deleted (along with the asset it contains) and id = order_id.
    // If its series, then only the asset will be deleted from the order and id = asset_id.
    let typeUrl;
    // const typeUrl = type === 'series' ? `/api/asset/${id}` : `/api/orders/${id}?cancel=false`;
    switch (type) {
        case 'disableOrder':
            typeUrl = `/api/orders/${id}?cancel=true`;
            break;
        case 'series':
            typeUrl = `/api/asset/${id}`;
            break;
        case 'single':
            typeUrl = `/api/orders/${id}?cancel=false`; //order is also "canceled" when it's deleted as a single order
            break;
        case 'deletePreserve':
            return Promise.reject('no such option at this moment'); //deletePreserve - needs API route from back-end team
        default:
            break;
    }

    return postOrderAxios({
        method: 'DELETE',
        url: typeUrl,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

//interceptors
postOrderAxios.interceptors.request.use(
    (request) => {
        // console.log(request);
        request.retries = 2;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        postOrderTimeOut = setTimeout(() => {
            cancel && cancel('Network Error');
        }, 60000); //if original timeout does not work
        // console.log('postOrder request success: ', request);
        return request;
    },
    (error) => {
        return error;
    }
);

postOrderAxios.interceptors.response.use(
    (response) => {
        retryCount = 0;
        requestCopy = {};
        body = {};
        postOrderTimeOut && clearTimeout(postOrderTimeOut);
        cancel && (cancel = null);
        // console.log('response: ', response);
        return response;
    },
    (error) => {
        // console.log('post order error', error.message, error.config, error.response);
        postOrderTimeOut && clearTimeout(postOrderTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, body);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 ||
                error.response.status === 409 ||
                error.response.status === 403 ||
                error.response.status === 405 ||
                (error.response.status === 404 && retryCount > 0))
        ) {
            //401 auth error!!! Kick them out! 409 - duplicate
            errorObject = makingErrorObject(error, requestCopy, retryCount, body);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Check if we've maxed out the total number of retries
        if (retryCount >= config.retries) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, body);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Increase the retry count
        retryCount += 1;
        //console.log(retryCount, config.retryDelay * retryCount);

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return postOrderAxios(config);
        });
    }
);
