import React from 'react';
import { connect } from 'react-redux';
import {
    loadChannels,
    changeChannel,
    setChannelList,
    setChannels,
    playLiveTV,
    pauseLiveTV,
    resumeLiveTV,
    stopLiveTV,
    suspendLiveTV,
    toggleSubtitle,
    toggleHalfHourLoad,
    hideMiniPLayer,
    toggleFullScreen,
    //halfHourEpgUpdate,
    connectivityChange,
    connLossInterval,
    resetAppState,
    clearEAS,
} from './actions/main';
import { toggleTicketScreen, toggleRecordingToast, onChannelBarInfo, onFullScreenInfoOpen, updateRestartAvailablity } from './actions/guide';
// import LoginPage from './components/Login/Containers/LoginPageContainer';
import AppMain from './AppMain';

const AppContainer = (props) => (
    <AppMain
        rootNavigation={props.screenProps}
        currentPage={props.currentPage}
        isPlayerShowing={props.isPlayerShowing}
        isPlaying={props.isPlaying}
        isSubtitleToggled={props.isSubtitleToggled}
        isFullScreen={props.isFullScreen}
        currentChannel={props.currentChannel}
        channels={props.channels}
        channelList={props.channelList}
        onLoadChannels={props.onLoadChannels}
        onLoadChannelsLogin={props.onLoadChannelsLogin}
        onChangeChannel={props.onChangeChannel}
        onSetChannelList={props.onSetChannelList}
        onSetChannels={props.onSetChannels}
        onPlayLiveTV={props.onPlayLiveTV}
        onPauseLiveTV={props.onPauseLiveTV}
        onResumeLiveTV={props.onResumeLiveTV}
        onStopLiveTV={props.onStopLiveTV}
        halfHourLoad={props.halfHourLoad}
        onToggleHalfHourLoad={props.onToggleHalfHourLoad}
        onToggleFullscreen={props.onToggleFullscreen}
        isTicketScreenOpen={props.isTicketScreenOpen}
        toggleTicketScreen={props.toggleTicketScreen}
        onSaveDeviceInfo={props.onSaveDeviceInfo}
        isRecordingToastOpen={props.isRecordingToastOpen}
        toggleRecordingToast={props.toggleRecordingToast}
        isPostingOrder={props.isPostingOrder}
        isUpdatingOrder={props.isUpdatingOrder}
        isDeletingOrder={props.isDeletingOrder}
        toastOrderMessage={props.toastOrderMessage}
        isOrderRequestSuccessful={props.isOrderRequestSuccessful}
        isOrderRequestCompleted={props.isOrderRequestCompleted}
        onHideMiniPlayer={props.onHideMiniPlayer}
        isPlayingRecording={props.isPlayingRecording}
        //onHalfHourEpgUpdate={props.onHalfHourEpgUpdate}
        isUpdatingChannelData={props.isUpdatingChannelData}
        isSortBySeason={props.isSortBySeason}
        allSENumsAvailable={props.allSENumsAvailable}
        isFullScreenInfoOpen={props.isFullScreenInfoOpen}
        onToggleSubtitle={props.onToggleSubtitle}
        isChannelBarInfoOpen={props.isChannelBarInfoOpen}
        onChannelBarInfo={props.onChannelBarInfo}
        onFullScreenInfoOpen={props.onFullScreenInfoOpen}
        isRecordingScreenOpen={props.isRecordingScreenOpen}
        isOptionsOpen={props.isOptionsOpen}
        isSelectedRecordingOpen={props.isSelectedRecordingOpen}
        isFullScreenOptionsOpen={props.isFullScreenOptionsOpen}
        onConnectivityChange={props.onConnectivityChange}
        onConnLossInterval={props.onConnLossInterval}
        connTimeOut={props.connTimeOut}
        isLogOutSuccess={props.isLogOutSuccess}
        allstates={props.allstates}
        prevStateIsPlaying={props.prevStateIsPlaying}
        onSuspendLiveTV={props.onSuspendLiveTV}
        keyMap={props.keyMap}
        boxModel={props.boxModel}
        statusCode={props.statusCode}
        resetAppState={props.resetAppState}
        alertEAS={props.alertEAS}
        messageEAS={props.messageEAS}
        clearEAS={props.clearEAS}
        urlEAS={props.urlEAS}
        authError={props.authError}
        generalError={props.generalError}
        responseError={props.responseError}
        subscriberData={props.subscriberData}
        prevPlayerState={props.prevPlayerState}
        firstFullLoadComplete={props.firstFullLoadComplete}
        onUpdateRestartAvailablity={props.onUpdateRestartAvailablity}
        lightweightVersion={props.lightweightVersion}
    />
);

const mapStateToProps = (state) => ({
    lightweightVersion: state.main.lightweightVersion,
    alertEAS: state.main.alertEAS,
    messageEAS: state.main.messageEAS,
    urlEAS: state.main.urlEAS,
    boxModel: state.main.boxModel,
    keyMap: state.main.keyMap,
    allstates: state,
    isPlayingRecording: state.main.isPlayingRecording,
    currentPage: state.main.currentPage,
    currentChannel: state.main.currentChannel,
    channels: state.main.channels,
    channelList: state.main.channelList,
    isPlayerShowing: state.main.isPlayerShowing,
    isPlaying: state.main.isPlaying,
    isSubtitleToggled: state.main.isSubtitleToggled,
    isFullScreen: state.main.isFullScreen,
    halfHourLoad: state.main.halfHourLoad,
    isTicketScreenOpen: state.guide.isTicketScreenOpen,
    isRecordingToastOpen: state.guide.isRecordingToastOpen,
    isPostingOrder: state.guide.isPostingOrder,
    isUpdatingOrder: state.guide.isUpdatingOrder,
    isDeletingOrder: state.guide.isDeletingOrder,
    toastOrderMessage: state.guide.toastOrderMessage,
    isOrderRequestCompleted: state.guide.isOrderRequestCompleted,
    isOrderRequestSuccessful: state.guide.isOrderRequestSuccessful,
    isUpdatingChannelData: state.main.isUpdatingChannelData,
    isSortBySeason: state.myshows.isSortBySeason,
    allSENumsAvailable: state.myshows.allSENumsAvailable,
    isChannelBarInfoOpen: state.guide.isChannelBarInfoOpen,
    isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
    isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
    isOptionsOpen: state.guide.isOptionsOpen,
    isSelectedRecordingOpen: state.myshows.isSelectedRecordingOpen,
    isFullScreenOptionsOpen: state.main.isFullScreenOptionsOpen,
    connTimeOut: state.main.connTimeOut,
    isLogOutSuccess: state.main.isLogOutSuccess,
    prevStateIsPlaying: state.main.prevStateIsPlaying,
    authError: state.main.authError,
    generalError: state.main.generalError,
    responseError: state.main.responseError,
    subscriberData: state.login.subscriberData,
    prevPlayerState: state.main.prevPlayerState,
    firstFullLoadComplete: state.main.firstFullLoadComplete,
});

export const mapDispatchToProps = (dispatch) => ({
    onUpdateRestartAvailablity: () => dispatch(updateRestartAvailablity()),
    onLoadChannels: (guideStartTime, guideEndTime) => dispatch(loadChannels(guideStartTime, guideEndTime)),
    //onHalfHourEpgUpdate: (channels) => dispatch(halfHourEpgUpdate(channels)),
    onChangeChannel: (channel, globalProgramIndex) => dispatch(changeChannel(channel, globalProgramIndex)),
    onSetChannelList: (channelList) => dispatch(setChannelList(channelList)),
    onSetChannels: (channels) => dispatch(setChannels(channels)),
    onPlayLiveTV: () => dispatch(playLiveTV()),
    onPauseLiveTV: (pausedChannel) => dispatch(pauseLiveTV(pausedChannel)),
    onResumeLiveTV: () => dispatch(resumeLiveTV()),
    onStopLiveTV: () => dispatch(stopLiveTV()),
    onToggleSubtitle: (isSubtitleToggled) => dispatch(toggleSubtitle(isSubtitleToggled)),
    onToggleHalfHourLoad: (isLoaded) => dispatch(toggleHalfHourLoad(isLoaded)),
    onToggleFullscreen: (isFullscreen) => dispatch(toggleFullScreen(isFullscreen)),
    toggleTicketScreen: (recVar) => dispatch(toggleTicketScreen(recVar)),
    toggleRecordingToast: (isOpen) => dispatch(toggleRecordingToast(isOpen)),
    onHideMiniPlayer: () => dispatch(hideMiniPLayer()),
    onChannelBarInfo: (isChannelBarInfoOpen) => dispatch(onChannelBarInfo(isChannelBarInfoOpen)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
    onConnectivityChange: (conType, isConnected) => dispatch(connectivityChange(conType, isConnected)),
    onConnLossInterval: (connTimeOut) => dispatch(connLossInterval(connTimeOut)),
    onSuspendLiveTV: (isPlayerShowing, isPlaying) => dispatch(suspendLiveTV(isPlayerShowing, isPlaying)),
    resetAppState: () => dispatch(resetAppState()),
    clearEAS: () => dispatch(clearEAS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
