import React from 'react';
import { connect } from 'react-redux';
import WebNavigator from './WebNavigator';

const WebNavigatorContainer = (props) => {
    //console.log(props);
    return (
        <WebNavigator
            isLoadingOnLogin={props.isLoadingOnLogin}
            isLoginSuccess={props.isLoginSuccess}
            authError={props.authError}
            generalError={props.generalError}
        />
    );
};

const mapStateToProps = (state) => ({
    isLoadingOnLogin: state.login.isLoadingOnLogin,
    isLoginSuccess: state.login.isLoginSuccess,
    authError: state.main.authError,
    generalError: state.main.generalError,
});

export const mapDispatchToProps = (dispatch) => ({
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(WebNavigatorContainer);
