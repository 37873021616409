import React, { Component } from 'react';
//import { Animated, DeviceEventEmitter, Dimensions, StyleSheet, Text, View } from 'react-native';
import SeasonRecord from './Containers/SeasonRecordContainer';
import SeasonMyTicket from './Containers/SeasonMyTicketContainer';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
//import { LinearTextGradient } from 'react-native-text-gradient';
import { FSProgramIndex } from '../../utils/FSProgramIndex';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import CancelMyTicket from './Containers/CancelMyTicketContainer';

export const Item = ({ text, isSelected, onSelection }) => {
    return (
        // <View style={isSelected ? styles.itemSelected : styles.item}>
        //     <Text style={isSelected ? styles.itemSelectedText : styles.itemText}>{text}</Text>
        // </View>
        <div onClick={onSelection} className={css(styles.OptionItem)}>
            {text}
        </div>
    );
};

export default class Options extends Component {
    constructor(props) {
        super(props);

        const { pageData, previewChannelIndex, previewProgramIndex } = this.props;

        this.state = {
            selectedIndex: 0,
            //bounceValue: new Animated.Value(yScale(240)),
            //isRecordedSingle: false,
            //isRecordedSeries: false,
            selectedAssetInfo: null,
            isMovie: false,
            restartResource: null,

            selectedProgram: pageData[previewChannelIndex].programs[previewProgramIndex],
            currentChannel: pageData[previewChannelIndex],
        };
    }

    componentDidMount = () => {
        // Animated.spring(
        //     this.state.bounceValue,
        //     {
        //         toValue: 0,
        //         velocity: 3,
        //         tension: 2,
        //         friction: 8,
        //         useNativeDriver: true
        //     }
        // ).start();

        //this._optionsEmitter = DeviceEventEmitter.addListener('onKeyDown', this._listenerFunction);

        if (this.state.selectedProgram.show_type !== 'MO' && this.state.selectedProgram.series_id !== '') {
            //only run this if this is not a movie
            this.setState({
                selectedIndex: 0,
                selectedAssetInfo: this.state.selectedProgram,
            });
        } else if (
            (this.state.selectedProgram.show_type === 'MO' || this.state.selectedProgram.show_type === 'OT') &&
            this.state.selectedProgram.series_id === ''
        ) {
            let selectedAssetInfo = this.state.selectedProgram;
            this.props.assetData.forEach((obj) => {
                //checks if that was scheduled before
                if (obj.schedule_id && obj.schedule_id.toString() === this.state.selectedProgram.schedule_id.toString()) {
                    if (obj.type === 'single') {
                        selectedAssetInfo = obj;
                    }
                }
            });
            this.setState({
                isMovie: true,
                selectedIndex: 0,
                selectedAssetInfo: selectedAssetInfo,
            });
        }

        this.setRestartAvailibility();
    };

    currentChannel = async (chNumber) => {
        let ch = this.props.channels.filter((val, i) => {
            return val.custom_channel_number === chNumber;
        });
        return (await (ch && ch[0])) ? ch[0] : null;
    };

    _listenerFunction = (key) => {
        const { keyMap } = this.props;
        const programUnavailable = this.state.selectedProgram.show_type === '';
        if (!this.props.isTicketScreenOpen && !this.props.isRecordingScreenOpen && !this.props.connTimeOut) {
            switch (key.keyCode) {
                // changing state of selectedIndex based on selection
                case keyMap.up: //up
                    if (!this.props.lightweightVersion && !programUnavailable) {
                        this.state.selectedIndex > 0 && this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                    }
                    break;
                case keyMap.down: //down
                    if (!this.props.lightweightVersion && !programUnavailable) {
                        if (
                            (!this.state.isRecordedSeries && !this.state.isMovie && this.state.selectedIndex < 2) ||
                            ((this.state.isRecordedSeries || this.state.isMovie) && this.state.selectedIndex < 1)
                        ) {
                            this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                        }
                    }
                    break;
                case keyMap.select: //Select
                    switch (this.state.selectedIndex) {
                        case 0: //watch live TV
                            let chNumber = this.props.currentChannel.custom_channel_number;
                            this.currentChannel(chNumber).then((ourChannel) => {
                                FSProgramIndex(moment().unix() * 1000, ourChannel.programs).then((globalProgramIndex) => {
                                    this.props.onChangeChannel(ourChannel, globalProgramIndex);
                                    //this.props.resetGuideDataToLivePrograms(); just resetting in componentDidUpdate of schedule for now
                                    this.props.onToggleFullScreen(true);
                                });
                            });
                            break;
                        case 1:
                            // console.log("props", this.props)
                            //needs some conditions here to diff if series or movie
                            if (!this.state.isMovie && !this.props.isTicketScreenOpen) {
                                // console.log("should be called for series")
                                this.props.getChannels(this.state.selectedProgram.series_id, this.state.selectedProgram.source_id);
                                this.props.toggleTicketScreen(true); //case for series
                            } else if (this.state.isMovie && !this.props.isRecordingScreenOpen) {
                                // console.log("should be called for MO or OT")
                                this.props.toggleRecordingScreen(true); //case for movies
                            }
                            break;
                        case 2:
                            !this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(true); //To Do - should be replaced to true
                            break;
                        default:
                            break;
                    }
                    break;
                case keyMap.back: //close options before going to Guide(works on i and Back button)
                    if (!this.props.isRecordingScreenOpen && !this.props.isTicketScreenOpen) {
                        this.closeOptions();
                    }
                    break;
                default:
                    break;
            }
        }
    };

    setRestartAvailibility = () => {
        // const startTime = this.state.selectedProgram.unix_start_time;
        // const endTime = this.state.selectedProgram.unix_end_time;
        //const restart_buffer = this.state.currentChannel.restart_buffer

        // const startTrimmed = startTime && +startTime.toString().slice(0, startTime.toString().length -3); //remove last three 0s
        // const endTrimmed = endTime && +endTime.toString().slice(0, endTime.toString().length -3);
        //const withinBuffer = startTrimmed && startTrimmed > screenProps.currentTime.unix() - (restart_buffer * 60) && endTrimmed < screenProps.currentTime.unix();
        const restartResource = this.props.eventListWithCatchup[
            this.props.eventListWithCatchup.findIndex((cur) => {
                return cur.ext_id === this.state.selectedProgram.schedule_id;
            })
        ];
        const restartAvailable = restartResource; // && withinBuffer
        //if (restartAvailable && !this.state.restartAvailable) { //this.props.isRestartAvailable
        this.setState({
            selectedIndex: restartAvailable ? this.getOptionItems().length - 2 : 0, //can also run check when setting the recording status and if not preset to Reset, then preset to recstatus
            restartAvailable: restartAvailable,
            restartResource: restartResource,
        });

        // } else if (!restartAvailable && this.state.restartAvailable) {
        //     this.setState({
        //         selectedIndex: 0,
        //         restartAvailable: false
        //     })
        // }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.generalError && this.props.generalError) {
            this.props.isOptionsOpen && this.props.toggleOptionsMenu(false);
        }
        if (this.state.restartAvailable && !prevState.restartAvailable) {
            this.setRestartAvailibility();
        }
    };

    componentWillUnmount() {
        // console.log('options container unmounting', this.props)
        this.props.toggleCancelTicketScreen(false);
        this._optionsEmitter && this._optionsEmitter.remove();
    }

    closeOptions() {
        // Animated.timing(
        //     this.state.bounceValue,
        //     {
        //         toValue: yScale(240),
        //         duration: 150,
        //         useNativeDriver: true
        //     }
        // ).start(
        this.props.onOptionClose(true);
        //);
    }

    launchRestartProgram() {
        if (this.props.restartProgramInProgress && this.props.restartProgram.schedule_id === this.state.selectedProgram.schedule_id) {
            this.closeOptions();
            console.log('restart not available, returning to lilve channel');
        } else {
            if (this.props.isDeviceLimitReached) {
                //still getting/setting restart, will not actually play until user hits force retry
                this.props.onClearRestart();
                this.props.onSetPendingRestartProgram(this.state.selectedProgram, this.state.restartResource, this.state.currentChannel);
                this.closeOptions();
            } else {
                this.props.onClearRestart();
                window.plugin.fireInit();
                // console.log("selected", this.state.selectedProgram)
                this.props.onStartRestartProgram(this.state.selectedProgram, this.state.restartResource, this.state.currentChannel);
                this.closeOptions();
                //this.props.onToggleFullScreen(true);
            }
        }
    }

    channelSelection = () => {
        this.props.restartProgramInProgress && this.props.onClearRestart();
        let chNumber = this.state.currentChannel.custom_channel_number;
        this.currentChannel(chNumber).then((ourChannel) => {
            FSProgramIndex(moment().unix() * 1000, ourChannel.programs).then((globalProgramIndex) => {
                this.props.onChangeChannel(ourChannel, globalProgramIndex);
                //this.props.onToggleFullScreen(true);
                this.props.resetGrid();
            });
        });
        this.closeOptions();
    };

    getOptionItems() {
        //determine which menu items to display
        const { isMovie, restartAvailable, selectedProgram } = this.state;
        const recordedSingleText = selectedProgram.recordedSingle ? 'Modify recording' : `Record this program`;
        const recordedSeriesText = selectedProgram.recordedSeries ? 'Modify series recording' : 'Create series recording';
        //console.log(this.props.restartProgramInProgress);
        const liveProgram =
            +this.state.selectedProgram.unix_start_time / 1000 < this.props.currentTime.unix() &&
            +this.state.selectedProgram.unix_end_time / 1000 > this.props.currentTime.unix();
        //console.log(this.state.selectedProgram.schedule_id);
        const restartTVText =
            this.props.restartProgramInProgress && this.props.restartProgram.schedule_id === this.state.selectedProgram.schedule_id
                ? 'Resume restarted program'
                : 'Restart this program';
        const programUnavailable = this.state.selectedProgram.show_type === '' && this.state.currentChannel.type !== 'local';
        const isMusicChannel = this.state.currentChannel.type === 'music';
        const isLocalChannel = this.state.currentChannel.type === 'local';
        //const isRestartAvailable = this.state.currentChannel.restart_time_limit > 0;
        const items = [];
        items.push(
            <Item
                text={!liveProgram ? 'Watch Live TV' : 'Watch Now'}
                onSelection={() => this.channelSelection()}
                isSelected={this.state.selectedIndex === 0}
                key={0}
            />
        );
        if (!isMovie && !this.props.lightweightVersion && !programUnavailable && !isMusicChannel && !isLocalChannel) {
            items.push(
                <Item
                    onSelection={() => {
                        this.props.getChannels(this.state.selectedProgram.series_id, this.state.selectedProgram.source_id);
                        this.props.toggleTicketScreen(true); //case for series
                    }}
                    text={recordedSeriesText}
                    isSelected={this.state.selectedIndex === 1}
                    key={1}
                />
            );
        }
        if (isMovie) {
            if (!this.props.lightweightVersion && !programUnavailable && !isMusicChannel && !isLocalChannel) {
                items.push(
                    <Item
                        onSelection={() => this.props.toggleRecordingScreen(true)}
                        text={recordedSingleText}
                        isSelected={this.state.selectedIndex === 1}
                        key={2}
                    />
                );
            }
        } else {
            if (!selectedProgram.recordedSeries && !this.props.lightweightVersion && !programUnavailable && !isMusicChannel && !isLocalChannel) {
                items.push(
                    <Item
                        onSelection={() => this.props.toggleRecordingScreen(true)}
                        text={recordedSingleText}
                        isSelected={this.state.selectedIndex === 2}
                        key={3}
                    />
                );
            }
        }
        if (restartAvailable && !this.props.lightweightVersion && !programUnavailable) {
            // && isRestartAvailable --is this check needed? (name should be changed if so)
            items.push(
                <Item
                    onSelection={() => this.launchRestartProgram()}
                    text={restartTVText}
                    isSelected={isMovie || selectedProgram.recordedSeries ? this.state.selectedIndex === 2 : this.state.selectedIndex === 3}
                    key={4}
                />
            );
        }
        return items;
    }

    render() {
        const { isMovie, selectedProgram, currentChannel } = this.state; ///isRecordedSeries, isRecordedSingle,
        //const programUnavailable = this.state.selectedProgram.show_type === "";

        // const recordedSingleText = selectedProgram.recordedSingle? 'Modify recording' : `Record this program`;
        // const recordedSeriesText = selectedProgram.recordedSeries? 'Modify MyTicket for this series' : 'Create a MyTicket for this series';
        //const { height, width } = Dimensions.get('window');
        let chNumberName = currentChannel.custom_channel_number + ' ' + currentChannel.custom_name;
        let showStart = selectedProgram && selectedProgram.unix_start_time;
        let day = `${moment(showStart).format('ddd M/D LT')}`;
        let dayNumberName = `${day} ${chNumberName}`;

        const optionItems = this.getOptionItems();
        return (
            <motion.div
                key="optionContainer"
                initial={{ y: '110%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                exit={{ y: '110%', opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={css(styles.motionContainer)}>
                {!this.props.isRecordingScreenOpen && !this.props.isTicketScreenOpen ? (
                    <>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={css(styles.Container)}>
                            <FontAwesomeIcon onClick={this.props.onOptionClose} className={css(styles.ExitButton)} icon="times" />
                            <div className={css(styles.Header)}>
                                <span className={css(styles.HeaderText)}>
                                    {selectedProgram.long_title !== '' ? selectedProgram.long_title : 'Program Data Unavailable'}
                                </span>
                            </div>
                            <div className={css(styles.SubHeader)}>
                                {/* {
                            this.state.selectedProgram.show_type === 'SE' && this.state.selectedProgram.se_number === '' || this.state.selectedProgram.ep_number === '' ?
                                this.state.selectedProgram.ep_name ? this.state.selectedProgram.ep_name : null 
                            :
                                this.state.selectedProgram.show_type === 'SE' && this.state.selectedProgram.se_number !== '' && this.state.selectedProgram.ep_number !== '' ?
                                'S' + this.state.selectedProgram.se_number + ' E' + this.state.selectedProgram.ep_number + " '" + this.state.selectedProgram.ep_name + "'"
                            : 
                                null
                        } */}
                            </div>
                            <div className={css(styles.Options)}>
                                {/* <Item text={this.props.isRecordButton ? "Watch Live TV" : "Watch Now"} onClick={this.channelSelection()} isSelected={this.state.selectedIndex === 0} /> */}
                                {optionItems}
                            </div>
                        </div>
                    </>
                ) : this.props.isTicketScreenOpen && !this.props.isRecordingScreenOpen ? (
                    this.props.isCancelTicketScreenOpen ? (
                        <CancelMyTicket
                            cameFromGuide={true}
                            recording={this.state.selectedProgram}
                            cancel={this.props.toggleRecordingScreen}
                            isRecordedSeries={this.state.selectedProgram.recordedSeries}
                            onOptionClose={this.props.onOptionClose}
                        />
                    ) : (
                        <SeasonMyTicket
                            channelInfo={this.state.currentChannel}
                            programIndex={this.props.programIndex}
                            postRecording={this.props.postRecording}
                            selectedProgram={this.state.selectedProgram}
                            channelsList={this.props.channelsList}
                            isLoadingChannels={this.props.isLoadingChannels}
                            isRecordedSeries={selectedProgram.recordedSeries}
                            selectedAssetInfo={this.state.selectedAssetInfo}
                            onOptionClose={this.props.onOptionClose}
                            isCurrentlyMyShows={false}
                        />
                    )
                ) : (
                    <SeasonRecord
                        channelInfo={this.state.currentChannel}
                        programIndex={this.props.programIndex}
                        postRecording={this.props.postRecording}
                        selectedProgram={this.state.selectedProgram}
                        isRecordedSingle={selectedProgram.recordedSingle}
                        selectedAssetInfo={this.state.selectedAssetInfo}
                        onOptionClose={this.props.onOptionClose}
                        dayNumberName={dayNumberName}
                        isMovie={isMovie}
                    />
                )}
            </motion.div>
        );
    }
}

const styles = StyleSheet.create({
    motionContainer: {
        height: '100%',
        width: '100%',
    },
    Container: {
        //alignSelf:'flex-end',
        // width: '20em',
        minHeight: '8em',
        position: 'absolute',
        // position: '-webkit-sticky',
        // position: 'sticky',
        //alignSelf: 'flex-end',
        //display: 'flex',
        //flexDirection: 'column',
        backgroundColor: '#202020',
        //justifyContent: 'center',
        //alignItems: 'flex-start',
        //paddingLeft: '4em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'white',
        //borderRadius: '5px',
        //marginTop:'10em',
        //paddingLeft: '4em',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        //marginTop: 'auto',
        marginBottom: '3em',
        //padding: '0 1em'
        width: '17em',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    OptionItem: {
        // cursor: 'pointer',
        // //margin: '3px',
        // //padding: '4px',
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        padding: '.6em',
    },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        // color: '#61dafb',
        //fontSize:'1.3em',
        // padding: '.1em',
        marginLeft: '4em',
        //borderColor: 'red',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        width: '40em',
    },
    HeaderText: {
        fontSize: '1.3em',
    },
    SubHeader: {
        marginLeft: '4em',
        //marginTop: '2em',
        color: 'white',
        //margin: '1em 2em',
        padding: '.1em',
        fontSize: '1.3em',
    },
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
});
//color: '#29d'
// const styles = StyleSheet.create({
//     optionsContainer: {
//         position: 'absolute',
//         width: xScale(650),
//         bottom: 0,
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignSelf: 'center',
//         backgroundColor: '#1B1B1B',
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(10),
//     },
//     optionsHeader: {
//         backgroundColor: '#1B1B1B',
//         alignSelf: 'stretch',
//         paddingHorizontal: xScale(25),
//         paddingVertical: yScale(5),
//         justifyContent: 'center',
//         // borderColor: "red",
//         // borderWidth: 2
//     },
//     itemListContainer: {
//         flex: 1,
//         backgroundColor: '#171717',
//         alignSelf: 'stretch',
//         paddingVertical: yScale(5),
//         bottom: yScale(5),
//     },
//     item: {
//         flex: 1,
//         paddingVertical: yScale(6)
//     },
//     itemText: {
//         fontFamily: 'OpenSans-Regular',
//         color: '#9B9B9B',
//         fontSize: fontScale(22),
//         paddingHorizontal: xScale(20),
//         paddingVertical: yScale(5),
//         borderWidth: 2,
//         borderColor: 'transparent',
//     },
//     itemSelected: {
//         flex: 1,
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(5),
//     },
//     itemSelectedText: {
//         fontFamily: 'OpenSans-Regular',
//         fontWeight: '600',
//         color: '#fff',
//         fontSize: fontScale(22),
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(4),
//         borderWidth: xScale(2),
//         borderColor: '#fff',
//         backgroundColor: '#5a5a5a'
//     }
// });
