import React, { Component } from 'react';

// Components
import TitleContainer from './Containers/TitleContainer';
import ScheduleContainer from './Containers/ScheduleContainer';
import Options from './Containers/OptionsMenuContainer';
import RecordingToast from './RecordingToast';
import GuideSearch from './GuideSearch';

// Services
import { closestChNumber } from '../../utils/ClosestChNumber';
import { StyleSheet, css } from 'aphrodite';
import NavBar from '../Common/Containers/NavBarContainer';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { getPageData } from '../../utils/getPageData';

export default class Guide extends Component {
    constructor() {
        super();
        this.state = {
            selectedChannelIndex: 0,
            previousChannelIndex: 0,
            isStopped: false,
            isOptionOpen: false,
            isProgressBarOpen: false,
            //bounceValue: new Animated.Value(300),
            showing: false,
            closePB: false,
            channelNumber: null,
            tempChannelHolder: '',
            startIndex: null,
            isRecordButton: false, //needed, so when press on Record - options come up with Record selected,
            navUtility: false,
            isSearchOpen: false,
        };
        // this.numberPad={7:"0",8:"1",9:"2",10:"3",11:"4",12:"5",13:"6",14:"7",15:"8",16:"9"},//numberPad codes 0..9
        // this.tempString = "";
        this.keyInputInterval = null;
        // this.scheduleContainerRef = React.createRef();
    }

    // shouldComponentUpdate(nextProps) {
    //     if (nextProps.channels !== this.props.channels) {
    //         //console.log('THIS.PROPS.CHANNELS HAS CHANGED');
    //         setTimeout(() => {
    //             this.refreshCurrentGuidePrograms()
    //         },100);
    //     }
    //     //return nextProps.currentPage === 'Guide';
    //     return true
    // }

    componentDidMount() {
        document.addEventListener('keyup', this._handleKeyPress);
        document.addEventListener('wheel', this._handleWheelScroll);
        // console.log(this.props.subscriberData.subDeviceInfo)
        this.props.onPlayLiveTV();
        // bugsnag.leaveBreadcrumb("<Guide /> componentDidMount", {
        //     type: 'navigation',
        //     component: "<Guide />",
        //     subDeviceInfo: this.props.subscriberData.subDeviceInfo
        // })
        //if (this.props.currentPage === 'Guide'){
        // Key Event Listener
        //this.guideTimeOut();
        //setting global variables for Guide component;
        //this.previousPage = this.props.previousPage;
        // TODO: line below is attempting to store all the recordings schedule ids, check on the usage
        // this.props.postOrderStatus && this.props.postOrderStatus == "success" && StorageService.setItem('Recordings', JSON.stringify(this.props.allRecordings))
        //}
        //this.props.onChangeProgramStart(0);
        //this.resetGrid();
        if (this.props.onUpcomingMenu) {
            this.props.updateOnUpcomingMenu(false);
        }
    }

    logicToSelectChannel = (selectedChannel) => {
        //logic to change station by channel number
        if (selectedChannel) {
            let startIndex = this.props.channelList.indexOf(selectedChannel.toString());
            this.setState(
                {
                    startIndex,
                },
                () => {
                    this.props.onChangeActiveRowIndex(0); //TO DO - may need more attention
                    this.getRowProgramsRatios(this.props.programIndex);
                    this.setState({
                        tempChannelHolder: '',
                    });
                }
            );
        }
    };

    resetGrid = () => {
        const momentObj = this.props.currentTime.clone();
        const newStartTime = momentObj
            .subtract(momentObj.minute() % 30, 'minutes')
            .subtract(momentObj.second(), 'seconds')
            .unix();

        //so we need to check to see if currentChannel.index is already between pagestartingindex + gridrows.
        //    If so we can just set the previewchannelindex to currentchannel index
        //    Else we need to set pagestartingIndex to currentchannel.index, others 0

        const pageChannelIndexes = this.props.pageData?.map((cur) => cur.channelIndex);
        let activeChannelIndexInPageData = pageChannelIndexes ? pageChannelIndexes.indexOf(this.props.currentChannel.channelIndex) : -1; //if index is not found or there's no PD at all this will be -1
        if (this.props.pageData && this.props.gridRows !== this.props.pageData.length) {
            //if gridRows have changed, we'll just reset;
            activeChannelIndexInPageData = -1;
        }
        if (activeChannelIndexInPageData > -1) {
            let pd = getPageData(
                this.props.pageStartingIndex,
                this.props.gridRows,
                this.props.allChannels,
                newStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                0
            );
            const gridVals = {
                pageData: pd,
                previewProgramStartTime: pd[0].programs[0].unix_start_time,
                pageStartTime: newStartTime,
                horizontalPageIndex: 0,
                previewProgramIndex: 0,
                previewChannelIndex: activeChannelIndexInPageData,
                pageStartingIndex: this.props.pageStartingIndex,
            };
            this.props.onGridNavigate('init', gridVals);
        } else {
            let pd = getPageData(
                this.props.currentChannel.channelIndex,
                this.props.gridRows,
                this.props.allChannels,
                newStartTime,
                this.props.eventListWithCatchup,
                this.props.assetDataProgramIDLookup,
                this.props.orderData,
                0
            );
            const gridVals = {
                pageData: pd,
                previewProgramStartTime: pd[0].programs[0].unix_start_time,
                pageStartTime: newStartTime,
                horizontalPageIndex: 0,
                previewProgramIndex: 0,
                previewChannelIndex: 0,
                pageStartingIndex: this.props.currentChannel.channelIndex,
            };
            this.props.onGridNavigate('init', gridVals);
        }
    };

    channelSelectorHelper = () => {
        let ourChannel;
        if (this.props.channelList && this.props.channelList.length > 0) {
            //ensures that data is loaded prior
            if (this.props.channelList.includes(this.state.tempChannelHolder)) {
                ourChannel = parseInt(this.state.channelNumber);
            } else if (
                this.state.channelNumber > parseInt(this.props.channelList[0]) &&
                this.state.channelNumber < parseInt(this.props.channelList[this.props.channelList.length - 1])
            ) {
                ourChannel = closestChNumber(this.props.channelList, this.state.channelNumber);
            } else if (this.state.channelNumber < parseInt(this.props.channelList[0])) {
                ourChannel = parseInt(this.props.channelList[0]);
            } else if (this.state.channelNumber > parseInt(this.props.channelList[this.props.channelList.length - 1])) {
                ourChannel = parseInt(this.props.channelList[this.props.channelList.length - 1]);
            }
            //now let's handle the channelChange
            this.setState({ channelNumber: ourChannel }, () => {
                this.logicToSelectChannel(ourChannel);
            });
        }
        return ourChannel;
    };

    // currentChannel = async (chNumber) => {
    //     let ch = this.props.channels.filter((val, i)=>{
    //         return val.custom_channel_number === chNumber;
    //     })
    //     return await (ch && ch[0]) ? ch[0] : null;
    // }

    // setChannel = (keyCode) => {
    //     if(this.tempString.length < 5){
    //         this.keyInputInterval && clearInterval(this.keyInputInterval);
    //         this.tempString += (this.numberPad[keyCode]);
    //         this.setState({
    //             channelNumber: parseInt(this.tempString),
    //             tempChannelHolder: this.tempString
    //         },()=>{
    //             this.keyInputInterval = setInterval(()=>{
    //                 clearInterval(this.keyInputInterval);
    //                 this.tempString = "";//clearing temp string for another number
    //                 //the following logic is to determing our channel
    //                 if(this.state.tempChannelHolder){
    //                     this.channelSelectorHelper();//finalized channel
    //                 }
    //             }, this.props.boxModel === 'Aminos' ? 4000 : 8000 )//after 2 seconds it has to find the program
    //         })
    //     }
    // }

    // this closes the options menu when a selection is confirmed in the second modal
    componentDidUpdate = (prevProps, prevState) => {
        /////////TO-DO: are any of these still needed???
        if (
            (prevProps.isRecordingScreenOpen === true && this.props.isRecordingScreenOpen === false) ||
            (prevProps.isTicketScreenOpen === true && this.props.isTicketScreenOpen === false)
        ) {
            this.closeOptions();
        }
        //to close Options we need to change isOptionsOpen
        if (!prevProps.isFullScreen && this.props.isFullScreen) {
            this.closeOptions();
            this.props.pausedChannel !== this.props.currentChannel.custom_channel_number && this.props.onPlayLiveTV();
        }
        if (!this.props.isFullScreen && prevProps.isFullScreen) {
            //from full screen to guide with miniplayer
            clearInterval(this.keyInputInterval);
            this.tempString = '';
            this.state.tempChannelHolder.length > 0 && this.setState({ tempChannelHolder: '' });
        }
        if (!this.props.isFullScreen && prevProps.isPlaying && !this.props.isPlaying) {
            this.timer60 && clearTimeout(this.timer60);
            this.timer120 && clearTimeout(this.timer120);
        } else if (prevProps.isFullScreen && !this.props.isFullScreen && this.props.isPlaying) {
            //start the timer again, when going from Full Screen to Guide
            // console.log("restarting timer")
            this.guideTimeOut();
        }
        if (prevProps.isFullScreen === false && this.props.isFullScreen === true) {
            this._guideEventEmitter && this._guideEventEmitter.remove();
            this._guideEventEmitter = null;
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener('keyup', this._handleKeyPress);
        document.removeEventListener('wheel', this._handleWheelScroll);
        this.closeOptions();
        //this.previousPage = null;
        this.keyInputInterval && clearInterval(this.keyInputInterval);
        this._guideEventEmitter && this._guideEventEmitter.remove();
    };

    closeOptions = () => {
        this.props.isOptionsOpen && this.props.toggleOptionsMenu(false);
        this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false);
        this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false);
    };

    closeNavUtility = () => {
        this.setState({ navUtility: false, tempChannelHolder: '' });
        this.tempString = '';
    };

    navUtilityEnter = () => {
        if (this.state.tempChannelHolder !== '') {
            this.channelSelectorHelper();
            this.setState({ navUtility: false, tempChannelHolder: '' });
            this.tempString = '';
        }
    };

    onOptionClose = (close) => {
        this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false); //precautions
        this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false); //precautions
        if (close) {
            this.setState({ isRecordButton: false }, () => {
                this.props.toggleOptionsMenu(false);
            });
        } else {
            this.props.toggleOptionsMenu(true); //opens options up again
        }
    };

    handleOpenGuideSearch = () => {
        this.setState({ isSearchOpen: true });
    };

    closeGuideSearch = () => {
        this.setState({ isSearchOpen: false });
    };

    logicToSelectChannelBySearch = (selectedChannel, show) => {
        //logic to change station by channel number
        //this props onToggleStationFocus
        try {
            //this.props.stationFocus && this.props.onToggleStationFocus(false);
            if (selectedChannel) {
                //method optimized by Kyle

                let startIndex = this.props.channelList.indexOf(selectedChannel.toString());
                const momentObj = moment();
                let newStartTime = momentObj
                    .subtract(momentObj.minute() % 30, 'minutes')
                    .subtract(momentObj.second(), 'seconds')
                    .unix();
                let horizontalPageIndex = 0;
                let previewChannelIndex = 0;
                let previewProgramIndex = 0;

                if (show) {
                    let grid_shift = Math.floor((show.unix_start_time / 1000 - newStartTime) / 7200); //7200 - 2hrs, grid_shift>0 - right, else left
                    horizontalPageIndex = horizontalPageIndex + grid_shift;
                    newStartTime = newStartTime + grid_shift * 7200; //seems to calculates properly
                }

                let pd = getPageData(
                    startIndex,
                    this.props.gridRows,
                    this.props.channels,
                    newStartTime,
                    this.props.eventListWithCatchup,
                    this.props.assetDataProgramIDLookup,
                    this.props.orderData,
                    horizontalPageIndex
                );
                let previewProgramStartTime = pd[0].programs[0].unix_start_time;

                if (show) {
                    previewProgramStartTime = show.unix_start_time;
                    for (let j = 0; j < pd[0].programs.length; j++) {
                        if (pd[0].programs[j].unix_start_time === show.unix_start_time) {
                            previewProgramIndex = j;
                            break;
                        }
                    }
                }
                const gridVals = {
                    pageData: pd,
                    previewProgramStartTime: previewProgramStartTime,
                    pageStartTime: newStartTime,
                    horizontalPageIndex: horizontalPageIndex,
                    previewProgramIndex: previewProgramIndex,
                    previewChannelIndex: previewChannelIndex,
                    pageStartingIndex: startIndex,
                };
                this.props.onGridNavigate('init', gridVals);
                // let newStateObj = {
                //     isSearchFieldOn: false
                // }
                // if (show) {
                //     newStateObj.showSearchedResultsModal = false
                // }
                this.setState({
                    isSearchOpen: false,
                });
            }
        } catch (error) {
            console.log('error: ', error);
            // bugSnagNotifierGeneral("Catch triggered" , "logicToSelectChannel in schedule", null, null, null, {errorMessage: {"errorMessage": error?.message || "error undefined"}});
        }
    };

    findThisShowInGrid = (show) => {
        if (show?.custom_channel_number) {
            this.logicToSelectChannelBySearch(show?.custom_channel_number);
            this.closeGuideSearch();
            return;
        }
        this.logicToSelectChannelBySearch(show.ch_number, show);
        //1.change to needed channel
        //2.move grid to needed page
    };

    // changeDay = (isLeft) => {
    //     this.scheduleContainerRef.current.changeDay(isLeft);
    // }

    // _handleWheelScroll = (event) => {
    //     const { isFullScreen, onToggleFullScreen, boxModel, activeRowIndex, changeProgressBarIndex, prBarIndex, onChangeChannel, currentPageData, currentPage, isRecordingScreenOpen, isTicketScreenOpen, programIndex, isPlaying, isChannelBarInfoOpen, connTimeOut, keyMap, authError, generalError } = this.props;
    //     if (event.deltaY < 0) { //up scroll
    //         if(!this.props.isOptionsOpen) { //Up
    //             if (activeRowIndex > 0 && !this.props.pageTransition) {//condition when it flips the page up
    //                 this.updateRowIndexesAndRatios(activeRowIndex - 1);
    //             } else if (activeRowIndex === 0 && this.props.guideAnimationsOff) {
    //                 this.updateRowIndexesAndRatios(currentPageData.length - 1);
    //             }
    //         }
    //     } else { //down scroll
    //         if (!this.props.isOptionsOpen && !(isFullScreen && this.props.isFullScreenInfoOpen)) { //Down
    //             if (activeRowIndex < currentPageData.length - 1 && !this.props.pageTransition) {//stops update of indexes during page animation
    //                 this.updateRowIndexesAndRatios(activeRowIndex + 1);
    //             } else if (activeRowIndex === currentPageData.length - 1 && this.props.guideAnimationsOff) {
    //                 this.updateRowIndexesAndRatios(0);
    //             }
    //         }
    //     }
    // }

    handleOptionsOverlayClick = () => {
        this.props.isOptionsOpen && this.props.toggleOptionsMenu(false);
        this.props.isTicketScreenOpen && this.props.toggleTicketScreen(false);
        this.state.isSearchOpen && this.setState({ isSearchOpen: false });
    };

    render() {
        //const { currentTime, guideStartUnix } = this.props.screenProps;
        const {
            isFullScreen,
            horizontalPage,
            programIndex,
            onSetProgramIndex,
            onUpdatePageData,
            currentPageData,
            isFullScreenInfoOpen,
            isOptionsOpen,
            currentTime,
            guideStartUnix,
            searchMap,
        } = this.props;
        const { isRecordButton } = this.state;
        // console.log("guide: ", this.props)

        return (
            <>
                <NavBar />
                {
                    // isOptionsOpen &&
                    // <div onClick={this.handleOptionsOverlayClick} className={css(styles.OptionsOverLay)}>
                    //     <div className={css(styles.OptionsOverLayRelative)}>
                    //         <Options
                    //             //channelInfo={currentChannel}
                    //             programIndex={programIndex}
                    //             //header={currentPageData[activeRowIndex].programs[programIndex] && currentPageData[activeRowIndex].programs[programIndex].long_title}
                    //             //selectedProgram={currentPageData[activeRowIndex].programs[programIndex]}
                    //             isRecordButton = {isRecordButton}
                    //             onOptionClose = {this.onOptionClose}
                    //             channelsList={this.props.channelsList}
                    //             resetGuideDataToLivePrograms={this.resetGuideDataToLivePrograms}
                    //             keyMap={this.props.keyMap}
                    //             currentTime={currentTime}
                    //         />
                    //     </div>
                    // </div>
                }
                <div className={css(styles.MainContainer)}>
                    {this.props.isRecordingToastOpen && (
                        <RecordingToast
                            isPostingOrder={this.props.isPostingOrder}
                            toggleRecordingToast={this.props.toggleRecordingToast}
                            isUpdatingOrder={this.props.isUpdatingOrder}
                            isDeletingOrder={this.props.isDeletingOrder}
                            toastOrderMessage={this.props.toastOrderMessage}
                            isOrderRequestSuccessful={this.props.isOrderRequestSuccessful}
                            isOrderRequestCompleted={this.props.isOrderRequestCompleted}
                        />
                    )}
                    {/* {
                    isOptionsOpen &&
                    <Options
                        //channelInfo={currentChannel}
                        programIndex={programIndex}
                        //header={currentPageData[activeRowIndex].programs[programIndex] && currentPageData[activeRowIndex].programs[programIndex].long_title} 
                        //selectedProgram={currentPageData[activeRowIndex].programs[programIndex]}
                        isRecordButton = {isRecordButton} 
                        onOptionClose = {this.onOptionClose}
                        channelsList={this.props.channelsList}
                        resetGuideDataToLivePrograms={this.resetGuideDataToLivePrograms}
                        keyMap={this.props.keyMap}
                        currentTime={currentTime}/>
                    } */}

                    <AnimatePresence>
                        {isOptionsOpen && (
                            <div onClick={this.handleOptionsOverlayClick} className={css(styles.OptionsOverLay)}>
                                {/* <div className={css(styles.OptionsOverLayRelative)}> */}
                                <Options
                                    resetGrid={this.resetGrid}
                                    //channelInfo={currentChannel}
                                    programIndex={programIndex}
                                    //header={currentPageData[activeRowIndex].programs[programIndex] && currentPageData[activeRowIndex].programs[programIndex].long_title}
                                    //selectedProgram={currentPageData[activeRowIndex].programs[programIndex]}
                                    isRecordButton={isRecordButton}
                                    onOptionClose={this.onOptionClose}
                                    channelsList={this.props.channelsList}
                                    resetGuideDataToLivePrograms={this.resetGuideDataToLivePrograms}
                                    keyMap={this.props.keyMap}
                                    currentTime={currentTime}
                                />
                                {/* </div> */}
                            </div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {this.state.isSearchOpen && (
                            <div onClick={this.handleOptionsOverlayClick} className={css(styles.OptionsOverLay)}>
                                {/* <div className={css(styles.OptionsOverLayRelative)}> */}
                                <GuideSearch
                                    onSearchClose={() => this.closeGuideSearch()}
                                    ////////// from SearchField ->
                                    eventListWithCatchup={this.props.eventListWithCatchup}
                                    allChannels={this.props.channels}
                                    logicToSelectChannelBySearch={this.logicToSelectChannelBySearch}
                                    //searchMapResults={this.handleSearchResults}
                                    searchMap={searchMap}
                                    currentTime={currentTime}
                                    ////////// from SearchResultsModal ->
                                    findThisShowInGrid={this.findThisShowInGrid}
                                    //searchResults={this.state.searchResults}
                                />
                                {/* </div> */}
                            </div>
                        )}
                    </AnimatePresence>
                    <>
                        <TitleContainer currentTime={this.props.currentTime} onOptionClose={this.onOptionClose} resetGrid={this.resetGrid} />
                        <ScheduleContainer
                            resetGrid={this.resetGrid}
                            handleProgramClick={(program) => this.handleProgramClick(program)}
                            pageDate={this.props.programIndex}
                            // ref={this.scheduleContainerRef}
                            currentTime={currentTime}
                            allChannels={this.props.channels}
                            channels={this.props.currentPageData}
                            horizontalPage={horizontalPage}
                            // previousChannel={channelList[previousChannelIndex]}
                            // selectedChannel={channelList[selectedChannelIndex]}
                            isOptionsOpen={isOptionsOpen}
                            isFullScreen={isFullScreen}
                            programIndex={programIndex}
                            programIndexCounter={this.props.programIndexCounter}
                            isProgramIndexCounterSet={this.props.isProgramIndexCounterSet}
                            changeProgramIndex={(programIndex) => onSetProgramIndex(programIndex)}
                            activeRowIndex={this.props.activeRowIndex}
                            selectedChannelPrograms={
                                currentPageData && currentPageData[this.props.activeRowIndex] && currentPageData[this.props.activeRowIndex].programs
                            }
                            currentPage={this.props.currentPage}
                            isFullScreenInfoOpen={isFullScreenInfoOpen}
                            onUpdatePageData={onUpdatePageData}
                            onNextHorizontalPage={this.props.onNextHorizontalPage}
                            pageTransition={this.props.pageTransition}
                            onUpdatePageTransition={this.props.onUpdatePageTransition}
                            nextPageData={this.props.nextPageData}
                            onUpdateNextPageData={this.props.onUpdateNextPageData}
                            channelsCurrentStartIndex={this.props.channelsCurrentStartIndex}
                            channelsCurrentEndIndex={this.props.channelsCurrentEndIndex}
                            channelsStartTime={this.props.channelsStartTime}
                            channelsEndTime={this.props.channelsEndTime}
                            onUpdateChannelStartEndTimes={this.props.onUpdateChannelStartEndTimes}
                            onUpdateAnimatedValue={this.props.onUpdateAnimatedValue}
                            onUpdateChannelIndexes={this.props.onUpdateChannelIndexes}
                            pageAnimatedValue={this.props.pageAnimatedValue}
                            onChangeProgramStart={this.props.onChangeProgramStart}
                            onSetProgramIndexCounter={this.props.onSetProgramIndexCounter}
                            onPreviousHorizontalPage={this.props.onPreviousHorizontalPage}
                            guideStartUnix={guideStartUnix}
                            onUpdatePageChannels={this.props.onUpdatePageChannels}
                            isRecordingScreenOpen={this.props.isRecordingScreenOpen}
                            isTicketScreenOpen={this.props.isTicketScreenOpen}
                            tempChannelHolder={this.state.tempChannelHolder}
                            startIndex={this.state.startIndex}
                            channelNumber={this.state.channelNumber}
                            updateRowIndexesAndRatios={this.updateRowIndexesAndRatios}
                            onSetHorizontalPage={this.props.onSetHorizontalPage}
                            resetGuideDataToLivePrograms={this.resetGuideDataToLivePrograms}
                            guideEndPagesTimes={this.props.guideEndPagesTimes}
                            connTimeOut={this.props.connTimeOut}
                            authError={this.props.authError}
                            generalError={this.props.generalError}
                            keyMap={this.props.keyMap}
                            navUtility={this.state.navUtility}
                            boxModel={this.props.boxModel}
                            getProgramRatio={this.getProgramRatio}
                            getRowProgramsRatios={this.getRowProgramsRatios}
                            guideAnimationsOff={this.props.guideAnimationsOff}
                            handleOptionsOverlayClick={this.handleOptionsOverlayClick}
                            openGuideSearch={this.handleOpenGuideSearch}
                            isGuideSearchOpen={this.state.isSearchOpen}
                        />
                    </>
                </div>
            </>
        );
    }
}

//styles in vw
// const styles = StyleSheet.create({
//     MainContainer:{
//         display:'flex',
//         flexDirection: 'column',
//         flex: 1,
//         alignContent: 'stretch',
//         backgroundColor: '#404040',
//         minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder
//         alignItems: 'center',
//     },
//     FixedContainer: {
//         width: '80vw',
//         borderColor: 'yellow',
//         borderStyle: 'solid',
//         borderWidth: '3px',
//         height: 'calc(80vw * 0.6)',
//         display:'flex',
//         flexDirection: 'column'
//         //maxHeight: 'calc(100vh - 87px)', //this looked good, however, was unable to limit fontsize to match
//         //maxWidth: 'calc((100vh - 87px)/0.6)',
//     }
// })

//styles in em
const styles = StyleSheet.create({
    MainContainer: {
        '@media screen and (min-width: 1500px)': {
            //if above 1600px and it expands relative to the width of the viewport
            fontSize: '1.2em',
        },
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
        backgroundColor: '#050505',
        minHeight: 'calc(100vh - 81px)', //81px is height of topbar + its bottomborder
        alignItems: 'left',
        //paddingLeft: '4em',
        width: '90vw',
        minWidth: '56em',
        fontWeight: '300', //this is the default for the guide. must be set on child to overwrite
        //backgroundColor: 'blue'
        position: 'relative',
        marginLeft: '4em',
        marginRight: '4em',
    },
    OptionsOverLayRelative: {
        height: '100%',
        width: '100%',
        //position: 'relative'
    },
    OptionsOverLay: {
        position: 'absolute',
        width: '100%',
        minWidth: '66em',
        height: '100%',
        //height: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(1,1,1,0.7)',
    },
});
