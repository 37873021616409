import { restartStreamAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';
import { isDRMEnabled } from './LoginServices';

let errorObject = {};
let retryCount = 0;
let requestCopy = {}; //only used for Network Error
let resetTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export const getRestartStream = (program, restartResource) => {
    //export const getRestartStream = (program, catchupEventList) =>
    //console.log('getRestartStream', program, restartResource);

    cancel && cancel();
    let url = isDRMEnabled
        ? `live-events/${restartResource.id}/catchup-playback-sessions?stream_format=dash`
        : `live-events/${restartResource.id}/catchup-url?stream_format=dash`;
    return restartStreamAxios({
        method: isDRMEnabled ? 'POST' : 'GET',
        url: url,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    }).then((res) => {
        //return res.data.data;
        if (isDRMEnabled) {
            return {
                streamURL: res.data.data.playback_url,
                drmLicenseURL: res.data.data.wv_license_url,
            };
        } else {
            return {
                //this option for normal - not DRM enabled stream
                streamURL: res.data.data,
                drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${restartResource.id}/wv-license?auth_token=${restartStreamAxios.defaults.headers.common['X-Auth-Token']}`,
            };
        }
    });
};

//interceptors
restartStreamAxios.interceptors.request.use(
    (request) => {
        request.retries = 0;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        // console.log('getRestart request: ', request);
        resetTimeOut && clearTimeout(resetTimeOut);
        resetTimeOut = setTimeout(() => {
            cancel && cancel('Network Error');
        }, 15000); //if original timeout does not work
        return request;
    },
    (error) => {
        //console.log("getAssets request error: ", error)
        return error;
    }
);

restartStreamAxios.interceptors.response.use(
    (response) => {
        retryCount = 0;
        requestCopy = {};
        resetTimeOut && clearTimeout(resetTimeOut);
        cancel && (cancel = null); //null the cance function for successful req
        // console.log('getRestart response: ', response);
        return response;
    },
    (error) => {
        // console.log(error.message, error.response)
        resetTimeOut && clearTimeout(resetTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 || error.response.status === 404) &&
            retryCount > 0
        ) {
            //auth error!!! Kick them out!
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Check if we've maxed out the total number of retries
        if (retryCount >= config.retries) {
            // Reject with the error
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Increase the retry count
        retryCount += 1;
        // console.log(retryCount, config.retryDelay * retryCount);

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return restartStreamAxios(config);
        });
    }
);
