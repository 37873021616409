import { imageAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';
const CancelToken = axios.CancelToken;

let body = {};
let errorObject = {};

let cancelRequests = []; //an array of callbacks to cancel open requests
let cancelUpcomingRequests = [];
let block_upcoming_response = false;

const cancelRecordingImagesRequests = () => {
    if (cancelRequests.length > 0) {
        cancelRequests.forEach((cur) => {
            cur('fcancel'); //if we forcefully cancel - do not do retries
        });
        cancelRequests = [];
    }
};

const cancelUpcomingRecordingImagesRequests = () => {
    if (cancelUpcomingRequests.length > 0) {
        cancelUpcomingRequests.forEach((cur) => {
            cur('fcancel'); //if we forcefully cancel - do not do retries
        });
        cancelUpcomingRequests = [];
    }
};

export const getUpcomingRecordingImages = (scheduleList) => {
    const scheduleListArr = []; // splitting scheduleList up into bacthes of id's -max 30 per batch
    let hundredArr = [];
    for (let i = 0; i < scheduleList.length; i++) {
        if (hundredArr.length < 30) {
            hundredArr.push(`${scheduleList[i]}`);
        } else {
            scheduleListArr.push(hundredArr);
            hundredArr = [];
            hundredArr.push(`${scheduleList[i]}`);
        }
        if (hundredArr.length > 0 && i === scheduleList.length - 1) {
            scheduleListArr.push(hundredArr);
        }
    }
    const getBody = (i) => {
        //returns the body of the request for the specified(i) batch of id's
        return {
            schedules: scheduleListArr[i],
            showcard_images: {
                aspect_ratio: '4:3',
                size_limit: 80348,
                dimension_limit: 1169764,
            },
            background_images: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
            movie_background: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
            poster_image: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
        };
    };
    cancelUpcomingRecordingImagesRequests();
    block_upcoming_response = false;

    let all = {};

    const getPromiseArray = async () => {
        //returns an array of promises. one for each batch of schedule id's/each request. max 20 requests sent at once to limit open requests.
        for (let i = 0; i < scheduleListArr.length; i += 20) {
            const requestBatch = scheduleListArr.slice(i, i + 20).map((cur, y) => {
                body = getBody(i + y);
                return imageAxios
                    .post('/getschedule?get_cast=false&get_images=true', body, {
                        cancelToken: new CancelToken(function executor(c) {
                            cancelUpcomingRequests.push(c);
                        }),
                    })
                    .then((res) => {
                        if (res && res.data && res.data.data) {
                            all = { ...all, ...res.data.data };
                        } else if (res && res.message && res.message === 'fcancel') {
                            //blocks response if we forcefully cancel
                            // block_upcoming_response = true;
                        }
                    });
            });
            await Promise.all(requestBatch);
        }
        return all;
    };
    return getPromiseArray().then((result) => {
        //needed here only to clear cancelRequests list
        if (block_upcoming_response) {
            return null;
        } else {
            cancelUpcomingRequests = [];
            return result;
        }
    });
};

export const getRecordingImages = (scheduleList) => {
    const scheduleListArr = []; // splitting scheduleList up into bacthes of id's -max 30 per batch
    let hundredArr = [];
    for (let i = 0; i < scheduleList.length; i++) {
        if (hundredArr.length < 30) {
            hundredArr.push(`${scheduleList[i]}`);
        } else {
            scheduleListArr.push(hundredArr);
            hundredArr = [];
            hundredArr.push(`${scheduleList[i]}`);
        }
        if (hundredArr.length > 0 && i === scheduleList.length - 1) {
            scheduleListArr.push(hundredArr);
        }
    }

    const getBody = (i) => {
        //returns the body of the request for the specified(i) batch of id's
        return {
            schedules: scheduleListArr[i],
            showcard_images: {
                // aspect_ratio: '4:3',
                // size_limit: 80348,
                // dimension_limit: 1169764,
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 280000,
            },
            background_images: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
            movie_background: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
            poster_image: {
                aspect_ratio: '16:9',
                size_limit: 80348,
                dimension_limit: 8169764,
            },
        };
    };

    // cancelRecordingImagesRequests();

    let all = {};

    const getPromiseArray = async () => {
        //returns an array of promises. one for each batch of schedule id's/each request. max 20 requests sent at once to limit open requests.
        for (let i = 0; i < scheduleListArr.length; i += 20) {
            const requestBatch = scheduleListArr.slice(i, i + 20).map((cur, y) => {
                body = getBody(i + y);
                return imageAxios
                    .post('/getschedule?get_cast=false&get_images=true', body, {
                        cancelToken: new CancelToken(function executor(c) {
                            cancelRequests.push(c);
                        }),
                    })
                    .then((res) => {
                        all = { ...all, ...res.data.data };
                    });
            });
            await Promise.all(requestBatch);
        }
        return all;
    };
    return getPromiseArray().then((result) => {
        //needed here only to clear cancelRequests list
        cancelRequests = [];
        return result;
    });
};

// Also, don't need to display edge image if # of images are 5 or less
// Also, display images more centered
// Update text on placeholder images

// Add asset.mainImage && asset.backgroundImage, when do we use main or background images?
export const checkImages = async (imagesObject, assetData) => {
    if (imagesObject === null) {
        assetData.cardImage = 'unavailable';
    } else if (imagesObject.show_type === 'SE') {
        if (imagesObject.se_program_showcard !== '' && imagesObject.se_program_showcard.length > 0) {
            //(SE) Show Type MAIN/BACKGROUND 1st option: se_program_showcard
            assetData.cardImage = imagesObject.se_program_showcard;
        } else if (imagesObject.se_series_showcard !== '' && imagesObject.se_series_showcard.length > 0) {
            //(SE) Show Type MAIN/BACKGROUND 2nd option: se_series_showcard
            assetData.cardImage = await imagesObject.se_series_showcard;
        } else {
            //(SE) Show Type default image
            // assetData.cardImage =  require('../assets/show-typeSeason.png');
            assetData.cardImage = 'unavailable';
        }
        if (imagesObject.ep_name !== '' && imagesObject.ep_name.length > 0) {
            assetData.episode_name = imagesObject.ep_name;
        }
    } else if (imagesObject.show_type === 'SM') {
        if (imagesObject.sm_program_showcard !== '' && imagesObject.sm_program_showcard.length > 0) {
            //(SM) Show Type MAIN 1st option: sm_program_showcard
            assetData.cardImage = imagesObject.sm_program_showcard;
        } else if (imagesObject.sm_series_showcard !== '' && imagesObject.sm_series_showcard.length > 0) {
            //(SM) Show Type MAIN 2nd option: sm_series_showcard
            assetData.cardImage = imagesObject.sm_series_showcard;
        } else {
            assetData.cardImage = 'unavailable';
        }
    } else if (imagesObject.show_type === 'MO') {
        if (imagesObject.mo_poster !== '' && imagesObject.mo_poster.length > 0) {
            //(MO) Show Type MAIN/BACKGROUND 1st option: mo_image
            // assetData.cardImage = imagesObject.mo_image;
            assetData.cardImage = imagesObject.mo_poster;
        } else if (imagesObject.mo_image !== '' && imagesObject.mo_image.length > 0) {
            //(MO) Show Type BACKGROUND 1st option: mo_poster
            assetData.cardImage = imagesObject.mo_image;
            // assetData.cardImage = imagesObject.mo_poster;
        } else if (imagesObject.mo_showcard !== '' && imagesObject.mo_showcard.length > 0) {
            //(MO) Show Type BACKGROUND 2nd option: mo_showcard
            assetData.cardImage = imagesObject.mo_showcard;
        } else {
            assetData.cardImage = 'unavailable';
        }
    } else if (imagesObject.show_type === 'OT') {
        if (imagesObject.ot_background !== '' && imagesObject.ot_background.length > 0) {
            //(OT) Show Type MAIN 1st option: ot_background
            assetData.cardImage = imagesObject.ot_background;
        } else {
            assetData.cardImage = 'unavailable';
        }
    } else {
        //UPDATE
        // assetData.cardImage =  require('../assets/show-typeSeason.png');
        assetData.cardImage = 'unavailable';
    }
};

export const checkImagesBG = (imagesObject, assetData) => {
    if (imagesObject === null) {
        assetData.backgroundImage = 'unavailable';
    } else if (imagesObject.show_type === 'SE') {
        if (imagesObject.se_background !== '' && imagesObject.se_background.length > 0) {
            //(SE) Show Type MAIN/BACKGROUND 1st option: se_background
            assetData.backgroundImage = imagesObject.se_background;
        } else {
            //(SE) Show Type default image
            // assetData.backgroundImage =  require('../assets/show-typeSeason.png');
            assetData.backgroundImage = '';
        }
        //*Don't use show card image as background on web
        //  else if (imagesObject.se_series_showcard !== '' && imagesObject.se_series_showcard.length > 0) {
        //             //(SE) Show Type MAIN/BACKGROUND 2nd option: se_series_showcard
        //             assetData.backgroundImage = imagesObject.se_program_showcard;
        //         }
    } else if (imagesObject.show_type === 'SM') {
        if (imagesObject.sm_background !== '' && imagesObject.sm_background.length > 0) {
            //(SM) Show Type BACKGROUND 1st option: sm_background
            assetData.backgroundImage = imagesObject.sm_background;
            // sm_series_showcard is main and background's second option...change conditions later
        } else {
            assetData.backgroundImage = '';
        }
        //*Don't use show card image as background on web
        // else if (imagesObject.sm_series_showcard !== '' && imagesObject.sm_series_showcard.length > 0) {
        //             //(SM) Show Type BACKGROUND 2nd option: sm_series_showcard
        //             assetData.backgroundImage = imagesObject.sm_series_showcard;
        //         }
    } else if (imagesObject.show_type === 'MO') {
        if (imagesObject.mo_image !== '' && imagesObject.mo_image.length > 0) {
            //(MO) Show Type BACKGROUND 1st option: mo_image
            assetData.backgroundImage = imagesObject.mo_image;
        } else if (imagesObject.mo_poster !== '' && imagesObject.mo_poster.length > 0) {
            //(MO) Show Type BACKGROUND 2nd option: mo_poster
            assetData.backgroundImage = imagesObject.mo_poster;
        } else {
            assetData.backgroundImage = '';
        }
    } else if (imagesObject.show_type === 'OT') {
        if (imagesObject.ot_background !== '' && imagesObject.ot_background.length > 0) {
            //(OT) Show Type MAIN 1st option: ot_background
            assetData.backgroundImage = imagesObject.ot_background;
        } else {
            assetData.backgroundImage = '';
        }
    } else {
        assetData.backgroundImage = '';
    }
};

//interceptors
imageAxios.interceptors.request.use(
    (request) => {
        if (!request.retries) {
            request.retries = 3; //how many times to retry
        } else {
            request.retries -= 1;
        }
        return request;
    },
    (error) => {
        return error;
    }
);

imageAxios.interceptors.response.use(
    (response) => {
        // console.log("imageAxios response: ", response);
        body = {};
        return response;
    },
    (error) => {
        // console.log("error: ", error);
        if (error?.message !== 'fcancel') {
            let config = error?.config ? error.config : null;

            if (
                config &&
                error &&
                error.response &&
                config.retries < 3 &&
                (error.response.status === 500 || error.response.status === 401 || error.response.status === 404)
            ) {
                errorObject = makingErrorObject((error && error.message) || error, config || 'config not available', (config && config.retries) || 0, body);
                body = {};
                return Promise.reject(errorObject);
            }

            if (error && config && config.retries > 0) {
                let backoff = new Promise(function (resolve) {
                    //retry request 3 times, 1sec after every failure
                    setTimeout(function () {
                        resolve();
                    }, 1000);
                });

                return backoff.then(function () {
                    return imageAxios(config);
                });
            }
            errorObject = makingErrorObject((error && error.message) || error, config || 'config not available', (config && config.retries) || 3, body);
            return Promise.reject(errorObject);
        } else {
            return;
        }
    }
);

export const resetRecordingImages = () => {
    //cancel all requests
    cancelRecordingImagesRequests();

    //reset
    body = null;

    //reinit
    body = {};
    errorObject = {};
};
