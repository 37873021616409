import React from 'react';
import { StyleSheet, css } from 'aphrodite';

function LoadingAnimation({ svgStyles }) {
    return (
        <svg className={css(svgStyles)} xmlns="http://www.w3.org/2000/svg" width="53" height="59" viewBox="0 0 53 59">
            <g id="ic_small_logo" transform="translate(-72 -939.182)">
                <path
                    className={css(styles.path0, styles.path)}
                    id="Path_1328"
                    data-name="Path 1328"
                    d="M1232.817-4314.293c-4.442-2.552-8.077-.464-8.077,4.64v47.542c0,5.1,3.635,7.192,8.077,4.64l3.686-2.118v-52.586Z"
                    transform="translate(-1152.74 5254.564)"
                    fill="#01b59c"
                    fillOpacity="0.2"
                />
                <path
                    className={css(styles.path1, styles.path)}
                    id="Path_1329"
                    data-name="Path 1329"
                    d="M1374.894-4262.827l-10.327-5.927v49.241l10.327-5.926Z"
                    transform="translate(-1277.898 5212.814)"
                    fill="#ff4060"
                    fillOpacity="0.2"
                />
                <path
                    //this is the last block
                    className={css(styles.path3, styles.path)}
                    id="Path_1330"
                    data-name="Path 1330"
                    d="M1625.328-4109.47c4.442-2.565,4.442-6.761,0-9.326l-8.539-4.93v19.186Z"
                    transform="translate(-1503.66 5082.814)"
                    fill="#00829e"
                    fillOpacity="0.2"
                />
                <path
                    className={css(styles.path2, styles.path)}
                    id="Path_1331"
                    data-name="Path 1331"
                    d="M1501.932-4190.09l-10.219-5.849v34l10.219-5.849Z"
                    transform="translate(-1391.707 5147.619)"
                    fill="#ffb834"
                    fillOpacity="0.2"
                />
            </g>
        </svg>
    );
}

const opacityKeyframes = {
    '0%': {
        fillOpacity: 0.2,
    },
    '30%': {
        fillOpacity: 1,
    },
    '100%': {
        fillOpacity: 0.2,
    },
};

const styles = StyleSheet.create({
    loadingImage: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    path: {
        animationName: [opacityKeyframes],
        animationDuration: '1200ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease',
        animationFillMode: 'forwards',
    },
    path0: {
        animationDelay: '0ms',
    },
    path1: {
        animationDelay: '300ms',
    },
    path2: {
        animationDelay: '600ms',
    },
    path3: {
        animationDelay: '900ms',
    },
});

export default LoadingAnimation;
