import {
    CARD_MENU_TOGGLE,
    TOGGLE_CARDS,
    MOVE_LEFT,
    MOVE_RIGHT,
    UPDATE_CARDS,
    GET_ASSETS_BEGIN,
    GET_ASSETS_SUCCESS,
    GET_ASSETS_FAILURE,
    FILTER_SHOW_LIST,
    FILTER_CARD_LIST,
    UPDATE_ASSET_DATA,
    UPDATE_ORDER_DATA,
    CHANGE_TITLES_INDEX,
    GET_IMAGES_BEGIN,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAILURE,
    UPDATE_LOADING,
    TOGGLE_FULLSCREEN_VIDEO,
    TOGGLE_SINGLE_RECORDING_PAGE,
    TOGGLE_RECORDING_LIST_PAGE,
    TOGGLE_SORT_BY_SEASON,
    ON_ALL_SENUMS_AVAILABLE,
    CHANGE_MENU_TITLE,
    GET_SC_BEGIN,
    GET_SC_SUCCESS,
    GET_SC_FAILURE,
    SERIES_VIEW_ENABLED,
    UPDATE_UPCOMING_DATA,
    ON_UPCOMING_MENU,
    UPDATE_UPCOMING_LOADING,
} from '../types/myshows';
import { getRecordingImages, checkImages, checkImagesBG, getUpcomingRecordingImages } from '../services/RecordingImages';
import { loginCompleted } from '../actions/login';
import { getAssetsData, getUpcomingData } from '../services/AssetsService';
import { recordedAssets } from '../services/SotalCloudService';
import { errorIntercept, setLoginReady, channelsArrayForMSSV } from '../actions/main';
import { errorHandler } from '../utils/ErrorHandler';
// import { bugSnagNotifierGeneral } from '../services/BugSnagService';
import moment from 'moment';
// MY SHOWS MAIN ACTIONS
let menuItems = [
    { menuTitle: 'ALL SHOWS' },
    { menuTitle: 'TV SERIES' },
    { menuTitle: 'MOVIES' },
    { menuTitle: 'SPORTS' },
    { menuTitle: 'KIDS' },
    { menuTitle: 'NEWS' },
    { menuTitle: 'UPCOMING' },
    { menuTitle: 'FAVORITES' },
    { menuTitle: 'EXPIRING' },
];

const slashExp = new RegExp(/(\\|\/|\?)/, 'gi'); //for forward/back slash detection - method for removal at the end

// let prevErrorRecordings = []; //initial values
// let prevUndefinedShowType = [];

//question - where would I place Lifestyle which comes with genres(in our case genre and in asset coming from server case === category)

export const changeMenuTitle = (index) => ({
    type: CHANGE_MENU_TITLE,
    index,
});

export function updateFilter(index) {
    return (dispatch, getState) => {
        const globalShowList = [...getState().myshows.globalShowList];
        if (index !== 6) {
            dispatch(updateOnUpcomingMenu(false));
        }
        dispatch(changeMenuTitle(index));
        dispatch(filterShowList(globalShowList, index));
    };
}

export const cardMenuToggle = (cardsEnabled) => ({
    type: CARD_MENU_TOGGLE,
    cardsEnabled,
});
export const recordingListToggle = (listPageToggle) => ({
    type: TOGGLE_RECORDING_LIST_PAGE,
    listPageToggle,
});
export const singleRecordingToggle = (singleToggle) => ({
    type: TOGGLE_SINGLE_RECORDING_PAGE,
    singleToggle,
});
export const fullscreenVideoToggle = (videoToggle) => ({
    type: TOGGLE_FULLSCREEN_VIDEO,
    videoToggle,
});
export const toggleCards = (areCardsShowing) => ({
    type: TOGGLE_CARDS,
    areCardsShowing,
});

export const moveLeft = (cardData) => ({
    type: MOVE_LEFT,
    cardData,
});

export const moveRight = (cardData) => ({
    type: MOVE_RIGHT,
    cardData,
});

export const updateCards = (cardData) => ({
    type: UPDATE_CARDS,
    cardData,
});

export const changeTitlesIndex = (titlesIndex) => ({
    type: CHANGE_TITLES_INDEX,
    titlesIndex,
});

export const getAssetsBegin = () => ({
    type: GET_ASSETS_BEGIN,
});

export const getAssetsSuccess = (products) => ({
    type: GET_ASSETS_SUCCESS,
    products, //making an object
});

export const getAssetsFailure = (getAssetsError) => ({
    type: GET_ASSETS_FAILURE,
    getAssetsError,
});

export const updateOnUpcomingMenu = (onUpcomingMenu) => ({
    type: ON_UPCOMING_MENU,
    onUpcomingMenu,
});

const whiteChannelLogoPicker = (logosArray) => {
    let chanLogo;
    if (logosArray.length > 0) {
        for (let g = 0; g < logosArray.length; g++) {
            // if (channelLogos[g].file_url.includes('white') && !channelLogos[g].file_urincludes('2400')) {
            //     chanLogo = channelLogos[g].file_url;
            //     break;
            // }
            if (logosArray[g].file_url.includes('white') && !logosArray[g].file_url.includes('2400') && !logosArray[g].file_url.includes('_4')) {
                chanLogo = logosArray[g].file_url;
                return chanLogo;
            }
        }
    } else {
        chanLogo = '';
        return chanLogo;
    }
};
const channelNameParser = (longName) => {
    let channelName;
    try {
        if (longName.includes('HD')) {
            channelName = longName.split('HD')[0];
            return channelName;
        } else if (longName.includes('(')) {
            channelName = longName.split('(')[0];
            return channelName;
        }
    } catch (e) {
        console.log(e);
        channelName = '';
        return channelName;
    }
};
const channelNumberSetter = (id) => {
    if (channelsArrayForMSSV.has(id)) {
        return channelsArrayForMSSV.get(id);
    }
};
export function getAssets(sub_id, isFetchingAssetsOnLogin) {
    let assetData = [];
    return (dispatch, getState) => {
        let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
        dispatch(getAssetsBegin());
        return getAssetsData(sub_id) //TO DO - remove sub_id from this call - and use
            .then((result) => {
                let limit15 = [];
                if (result?.data?.data?.length > 0) {
                    limit15 = [...result.data.data];
                } else {
                    throw Error('noAssets');
                }
                let orderData = [];
                dispatch(getAssetsSuccess(limit15)); //this filter will need to change because we will need to allow empty orders
                //order_type, schedule_id, subscriber_id, start_recording, stop_recording, start_from, channel, get_hd, record_state, is_start_from_year
                if (result && limit15.length > 0) {
                    limit15.map((order, i) => {
                        orderData.push({
                            order_id: order.id.toString(),
                            order_type: order.type,
                            subscriber_id: order.user_id.toString(),
                            start_recording: order.start_recording.toString(),
                            stop_recording: order.stop_recording.toString(),
                            start_from: order.start_from ? order.start_from.toString() : null,
                            channel: order.channel,
                            get_hd: order.get_hd ? order.get_hd.toString() : null,
                            record_state: order.record_state,
                            is_start_from_year: order.is_start_from_year,
                            enabled: order.enabled,
                            series_id: order.series_id ? order.series_id.toString() : null,
                            source_id: order.source_id ? order.source_id.toString() : null,
                        });
                        //if (order.type === 'single') { ///////this filter can be removed after get program request is multithreaded on back end, or else get program will time out
                        order.assets.forEach((asset, y) => {
                            assetData = [
                                ...assetData,
                                {
                                    //series_name: program.assets[0].series_name
                                    program_name: removeSlashes(asset.program_name),
                                    schedule_id: asset.schedule_id,
                                    program_id: asset.program_id.toString(),
                                    asset_id: asset.id.toString(),
                                    starts_at: asset.starts_at,
                                    ends_at: asset.ends_at,
                                    order_id: order.id.toString(),
                                    //order_enabled: order.enabled,
                                    show_type: '',
                                    description: '',
                                    backgroundImage: '',
                                    cardImage: '',
                                    start_recording: asset.start_recording.toString(),
                                    stop_recording: asset.stop_recording.toString(),
                                    type: order.type,
                                    source_id: asset.source_id.toString(),
                                    id: y,
                                    season_number: '',
                                    episode_number: '',
                                    episode_name: '',
                                    series_id: asset.series_id.toString(),
                                    series_description: '',
                                    release_year: '',
                                    tv_rating: '',
                                    start_formated: '',
                                    end_formated: '',
                                    long_title: '',
                                    sc_id: asset.sc_id || '',
                                    sc_recording_state: '',
                                    favorite: asset.favorite,
                                    expires_unix: asset.expires_unix,
                                },
                            ];
                        });
                        //} //close series filter
                    });
                } else {
                    limit15 = []; //it should be resetting to []
                }
                dispatch(updateOrderData(orderData));
                return limit15;
                //missing schedule id here
            })
            .then((assets) => {
                if (assets && Array.isArray(assets) && assets.length > 0) {
                    //if any assets detected - fetch it's images
                    let scheduleList = assetData.map((data, idx) => {
                        return Number(data.schedule_id);
                    });
                    dispatch(getImagesBegin());
                    return getRecordingImages(scheduleList) //5c61a52420c2c2350a338450
                        .then((data) => {
                            // map important data
                            for (let i = 0; i < assetData.length; i++) {
                                //here we are mapping the returned get-schedule data to the existing assetData
                                let sch = assetData[i].schedule_id;
                                if (data[sch]) {
                                    checkImages(data[sch], assetData[i]);
                                    checkImagesBG(data[sch], assetData[i]);
                                    assetData[i].description = data[sch].program_desc;
                                    assetData[i].show_type = data[sch].show_type;
                                    // assetData[i].cast = json.data[sch].cast;//cast is not in use yet
                                    assetData[i].cast = data[sch].cast;
                                    assetData[i].tv_rating = data[sch].tv_rating;
                                    assetData[i].episode_name = data[assetData[i].schedule_id].ep_name;
                                    assetData[i].season_number = data[sch].se_number;
                                    assetData[i].episode_number = data[sch].ep_number;
                                    assetData[i].tv_rating = data[sch].tv_rating;
                                    assetData[i].release_year = data[sch].release_year;
                                    assetData[i].start_formated = data[sch].start_formated;
                                    assetData[i].end_formated = data[sch].end_formated;
                                    assetData[i].unix_start_time = data[sch].unix_start_time;
                                    assetData[i].unix_end_time = data[sch].unix_end_time;
                                    assetData[i].long_title = data[sch].long_title;
                                    assetData[i].genre = data[sch].category;
                                    assetData[i].group_id = data[sch].group_id;
                                    assetData[i].channelLogos = data[sch].channellogos;
                                    assetData[i].channel = data[sch].source[0].full_name;
                                    assetData[i].channel_logo = whiteChannelLogoPicker(
                                        data[sch].channellogos && data[sch].channellogos[0].logos && data[sch].channellogos[0].logos
                                            ? data[sch].channellogos[0].logos
                                            : []
                                    );
                                    assetData[i].channel_name = assetData[i].channel_logo ? channelNameParser(data[sch].source[0].full_name) : '';
                                    assetData[i].channel_number = channelNumberSetter(data[sch].source_id);
                                } else {
                                    checkImages(data[sch], assetData[i]);
                                    checkImagesBG(data[sch], assetData[i]);
                                }
                            }
                            dispatch(completedRecordingsFilter(assetData)); //to filter out the recorded assets
                            dispatch(getImagesSuccess());
                            dispatch(updateAssetData(assetData));
                            if (getState().main.isLoginReady) {
                                dispatch(loginCompleted());
                            } else {
                                dispatch(setLoginReady());
                            }
                            return data;
                        })
                        .catch((error) => {
                            //console.log("getSchedule error", error);
                            let errorObject = errorHandler(error, 'getAssets inner .catch', subDeviceInfo); //variable upfront only for console.log
                            dispatch(errorIntercept(errorObject));
                            dispatch(getImagesFailure(errorObject.message));
                        });
                } else {
                    let errorObject = errorHandler(new Error('getAssets after else'), 'getAssets after else', subDeviceInfo);
                    dispatch(errorIntercept(errorObject));
                    dispatch(updateAssetData(assetData));
                    dispatch(completedRecordingsFilter(assetData));
                    if (getState().main.isLoginReady) {
                        dispatch(loginCompleted());
                    } else {
                        dispatch(setLoginReady());
                    }
                }
            })
            .catch((error) => {
                // console.log("getAssets error", error);
                if (error.message === 'noAssets') {
                    //To Do - remove when Alex will return empty array instead of 404 - remove!
                    dispatch(updateAssetData(assetData));
                    dispatch(completedRecordingsFilter(assetData));
                    //dispatch(loginCompleted());
                    if (getState().main.isLoginReady) {
                        dispatch(loginCompleted());
                    } else {
                        dispatch(setLoginReady());
                    }
                } else {
                    let errorObject = errorHandler(error, 'getAssets outer .catch', subDeviceInfo);
                    dispatch(errorIntercept(errorObject));
                    dispatch(getAssetsFailure(errorObject.message));
                }
            });
    };
}

export const filterShowList = (recordedAssetData, index) => {
    //this list is produced in my Shows
    return (dispatch, getState) => {
        const prevAllShowsCategoryList = getState().myshows.allShowsCategoryList; //TODO - should this be not the pointer
        const val = index ? index : getState().myshows.menuIndex;
        const menuIndex = Math.floor(val % 9); //num 9 represents 9 menu titles in My Shows
        const isFavoritesCategory = menuItems[menuIndex].menuTitle === 'FAVORITES'; //handle favorites & expiring differently
        const isExpiringCategory = menuItems[menuIndex].menuTitle === 'EXPIRING';

        // no duplicate showTitles
        let filterVal = '';
        const unsortedShowTitles = [];
        let duplicate = false;

        recordedAssetData.forEach((asset, i) => {
            // for each recording
            duplicate = false;

            for (let j = 0; j < unsortedShowTitles.length; j++) {
                if (asset.series_id && asset.series_id === unsortedShowTitles[j].series_id) {
                    // and if it's already present dont add it but increase quantity ++
                    // !duplicate ? ds = show: null;
                    // duplicate = true;
                    duplicate = true;

                    if (unsortedShowTitles[j].cardImage === 'unavailable' && asset.cardImage && asset.cardImage !== 'unavailable') {
                        //checking duplicates for image, in case the the first(nonduplicate) doesn't have one
                        unsortedShowTitles[j].cardImage = asset.cardImage;
                        unsortedShowTitles[j].backgroundImage = asset.backgroundImage;
                        unsortedShowTitles[j].show_type = asset.show_type;
                    }
                    if (isFavoritesCategory) {
                        if (asset.favorite) {
                            //same show & favorited or expiring -> up quantity, otherwise just break loop
                            unsortedShowTitles[j].quantity++;
                            break;
                        } else {
                            break;
                        }
                    } else if (isExpiringCategory) {
                        if (!asset.favorite && asset.expires_unix < moment().unix() + 604800) {
                            unsortedShowTitles[j].quantity++;
                            break;
                        } else {
                            break;
                        }
                    } else {
                        unsortedShowTitles[j].quantity++;
                        break;
                    }
                }
            }

            if (!duplicate) {
                if (isFavoritesCategory) {
                    if (asset.favorite) {
                        asset.quantity = 1;
                        unsortedShowTitles.push({ ...asset });
                    }
                } else if (isExpiringCategory) {
                    if (!asset.favorite && asset.expires_unix < moment().unix() + 604800) {
                        asset.quantity = 1;
                        unsortedShowTitles.push({ ...asset });
                    }
                } else {
                    asset.quantity = 1;
                    unsortedShowTitles.push({ ...asset });
                }
            }
        });

        //showtitles is being sorted aplhabetically
        let sortedShowTitles = unsortedShowTitles.sort(function (a, b) {
            if (a.program_name < b.program_name) {
                return -1;
            } else if (a.program_name > b.program_name) {
                return 1;
            } else {
                return 0;
            }
        });

        switch (menuItems[menuIndex].menuTitle) {
            case 'NEWS':
                filterVal = 'news';
                break;
            case 'KIDS':
                filterVal = "children's";
                break;
            case 'SPORTS':
                filterVal = 'sport';
                break;
            case 'MOVIES':
                filterVal = 'film'; // this should filter by showtype (to be 'MO') instead of checking 'genre' . For now just filter out all
                break;
            case 'FAVORITES':
                filterVal = 'favorites';
                break;
            case 'EXPIRING':
                filterVal = 'expiring';
                break;
            case 'TV SERIES':
                filterVal = 'other';
                break;
            case 'UPCOMING':
                filterVal = 'upcoming';
                break;
            default:
                filterVal = '';
        }
        let sortedForCategory;
        if (filterVal === 'upcoming') {
            let series = {};
            sortedForCategory = getState().myshows.upcomingData.filter((asset) => {
                if (!asset.series_id && asset.long_title) {
                    return true;
                }
                if (!series[asset.series_id] && asset.long_title) {
                    series[asset.series_id] = 1;
                    return true;
                } else {
                    series[asset.series_id]++;
                    return false;
                }
            });
            sortedForCategory.forEach((element) => {
                if (element.series_id) {
                    element.quantity = series[element.series_id];
                }
            });
        } else {
            sortedForCategory = sortedShowTitles.filter((asset, i) => {
                if (filterVal && filterVal !== 'favorites' && filterVal !== 'expiring') {
                    ///// if the user is browsing a category that has a filter value, the programs are filtered
                    if (asset.genre && asset.genre.toLowerCase() === filterVal) {
                        ///////  programs are filtered out if their 'genre' does not match up
                        return true;
                    } else {
                        return false;
                    }
                } else if (filterVal === 'favorites') {
                    if (asset.favorite) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (filterVal === 'expiring') {
                    //console.log('asset.expires_unix', asset.expires_unix,'now: ',moment().unix(), 'weekfromnow:', moment().unix() + 86400)
                    if (asset.expires_unix < moment().unix() + 604800) {
                        //date < 7 days away or whatever it is
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            });
        }
        // do not need if we make API call on order creation
        // let thisMoment = moment().utc().unix();
        // let sortedForCategoryAndTime = timeFilter(sortedForCategory, thisMoment);
        // let showList = [{program_name: menuItems[menuIndex].menuTitle}, ...sortedForCategoryAndTime];
        let showList = [{ program_name: menuItems[menuIndex].menuTitle }, ...sortedForCategory];
        const allShowsCategoryList = filterVal === '' ? showList : prevAllShowsCategoryList;

        dispatch(updateShowList(showList, recordedAssetData, allShowsCategoryList)); //where assetData in this block - represents globalShowList that we are going to be filtering

        //recordedAssetData becomes globalShowList;
    };
};

// export const filterCardList = (assetData) => {
//     return (dispatch) => {
//         function makeCards(cardData) {
//             return cardData.map((show, i) => {
//                 return {
//                     ...show,
//                     order_id: show.order_id,
//                     key: show.order_id,
//                     id: i,
//                     name: show.program_name,
//                     headerText: show.program_name,
//                     image: show.cardImage? show.cardImage: null,
//                     backgroundImage: show.backgroundImage? show.backgroundImage : null,
//                     description: show.description,
//                     actors: "",
//                     info: [
//                         {
//                             id: i,
//                             season: 1,
//                             episode: 1,
//                             headerText: "",
//                             date: '',
//                             image: show.cardImage? show.cardImage: null,
//                             recordStatus: 'recorded'
//                         }
//                     ],
//                     cast: show.cast,
//                     group_id: show.group_id
//                 }
//             })
//         }

//         //filter by series id, per Alex, looks like no need to use group_id here as they are all different
//         const seriesArr = [];
//         const group_ids = [];//not sure if we need it for filtering, but Alex says we do
//         const filteredList = [];
//         for (let i = 0; i <= assetData.length - 1 ; i++) { // filtering out duplicates
//             const revCur = assetData[assetData.length -1 - i];//run from back of the list
//             if(revCur.series_id){//filter out series only
//                 if (seriesArr.indexOf(revCur.series_id) === -1) {
//                     seriesArr.push(revCur.series_id);
//                     filteredList.push(revCur);
//                 }
//             }else if(revCur.group_id){
//                 if (group_ids.indexOf(revCur.group_id) === -1) {
//                     group_ids.push(revCur.group_id);
//                     filteredList.push(revCur);
//                 }
//             }else{//just a precaution
//                 filteredList.push(revCur);
//             }
//         }

//         //previously filtering based on name
//         // const filtArr = []
//         // const filteredList = []
//         // for (let i = 0; i <= assetData.length - 1 ; i++) { // filtering out duplicates
//         //     let title;
//         //     const revCur = assetData[assetData.length -1 - i]
//         //     if (filtArr.indexOf(revCur.program_name) === -1) {
//         //         filtArr.push(revCur.program_name);
//         //         title = revCur.program_name
//         //     }
//         //     if (title && filtArr[filtArr.length -1] === title) { // 5c7df47b00fbc816416a580e
//         //         filteredList.push(revCur)
//         //     }
//         // }

//         //if we do not make API call
//         // let thisMoment = moment().utc().unix();
//         // let filteredListByTime = timeFilter(filteredList, thisMoment);
//         // let cardList = makeCards(filteredListByTime);//cards

//         let cardList = makeCards(filteredList);
//         dispatch(updateCardList(cardList));
//     }
// }

export const updateAssetData = (assetData) => {
    //when updating asset data array, also create/update a quick schedule_id key lookup object for EPG
    const assetDataProgramIDLookup = {};
    //const assetDataScheduleIDLookup = {};
    for (let i = 0; i < assetData.length; i++) {
        if (!assetDataProgramIDLookup[assetData[i].program_id]) {
            //if the program_id doesn't exist, add it to the program_id map
            assetDataProgramIDLookup[assetData[i].program_id] = { ...assetData[i] };
            //this is a set of all the different schedule_ids for a program with the same program_id, needed for programs type: "SM"
            assetDataProgramIDLookup[assetData[i].program_id].schedule_id_instances = {};
        }
        //add the new schedule_id to its schedule_id_instances, along with that asset's sc recording status and order_id, for later lookup
        let instance_data = {
            sc_recording_state: assetData[i].sc_recording_state,
            order_id: assetData[i].order_id,
        };
        assetDataProgramIDLookup[assetData[i].program_id].schedule_id_instances[assetData[i].schedule_id] = instance_data;
    }

    return {
        type: UPDATE_ASSET_DATA,
        assetData,
        assetDataProgramIDLookup,
    };
};

export const updateShowList = (showList, globalShowList) => ({
    type: FILTER_SHOW_LIST,
    globalShowList,
    showList,
});

export const updateCardList = (cardList) => ({
    type: FILTER_CARD_LIST,
    cardList,
});

export const updateOrderData = (orderData) => {
    return {
        type: UPDATE_ORDER_DATA,
        orderData,
    };
};

export const getImagesBegin = () => ({
    type: GET_IMAGES_BEGIN,
});

export const updateLoading = (isLoading) => ({
    type: UPDATE_LOADING,
    isLoading,
});

export const getImagesSuccess = (products) => ({
    type: GET_IMAGES_SUCCESS,
    payload: { products },
});

export const getImagesFailure = (error) => ({
    type: GET_IMAGES_FAILURE,
    error,
});

export const seriesViewEnabled = (isEnabled) => ({
    type: SERIES_VIEW_ENABLED,
    isEnabled,
});

//upcoming assets
export const updateUpcomingData = (upcomingData) => {
    return {
        type: UPDATE_UPCOMING_DATA,
        upcomingData,
    };
};

// SELECTED RECORDINGS ACTIONS

export const toggleSortBySeason = (isSortBySeason) => ({
    type: TOGGLE_SORT_BY_SEASON,
    isSortBySeason,
});

export const onAllSENumsAvailable = (onAllSENumsAvailable) => ({
    type: ON_ALL_SENUMS_AVAILABLE,
    onAllSENumsAvailable,
});

export const updateUpcomingLoading = (isUpcomingLoading) => ({
    type: UPDATE_UPCOMING_LOADING,
    isUpcomingLoading,
});

export const completedRecordingsFilter = (assetData) => {
    // console.log("completedRecordingsFilter", assetData, moment());
    let errorRecordings = []; //array for recordings with status: "error"
    let undefinedShowType = []; //array for recordings with show_type: "undefined"
    let recordingsWithStateErrorOnUpdate = [];
    // let recordedOrders = [];
    let upcomingList = [];

    let finalizedList = [];
    return (dispatch, getState) => {
        if (getState().login.isLoginSuccess) {
            //we only need to call this if login was successful and if we are not on LogOut page
            dispatch(getSCBegin());
            //filter out only assets with SC_STATUS: "recorded"
            let helper_list = [...assetData].filter((asset) => {
                return asset.sc_recording_state === 'recorded';
            });
            return recordedAssets()
                .then((result) => {
                    if (result && result.data && result.data.data && Array.isArray(result.data.data)) {
                        result.data.data.forEach((scRecording) => {
                            // known possible sotalcloud states: "recorded", "recording", "scheduled", "pending", "error"
                            let prevRecordingState = '';
                            const itemIndex = assetData.findIndex((asset) => {
                                //cross-check asset data for item index
                                return asset.sc_id === scRecording.id;
                            });
                            if (itemIndex !== -1) {
                                //if found
                                if (scRecording.state === 'recorded') {
                                    //create filtered list for "recorded" items
                                    finalizedList = [...finalizedList, assetData[itemIndex]];
                                    if (assetData[itemIndex] && (assetData[itemIndex].show_type === '' || assetData[itemIndex].show_type === undefined)) {
                                        //collecting problem show_type
                                        undefinedShowType.push(assetData[itemIndex]);
                                    }
                                }
                                prevRecordingState = assetData[itemIndex].sc_recording_state;
                                assetData[itemIndex].sc_recording_state = scRecording.state; //update asset data with sotal cloud state
                                if (scRecording.state === 'error') {
                                    if (prevRecordingState !== 'error' && prevRecordingState !== '' && itemIndex !== -1) {
                                        //state is not empty and is not already in the error state, then prompt the user
                                        //trigger an error prompt on assets that go from another state to "error" state
                                        const dataObjectCombinedData = {
                                            epicData: {
                                                ...assetData[itemIndex],
                                            },
                                            scData: {
                                                ...scRecording,
                                            },
                                        };
                                        recordingsWithStateErrorOnUpdate.push(dataObjectCombinedData);
                                    }
                                    errorRecordings.push(scRecording); //shouls be the errored assets
                                }
                                if (scRecording.state === 'scheduled' || scRecording.state === 'recording') {
                                    assetData[itemIndex].sc_recording_state = scRecording.state;
                                    upcomingList = [...upcomingList, assetData[itemIndex]];
                                }
                            }
                        });
                        upcomingList = upcomingList.sort(function (a, b) {
                            return a.program_name.localeCompare(b.program_name);
                        });
                        dispatch(updateUpcomingData(upcomingList));
                        dispatch(updateUpcomingLoading(false));

                        if (recordingsWithStateErrorOnUpdate.length > 0) {
                            const error = {
                                type: 'sotalCloudAssetUpdate',
                                programName: recordingsWithStateErrorOnUpdate[0].epicData.program_name,
                                size: recordingsWithStateErrorOnUpdate.length,
                            };
                            let errorObject = errorHandler(error, 'completedRecordingsFilter from myshows.js', getState().login.subscriberData.subDeviceInfo);
                            if (recordingsWithStateErrorOnUpdate[0].epicData.type === 'single') {
                                dispatch(errorIntercept(errorObject));
                            }
                        }

                        // Mock Data created here -- Only for testing!!!
                        // for(let i = 0; i < finalizedList.length; i++){
                        //     if(finalizedList[i].schedule_id == 7953670012){ //replace schedule_id with asset to create duplicates of
                        //         console.log("found show 2 and a half men and creating duplicates..");
                        //         let seasonNumber = 158;
                        //         for(let j = 10; j < 30; j++){
                        //             const mockAsset = {
                        //                 ...finalizedList[i],
                        //                 id: j,
                        //                 season_number: seasonNumber.toString()
                        //             };
                        //             finalizedList.push(mockAsset)
                        //             seasonNumber++;
                        //         }
                        //         break;
                        //     }
                        // }
                        dispatch(updateAssetData(assetData)); //another flag added to each asset with verified SC recording;

                        //based on logic below we update data of local assetData/finalizedList with recordingImages API call;
                        if (helper_list.length > 0 && finalizedList.length !== helper_list.length) {
                            //find the difference between 2 collections and make
                            let diff_asset = [];
                            if (finalizedList.length > helper_list.length) {
                                diff_asset = finalizedList.filter((asset1) => !helper_list.some((asset2) => asset2.asset_id === asset1.asset_id));
                            } else if (helper_list.length > finalizedList.length) {
                                //should never execute, but just in case
                                diff_asset = helper_list.filter((asset1) => !finalizedList.some((asset2) => asset2.asset_id === asset1.asset_id));
                            }
                            let schedule_ids = diff_asset.map((asset) => asset.schedule_id);
                            additionalAssetInfoGetter(schedule_ids, dispatch, getState, finalizedList);
                        } else {
                            dispatch(filterShowList(finalizedList));
                            // dispatch(filterCardList(finalizedList));
                        }

                        // needed the following on for debugging and testing
                        // if ((errorRecordings && Array.isArray(errorRecordings) && errorRecordings.length > 0 && (errorRecordings.length !== prevErrorRecordings)) || (undefinedShowType && Array.isArray(undefinedShowType) && undefinedShowType.length > 0 && (prevUndefinedShowType !== undefinedShowType.length))){
                        //     //the above condition will ensure to data for bugsnag is sent only first time and if there are any changes
                        //     bugSnagNotifierGeneral("Status: includes show_type: undefined and status: error", "completedRecordingsFilter", getState().login.subscriberData.subDeviceInfo, errorRecordings, undefinedShowType);
                        // }
                        // prevErrorRecordings = errorRecordings.length;//datastructure for recordings with status: "error"
                        // prevUndefinedShowType = undefinedShowType.length;//datastructure for recordings with status "undefined"

                        errorRecordings = null;
                        undefinedShowType = null;
                    }
                    dispatch(getSCSuccess());
                })
                .catch((error) => {
                    // console.log("completed recordings error", error);
                    let errorObject = errorHandler(error, 'SC completed recordings call failure', getState().login.subscriberData.subDeviceInfo);
                    dispatch(errorIntercept(errorObject));
                    dispatch(getAssetsFailure(errorObject.message));
                    dispatch(getSCFailure());
                });
        }
    };
};

export const getSCBegin = () => ({
    type: GET_SC_BEGIN,
});

export const getSCSuccess = () => ({
    type: GET_SC_SUCCESS,
});

export const getSCFailure = () => ({
    type: GET_SC_FAILURE,
});

const timeFilter = (showList, currentTime) => {
    //so the shows will not be showing up in MyShows before the time
    let showEndTime;
    return showList.filter((show) => {
        if (show && !show.unix_end_time && show.ends_at.date) {
            //some shows may not have unix_end_time - condition for that
            showEndTime = moment(show.ends_at.date).utc().unix();
        } else if (show && show.unix_end_time) {
            showEndTime = parseInt(show.unix_end_time);
        } else {
            return false;
        }
        return parseInt(showEndTime) + 900 < currentTime; //end of show plus 15 min, just like in AppMain
    });
};

const additionalAssetInfoGetter = (schedule_ids, dispatch, getState, finalizedList) => {
    let finList = [...finalizedList];
    //let's populate needed information
    getRecordingImages(schedule_ids, dispatch)
        .then((data) => {
            // console.log("data: ", data);
            //update information in both lists
            let assets = [...getState().myshows.assetData];
            for (let k = 0; k < schedule_ids.length; k++) {
                //update info in assetData
                for (let l = 0; l < assets.length; l++) {
                    if (assets[l].schedule_id === schedule_ids[k]) {
                        // console.log("found asset: ", assets[l]);
                        if (data[schedule_ids[k]]) {
                            checkImages(data[schedule_ids[k]], assets[l]);
                            checkImagesBG(data[schedule_ids[k]], assets[l]);
                            assets[l]['description'] = data[schedule_ids[k]].program_desc || '';
                            assets[l]['show_type'] = data[schedule_ids[k]].show_type || '';
                            assets[l]['cast'] = data[schedule_ids[k]].cast || '';
                            assets[l]['tv_rating'] = data[schedule_ids[k]].tv_rating || '';
                            assets[l]['episode_name'] = data[schedule_ids[k]].ep_name || '';
                            assets[l]['season_number'] = data[schedule_ids[k]].se_number || '';
                            assets[l]['episode_number'] = data[schedule_ids[k]].ep_number || '';
                            assets[l]['tv_rating'] = data[schedule_ids[k]].tv_rating || '';
                            assets[l]['release_year'] = data[schedule_ids[k]].release_year || '';
                            assets[l]['start_formated'] = data[schedule_ids[k]].start_formated || '';
                            assets[l]['end_formated'] = data[schedule_ids[k]].end_formated || '';
                            assets[l]['unix_start_time'] = data[schedule_ids[k]].unix_start_time || '';
                            assets[l]['unix_end_time'] = data[schedule_ids[k]].unix_end_time || '';
                            assets[l]['long_title'] = data[schedule_ids[k]].long_title || '';
                            assets[l]['group_id'] = data[schedule_ids[k]].group_id || '';
                        }
                    }
                }
                //update info for finalizedList
                for (let m = 0; m < finList.length; m++) {
                    if (finList[m].schedule_id === schedule_ids[k]) {
                        // console.log("asset found in finalizedList: ", finList[m]);
                        if (data[schedule_ids[k]]) {
                            checkImages(data[schedule_ids[k]], finList[m]);
                            checkImagesBG(data[schedule_ids[k]], finList[m]);
                            finList[m]['description'] = data[schedule_ids[k]].program_desc || '';
                            finList[m]['show_type'] = data[schedule_ids[k]].show_type || '';
                            finList[m]['cast'] = data[schedule_ids[k]].cast || '';
                            finList[m]['tv_rating'] = data[schedule_ids[k]].tv_rating || '';
                            finList[m]['episode_name'] = data[schedule_ids[k]].ep_name || '';
                            finList[m]['season_number'] = data[schedule_ids[k]].se_number || '';
                            finList[m]['episode_number'] = data[schedule_ids[k]].ep_number || '';
                            finList[m]['tv_rating'] = data[schedule_ids[k]].tv_rating || '';
                            finList[m]['release_year'] = data[schedule_ids[k]].release_year || '';
                            finList[m]['start_formated'] = data[schedule_ids[k]].start_formated || '';
                            finList[m]['end_formated'] = data[schedule_ids[k]].end_formated || '';
                            finList[m]['unix_start_time'] = data[schedule_ids[k]].unix_start_time || '';
                            finList[m]['unix_end_time'] = data[schedule_ids[k]].unix_end_time || '';
                            finList[m]['long_title'] = data[schedule_ids[k]].long_title || '';
                            finList[m]['group_id'] = data[schedule_ids[k]].group_id || '';
                        }
                    }
                }
            }
            // console.log("updated assets: ", assets);
            dispatch(updateAssetData(assets));
            dispatch(filterShowList(finList));
            // dispatch(filterCardList(finList));
        })
        .catch((error) => {
            //added this for error handling on call
            console.log('additional asset info getter: ', error);
            dispatch(filterShowList(finList)); //if the call fails - let's update with what we have and do not crash the app
            // dispatch(filterCardList(finList));
            let errorObject = errorHandler(error, 'getRecordedImages in PusherOrderCreated', getState().login.subscriberData.subDeviceInfo); //variable upfront only for console
            // dispatch(errorIntercept(errorObject));//even if error on this call occurs - there is enough info to keep working
        });
};

export const removeSlashes = (str) => {
    if (slashExp.test(str)) {
        return str.replace(slashExp, '');
    }
    return str;
};
