// Main Menu
export const CHANGE_MAIN_MENU_INDEX = 'CHANGE_MAIN_MENU_INDEX';
export const CHANGE_MAIN_MENU_ITEM_INDEX = 'CHANGE_MAIN_MENU_ITEM_INDEX';
export const TOGGLE_SUBMENU = 'TOGGLE_SUBMENU';
export const CHANGE_SELECTED_ROW_INDEX = 'CHANGE_SELECTED_ROW_INDEX';

// Parental Controls
export const TOGGLE_PARENTAL_CONTROLS = 'TOGGLE_PARENTAL_CONTROLS';
export const TOGGLE_HIDE_ADULT_CONTENT = 'TOGGLE_HIDE_ADULT_CONTENT';
export const TOGGLE_PURCHASE_CONTROLS = 'TOGGLE_PURCHASE_CONTROLS';
export const TOGGLE_PIN_POPUP = 'TOGGLE_PIN_POPUP';
export const CHANGE_PIN_POPUP_INDEX = 'CHANGE_PIN_POPUP_INDEX';
export const CHANGE_PARENTAL_CONTROLS_OPTION_INDEX = 'CHANGE_PARENTAL_CONTROLS_OPTION_INDEX';
export const CHANGE_PIN_NUMBER = 'CHANGE_PIN_NUMBER';
export const CHANGE_CONFIRM_PIN_NUMBER = 'CHANGE_CONFIRM_PIN_NUMBER';
export const CHANGE_LOCKED_CHANNELS = 'CHANGE_LOCKED_CHANNELS';
export const CHANGE_RATING_LIMITS = 'CHANGE_RATING_LIMITS';

// Lock Channels
export const INITIALIZE_LOCKED_CHANNELS = 'INITIALIZE_LOCKED_CHANNELS';
export const LOCK_CHANNEL = 'LOCK_CHANNEL';
export const LOCK_ALL_CHANNELS = 'LOCK_ALL_CHANNELS';
export const UNLOCK_ALL_CHANNELS = 'UNLOCK_ALL_CHANNELS';

//
export const CHANGE_CAROUSEL_ITEM_INDEX = 'CHANGE_CAROUSEL_ITEM_INDEX';
export const CHANGE_ACCOUNT_SYSTEM_INFO_ROW_INDEX = 'CHANGE_ACCOUNT_SYSTEM_INFO_ROW_INDEX';
export const CHANGE_COPYRIGHTS_PAGE = 'CHANGE_COPYRIGHTS_PAGE';
export const CHANGE_TIPS_TROUBLESHOOTING_ROW_INDEX = 'CHANGE_TIPS_TROUBLESHOOTING_ROW_INDEX';
