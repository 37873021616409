import { SET_LIVE_LOCATION } from '../types/progressbar';

const initialState = {
    liveBarLocation: 0, // green bar length showing how long show has been watched
    currentProgressState: {
        play: false,
        paused: false,
        stopped: false,
        pausedFF: false,
        pausedRW: false,
        rw2x: false,
        rw6x: false,
        rw30x: false,
        ff2x: false,
        ff6x: false,
        ff30x: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LIVE_LOCATION:
            return {
                ...state,
                liveBarLocation: action.liveLocation,
            };
        default:
            return state;
    }
};
