import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
//import Icon from 'react-native-vector-icons/FontAwesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Item = ({ text, isSelected, onSelection }) => {
    return (
        // <View style={{ flexDirection: 'row' }}>
        //     <View style={isSelected ? styles.itemSelected : styles.item}>
        //         <Text style={isSelected ? styles.itemSelectedText : styles.itemText}>{text}</Text>
        //     </View>
        // </View>
        <>
            <div onClick={onSelection} className={css(styles.OptionItem)}>
                {text}
            </div>
        </>
    );
};

export const CustomItem = ({ text, option, isSelected, optionIndex, changeOptionIndex }) => {
    return (
        <>
            <div className={css(styles.CustomItemContainer)}>
                <div className={css(styles.CustomItemText)}>{text}</div>
                <button className={css(styles.customItemArrow)} onClick={() => changeOptionIndex(true)}>
                    <img className={css(styles.ArrowIcon)} src={require('../../assets/SC_SVGs/ic_small_arrow_left.svg')} />
                </button>
                <div className={css(styles.CustomItemOption)}>{option}</div>
                <button className={css(styles.customItemArrow)} onClick={() => changeOptionIndex(false)}>
                    <img className={css(styles.ArrowIcon)} src={require('../../assets/SC_SVGs/ic_small_arrow_right.svg')} />
                </button>
            </div>
        </>
    );
};

export default class SeasonRecord extends Component {
    startIndex = 0;
    stopIndex = 0;
    startRecValues = [];
    stopRecValues = [];
    constructor(props) {
        super(props);
        this.state = {
            //isRecorded: false,
            selectedOrder: '',
            selectedIndex: 0,
            //bounceValue: new Animated.Value(yScale(300))
        };
        this.startRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];
        this.stopRecValues = ['0', '1', '2', '3', '4', '5', '10', '30'];
    }

    componentDidMount() {
        if (this.props.isRecordedSingle) {
            let selectedOrder;
            this.props.orderData.forEach((cur, i) => {
                if (this.props.selectedAssetInfo?.order_id === cur?.order_id) {
                    //may need to add condition, just like in seasonMyTicket
                    selectedOrder = cur;
                }
            });
            if (selectedOrder) {
                this.selectedOrderId = this.props.selectedAssetInfo?.order_id; //does this even do anything?
                //this will set the initial values of the modify myticket options to the values already selected for that order
                // this.seasonIndex = this.seasonValues.indexOf(selectedOrder.start_from) > -1 ? this.seasonValues.indexOf(selectedOrder.start_from): null;
                // this.recIndex = this.recValues.indexOf(selectedOrder.record_state) > -1 ? this.recValues.indexOf(selectedOrder.record_state): null;
                // this.chanIndex = this.chanValues.indexOf(selectedOrder.channel) > -1 ? this.chanValues.indexOf(selectedOrder.channel): null;

                this.startIndex =
                    this.startRecValues.indexOf(selectedOrder.start_recording) > -1 ? this.startRecValues.indexOf(selectedOrder.start_recording) : null;
                this.stopIndex =
                    this.stopRecValues.indexOf(selectedOrder.stop_recording) > -1 ? this.stopRecValues.indexOf(selectedOrder.stop_recording) : null;

                //this.startFromIndex = this.startFromValues.indexOf(selectedOrder.start_from) || this.startFromIndex;
                this.setState({ selectedOrder });
            }
        }
    }

    _listenerFunction = (key) => {
        const { keyMap, connTimeOut } = this.props;
        if (!connTimeOut) {
            if (!this.props.isRecordedSingle) {
                switch (key.keyCode) {
                    case keyMap.up: //up
                        this.state.selectedIndex > 0 && this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                        break;
                    case keyMap.down: //down
                        this.state.selectedIndex < 4 && this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                        break;
                    case keyMap.right:
                        if (this.state.selectedIndex === 3)
                            this.startIndex < this.startRecValues.length - 1 ? (this.startIndex = this.startIndex + 1) : (this.startIndex = 0);
                        if (this.state.selectedIndex === 4)
                            this.stopIndex < this.stopRecValues.length - 1 ? (this.stopIndex = this.stopIndex + 1) : (this.stopIndex = 0);
                        this.setState({ selectedIndex: this.state.selectedIndex });
                        break;
                    case keyMap.left:
                        if (this.state.selectedIndex === 3)
                            this.startIndex > 0 ? (this.startIndex = this.startIndex - 1) : (this.startIndex = this.startRecValues.length - 1);
                        if (this.state.selectedIndex === 4)
                            this.stopIndex > 0 ? (this.stopIndex = this.stopIndex - 1) : (this.stopIndex = this.stopRecValues.length - 1);
                        this.setState({ selectedIndex: this.state.selectedIndex });
                        break;
                    case keyMap.select:
                        if (this.state.selectedIndex === 2) {
                            //user selects back option
                            this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen);
                        } else if (this.state.selectedIndex === 0) {
                            !this.props.isPostingOrder &&
                                this.props.postRecording(
                                    'single',
                                    this.props.selectedProgram.schedule_id,
                                    this.props.subscriberID,
                                    this.startIndex === 0 ? 0 : this.startRecValues[this.startIndex],
                                    this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]
                                );
                            this.closeOptions('recordWithTheseOptions');
                        } else if (this.state.selectedIndex === 1) {
                            this.closeOptions('cancel');
                        } else {
                            //console.log("this selected " + this.state.selectedIndex);
                        }
                        break;
                    case keyMap.back:
                        this.closeOptions('dismiss');
                        break;
                }
            } else {
                switch (key.keyCode) {
                    case keyMap.up: //up
                        this.state.selectedIndex > 0 && this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                        break;
                    case keyMap.down: //down
                        this.state.selectedIndex < 5 && this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                        break;
                    case keyMap.right:
                        if (this.state.selectedIndex === 4)
                            this.startIndex < this.startRecValues.length - 1 ? (this.startIndex = this.startIndex + 1) : (this.startIndex = 0);
                        if (this.state.selectedIndex === 5)
                            this.stopIndex < this.stopRecValues.length - 1 ? (this.stopIndex = this.stopIndex + 1) : (this.stopIndex = 0);
                        this.setState({ selectedIndex: this.state.selectedIndex });
                        break;
                    case keyMap.left:
                        if (this.state.selectedIndex === 4)
                            this.startIndex > 0 ? (this.startIndex = this.startIndex - 1) : (this.startIndex = this.startRecValues.length - 1);
                        if (this.state.selectedIndex === 5)
                            this.stopIndex > 0 ? (this.stopIndex = this.stopIndex - 1) : (this.stopIndex = this.stopRecValues.length - 1);
                        this.setState({ selectedIndex: this.state.selectedIndex });
                        break;
                    case keyMap.select:
                        // const { orderInfo } = this.state;
                        if (this.state.selectedIndex === 0) {
                            this.props.updateRecording(
                                this.state.selectedOrder.order_type,
                                this.state.selectedOrder.order_id,
                                this.props.subscriberID,
                                this.startIndex === 0 ? 0 : this.startRecValues[this.startIndex],
                                this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]
                            );
                            this.closeOptions('modifyWithTheseOptions');
                            // (this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen) && this.props.updateRecording(orderInfo.order_id, orderInfo.type, orderInfo.schedule_id, this.props.subscriberID, (this.startIndex === 0) ? 0 : this.startRecValues[this.startIndex], this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]));
                            //(this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen) && this.props.updateRecording( orderInfo.type, orderInfo.order_id, this.props.subscriberID, (this.startIndex === 0) ? 0 : this.startRecValues[this.startIndex], this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]));
                        } else if (this.state.selectedIndex === 1) {
                            //This (below) will be needed in the confirmation component(modifyConfirm)
                            //(this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen) && this.props.deleteRecording(orderInfo.order_id));
                            this.closeOptions('modifyConfirm');
                        } else if (this.state.selectedIndex === 2) {
                            this.closeOptions('justToggleRecordingScreen');
                        }
                        break;
                    case keyMap.back:
                        this.closeOptions('dismiss');
                        break;
                }
            }
        }
    };

    componentWillUnmount() {
        this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false);
        // this._seasonEmitter && this._seasonEmitter.remove();
    }

    closeOptions(nextAction) {
        this.actionOnSelection(nextAction);
    }

    actionOnSelection = (nextAction) => {
        // console.log("what's happening: ", nextAction, this.props)
        switch (nextAction) {
            case 'cancel':
                !this.props.fsRecFlag && this.props.isFullScreen && !this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(true);
                this.props.fsRecFlag && this.props.isFullScreen && this.props.reopenFSOptionsMenu(true);
                !this.props.isFullScreen && this.props.isRecordingScreenOpen && this.props.onOptionClose(false);
                this.props.isFullScreen && this.props.toggleRecordingScreen(false);
                break;
            case 'recordWithTheseOptions':
                this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen);
                // this.props.isFullScreen && !this.props.onFullScreenInfoOpen && this.props.onFullScreenInfoOpen(true);
                break;
            case 'modifyWithTheseOptions':
                this.props.toggleRecordingScreen(!this.props.isRecordingScreenOpen);
                // this.props.isFullScreen && !this.props.onFullScreenInfoOpen && this.props.onFullScreenInfoOpen(true);
                break;
            case 'justToggleRecordingScreen':
                !this.props.isFullScreen && this.props.onOptionClose(false);
                this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false);
                this.props.fsRecFlag && this.props.isFullScreen && this.props.reopenFSOptionsMenu(true);
                this.props.isFullScreen && !this.props.isFullScreenInfoOpen && this.props.onFullScreenInfoOpen(true);
                break;
            case 'dismiss':
                this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false);
                this.props.isFullScreen && this.props.onFullScreenInfoOpen && this.props.onFullScreenInfoOpen(false);
                !this.props.isFullScreen && this.props.isOptionOpen && this.props.onOptionClose(false); //open options
                break;
            case 'modifyConfirm':
                // console.log("is recording single: ", this.props.isRecordedSingle);
                if (this.state.selectedOrder.order_type === 'single') {
                    //if this is single
                    this.props.deleteRecording(this.state.selectedOrder.order_type, this.state.selectedOrder.order_id);
                } else {
                    //let's add logic - not sure, problem is not consistent - order deleted, but still in assets
                    for (let c = 0; c < this.props.assetData.length; c++) {
                        if (this.props.assetData[c].order_id === this.props.selectedAssetInfo.order_id) {
                            this.props.deleteRecording(this.props.assetData[c].type, this.props.assetData[c].order_id);
                        }
                    }
                }
                this.props.isRecordingScreenOpen && this.props.toggleRecordingScreen(false);
                break;
        }
    };

    handleRecordSelection = () => {
        !this.props.isPostingOrder &&
            this.props.postRecording(
                'single',
                this.props.selectedProgram.schedule_id,
                this.props.subscriberID,
                this.startIndex === 0 ? 0 : this.startRecValues[this.startIndex],
                this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]
            );
        this.closeOptions('recordWithTheseOptions');
    };

    handleChangeOptionIndex = (isDecrement, option) => {
        switch (option) {
            // case "timeChannel": //has not been implemented yet. Exists as placeholder in mobile app
            //     if (isDecrement) {

            //     } else {

            //     }
            //     this.setState({ selectedIndex : this.state.selectedIndex})
            //     break;
            case 'startRec':
                if (isDecrement) {
                    this.startIndex > 0 ? (this.startIndex = this.startIndex - 1) : (this.startIndex = this.startRecValues.length - 1);
                } else {
                    this.startIndex < this.startRecValues.length - 1 ? (this.startIndex = this.startIndex + 1) : (this.startIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            case 'stopRec':
                if (isDecrement) {
                    this.stopIndex > 0 ? (this.stopIndex = this.stopIndex - 1) : (this.stopIndex = this.stopRecValues.length - 1);
                } else {
                    this.stopIndex < this.stopRecValues.length - 1 ? (this.stopIndex = this.stopIndex + 1) : (this.stopIndex = 0);
                }
                this.setState({ selectedIndex: this.state.selectedIndex });
                break;
            default:
                break;
        }
    };

    render() {
        const { isMovie, selectedAssetInfo } = this.props;
        let minOrMinsStartRec = this.startRecValues[this.startIndex] === '1' ? ' minute ' : ' minutes ';
        let minOrMinsStopRec = this.stopRecValues[this.stopIndex] === '1' ? ' minute ' : ' minutes ';
        const startRec = this.startIndex === 0 ? 'on time' : this.startRecValues[this.startIndex] + minOrMinsStartRec + 'early';
        const stopRec = this.stopIndex === 0 ? 'on time' : this.stopRecValues[this.stopIndex] + minOrMinsStopRec + 'after';
        // <CustomItem text='Keep Until:' option='Space needed' isSelected={this.state.selectedIndex === 4} />
        //console.log("props from season record: ", this.props, this.state);
        return (
            <>
                {!this.props.isRecordedSingle ? (
                    //creating new recordings
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={css(styles.Container)}>
                        <FontAwesomeIcon onClick={this.props.onOptionClose} className={css(styles.ExitButton)} icon="times" />
                        <div className={css(styles.Header)}>
                            <div className={css(styles.HeaderText)}>{'Recording Options'}</div>
                        </div>
                        <div className={css(styles.Options)}>
                            <Item text={`Record ${isMovie ? 'movie' : 'episode'} with these options`} onSelection={this.handleRecordSelection} />
                            <Item text={`Cancel (don't record this ${isMovie ? 'movie' : 'episode'})`} onSelection={() => this.closeOptions('cancel')} />
                            {/* <CustomItem 
                                    text='Time & Channel:'
                                    option={startRec} 
                                    //optionIndex={this.startIndex}
                                    changeOptionIndex={(isDecrement) => {this.handleChangeOptionIndex(isDecrement,'timeChannel')}}
                                /> */}
                            <CustomItem
                                text="Start recording:"
                                option={startRec}
                                //optionIndex={this.startIndex}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'startRec');
                                }}
                            />
                            <CustomItem
                                text="Stop recording:"
                                option={stopRec}
                                //optionIndex={this.stopIndex}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'stopRec');
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    //modify scheduled recordings
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={css(styles.Container)}>
                        <FontAwesomeIcon onClick={this.props.onOptionClose} className={css(styles.ExitButton)} icon="times" />
                        <div className={css(styles.Header)}>
                            <div className={css(styles.HeaderText)}>{'Recording Options'}</div>
                        </div>
                        <div className={css(styles.Options)}>
                            <Item
                                text={`Modify this ${isMovie ? 'movie' : 'episode'} with these options`}
                                onSelection={() => {
                                    this.props.updateRecording(
                                        this.state.selectedOrder.order_type,
                                        this.state.selectedOrder.order_id,
                                        this.props.subscriberID,
                                        this.startIndex === 0 ? 0 : this.startRecValues[this.startIndex],
                                        this.stopIndex === 0 ? 0 : this.stopRecValues[this.stopIndex]
                                    );
                                    this.closeOptions('modifyWithTheseOptions');
                                }}
                            />
                            <Item
                                text={
                                    selectedAssetInfo.sc_recording_state && selectedAssetInfo.sc_recording_state !== 'recorded'
                                        ? 'Unschedule this recording'
                                        : 'Delete this recording'
                                } //here
                                onSelection={() => this.closeOptions('modifyConfirm')}
                            />
                            <Item text={`Exit`} onSelection={() => this.closeOptions('cancel')} />
                            <CustomItem
                                text="Start recording:"
                                option={startRec}
                                //optionIndex={this.startIndex}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'startRec');
                                }}
                            />
                            <CustomItem
                                text="Stop recording:"
                                option={stopRec}
                                //optionIndex={this.stopIndex}
                                changeOptionIndex={(isDecrement) => {
                                    this.handleChangeOptionIndex(isDecrement, 'stopRec');
                                }}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    Container: {
        //alignSelf:'flex-end',
        // width: '20em',
        minHeight: '8em',
        position: 'absolute',
        // position: '-webkit-sticky',
        // position: 'sticky',
        //alignSelf: 'flex-end',
        //display: 'flex',
        //flexDirection: 'column',
        backgroundColor: '#202020',
        //justifyContent: 'center',
        //alignItems: 'flex-start',
        //paddingLeft: '4em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'white',
        //borderRadius: '5px',
        //marginTop:'10em',
        //paddingLeft: '4em',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        //marginTop: 'auto',
        marginBottom: '3em',
        //padding: '0 1em'
        width: '25em',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    OptionItem: {
        // cursor: 'pointer',
        // //margin: '3px',
        // //padding: '4px',
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        padding: '.6em',
    },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        // color: '#61dafb',
        //fontSize:'1.3em',
        // padding: '.1em',
        marginLeft: '4em',
        //borderColor: 'red',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        width: '17em',
    },
    HeaderText: {
        fontSize: '1.3em',
    },
    CustomItemContainer: {
        //margin: '3px',
        //padding: '4px',
        padding: '.6em',
        display: 'flex',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    // CustomItemText: {

    // },
    CustomItemOption: {
        width: '9em',
        textAlign: 'center',
    },
    customItemArrow: {
        backgroundColor: '#202020',
        cursor: 'pointer',
        margin: '0 1em',
        //borderRadius: '.3em',
        width: '1.3em',
        height: '1.2em',
        //textDecoration: 'none',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.02em',
        fontSize: '1.2em',
        color: 'white',
        padding: 0,
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
        },
        overflow: 'hidden',
    },
    // SubHeader: {
    //     marginTop: '2em',
    //     color: 'white',
    //     margin: '1em 2em',
    //     padding: '.1em'
    // }
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
    ArrowIcon: {
        width: '1.1em',
    },
});

// const styles = StyleSheet.create({
//     optionsContainer: {
//         position: 'absolute',
//         width: xScale(650),
//         height: yScale(300),
//         bottom: 0,
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignSelf: 'center',
//         backgroundColor: '#1B1B1B'
//     },
//     optionsHeader: {
//         flex: 2,
//         backgroundColor: '#1B1B1B',
//         alignSelf: 'stretch',
//         paddingHorizontal: xScale(20),
//         justifyContent: 'center',
//         paddingBottom: yScale(5)
//     },
//     itemListContainer: {
//         flex: 8,
//         backgroundColor: '#171717',
//         alignSelf: 'stretch',
//         paddingHorizontal: xScale(15),
//         paddingVertical: yScale(5)
//     },
//     customItemText: {
//         fontFamily: 'OpenSans-Light',
//         color: '#9B9B9B',
//         fontSize: fontScale(22),
//         flex: 3,
//         marginLeft: xScale(12),
//         paddingVertical: xScale(3)
//     },
//     customText: {
//         fontFamily: 'OpenSans-Light',
//         color: '#9B9B9B',
//         fontSize: fontScale(22)
//     },
//     customTextSelected: {
//         fontFamily: 'OpenSans-Light',
//         fontWeight: '600',
//         color: '#fff',
//         fontSize: fontScale(22),
//     },
//     customItem: {
//         flex: 1,
//         borderRadius: xScale(2),
//         paddingHorizontal: xScale(12),
//         paddingVertical: yScale(4),
//     },
//     customItemSelected: {
//         flex: 1,
//         borderRadius: xScale(2),
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(3),
//         borderRadius: xScale(2),
//         borderColor: '#fff',
//         backgroundColor: '#5a5a5a',
//         borderWidth: xScale(2)
//     },
//     item: {
//         flex: 1,
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(6),//accounting for borderWidth selected
//     },
//     itemText: {
//         fontFamily: 'OpenSans-Regular',
//         color: '#9B9B9B',
//         fontSize: fontScale(22),
//     },
//     itemSelectedText: {
//         fontFamily: 'OpenSans-Regular',
//         fontWeight: '600',
//         color: '#fff',
//         fontSize: fontScale(22),
//     },
//     itemSelected: {
//         flex: 1,
//         justifyContent: 'center',
//         paddingHorizontal: xScale(10),
//         paddingVertical: yScale(4),
//         borderRadius: xScale(2),
//         borderColor: '#fff',
//         backgroundColor: '#5a5a5a',
//         borderWidth: xScale(2)
//     },
// });
