import axios from 'axios';

export const lab = false; //change to false for production
// if(__DEV__) lab = true;

const EpicURLs = lab
    ? {
          sms: 'https://sms.lab-development.epicvideo.tech',
          epg: 'https://goepg.lab-development.epicvideo.tech/v1', //"http://goepg-ingress.goepg-dev.10.250.250.73.xip.io/v1",
          // epg: "https://epg.lab-development.skittertv.com",
          cdvr: 'https://cdvr.lab-development.epicvideo.tech',
          sotalAxios: 'https://epic-mha001.epicvideo.tech/sdp/v2',
      }
    : {
          sms: 'https://sms.staging.epicvideo.tech',
          epg: 'https://goepg.staging.epicvideo.tech/v1',
          //   epg: 'https://goepg.aureon-production.epicvideo.tech/v1',
          cdvr: 'https://cdvr.staging.epicvideo.tech',
          sotalAxios: 'https://epic-mha001.epicvideo.tech/sdp/v2',
      };
// {
//       sms: 'https://sms.aureon-production.skittertv.com',
//       //epg: "https://epg.aureon-production.skittertv.com",
//       epg: 'https://goepg.aureon-production.epicvideo.tech/v1', //"http://goepg-ingress.goepg-dev.10.250.250.73.xip.io/v1",
//       cdvr: 'https://cdvr.aureon-production.skittertv.com',
//       sotalAxios: 'https://discovery-ams.sotalcloud.com/sdp/v2',
//   };

export const loginAxios = axios.create({
    //creating an instance for login requests
    baseURL: EpicURLs.sms,
    // timeout: 10000,//only works out when there is no phisical connection to the Internet
    headers: {
        'Content-Type': 'application/json', //tells the server that JSON is incoming
        // 'Accept': 'application/json' //tells the server that we expect JSON back - (application/json - should be default)
    },
});

export const channelsAxios = axios.create({
    //creating an instance for getChannels requests
    baseURL: EpicURLs.epg,
    // timeout: 30000,//timeout is 20 seconds for now
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});

export const postOrderAxios = axios.create({
    //creating an instance for postOrder requests
    baseURL: EpicURLs.cdvr,
    // timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});

export const imageAxios = axios.create({
    //creating an instance for image requests
    baseURL: EpicURLs.epg,
    // timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});

export const assetsAxios = axios.create({
    //creating an instance for assets requests
    baseURL: EpicURLs.cdvr,
    // timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});

export const sotalAxios = axios.create({
    baseURL: EpicURLs.sotalAxios,
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});

export const restartLiveEventsAxios = axios.create({
    baseURL: EpicURLs.sotalAxios,
    // timeout: 30000,
    headers: {
        'Content-Type': 'application/json', //default
    },
});

export const restartStreamAxios = axios.create({
    baseURL: EpicURLs.sotalAxios,
    // timeout: 30000,
    headers: {
        'Content-Type': 'application/json', //default
    },
});

export const channelsLoadAxios = axios.create({
    //creating an instance for getChannels requests
    baseURL: EpicURLs.epg,
    timeout: 16000, //12 sec timeout for each request
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json'
    },
});
//for fetching upcoming series assets
export const upcomingAxios = axios.create({
    baseURL: EpicURLs.cdvr, //'https://cdvr.epicvideo.tech/api/upcoming/?limit=5',
    headers: {
        'Content-Type': 'application/json',
        // 'x-requested-with: XMLHttpRequest'
    },
});

export const setTokenInHeader = (AUTH_TOKEN) => {
    //set's this token in header of every consecutive request
    loginAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    channelsAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    postOrderAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    //restartAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    imageAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    assetsAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    channelsLoadAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    upcomingAxios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
};

export const clearTokenInHeader = () => {
    loginAxios.defaults.headers.common['Authorization'] = ``;
    channelsAxios.defaults.headers.common['Authorization'] = ``;
    postOrderAxios.defaults.headers.common['Authorization'] = ``;
    restartLiveEventsAxios.defaults.headers.common['X-Auth-Token'] = ``;
    restartStreamAxios.defaults.headers.common['X-Auth-Token'] = ``;
    imageAxios.defaults.headers.common['Authorization'] = ``;
    assetsAxios.defaults.headers.common['Authorization'] = ``;
    sotalAxios.defaults.headers.common['X-Auth-Token'] = ``;
    channelsLoadAxios.defaults.headers.common['Authorization'] = ``;
    upcomingAxios.defaults.headers.common['Authorization'] = ``;
};

export const setScTokenInHeader = (AUTH_TOKEN) => {
    //set's this token in header of every consecutive request
    sotalAxios.defaults.headers.common['X-Auth-Token'] = `${AUTH_TOKEN}`;
    restartLiveEventsAxios.defaults.headers.common['X-Auth-Token'] = `${AUTH_TOKEN}`;
    restartStreamAxios.defaults.headers.common['X-Auth-Token'] = `${AUTH_TOKEN}`;
};
