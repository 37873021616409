import Bugsnag from '@bugsnag/js';

export const bugSnagNotifierSetup = (user) => {
    // console.log(user);
    Bugsnag.setUser(user.id.toString(), user.first_name + ' ' + user.last_name, user.email);
    Bugsnag.addMetadata('company', {
        name: user.telco_name,
        id: user.telco_id,
    });
};

export const bugSnagNotifier = (errorObject, sentFrom, subDeviceInfo, streamDetails, retryCount, requestCopy, body) => {
    if (requestCopy || body) {
        //bugsnag for APIs
        Bugsnag.notify(new Error(errorObject.error), function (event) {
            //"API request error"
            const metaBundle = {
                requestURL: requestCopy.url,
                method: requestCopy.method,
                headers: requestCopy.headers,
                retries: retryCount,
                error: errorObject.message,
                sentFrom: sentFrom,
                statusCode: errorObject.status,
                body: body,
                subDeviceInfo: subDeviceInfo,
                streamDetails: streamDetails,
                ...errorObject.extraFields,
            };
            event.addMetadata('metaData', metaBundle);
        });
        //Bugsnag.notify(new Error("API CALL FAIL TEST"))
    } else {
        Bugsnag.notify(new Error(errorObject), function (event) {
            const metaBundle = {
                message: errorObject.message,
                sentFrom: sentFrom,
                subDeviceInfo: subDeviceInfo,
                streamDetails: streamDetails,
                ...errorObject.extraFields,
            };
            event.addMetadata('metaData', metaBundle);
        });
    }
};

export const bugSnagNotifierGeneral = (message, sentFrom, subDeviceInfo, statusError, showTypeUndefined, customData) => {
    //general is used for miscelaneous debugging
    Bugsnag.notify(new Error(message), function (event) {
        const metaBundle = {
            message: message,
            sentFrom: sentFrom,
            subDeviceInfo: subDeviceInfo,
            statusError: statusError,
            showTypeUndefined: showTypeUndefined,
            ...customData,
        };
        event.addMetadata('metaData', metaBundle);
    });
};

// export const bugSnagNotifierSetup = (user) => {
//     // console.log(user)
//     bugsnag.setUser(user.id.toString(), user.first_name+" "+user.last_name, user.email);
// }

// export const bugSnagNotifier = (errorObject, sentFrom, subDeviceInfo, retryCount, requestCopy, body, ) => {
//     // console.log("error object", errorObject, retryCount, requestCopy, body, sentFrom)
//     if (requestCopy || body){ //bugsnag for APIs
//         bugsnag.notify(new Error("API request error"), function(report) {
//             report.metadata = {
//                 requestURL: {"requestURL": requestCopy.url},
//                 method: {"method": requestCopy.method},
//                 retries: {"retryCount": retryCount},
//                 error: {"error" : errorObject.message},
//                 sentFrom: {"sent from": sentFrom},
//                 statusCode: {"statusCode" : errorObject.status},
//                 body: {"body": body},
//                 subDeviceInfo: {"subDeviceInfo": subDeviceInfo}
//             };
//         });
//     }else{
//         bugsnag.notify(new Error(errorObject.status), function(report) {
//             report.metadata = {
//                 message: {"message": errorObject.message},
//                 sentFrom: {"sent from": sentFrom},
//                 subDeviceInfo: {"subDeviceInfo": subDeviceInfo}
//             };
//         });
//     }
// }
