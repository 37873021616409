import { assetsAxios, upcomingAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import axios from 'axios';

let errorObject = {};
let retryCount = 0;
let requestCopy = {}; //only used for Network Error
let assetTimeOut = null;
const CancelToken = axios.CancelToken;
let cancel;

export const getAssetsData = (sub_id) => {
    return assetsAxios.get(`/api/orders`, {
        params: {
            subscriber_id: sub_id,
        },
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

// for upcoming shows
export const getUpcomingData = (sub_id) => {
    return upcomingAxios.get(`/api/upcoming`, {
        params: {
            subscriber_id: sub_id,
        },
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

export const updateAssetFavorite = (asset_id, favoriteStatus) => {
    return assetsAxios.put(`/api/asset/favorite/${asset_id}`, {
        favorite: favoriteStatus,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

//interceptors
assetsAxios.interceptors.request.use(
    (request) => {
        request.retries = 3;
        request.retryDelay = 3000; //in ms
        requestCopy = request;
        let timeoutAmount = 51000;
        if (request.url.startsWith('/api/asset/favorite/')) {
            timeoutAmount = 20000;
        }
        // console.log("getAssets request: ", request)
        assetTimeOut = setTimeout(() => {
            cancel && cancel('Network Error');
        }, timeoutAmount); //if original timeout does not work
        return request;
    },
    (error) => {
        //console.log("getAssets request error: ", error)
        return error;
    }
);

assetsAxios.interceptors.response.use(
    (response) => {
        retryCount = 0;
        requestCopy = {};
        assetTimeOut && clearTimeout(assetTimeOut);
        cancel && (cancel = null); //null the cance function for successful req
        // console.log("response: ", response);
        return response;
    },
    (error) => {
        // console.log(error.message, error.response)
        assetTimeOut && clearTimeout(assetTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        //don't retry for favorite call
        if (config.url.startsWith('/api/asset/favorite/')) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 || (error.response.status === 404 && retryCount > 0))
        ) {
            //auth error!!! Kick them out!
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Check if we've maxed out the total number of retries
        if (retryCount >= config.retries) {
            // Reject with the error
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        // Increase the retry count
        retryCount += 1;
        //console.log(retryCount, config.retryDelay * retryCount);

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay * retryCount || 1);
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return assetsAxios(config);
        });
    }
);
