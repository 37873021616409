import {
    TOGGLE_FULLSCREEN_INFO,
    TOGGLE_OPTIONS_POPUP,
    SET_HORIZONTAL_PAGE,
    NEXT_HORIZONTAL_PAGE,
    PREVIOUS_HORIZONTAL_PAGE,
    SET_PROGRAM_INDEX,
    SET_SELECTED_CHANNEL_INDEX,
    SET_PREVIOUS_CHANNEL_INDEX,
    SET_PROGRAM_INDEX_COUNTER,
    TOGGLE_PROGRAM_INDEX_COUNTER,
    TOGGLE_RECORDING_SCREEN,
    IS_RECORDING_SCREEN,
    POST_RECORD_BEGIN,
    POST_RECORD_SUCCESSFUL,
    POST_RECORD_ERROR,
    TOGGLE_RECORDING_TOAST,
    UPDATE_RECORD_BEGIN,
    UPDATE_RECORD_SUCCESSFUL,
    UPDATE_RECORD_ERROR,
    DELETE_RECORD_BEGIN,
    DELETE_RECORD_SUCCESSFUL,
    DELETE_RECORD_ERROR,
    CANCEL_RECORD_ERROR,
    CHANGE_PROGRAM_START,
    CHANGE_ACTIVE_ROW_INDEX,
    UPDATE_PAGE_DATA,
    TRANSITION_NEW_PAGE_IN,
    UPDATE_NEXT_PAGE_DATA,
    UPDATE_CHANNEL_INDEXES,
    UPDATE_CHANNEL_START_END_TIMES,
    UPDATE_ANIMATED_VALUE,
    TOGGLE_TICKET_SCREEN,
    IS_CREATING_TICKET_SCREEN,
    TOGGLE_CANCEL_TICKET_SCREEN,
    IS_CANCELLING_TICKET_SCREEN,
    CHANNEL_BAR_INFO_STATE,
    CANCEL_ORDER_BEGIN,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    UPDATE_CHANNELS_LIST,
    GET_CHANNELS_SUCCESS,
    GET_CHANNELS_FAILURE,
    GET_CHANNELS_BEGIN,
    TOGGLE_OPTIONS_MENU,
    TOGGLE_GUIDE_ANIMATIONS,
    SET_RESTART_IN_PROGRESS,
    SET_RESTART_LOADING,
    SET_RESTART_STREAM,
    CLEAR_RESTART,
    RESTART_COMPLETED_TOAST_SET,
    UPDATE_CATCHUP_LIVE_EVENTS,
    PAGE_INIT,
    PAGE_CHANGE_RIGHT,
    PROGRAM_CHANGE_RIGHT,
    PAGE_CHANGE_LEFT,
    PROGRAM_CHANGE_LEFT,
    PROGRAM_CHANGE_UP,
    PAGE_CHANGE_UP,
    PROGRAM_CHANGE_DOWN,
    PAGE_CHANGE_DOWN,
    SET_SEARCH_MAP,
} from '../types/guide';
//import { ToastAndroid } from 'react-native';
// import { xScale, yScale } from '../utils/Scaling';
import { postRecordingDVR, updateRecordingDVR, deleteRecordingDVR } from '../services/PostOrder';
import { updateLoading, getUpcomingAssets, getAssets, updateUpcomingLoading } from '../actions/myshows';
import { errorIntercept } from '../actions/main';
import { errorHandler } from '../utils/ErrorHandler';
import { getChannelsWithSeriesId, getChannelsWithSourceId } from '../services/ChannelService';
import { getRestartStream } from '../services/RestartStreamService';
import { getCatchupLiveEvents } from '../services/RestartLiveEventsService';
import moment from 'moment';

let dRegEx = /\d+/; //regEx for getChannels method;
let getAssetsInterval = null;

// export const toggleFullScreen = () => ({
//     type: TOGGLE_FULLSCREEN,
// });

// export const toggleFullScreenInfo = () => ({
//     type: TOGGLE_FULLSCREEN_INFO,
// });

// Create a new recording
export const postRecording = (
    order_type,
    schedule_id,
    subscriber_id,
    start_recording,
    stop_recording,
    start_from,
    channel,
    get_hd,
    record_state,
    is_start_from_year
) => {
    // console.log("order type: ", order_type, "schedule_id: ", schedule_id, "subscriber_id: ", "subscriber_id", subscriber_id, "start_recording: ", start_recording, "stop_recording: ", stop_recording, "start", start_from, channel, get_hd, record_state, is_start_from_year);
    return (dispatch, getState) => {
        getAssetsInterval && clearTimeout(getAssetsInterval);
        let subData = getState().login.subscriberData;
        let subDeviceInfo = subData.subDeviceInfo;
        dispatch(updateLoading(true));
        dispatch(updateUpcomingLoading(true));
        dispatch(postRecordBegin());
        return postRecordingDVR(
            order_type,
            schedule_id,
            subscriber_id,
            start_recording,
            stop_recording,
            start_from,
            channel,
            get_hd,
            record_state,
            is_start_from_year,
            dispatch
        )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(postRecordSuccess(response.status));
                    getAssetsInterval = setTimeout(() => {
                        dispatch(getAssets(subData));
                    }, 40000);
                    dispatch(updateLoading(false));
                }
            })
            .catch((error) => {
                //if error code = 409 - post the message that order alread created
                getAssetsInterval && clearTimeout(getAssetsInterval);
                dispatch(updateLoading(false));
                dispatch(updateUpcomingLoading(false));
                let errorObject = errorHandler(error, 'postRecording', subDeviceInfo);
                dispatch(errorIntercept(errorObject));
                dispatch(postRecordError(errorObject.message));
            });
    };
};

//Update a Recording
export const updateRecording = (
    order_type,
    order_id,
    subscriber_id,
    start_recording,
    stop_recording,
    start_from,
    channel,
    get_hd,
    record_state,
    is_start_from_year
) => {
    return (dispatch, getState) => {
        getAssetsInterval && clearTimeout(getAssetsInterval);
        let subData = getState().login.subscriberData;
        let subDeviceInfo = subData.subDeviceInfo;
        let isLoading = getState().myshows.isLoadingAssets;
        dispatch(updateLoading(true));
        dispatch(updateRecordBegin());
        dispatch(updateUpcomingLoading(true));
        return updateRecordingDVR(
            order_type,
            order_id,
            subscriber_id,
            start_recording,
            stop_recording,
            start_from,
            channel,
            get_hd,
            record_state,
            is_start_from_year
        )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateRecordSuccess(response.status));
                    getAssetsInterval = setTimeout(() => {
                        dispatch(getAssets(subData));
                    }, 40000);
                    isLoading && dispatch(updateLoading(false));
                }
            })
            .catch((error) => {
                getAssetsInterval && clearTimeout(getAssetsInterval);
                dispatch(updateLoading(false));
                dispatch(updateUpcomingLoading(false));
                let errorObject = errorHandler(error, 'updateRecording', subDeviceInfo);
                dispatch(errorIntercept(errorObject));
                dispatch(updateRecordError(errorObject.message));
            });
    };
};

export const deleteRecording = (type, id) => {
    // was (order_id)
    return (dispatch, getState) => {
        let isCancel = false;
        let isEnable = false;
        if (type === 'disableOrder') {
            isCancel = true;
        } else if (type === 'enableOrder') {
            isEnable = true;
        }
        let subData = getState().login.subscriberData;
        let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
        // const subscriberID = getState().login.subscriberData.id;
        dispatch(updateLoading(true));
        isCancel ? dispatch(cancelOrderBegin()) : isEnable ? dispatch(postRecordBegin()) : dispatch(deleteRecordBegin());
        return deleteRecordingDVR(type, id, dispatch) // was (order_id)
            .then((response) => {
                //TODO: change (value.status !== undefined && dispatch(postRecordSuccess(value.status, value.data.assets[0].schedule_id)))
                //!isCancel ? dispatch(deleteRecordSuccess()) : dispatch(cancelOrderSuccess());
                if (response.status === 200) {
                    isCancel ? dispatch(cancelOrderSuccess()) : isEnable ? dispatch(postRecordSuccess()) : dispatch(deleteRecordSuccess());
                    setTimeout(() => {
                        // if (getState().myshows.isLoadingAssets) {
                        //     ToastAndroid.showWithGravityAndOffset(
                        //         "something went wrong, check again later",
                        //         ToastAndroid.SHORT,
                        //         ToastAndroid.TOP,
                        //         xScale(0),
                        //         yScale(900)
                        //     );
                        //     dispatch(updateLoading(false))
                        //     console.log('loading set back to false after 5 second delay');
                        // }
                        dispatch(updateLoading(false));
                    }, 5000);
                }
            })
            .catch((error) => {
                dispatch(updateLoading(false));
                let errorObject = errorHandler(error, 'deleteRecording', subDeviceInfo); //variable upfront only for console.log
                dispatch(errorIntercept(errorObject));
                isCancel ? dispatch(cancelOrderError()) : isEnable ? dispatch(postRecordError(error.message)) : dispatch(deleteRecordError(error.message));
            });
    };
};

export const clearRestart = () => {
    return {
        type: CLEAR_RESTART,
    };
};

export const setRestartStreamInfo = (sourceInfo) => {
    return {
        type: SET_RESTART_STREAM,
        restartStreamInfo: sourceInfo,
    };
};

export const setRestartInProgress = (program, channel) => {
    return {
        type: SET_RESTART_IN_PROGRESS,
        program: program,
        channel: channel,
    };
};

export const setRestartLoading = (isLoading, isFailure) => {
    return {
        type: SET_RESTART_LOADING,
        isLoading: isLoading,
        isFailure: isFailure,
    };
};

export const startRestartProgram = (program, resource, channel) => {
    return (dispatch, getState) => {
        dispatch(setRestartInProgress(program, channel));
        return getRestartStream(program, resource)
            .then((res) => {
                if (res && typeof res === 'object') {
                    dispatch(setRestartStreamInfo(res));
                } else {
                    //console.log('failed, invalid url string');
                    return new Promise.reject({
                        status: 'restart service error',
                        message: 'invalid url string',
                    });
                }
            })
            .catch((error) => {
                //console.log('startRestartProgram error! :', 'in catch', error);
                let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
                let errorObject = errorHandler(error, 'Restart', subDeviceInfo);
                dispatch(errorIntercept(errorObject));
                const isFailure = true;
                dispatch(setRestartLoading(false, isFailure));
            });
    };
};

export const setPendingRestartProgram = (program, scAssetId, channel) => {
    //only used to set restart when video is blocked by concurrent stream limit
    return (dispatch, getState) => {
        dispatch(setRestartInProgress(program, scAssetId, channel));
    };
};

export const restartCompleted = () => {
    return (dispatch) => {
        dispatch(clearRestart());
        dispatch(restartCompletedToastSet());
    };
};

export const updateRestartAvailablity = () => {
    // this triggers call to get the event list and updates store
    //console.log('UPDATING CATCHUP EVENT LIST');
    return (dispatch, getState) => {
        return getCatchupLiveEvents()
            .then((eventListWithCatchup) => {
                dispatch(updateCatchupLiveEvents(eventListWithCatchup));
            })
            .catch((error) => {
                // console.log('updateRestartAvailablity error! :', 'in catch', error);
                let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
                let errorObject = errorHandler(error, 'get restart live event list', subDeviceInfo);
                dispatch(errorIntercept(errorObject)); //going silent
            });
    };
};

export const updateCatchupLiveEvents = (eventListWithCatchup) => ({
    type: UPDATE_CATCHUP_LIVE_EVENTS,
    eventListWithCatchup,
});

export const restartCompletedToastSet = () => ({
    //sets and triggers toast: "Restart program completed, returning to live TV"
    type: RESTART_COMPLETED_TOAST_SET,
});

//POST Recording
export const postRecordBegin = () => ({
    type: POST_RECORD_BEGIN,
});
export const postRecordSuccess = (status) => ({
    type: POST_RECORD_SUCCESSFUL,
    status,
});
export const postRecordError = (error) => ({
    type: POST_RECORD_ERROR,
    error,
});
//UPDATE Recording
export const updateRecordBegin = () => ({
    type: UPDATE_RECORD_BEGIN,
});
export const updateRecordSuccess = (status) => ({
    type: UPDATE_RECORD_SUCCESSFUL,
    status,
});
export const updateRecordError = (error) => ({
    type: UPDATE_RECORD_ERROR,
    error,
});
//DELETE Recording
export const deleteRecordBegin = () => ({
    type: DELETE_RECORD_BEGIN,
});
export const deleteRecordSuccess = (status) => ({
    type: DELETE_RECORD_SUCCESSFUL,
    status,
});
export const deleteRecordError = (error) => ({
    type: DELETE_RECORD_ERROR,
    error,
});

export const cancelOrderError = (error) => ({
    type: CANCEL_RECORD_ERROR,
    error,
});

export const toggleRecordingToast = (isOpen) => ({
    type: TOGGLE_RECORDING_TOAST,
    isOpen,
});

export const isScreenRecording = (isRecordingScreen) => ({
    type: IS_RECORDING_SCREEN,
    isRecordingScreen,
});

export const toggleRecordingScreen = (isRecordingScreenOpen) => ({
    type: TOGGLE_RECORDING_SCREEN,
    isRecordingScreenOpen,
});

export const setProgramIndex = (programIndex) => ({
    type: SET_PROGRAM_INDEX,
    programIndex,
});

export const setProgramIndexCounter = (programIndexCounter) => ({
    type: SET_PROGRAM_INDEX_COUNTER,
    programIndexCounter,
});

export const toggleProgramIndexCounter = (isProgramIndexCounterSet) => ({
    type: TOGGLE_PROGRAM_INDEX_COUNTER,
    isProgramIndexCounterSet,
});

export const setSelectedChannelIndex = (selectedChannelIndex) => ({
    type: SET_SELECTED_CHANNEL_INDEX,
    selectedChannelIndex,
});

export const setPreviousChannelIndex = (previousChannelIndex) => ({
    type: SET_PREVIOUS_CHANNEL_INDEX,
    previousChannelIndex,
});

export const toggleOptionsPopup = () => ({
    type: TOGGLE_OPTIONS_POPUP,
});

export const setHorizontalPage = (page) => ({
    type: SET_HORIZONTAL_PAGE,
    page,
});

export const nextHorizontalPage = () => ({
    type: NEXT_HORIZONTAL_PAGE,
});

export const previousHorizontalPage = () => ({
    type: PREVIOUS_HORIZONTAL_PAGE,
});

export const changeProgramStart = (newStartTime) => ({
    type: CHANGE_PROGRAM_START,
    newStartTime,
});

export const changeActiveRowIndex = (newRowIndex) => ({
    type: CHANGE_ACTIVE_ROW_INDEX,
    newRowIndex,
});

export const updatePageData = (payload) => ({
    type: UPDATE_PAGE_DATA,
    payload,
});

export const updateNextPageData = (payload) => ({
    type: UPDATE_NEXT_PAGE_DATA,
    payload,
});
export const updatePageTransition = (transitionActive) => ({
    type: TRANSITION_NEW_PAGE_IN,
    transitionActive,
});
// 0-based index, adds 10(non-inclusive) to endIndex
export const updateChannelIndexes = (beginningIndex, endingIndex) => ({
    type: UPDATE_CHANNEL_INDEXES,
    beginningIndex,
    endingIndex,
});
export const updateChannelStartEndTimes = (startTime, endTime) => ({
    type: UPDATE_CHANNEL_START_END_TIMES,
    startTime,
    endTime,
});
export const updateAnimatedValue = (animatedXY) => ({
    type: UPDATE_ANIMATED_VALUE,
    animatedXY,
});

export const getChannels = (series_id, source_id) => (dispatch, getState) => {
    //to get channels for series recording
    let subDeviceInfo = getState().login.subscriberData.subDeviceInfo;
    let subChannelData = getState().login.subscriberData.channels;
    // let newSubChannelArr = [];
    dispatch(getChannelsBegin());
    return getChannelsWithSeriesId(series_id)
        .then((json) => {
            dispatch(getChannelsSuccess(json.data));
            let tempVariable = json.data.data[series_id];
            let tempChannelList = [];
            let tempSeasonNamesObject = {};
            let tempSeasonList = [];
            let channelNames = new Set();
            let customChannelNames = new Set();
            let seasonNames = [];
            let channelObject = {};
            // let tempSeasonNamesKeys = [];
            let sortedAndFilteredSeasonList = [];
            let theBeginningObject = {};
            let newEpisodesOnly = {
                //I put it here because we do not have to recreate it all the time
                end_year: '',
                season_name: '',
                season_number: 'New',
                season_program_id: '0',
                start_year: '0',
            };

            if (
                json.data &&
                json.data.data &&
                !Array.isArray(tempVariable) &&
                json.data.data[series_id] &&
                json.data.data[series_id].channels.length > 0 &&
                json.data.data[series_id].seasons.length > 0
            ) {
                tempChannelList = tempVariable.channels.map((channel, index) => {
                    return {
                        channel: channel.source_id,
                        short_name: channel.short_name,
                        full_name: channel.full_name,
                        call_letters: channel.call_letters,
                        official_call_sign: channel.official_call_sign,
                        source_type: channel.source_type,
                        time_zone: channel.time_zone,
                    };
                });

                tempSeasonList = tempVariable.seasons.map((season, index) => {
                    return {
                        series_id: season.series_id,
                        season_program_id: season.season_program_id,
                        season_number: season.season_number,
                        season_name: season.season_name,
                        start_year: season.start_year,
                        end_year: season.end_year,
                    };
                });

                // console.log("series and source id",series_id,source_id);
                // console.log("new subchanneldata", subChannelData);

                //channelNames.add('All');
                if (tempChannelList.length > 0) {
                    tempChannelList.forEach((list, index) => {
                        channelNames.add(list.full_name);
                    });
                }
                //customChannelNames.add('All');
                let subchannelsArr = subChannelData.filter((x) => x.rovi_source_id === source_id);
                //    console.log("subchannelsArr", subchannelsArr);
                customChannelNames.add(subchannelsArr[0].name);
                //    console.log("customChannelNames", customChannelNames);

                if (tempSeasonList && tempSeasonList.length > 0) {
                    tempSeasonList.forEach((list, index) => {
                        if (list.season_name && list.season_name.match(dRegEx)) {
                            tempSeasonNamesObject[list.season_name.match(dRegEx)[0]] = list.season_name;
                        } else {
                            tempSeasonNamesObject[list.season_number] = 'Season ' + list.season_number;
                        }
                    });
                }

                //we need to filter and sort the tempSeasonList so it will match up with seasonNames. otherwise wrong season will be requested if data is not consistent

                let filteredSeasonList = [];

                tempSeasonList.forEach((cur) => {
                    //test to see if this works then fix test and fix channels
                    if (cur.season_name) {
                        if (filteredSeasonList.findIndex((szn) => +szn.season_name.split(' ')[1] === +cur.season_name.split(' ')[1]) === -1) {
                            filteredSeasonList.push(cur);
                        }
                    } else {
                        if (filteredSeasonList.findIndex((szn) => +szn.season_number === +cur.season_number) === -1) {
                            filteredSeasonList.push(cur);
                        }
                    }
                });

                sortedAndFilteredSeasonList = filteredSeasonList.sort((a, b) => {
                    // filteredSeasonList.sort((a,b)
                    return (
                        (a.season_name ? +a.season_name.split(' ')[1] : +b.season_number) - (b.season_name ? +b.season_name.split(' ')[1] : +b.season_number)
                    );
                });

                // let's get the keys from that object and sort it
                let tempSeasonNamesKeys = Object.keys(tempSeasonNamesObject).sort((a, b) => {
                    return Number(a) - Number(b);
                });

                seasonNames = tempSeasonNamesKeys.map((key) => {
                    return tempSeasonNamesObject[key];
                });

                if (sortedAndFilteredSeasonList && sortedAndFilteredSeasonList.length > 0) {
                    seasonNames.unshift('The beginning');
                    theBeginningObject = { ...sortedAndFilteredSeasonList[0] }; //extracts the first object from Array
                    theBeginningObject['season_name'] = 'Season 0';
                    theBeginningObject['season_number'] = '0';
                    theBeginningObject['season_program_id'] = '0';
                    sortedAndFilteredSeasonList.unshift(theBeginningObject);
                    //for New Episodes Only
                    newEpisodesOnly = { ...sortedAndFilteredSeasonList[0] };
                    newEpisodesOnly['season_number'] = 'New';
                }

                sortedAndFilteredSeasonList.push(newEpisodesOnly);
                // seasonNames.push('New episodes only');
                channelObject = {
                    tempChannelList,
                    tempSeasonList: sortedAndFilteredSeasonList,
                    channelNames: [...channelNames], //spread converts Set(Array like) to Array
                    customChannelNames: [...customChannelNames],
                    seasonNames,
                };

                dispatch(updateChannelsList(channelObject));
                // console.log("channelObject series", channelObject);
                // console.log("series json object", json.data)
                return json.data;
            } else if (
                json.data &&
                json.data.data &&
                !Array.isArray(tempVariable) &&
                json.data.data[series_id] &&
                json.data.data[series_id].channels.length === 0
            ) {
                //let's make request to get channels
                return getChannelsWithSourceId(source_id)
                    .then((result) => {
                        // console.log("get channels with source_id: ", result);
                        let channel = result.data.data[source_id];
                        let tempChannelObject = {};
                        tempChannelObject['channel'] = channel.source_id;
                        tempChannelObject['short_name'] = channel.short_name;
                        tempChannelObject['full_name'] = channel.full_name;
                        tempChannelObject['call_letters'] = channel.call_letters;
                        tempChannelObject['official_call_sign'] = channel.official_call_sign;
                        tempChannelObject['source_type'] = channel.source_type;
                        tempChannelObject['time_zone'] = channel.time_zone;
                        tempChannelList.push(tempChannelObject);

                        if (json.data && json.data.data && json.data.data[series_id] && json.data.data[series_id].seasons.length > 0) {
                            tempSeasonList = tempVariable.seasons.map((season, index) => {
                                return {
                                    series_id: season.series_id,
                                    season_program_id: season.season_program_id,
                                    season_number: season.season_number,
                                    season_name: season.season_name,
                                    start_year: season.start_year,
                                    end_year: season.end_year,
                                };
                            });
                        }

                        //channelNames.add('All');
                        if (tempChannelList.length > 0) {
                            tempChannelList.forEach((list, index) => {
                                channelNames.add(list.full_name);
                            });
                        }

                        //customChannelNames.add('All');
                        let subchannelsArr = subChannelData.filter((x) => x.rovi_source_id === source_id);
                        // console.log("subchannelsArr", subchannelsArr);
                        customChannelNames.add(subchannelsArr[0].custom_channel.custom_name);
                        // console.log("customChannelNames", customChannelNames);

                        if (tempSeasonList && tempSeasonList.length > 0) {
                            tempSeasonList.forEach((list, index) => {
                                if (list.season_name && list.season_name.match(dRegEx)) {
                                    tempSeasonNamesObject[list.season_name.match(dRegEx)[0]] = list.season_name;
                                } else {
                                    tempSeasonNamesObject[list.season_number] = 'Season ' + list.season_number;
                                }
                            });
                        }

                        //we need to filter and sort the tempSeasonList so it will match up with seasonNames. otherwise wrong season will be requested if data is not consistent

                        let filteredSeasonList = [];

                        tempSeasonList.forEach((cur) => {
                            //test to see if this works then fix test and fix channels
                            if (cur.season_name) {
                                if (filteredSeasonList.findIndex((szn) => +szn.season_name.split(' ')[1] === +cur.season_name.split(' ')[1]) === -1) {
                                    filteredSeasonList.push(cur);
                                }
                            } else {
                                if (filteredSeasonList.findIndex((szn) => +szn.season_number === +cur.season_number) === -1) {
                                    filteredSeasonList.push(cur);
                                }
                            }
                        });

                        sortedAndFilteredSeasonList = filteredSeasonList.sort((a, b) => {
                            // filteredSeasonList.sort((a,b)
                            return (
                                (a.season_name ? +a.season_name.split(' ')[1] : +b.season_number) -
                                (b.season_name ? +b.season_name.split(' ')[1] : +b.season_number)
                            );
                        });

                        // let's get the keys from that object and sort it
                        let tempSeasonNamesKeys = Object.keys(tempSeasonNamesObject).sort((a, b) => {
                            return Number(a) - Number(b);
                        });

                        seasonNames = tempSeasonNamesKeys.map((key) => {
                            return tempSeasonNamesObject[key];
                        });

                        if (sortedAndFilteredSeasonList && sortedAndFilteredSeasonList.length > 0) {
                            seasonNames.unshift('The beginning');
                            theBeginningObject = { ...sortedAndFilteredSeasonList[0] }; //extracts the first object from Array
                            theBeginningObject['season_name'] = 'Season 0';
                            theBeginningObject['season_number'] = '0';
                            theBeginningObject['season_program_id'] = '0';
                            sortedAndFilteredSeasonList.unshift(theBeginningObject);
                        } else if (sortedAndFilteredSeasonList && sortedAndFilteredSeasonList.length === 0) {
                            //when show has no seasons due to Rovie
                            theBeginningObject['end_year'] = '';
                            theBeginningObject['season_name'] = 'Season 0';
                            theBeginningObject['season_number'] = '0';
                            theBeginningObject['season_program_id'] = '0';
                            theBeginningObject['start_year'] = '0';
                            seasonNames.push('The beginning'); //this case only occurs when there are no seasonNames
                            sortedAndFilteredSeasonList.push(theBeginningObject);
                        }

                        sortedAndFilteredSeasonList.push(newEpisodesOnly);
                        // seasonNames.push('New episodes only');

                        channelObject = {
                            tempChannelList,
                            tempSeasonList: sortedAndFilteredSeasonList,
                            channelNames: [...channelNames], //spread converts Set(Array like) to Array,
                            customChannelNames: [...customChannelNames],
                            seasonNames,
                        };

                        dispatch(updateChannelsList(channelObject));
                        // console.log("channelobject source", channelObject);
                        return json.data;
                    })
                    .catch((error) => {
                        return new Promise.reject({
                            status: 'postOrder',
                            message: 'We have techinical difficulties retrieving program data. Please try again later.',
                        });
                    });
            } else {
                return new Promise.reject({
                    status: 'postOrder',
                    message: 'We have techinical difficulties retrieving program data. Please try again later.',
                });
            }
        })
        .catch((error) => {
            //code 409 would fail
            let errorObject = errorHandler(error, 'getChannels from guide.js', subDeviceInfo);
            dispatch(errorIntercept(errorObject));
            dispatch(getChannelsFailure(errorObject.message));
        });
};

export const updatePageChannels = (startChannelIndex, endChannelIndex, channels, startTime, endTime) => {
    // console.log("arguments: ", startChannelIndex, endChannelIndex, channels, startTime, endTime);
    return (dispatch) => {
        let channelData = [];

        //Check if time being fetched is more than 3 days(259200 seconds) in the past - return null if true
        //(current time - guide page start time) > 259200 seconds
        let currentTime = moment()
            .subtract(moment().minute() % 30, 'minutes')
            .subtract(moment().second(), 'seconds')
            .unix(); //math.floor(closest 30 minutes)
        if (currentTime - startTime > 259200) return null;

        //if startChannelIndex is negative, the user passed the 0th index of all our channels data
        if (startChannelIndex < 0) {
            if (endChannelIndex !== 0) {
                let tempChannelData1 = JSON.parse(JSON.stringify(channels.slice(channels.length + startChannelIndex, channels.length)));
                let tempChannelData2 = JSON.parse(JSON.stringify(channels.slice(0, endChannelIndex)));
                channelData = tempChannelData1.concat(tempChannelData2);
                dispatch(updateChannelIndexes(channels.length + startChannelIndex, endChannelIndex));
            } else {
                //startChannelIndex will be -10 in this case. update the index after grabbing the correct data
                channelData = JSON.parse(JSON.stringify(channels.slice(channels.length + startChannelIndex, channels.length)));
                dispatch(updateChannelIndexes(channels.length + startChannelIndex, channels.length));
            }
        } else if (endChannelIndex > channels.length) {
            if (startChannelIndex !== channels.length) {
                let tempChannelData1 = JSON.parse(JSON.stringify(channels.slice(startChannelIndex, channels.length)));
                let tempChannelData2 = JSON.parse(JSON.stringify(channels.slice(0, endChannelIndex - channels.length)));
                channelData = tempChannelData1.concat(tempChannelData2);
                dispatch(updateChannelIndexes(startChannelIndex, endChannelIndex - channels.length));
            } else {
                //endChannelIndex will be 10 in this case. update the index after grabbing the correct data
                channelData = JSON.parse(JSON.stringify(channels.slice(0, endChannelIndex - channels.length)));
                dispatch(updateChannelIndexes(0, endChannelIndex - channels.length));
            }
            //case where the end of the channels array ends halfway on the page
        } else if (startChannelIndex > endChannelIndex) {
            let tempChannelData1 = JSON.parse(JSON.stringify(channels.slice(startChannelIndex, channels.length)));
            let tempChannelData2 = JSON.parse(JSON.stringify(channels.slice(0, endChannelIndex)));
            channelData = tempChannelData1.concat(tempChannelData2);
        } else {
            channelData = JSON.parse(JSON.stringify(channels.slice(startChannelIndex, endChannelIndex)));
        }
        //checks if we have enough shows for epg two hour window
        let isChannelListInTwoHourWindow = true;
        //Take the data and grab the correct programs depending on start and end unix times
        channelData.map((channel, idx) => {
            if (channel.programs) {
                isChannelListInTwoHourWindow = replaceChannelProgramList(startTime, endTime, channel, isChannelListInTwoHourWindow);
            }
        });
        if (isChannelListInTwoHourWindow) {
            return channelData;
        } else {
            return null; //may need to be chaned to []
        }
    };
};

const replaceChannelProgramList = (startUnixTime, endUnixTime, channel, isChannelListInTwoHourWindow) => {
    // if(!isChannelListInTwoHourWindow)
    //     return false;
    let programList = [];
    let foundFirstProgram = false;
    const firstIndex = closestTimeIndex(startUnixTime * 1000, channel.programs);
    for (let i = firstIndex; i < channel.programs.length; i++) {
        //checks if the page start time is between the first program's start and end times (less than or equal to zero)
        if (
            !foundFirstProgram &&
            channel.programs[i].unix_start_time &&
            startUnixTime !== channel.programs[i].unix_end_time / 1000 &&
            (startUnixTime - channel.programs[i].unix_start_time / 1000) * (startUnixTime - channel.programs[i].unix_end_time / 1000) <= 0
        ) {
            programList = [...programList, channel.programs[i]];
            foundFirstProgram = true;
        } else {
            //checks if program start times are between the 2-hour window of start and end unix times on the current page (less than or equal to zero)
            if (
                channel.programs[i].unix_start_time &&
                (channel.programs[i].unix_start_time / 1000 - startUnixTime) * (channel.programs[i].unix_start_time / 1000 - endUnixTime) <= 0
            ) {
                programList = [...programList, channel.programs[i]];
                //breaks loop if first program was already found and next program didn't pass the previous condition
            } else if (foundFirstProgram) {
                break;
            }
        }
    }
    //Conditions where updating the page data is invalid
    //No first show
    if (!programList[0]) {
        // console.log("false1", channel.programs[firstIndex]);
        // return false;
    }
    //Check if start time on the page falls outside range of the first show's time slots (greater than zero)
    if (programList[0] && (startUnixTime - programList[0].unix_start_time / 1000) * (startUnixTime - programList[0].unix_end_time / 1000) > 0) {
        // console.log("false2: ", programList[0]);
        //Check for 11Alive Weather(not enough data comes in for this show(maybe due to time zone??)) *change after fix
        // if(programList[0].source_id !== "69027727")
        //     return false;
    }
    //Check if end time on the page falls within range of the last show's time slots (greater than zero)
    if (
        programList[programList.length - 1] &&
        (endUnixTime - programList[programList.length - 1].unix_start_time / 1000) * (endUnixTime - programList[programList.length - 1].unix_end_time / 1000) >
            0
    ) {
        // console.log("false3: ", programList[programList.length - 1]);
        // if(programList[programList.length - 1].source_id !== "69027727")
        //     return false;
    }
    channel.programs = [...programList];

    return true;
};

// const replaceChannelProgramList = (startUnixTime, endUnixTime, channel, isChannelListInTwoHourWindow) => {
//     if(!isChannelListInTwoHourWindow)
//         return false;
//     let programList = [];
//     let foundFirstProgram = false;
//     const firstIndex = closestTimeIndex(startUnixTime * 1000, channel.programs);
//     for(let i = firstIndex; i < channel.programs.length; i++){
//         //checks if the page start time is between the first program's start and end times (less than or equal to zero)
//         if(!foundFirstProgram && channel.programs[i].unix_start_time && startUnixTime !== channel.programs[i].unix_end_time / 1000 && (startUnixTime - (channel.programs[i].unix_start_time / 1000)) * (startUnixTime - (channel.programs[i].unix_end_time / 1000)) <= 0){
//             programList = [...programList, channel.programs[i]]
//             foundFirstProgram = true;
//         }else{
//             //checks if program start times are between the 2-hour window of start and end unix times on the current page (less than or equal to zero)
//             if(channel.programs[i].unix_start_time && ((channel.programs[i].unix_start_time / 1000) - startUnixTime) * ((channel.programs[i].unix_start_time / 1000) - endUnixTime) <= 0){
//                 programList = [...programList, channel.programs[i]];
//             //breaks loop if first program was already found and next program didn't pass the previous condition
//             }else if(foundFirstProgram){
//                 break;
//             }
//         }
//     }

//     //Conditions where updating the page data is invalid
//     //No first show
//     if(!programList[0]){
//         // console.log("false1", channel.programs[firstIndex]);
//         return false;
//     }
//     //Check if start time on the page falls outside range of the first show's time slots (greater than zero)
//     if(programList[0] && (startUnixTime - (programList[0].unix_start_time / 1000)) * (startUnixTime - (programList[0].unix_end_time / 1000)) > 0){
//         // console.log("false2: ", programList[0]);
//         //Check for 11Alive Weather(not enough data comes in for this show(maybe due to time zone??)) *change after fix
//         if(programList[0].source_id !== "69027727")
//             return false;
//     }
//     //Check if end time on the page falls within range of the last show's time slots (greater than zero)
//     if(programList[programList.length - 1] && (endUnixTime - (programList[programList.length - 1].unix_start_time / 1000)) * (endUnixTime - (programList[programList.length - 1].unix_end_time / 1000)) > 0){
//         // console.log("false3: ", programList[programList.length - 1]);
//         if(programList[programList.length - 1].source_id !== "69027727")
//             return false;
//     }
//     channel.programs = [...programList];

//     return true;
// }

const closestTimeIndex = (pageStartTime, arr) => {
    if (arr.length > 0) {
        let closestNumber = 0;
        let mid;
        let lo = 0;
        let hi = arr.length - 1;
        //grab the closest index out the row through binary search
        while (hi - lo > 1) {
            //create mid-point with lo and hi values
            mid = Math.floor((lo + hi) / 2);
            if (arr[mid].unix_start_time < pageStartTime) {
                //move lo up to middle if our midpoint is less than the unix time we're looking for
                lo = mid;
            } else {
                //else move hi to midpoint
                hi = mid;
            }
        }

        //set number to lo or hi depending on which is closer
        if (pageStartTime - arr[lo].unix_start_time <= arr[hi].unix_start_time - pageStartTime) {
            closestNumber = lo;
        } else {
            closestNumber = hi;
        }

        // check if page start time is between the start and end times of the current, next or previous program
        if (arr[closestNumber] && (pageStartTime - arr[closestNumber].unix_start_time) * (pageStartTime - arr[closestNumber].unix_end_time) <= 0)
            return closestNumber;
        else if (
            arr[closestNumber + 1] &&
            (pageStartTime - arr[closestNumber + 1].unix_start_time) * (pageStartTime - arr[closestNumber + 1].unix_end_time) <= 0
        ) {
            return closestNumber + 1;
        } else if (
            arr[closestNumber - 1] &&
            (pageStartTime - arr[closestNumber - 1].unix_start_time) * (pageStartTime - arr[closestNumber - 1].unix_end_time) <= 0
        ) {
            return closestNumber - 1;
        } else {
            return closestNumber;
        }
    } else {
        return 0;
    }
};

export const cancelOrderBegin = () => ({
    type: CANCEL_ORDER_BEGIN,
});

export const cancelOrderSuccess = (status) => ({
    type: CANCEL_ORDER_SUCCESS,
    status,
});

export const cancelOrderFailure = (error) => ({
    type: CANCEL_ORDER_FAILURE,
    error,
});

export const isScreenCreatingTicket = (isCreatingTicketScreen) => ({
    type: IS_CREATING_TICKET_SCREEN,
    isCreatingTicketScreen,
});

export const toggleOptionsMenu = (isOptionsOpen) => ({
    type: TOGGLE_OPTIONS_MENU,
    isOptionsOpen,
});

export const toggleTicketScreen = (isTicketScreenOpen) => ({
    type: TOGGLE_TICKET_SCREEN,
    isTicketScreenOpen,
});

export const isCancellingTicket = (isCancellingTicketScreen) => ({
    type: IS_CANCELLING_TICKET_SCREEN,
    isCancellingTicketScreen,
});

export const toggleCancelTicketScreen = (isCancelTicketScreenOpen) => ({
    type: TOGGLE_CANCEL_TICKET_SCREEN,
    isCancelTicketScreenOpen,
});

export const onChannelBarInfo = (isChannelBarInfoOpen) => ({
    type: CHANNEL_BAR_INFO_STATE,
    isChannelBarInfoOpen,
});

export const onFullScreenInfoOpen = (isFullScreenInfoOpen) => ({
    type: TOGGLE_FULLSCREEN_INFO,
    isFullScreenInfoOpen,
});
export const getChannelsBegin = () => ({
    type: GET_CHANNELS_BEGIN,
});

export const getChannelsSuccess = (list) => ({
    type: GET_CHANNELS_SUCCESS,
    list,
});

export const updateChannelsList = (channelsList) => ({
    type: UPDATE_CHANNELS_LIST,
    channelsList,
});

export const getChannelsFailure = (error) => ({
    type: GET_CHANNELS_FAILURE,
    error,
});

export const toggleGuideAnimations = (isOff) => {
    return {
        type: TOGGLE_GUIDE_ANIMATIONS,
        isOff,
    };
};

export const setSearchMap = (searchObj) => {
    return {
        type: SET_SEARCH_MAP,
        searchMap: searchObj,
    };
};

export const gridNavigate = (direction, gridVals) => {
    if (direction === 'init') {
        //no new logic in action
        return {
            type: PAGE_INIT,
            pageData: gridVals.pageData,
            previewProgramStartTime: gridVals.previewProgramStartTime,
            pageStartTime: gridVals.pageStartTime,
            horizontalPageIndex: gridVals.horizontalPageIndex,
            previewChannelIndex: gridVals.previewChannelIndex,
            previewProgramIndex: gridVals.previewProgramIndex,
            pageStartingIndex: gridVals.pageStartingIndex,
        };
    }
    if (direction === 'rightPage') {
        return {
            type: PAGE_CHANGE_RIGHT,
            tempAnimationData: gridVals.tempAnimationData,
            pageData: gridVals.pageData,
            previewProgramIndex: gridVals.previewProgramIndex,
            previewProgramStartTime: gridVals.previewProgramStartTime,
            pageStartTime: gridVals.pageStartTime,
            horizontalPageIndex: gridVals.horizontalPageIndex,
        };
    }
    if (direction === 'rightProgram') {
        return {
            type: PROGRAM_CHANGE_RIGHT,
            previewProgramStartTime: gridVals.previewProgramStartTime,
            previewProgramIndex: gridVals.previewProgramIndex,
        };
    }
    if (direction === 'leftPage') {
        return {
            type: PAGE_CHANGE_LEFT,
            tempAnimationData: gridVals.tempAnimationData,
            pageData: gridVals.pageData,
            previewProgramIndex: gridVals.previewProgramIndex,
            previewProgramStartTime: gridVals.previewProgramStartTime,
            pageStartTime: gridVals.pageStartTime,
            horizontalPageIndex: gridVals.horizontalPageIndex,
        };
    }
    if (direction === 'leftProgram') {
        return {
            type: PROGRAM_CHANGE_LEFT,
            previewProgramStartTime: gridVals.previewProgramStartTime,
            previewProgramIndex: gridVals.previewProgramIndex,
        };
    }
    //note: chnage all state references to props and all starttime refs to pagestarttime
    if (direction === 'upProgram') {
        return {
            type: PROGRAM_CHANGE_UP,
            previewProgramIndex: gridVals.previewProgramIndex,
            previewChannelIndex: gridVals.previewChannelIndex,
        };
    }
    if (direction === 'upPage') {
        return {
            type: PAGE_CHANGE_UP,
            tempAnimationData: gridVals.tempAnimationData,
            previewChannelIndex: gridVals.previewChannelIndex,
            pageStartingIndex: gridVals.pageStartingIndex,
            pageData: gridVals.pageData,
            previewProgramIndex: gridVals.previewProgramIndex,
        };
    }
    if (direction === 'downProgram') {
        return {
            type: PROGRAM_CHANGE_DOWN,
            previewProgramIndex: gridVals.previewProgramIndex,
            previewChannelIndex: gridVals.previewChannelIndex,
        };
    }
    if (direction === 'downPage') {
        return {
            type: PAGE_CHANGE_DOWN,
            tempAnimationData: gridVals.tempAnimationData,
            previewChannelIndex: gridVals.previewChannelIndex,
            pageStartingIndex: gridVals.pageStartingIndex,
            pageData: gridVals.pageData,
            previewProgramIndex: gridVals.previewProgramIndex,
        };
    }
};
