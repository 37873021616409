import React from 'react';
import { connect } from 'react-redux';

import { toggleRecordingScreen, postRecording, deleteRecording, updateRecording, onFullScreenInfoOpen } from '../../../actions/guide';
import SeasonRecord from '../SeasonRecord';

const SeasonRecordContainer = (props) => (
    <SeasonRecord
        toggleRecordingScreen={props.toggleRecordingScreen}
        isRecordingScreenOpen={props.isRecordingScreenOpen}
        channelInfo={props.channelInfo}
        programIndex={props.programIndex}
        postRecording={props.postRecording}
        updateRecording={props.updateRecording}
        deleteRecording={props.deleteRecording}
        // allRecordings={props.allRecordings}
        subscriberID={props.subscriberID}
        assetData={props.assetData}
        isPostingOrder={props.isPostingOrder}
        selectedProgram={props.selectedProgram}
        isRecordedSingle={props.isRecordedSingle}
        selectedAssetInfo={props.selectedAssetInfo}
        orderData={props.orderData}
        onFullScreenInfoOpen={props.onFullScreenInfoOpen}
        isFullScreen={props.isFullScreen}
        currentPage={props.currentPage}
        onOptionClose={props.onOptionClose}
        isFullScreenInfoOpen={props.isFullScreenInfoOpen}
        dayNumberName={props.dayNumberName}
        isMovie={props.isMovie}
        fsRecFlag={props.fsRecFlag}
        reopenFSOptionsMenu={props.reopenFSOptionsMenu}
        connTimeOut={props.connTimeOut}
        keyMap={props.keyMap}
        alertEAS={props.alertEAS}
    />
);

const mapStateToProps = (state) => ({
    alertEAS: state.main.alertEAS,
    keyMap: state.main.keyMap,
    isRecordingScreenOpen: state.guide.isRecordingScreenOpen,
    // allRecordings : state.guide.allRecordings,
    subscriberID: state.login.subscriberData.id,
    assetData: state.myshows.assetData,
    isPostingOrder: state.guide.isPostingOrder,
    orderData: state.myshows.orderData,
    isFullScreen: state.main.isFullScreen,
    currentPage: state.main.currentPage,
    isFullScreenInfoOpen: state.guide.isFullScreenInfoOpen,
    connTimeOut: state.main.connTimeOut,
});

export const mapDispatchToProps = (dispatch) => ({
    toggleRecordingScreen: (recVar) => dispatch(toggleRecordingScreen(recVar)),
    postRecording: (order_type, schedule_id, subscriber_id, start_recording, stop_recording) =>
        dispatch(postRecording(order_type, schedule_id, subscriber_id, start_recording, stop_recording)),
    updateRecording: (order_type, order_id, subscriber_id, start_recording, stop_recording) =>
        dispatch(updateRecording(order_type, order_id, subscriber_id, start_recording, stop_recording)),
    deleteRecording: (type, id) => dispatch(deleteRecording(type, id)),
    onFullScreenInfoOpen: (isFullScreenInfoOpen) => dispatch(onFullScreenInfoOpen(isFullScreenInfoOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonRecordContainer);
