import React, { Component } from 'react';
//import { Animated, DeviceEventEmitter, Dimensions, StyleSheet, Text, View } from 'react-native';
import SeasonRecord from './Containers/SeasonRecordContainer';
import SeasonMyTicket from './Containers/SeasonMyTicketContainer';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
//import { LinearTextGradient } from 'react-native-text-gradient';
import { FSProgramIndex } from '../../utils/FSProgramIndex';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { TextField } from '@material-ui/core';

export default class GuideSearch extends Component {
    constructor(props) {
        super(props);

        const { pageData, previewChannelIndex, previewProgramIndex } = this.props;

        this.state = {
            inputVal: '',
            searchResults: null,
            textSearched: null,
            loadingResults: false,
        };
    }

    closeSearch() {
        this.props.onSearchClose(true);
    }

    handleInputChange = (e) => {
        this.setState({
            inputVal: e.target.value,
        });
    };

    handleSubmit = (e, text) => {
        e.preventDefault();
        if (text !== '') {
            this.setState({
                textSearched: text,
                inputVal: '',
                loadingResults: true,
            });
            this.getResults(text);
        }
    };

    getResults = (text) => {
        try {
            //channel results - in case if several
            let ch_results = this.props.allChannels.filter((channel) => {
                if (new RegExp(`\\b` + text + `\\b`, 'gi').test(channel.ch_name)) {
                    return true;
                }
                return false;
            });

            if (ch_results?.length === 1) {
                //only one channel found
                this.props.logicToSelectChannelBySearch(ch_results[0].custom_channel_number);
                return;
            } else if (ch_results?.length > 1) {
                //create and list, sort it, produce results on screen
                // console.log("second option: ", ch_results);
                this.searchMapResults(ch_results);
                return;
            }

            //if we did not exit - means that No match on channel found, find names of every show that has this in name
            let results = Object.keys(this.props.searchMap).filter((key) => {
                //console.log("No match on channel found, find names of every show that has this in name")
                if (new RegExp(`\\b` + text + `\\b`, 'gi').test(key)) {
                    return true;
                }
                return false;
            });
            //sort to have shortest first
            results.sort((str1, str2) => str1.length - str2.length).splice(3); //3 most relevant searches

            let mostRelevantList = [];
            for (let a = 0; a < results.length; a++) {
                for (let i = 0; i < this.props.searchMap[results[a]].length; i++) {
                    mostRelevantList.push(this.props.searchMap[results[a]][i]);
                }
            }
            // console.log("most relevant results: ", mostRelevantList);
            // console.log("mostRelevantList: ", mostRelevantList)
            //remove all shows that are more than so many hours back - ideally, crossreference to available restart shows

            if (mostRelevantList.length > 0) {
                //filters out past events that can not be Restarted
                let filteredWithCatchUp = mostRelevantList.filter((value, index) => {
                    // return value.unix_start_time/1000 > this.props.currentTime.unix()-86400;//12*60*60 - 12 hours
                    if (value.unix_start_time / 1000 < this.props.currentTime.unix() - 7200) {
                        //check only shows that are more than 2 hours before present
                        for (let b = 0; b < this.props.eventListWithCatchup.length; b++) {
                            if (this.props.eventListWithCatchup[b].ext_id === value.schedule_id) {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                });

                this.searchMapResults(filteredWithCatchUp);
                mostRelevantList = null;
                return;
            }
            //this.setState({value: "", isChannelFound: false, isSearching: false});
            return;
        } catch (error) {
            console.log('something is wrong: ', error);
            //this.setState({value: "", isChannelFound: false, isSearching: false});
            return;
        }
    };

    searchMapResults = (res) => {
        this.setState({
            searchResults: res,
            //textSearched: null,
            loadingResults: false,
        });
    };

    resetSearch = () => {
        this.setState({
            inputVal: '',
            searchResults: null,
            textSearched: null,
            loadingResults: false,
        });
    };

    render() {
        const {} = this.state; ///isRecordedSeries, isRecordedSingle,
        //const programUnavailable = this.state.selectedProgram.show_type === "";

        // const recordedSingleText = selectedProgram.recordedSingle? 'Modify recording' : `Record this program`;
        // const recordedSeriesText = selectedProgram.recordedSeries? 'Modify MyTicket for this series' : 'Create a MyTicket for this series';
        //const { height, width } = Dimensions.get('window');
        //let chNumberName = currentChannel.custom_channel_number + ' ' + currentChannel.custom_name;
        //let showStart = selectedProgram && selectedProgram.unix_start_time;
        //let day = `${moment(showStart).format('ddd M/D LT')}`;
        //let dayNumberName = `${day} ${chNumberName}`;

        //const optionItems = this.getOptionItems();
        let HeaderText = '';

        if (this.state.textSearched) {
            if (this.state.searchResults && this.state.searchResults.length > 0) HeaderText = `Search results for "${this.state.textSearched}"`;
            else {
                HeaderText = `No results for "${this.state.textSearched}"`;
            }
        } else {
            HeaderText = 'Search for channels or programs';
        }

        const logoCreator = (program) => {
            //later may be move logic to main.js, but for now/debugging let's keep it here
            //console.log("program", program)
            return (
                <div className={css(styles.LogoContainer)}>
                    {program.station_logo ? (
                        <img className={css(styles.Logo)} src={program.station_logo ? program.station_logo : null} />
                    ) : (
                        <div className={css(styles.callSignContainer)}>{program.ch_name ? program.ch_name : ''}</div>
                    )}
                </div>
            );
        };

        const episode_or_movie_year = (element) => {
            //helper function
            if ((element.show_type === 'MO' || element.show_type === 'OT') && element.release_year) {
                return `${element.release_year}`;
            }
            return (
                (element.se_number ? `S${element.se_number}` : element.se_name ? `S${element.se_name.replace('Season', '').trim()}` : '') +
                (element.ep_number ? `E${element.ep_number}` : '')
            );
        };

        const formatResultItems = (results) => {
            return results.map((cur, i) => {
                if (cur.custom_channel_number) {
                    //channels
                    return (
                        <li onClick={() => this.props.logicToSelectChannelBySearch(cur?.custom_channel_number)} className={css(styles.OptionItem)} key={i}>
                            <div className={css(styles.ChannelContainer)}>
                                <div className={css(styles.ChannelNumber)}>{cur.custom_channel_number}</div>
                                {logoCreator(cur)}
                            </div>

                            <div className={css(styles.programInfo)}>
                                <div className={css(styles.channelName)}>{cur.ch_name} </div>
                            </div>
                        </li>
                    );
                } else {
                    //programs
                    return (
                        <li onClick={() => this.props.logicToSelectChannelBySearch(cur.ch_number, cur)} className={css(styles.OptionItem)} key={i}>
                            <div className={css(styles.ChannelContainer)}>
                                <div className={css(styles.ChannelNumber)}>{cur.ch_number}</div>
                                {logoCreator(cur)}
                            </div>

                            <div className={css(styles.programInfo)}>
                                <div className={css(styles.programTitle)}>{cur.show_name} </div>
                                <div className={css(styles.programExtra)}>
                                    <div className={css(styles.dateTime)}>
                                        {' '}
                                        {moment(cur.unix_start_time).format('MM/DD')} {moment(cur.unix_start_time).format('hh:mmA')}
                                    </div>
                                    <div>
                                        {episode_or_movie_year(cur)} {cur.ep_name}
                                    </div>
                                </div>
                            </div>
                            {/* {cur.ch_number} {cur.show_name} - {moment(cur.unix_start_time).format("MM/DD")} {moment(cur.unix_start_time).format("hh:mm A")}  {episode_or_movie_year(cur)}  {cur.ep_name} */}
                        </li>
                    );
                }
            });
        };

        return (
            <motion.div
                key="optionContainer"
                initial={{ y: '110%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                exit={{ y: '110%', opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={css(styles.motionContainer)}>
                {
                    <>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={css(styles.Container)}>
                            <FontAwesomeIcon onClick={this.props.onSearchClose} className={css(styles.ExitButton)} icon="times" />
                            <div className={css(styles.Header)}>
                                <span className={css(styles.HeaderText)}>{HeaderText}</span>
                            </div>
                            {!this.state.searchResults ? (
                                <div className={css(styles.Options)}>
                                    <form className={css(styles.formContainer)} onSubmit={(e) => this.handleSubmit(e, this.state.inputVal)}>
                                        <input
                                            className={css(styles.input)}
                                            //type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.inputVal}
                                            //placeholder={'Password'}
                                            name="search input"
                                            onChange={(e) => this.handleInputChange(e)}
                                            //onFocus={resetDisplayError}
                                        />
                                        <input className={css(styles.submitInput)} type="submit" value="Search" />
                                    </form>
                                </div>
                            ) : (
                                <>
                                    <div className={css(styles.resultsContainer)}>
                                        <ul className={css(styles.resultsUl)}>{formatResultItems(this.state.searchResults)}</ul>
                                    </div>
                                    <input onClick={() => this.resetSearch()} className={css(styles.searchAgain)} type="submit" value="New Search" />
                                </>
                            )}
                        </div>
                    </>
                }
            </motion.div>
        );
    }
}

const styles = StyleSheet.create({
    motionContainer: {
        height: '100%',
        width: '100%',
    },
    Container: {
        //alignSelf:'flex-end',
        // width: '20em',
        minHeight: '8em',
        maxHeight: '36em',
        position: 'absolute',
        // position: '-webkit-sticky',
        // position: 'sticky',
        //alignSelf: 'flex-end',
        //display: 'flex',
        //flexDirection: 'column',
        backgroundColor: '#202020',
        //justifyContent: 'center',
        //alignItems: 'flex-start',
        //paddingLeft: '4em',
        // borderwidth: '1em',
        // borderStyle: 'solid',
        // borderColor: 'white',
        //borderRadius: '5px',
        //marginTop:'10em',
        //paddingLeft: '4em',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        //marginTop: 'auto',
        marginBottom: '3em',
        //padding: '0 1em'
        width: '17em',
        // borderColor: 'rgba(230,230,230, 0.3)',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
    },
    OptionItem: {
        // cursor: 'pointer',
        // //margin: '3px',
        // //padding: '4px',
        height: '3.5em',
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        display: 'flex',
        flexDirection: 'row',
        listStyle: 'none',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        //padding: '.6em',
    },
    // channelInfo: {
    //     height: '3.5em',
    //     borderColor: 'yellow',
    //     borderStyle: 'solid',
    //     borderWidth: '.01em',
    //     width: '12em'
    // },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        marginLeft: '4em',
        // color: '#61dafb',
        //fontSize:'1.3em',
        // padding: '.1em',
        //borderColor: 'red',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        width: '40em',
    },
    resultsContainer: {
        marginLeft: '4em',
        //marginTop: 'auto',
        marginBottom: '1em',
        //padding: '0 1em'
        //maxHeight: '15em',
        width: '65em',
        maxHeight: '26em',
        overflowY: 'auto',
        // borderColor: 'yellow',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
    },
    resultsUl: {
        padding: 0,
        listStyleType: 'none',
    },
    HeaderText: {
        fontSize: '1.3em',
    },
    SubHeader: {
        marginLeft: '4em',
        //marginTop: '2em',
        color: 'white',
        //margin: '1em 2em',
        padding: '.1em',
        fontSize: '1.3em',
    },
    input: {
        '@media (max-width: 600px)': {
            height: '60%',
            fontSize: '1.2rem',
            '::placeholder': {
                fontSize: '0.7rem',
            },
        },
        '::placeholder': {
            fontStyle: 'italic',
            fontSize: '0.9rem',
        },
        ':focus': {
            outline: 'none',
            border: '2px solid rgb(210,210,210)',
        },
        backgroundColor: 'initial',
        borderColor: 'rgb(150,150,150)',
        height: '100%',
        width: '35vw',
        minWidth: '200px',
        maxWidth: '400px',
        minHeight: '25px',
        padding: '0.1rem 5%',
        color: '#fff',
        //fontSize: '1.5rem',
    },
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        top: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
    submitInput: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(200,200,200)',
            color: '#000',
        },
        ':focus': {
            outline: 'none',
        },
        backgroundColor: 'initial',
        borderColor: 'rgb(150,150,150)',
        color: 'rgb(150,150,150)',
        display: 'inline',
        width: '200px',
        borderStyle: 'solid',
        padding: '10px 20px',
        appearance: 'none',
        marginTop: '3em',
        //fontSize: ''
        //transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    searchAgain: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#00B59C',
            borderColor: 'rgb(200,200,200)',
            color: '#000',
        },
        ':focus': {
            outline: 'none',
        },
        backgroundColor: 'initial',
        borderColor: 'rgb(150,150,150)',
        color: 'rgb(150,150,150)',
        display: 'inline',
        width: '200px',
        borderStyle: 'solid',
        padding: '10px 20px',
        appearance: 'none',
        marginTop: '1.5em',
        marginBottom: '1.5em',
        marginLeft: '6em',
        //fontSize: ''
        //transition: 'border-color 1000ms, background-color 1000ms, color 500ms',
    },
    programInfo: {
        height: '3.5em',
        // borderColor: 'red',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        width: '45em',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        //justifyContent: 'space-between',
    },
    programTitle: {
        // borderColor: 'green',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        display: 'flex',
        flex: 4,
        fontSize: '1.4em',
        paddingTop: '.2em',
    },
    programExtra: {
        display: 'flex',
        flex: 3,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    dateTime: {
        //fontSize: '1.1em',
        fontWeight: '500',
        marginRight: '.3em',
    },
    ChannelContainer: {
        paddingLeft: '.5em',
        maxWidth: '10em',
        flex: 1,
        // borderColor: 'blue',
        // borderStyle: 'solid',
        // borderWidth: '.01em',
        //overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        //color: '#dbdbdb',
        fontSize: '1em',
    },
    ChannelNumber: {
        fontSize: '1.2em',
        //paddingLeft: '.4em'
    },
    LogoContainer: {
        width: '6em',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        marginLeft: '1em',
        marginTop: '.2em',
    },
    Logo: {
        maxHeight: '2.5em',
    },
    channelName: {
        fontSize: '1.6em',
        paddingTop: '.55em',
    },
});
