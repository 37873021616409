import { sotalAxios } from '../utils/AxiosInstances';
import { makingErrorObject, errorHandler } from '../utils/ErrorHandler';
import { errorIntercept, fetchGuideDataError } from '../actions/main';

import axios from 'axios';
import moment from 'moment';
import { bugSnagNotifierGeneral } from './BugSnagService';
import { isDRMEnabled } from './LoginServices';
//import { platform } from "../utils/Scaling";

let errorObject = {};
let requestCopy = {}; //only used for Network Error
let sotalTimeOut = null;
let retryCount = 0;
const CancelToken = axios.CancelToken;
let cancel;

//arrays
let liveStreamArr = []; //this array is need for when we change channels and such
let liveStreamArr2 = []; //this array is need for when we change channels and such
let subInfo = {}; //we are getting it on the first call to SC when logging in

export const getChannelMapping = () => {
    return [...liveStreamArr];
};

export const setChannelMapping = (channelMapping) => {
    liveStreamArr = channelMapping;
};

export const getSotalRecording = (sc_id) => {
    //return sotalAxios
    // .post(`/dvr-tasks/${sc_id}/playback-sessions?stream_format=dash`, {
    //     cancelToken: new CancelToken(function executor(c) {
    //         cancel = c;
    //     }),
    // })
    const url = isDRMEnabled ? `/dvr-tasks/${sc_id}/playback-sessions?stream_format=dash` : `/dvr-tasks/${sc_id}/url?stream_format=dash`;
    return sotalAxios({
        method: isDRMEnabled ? 'POST' : 'GET',
        url: url,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    })
        .then((res) => {
            if (isDRMEnabled) {
                return {
                    streamURL: res.data.data.playback_url,
                    drmLicenseURL: res.data.data.wv_license_url,
                };
            } else {
                return {
                    streamURL: res.data.data,
                    drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${sc_id}/wv-license?auth_token=${sotalAxios.defaults.headers.common['X-Auth-Token']}`,
                };
            }
        })
        .catch((error) => {
            // console.log("error: ", error);
            let assetObjectInfo = {
                sc_id: sc_id,
                headers: (error && error.requestCopy && error.requestCopy.headers) || 'can not get headers',
            };
            bugSnagNotifierGeneral('recorded stream error', 'from getSotalRecording', subInfo, assetObjectInfo, {
                errorURL: (error && error.requestCopy && error.requestCopy.baseURL + error.requestCopy.url) || 'can not retrieve',
            });
            return Promise.reject(error);
        });
};

// export const getSotalLiveStream = (sourceID) => {//gets tokenized url
//      // Also, add error handling with these requests
//     let streamInfo = false;
//     let assetID = 0;
//     let resourceID = 0;
//     for(let i = 0; i < liveStreamArr.length; i++){
//         if(liveStreamArr[i].rovi_source_id === sourceID){
//             streamInfo = true;
//             assetID = liveStreamArr[i].sc_asset_id;
//             resourceID = liveStreamArr[i].sc_resource_id;
//             break;
//         }
//     }
//     if(streamInfo && resourceID && resourceID !== 0){
//         return sotalAxios.get(`/assets/${assetID}/resources/${resourceID}/url`,
//             {cancelToken: new CancelToken(function executor(c) {
//                 cancel = c;
//             })}
//         )
//         .then((res)=>{
//             if(res.data && res.data.data && res.data.data.length > 0){
//                 return {
//                     streamURL: res.data.data,
//                     drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${assetID}/wv-license?auth_token=${sotalAxios.defaults.headers.common['X-Auth-Token']}`
//                 };
//             }else{
//                 let e = new Error("unexpected data from get sotal live stream");
//                 e.extraFields = {requestedUrl: {"requestedUrl": res.config.baseURL + res.config.url},invalidResponse: {"invalidResponse": JSON.stringify(res)}}
//                 return Promise.reject(e);
//             }
//         })
//         .catch(err => {
//             if (!err.extraFields) {
//                 err.extraFields = {failedSourceID: {"failedSourceID": sourceID}, liveStreamArray: {"liveStreamArray": liveStreamArr}}
//             }
//             return Promise.reject(err);
//         })
//     }else{
//         let e = new Error("sotal live stream asset not found");
//         e.extraFields = {failedSourceID: {"failedSourceID": sourceID}, failedResourceID: {"failedResourceID": resourceID},liveStreamArray: {"liveStreamArray": liveStreamArr}}
//         return Promise.reject(e);
//     }
// };

export const getSotalLiveStream = (asset_id) => {
    //gets tokenized url
    // Also, add error handling with these requests
    let streamInfo = false;
    let assetID = 0;
    let resourceID = 0;
    for (let i = 0; i < liveStreamArr.length; i++) {
        if (liveStreamArr[i].sc_asset_id === asset_id) {
            streamInfo = true;
            assetID = liveStreamArr[i].sc_asset_id;
            resourceID = liveStreamArr[i].sc_resource_id;
            break;
        }
    }
    if (streamInfo && resourceID && resourceID !== 0) {
        console.log('isDRMEnabled ', isDRMEnabled);
        return sotalAxios({
            method: isDRMEnabled ? 'POST' : 'GET',
            url: isDRMEnabled ? `/assets/${assetID}/resources/${resourceID}/playback-sessions` : `/assets/${assetID}/resources/${resourceID}/url`,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            // https://epic-mha001.epicvideo.tech/sdp/v2/assets/:asset_id/resources/:resource_id/playback-sessions
        })
            .then((res) => {
                if (res.data && res.data.data) {
                    // && res.data.data.length > 0
                    if (isDRMEnabled) {
                        return {
                            streamURL: res.data.data.playback_url,
                            drmLicenseURL: res.data.data.wv_license_url,
                        };
                    } else {
                        return {
                            streamURL: res.data.data,
                            drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${assetID}/wv-license?auth_token=${sotalAxios.defaults.headers.common['X-Auth-Token']}`,
                        };
                    }
                } else {
                    let e = new Error('unexpected data from get sotal live stream');
                    e.extraFields = {
                        requestedUrl: { requestedUrl: res.config.baseURL + res.config.url },
                        invalidResponse: { invalidResponse: JSON.stringify(res) },
                    };
                    return Promise.reject(e);
                }
            })
            .catch((err) => {
                if (!err.extraFields) {
                    err.extraFields = { failedAssetID: { failedAssetID: asset_id }, liveStreamArray: { liveStreamArray: liveStreamArr } };
                }
                return Promise.reject(err);
            });
    } else {
        let e = new Error('sotal live stream asset not found');
        e.extraFields = {
            failedAssetID: { failedAssetID: asset_id },
            failedResourceID: { failedResourceID: resourceID },
            liveStreamArray: { liveStreamArray: liveStreamArr },
        };
        return Promise.reject(e);
    }
};

//Get sotal cloud start over stream (PauseLiveTV)
// export const getSotalPauseLiveStream = (sourceID, user) => {
//     let current_UTC_time = moment().utc().format("YYYY-MM-DDTHH:mm:ss")+'Z';
//     let pauseLiveID = 0;
//     let assetID = 0;
//     //in the future, attach assetID & possibly resourceID to channels array (to avoid unnecessary loop as # of channels grow)
//     for(let i = 0; i < liveStreamArr.length; i++){
//         if(liveStreamArr[i].rovi_source_id === sourceID){
//             assetID = liveStreamArr[i].sc_asset_id;
//             break;
//         }
//     }

//     if(assetID !== 0){
//         return sotalAxios.get(`live-events?live.id[eq]=${assetID}&end_time[gt]=${current_UTC_time}&$limit=1`,
//             {cancelToken: new CancelToken(function executor(c) {
//                 cancel = c;
//             })}
//         ).then((response)=>{
//             if(response.data && response.data.data && response.data.data.length && response.data.data.length > 0){
//                 pauseLiveID = response.data.data[0].id;
//                 // console.log("pauseLiveID", pauseLiveID);

//                 return sotalAxios.get(`live-events/${pauseLiveID}/catchup-url${platform !== "ios" ? '?stream_format=dash' : ''}`,
//                     {cancelToken: new CancelToken(function executor(c) {
//                         cancel = c;
//                     })}
//                 )
//             }else{
//                 let e = new Error("unexpected data from get pause live event list");
//                 e.extraFields = {requestedUrl: {"requestedUrl": response.config.baseURL + response.config.url},invalidResponse: {"invalidResponse": JSON.stringify(response)}}
//                 return Promise.reject(e);
//             }
//         }).then((response) => {
//             if(response.data && response.data.data && response.data.data.length > 0 && (response.data.data.startsWith("http://") || response.data.data.startsWith("https://"))){
//                 return {
//                     streamURL: response.data.data,
//                     drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${pauseLiveID}/wv-license?auth_token=${sotalAxios.defaults.headers.common['X-Auth-Token']}`
//                 };
//             }else{
//                 let str = response && response.data && response.data.data ? response.data.data : "";
//                 let e = new Error("unexpected data from get pause live stream: " + str);
//                 e.extraFields = {requestedUrl: {"requestedUrl": response.config.baseURL + response.config.url},invalidResponse: {"invalidResponse": JSON.stringify(response)}}
//                 return Promise.reject(e);
//             }
//         }).catch(error => {
//             return Promise.reject(error);
//         })
//     }else {
//         return Promise.reject(new Error("sotal catchup asset not found"));
//     }
// }

//Get sotal cloud start over stream (PauseLiveTV)
// export const getSotalPauseLiveStream = (asset_id, user) => {
//     let current_UTC_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
//     let pauseLiveID = 0;
//     let assetID = 0;
//     //in the future, attach assetID & possibly resourceID to channels array (to avoid unnecessary loop as # of channels grow)
//     for (let i = 0; i < liveStreamArr.length; i++) {
//         if (liveStreamArr[i].sc_asset_id === asset_id) {
//             assetID = liveStreamArr[i].sc_asset_id;
//             break;
//         }
//     }

//     if (assetID !== 0) {
//         return sotalAxios
//             .get(`live-events?live.id[eq]=${assetID}&end_time[gt]=${current_UTC_time}&$limit=1`, {
//                 cancelToken: new CancelToken(function executor(c) {
//                     cancel = c;
//                 }),
//             })
//             .then((response) => {
//                 if (response.data && response.data.data && response.data.data.length && response.data.data.length > 0) {
//                     pauseLiveID = response.data.data[0].id;
//                     // console.log("pauseLiveID", pauseLiveID);

//                     return sotalAxios.get(`live-events/${pauseLiveID}/catchup-url?stream_format=dash`, {
//                         cancelToken: new CancelToken(function executor(c) {
//                             cancel = c;
//                         }),
//                     });
//                 } else {
//                     let e = new Error('unexpected data from get pause live event list');
//                     e.extraFields = {
//                         requestedUrl: { requestedUrl: response.config.baseURL + response.config.url },
//                         invalidResponse: { invalidResponse: JSON.stringify(response) },
//                     };
//                     return Promise.reject(e);
//                 }
//             })
//             .then((response) => {
//                 if (
//                     response.data &&
//                     response.data.data &&
//                     response.data.data.length > 0 &&
//                     (response.data.data.startsWith('http://') || response.data.data.startsWith('https://'))
//                 ) {
//                     return {
//                         streamURL: response.data.data,
//                         drmLicenseURL: `https://epic-mha001.epicvideo.tech/sdp/v2/assets/${pauseLiveID}/wv-license?auth_token=${sotalAxios.defaults.headers.common['X-Auth-Token']}`,
//                     };
//                 } else {
//                     let str = response && response.data && response.data.data ? response.data.data : '';
//                     let e = new Error('unexpected data from get pause live stream: ' + str);
//                     e.extraFields = {
//                         requestedUrl: { requestedUrl: response.config.baseURL + response.config.url },
//                         invalidResponse: { invalidResponse: JSON.stringify(response) },
//                     };
//                     return Promise.reject(e);
//                 }
//             })
//             .catch((error) => {
//                 return Promise.reject(error);
//             });
//     } else {
//         return Promise.reject(new Error('sotal catchup asset not found'));
//     }
// };

//SC channels on Login
const getLiveChannels = (user, dispatch, subDeviceInfo, epArr) => {
    return sotalAxios
        .get('/assets?type[eq]=channel&$expand=resources(url),epg&$orderby=lcn', {
            cancelToken: new CancelToken(function executor(c) {
                //An executor function receives a cancel function as a parameter
                cancel = c;
            }),
        })
        .then((response) => {
            let scArr = [];
            let noStreams = [];
            let scArr2 = [];
            let epArrFree = epArr.map((item) => {
                return {
                    ext_id: item.ext_id,
                    free_channel: item.free_channel,
                    name: item.name,
                    rovi_source_id: item.rovi_source_id,
                    sc_asset_id: item.sc_asset_id,
                };
            });

            for (let i = 0; i < response.data.data.length; i++) {
                let resourcesArr = response.data.data[i].resources;
                // console.log('resourcesArr', resourcesArr);
                if (resourcesArr) {
                    for (let j = 0; j < resourcesArr.length; j++) {
                        if (resourcesArr[j].type === 'dash-live') {
                            scArr2.push({
                                ext_id: response.data.data[i].ext_id,
                                sc_asset_id: response.data.data[i].id,
                                dash_sc_resource_id: resourcesArr[j].id,
                            });
                        } else if (resourcesArr[j].type === 'hls-live') {
                            scArr2.push({
                                ext_id: response.data.data[i].ext_id,
                                sc_asset_id: response.data.data[i].id,
                                hls_sc_resource_id: resourcesArr[j].id,
                            });
                        }
                    }
                }
            }

            const mergeChData2 = (epArrFree, scArr2) => {
                if (Array.isArray(liveStreamArr2)) {
                    epArrFree.forEach((epvalue) => {
                        scArr2.forEach((scvalue) => {
                            if (epvalue.sc_asset_id === scvalue.sc_asset_id && epvalue.free_channel === 'no' && scvalue.dash_sc_resource_id) {
                                console.log('not a free channel');
                                liveStreamArr2.push(Object.assign({}, epvalue, scvalue));
                            } else if (epvalue.sc_asset_id === scvalue.sc_asset_id && epvalue.free_channel === 'yes' && scvalue.hls_sc_resource_id) {
                                console.log('free channel');
                                liveStreamArr2.push(Object.assign({}, epvalue, scvalue));
                            }
                        });
                    });
                }

                var secondArr = liveStreamArr2.map((e) => {
                    return {
                        sc_resource_id: e.dash_sc_resource_id || e.hls_sc_resource_id,
                        ...e,
                    };
                });
                secondArr.forEach((object) => {
                    delete object['dash_sc_resource_id'];
                    delete object['hls_sc_resource_id'];
                });
                liveStreamArr = secondArr;
                // console.log('secondArr', secondArr);
                // console.log("epArrFree", epArrFree);
                // console.log("scArr2", scArr2);
                // console.log("liveStreamArr2", liveStreamArr2);
            };

            mergeChData2(epArrFree, scArr2);
            // console.log('liveStreamArr', liveStreamArr);
            // next commented out portion is needed for debugging - do not delete please!!!
            // if(epArr.length !== response.data.data.length){//discrepancies found, might be SC channels misconfig
            //     let discrepancies = [];
            //     //let's check an external_id
            //     let responseExtId = response.data.data.map(value => {
            //         return value.ext_id.toString();
            //     })
            //     let epArrExtId = epArr.map(value => {
            //         return value.ext_id.toString();
            //     })
            //     if(responseExtId.length > epArrExtId.length){
            //         discrepancies = responseExtId.filter(value => {
            //             return !epArrExtId.includes(value);
            //         });
            //         //create list of channels with discrepancies
            //         let badData = response.data.data.filter(value => {
            //             if(value.status !== "inactive" && discrepancies.includes(value.ext_id.toString())){
            //                 return value.ext_id.toString();
            //             }
            //             return false;
            //         })
            //         if(badData.length > 0){//send to bugsnag //TODO - uncomment
            //             bugSnagNotifierGeneral("discrepancies between SMS and SC channels", "SC channels that are not in SMS", subDeviceInfo, badData, "not applicable here");
            //         }
            //     }else{
            //         discrepancies = epArrExtId.filter(value => {
            //             return !responseExtId.includes(value);
            //         });
            //         let badData = user.channels.filter(value => {
            //             return discrepancies.includes(value.sotal_cloud_channel_asset.ext_id.toString());
            //         })
            //         bugSnagNotifierGeneral("discrepancies between SC channels and SMS", "These channels are not in SC call but only in SMS", subDeviceInfo, badData, "not applicable here");
            //     }
            //     // console.log("discr: ", discrepancies);
            // }

            // for (let i = 0; i < response.data.data.length; i++) {
            //     let resourcesArr = response.data.data[i].resources;
            //     if (resourcesArr) {
            //         for (let j = 0; j < resourcesArr.length; j++) {
            //             if (resourcesArr[j].type === 'dash-live') {
            //                 scArr.push({
            //                     ext_id: response.data.data[i].ext_id,
            //                     sc_asset_id: response.data.data[i].id,
            //                     sc_resource_id: resourcesArr[j].id,
            //                 });
            //             }
            //         }
            //     } else if (response.data.data[i].status === 'active') {
            //         //we are not concerned with other status
            //         noStreams.push(response.data.data[i]);
            //     }
            // }
            // mergeChData(epArr, scArr);
            if (noStreams.length > 0) {
                //if channels with no resources collected - send to bugsnag
                bugSnagNotifierGeneral(
                    'Channels with empty streams',
                    'getLiveChannels in SotalCloudService.js',
                    subDeviceInfo,
                    noStreams,
                    'not applicable here'
                );
            }
            noStreams = null;
        })
        .catch((error) => {
            console.log('catch error', error);
            let errorObject = errorHandler(error, 'get sotal JSON failed on login', user);
            dispatch(errorIntercept(errorObject));
            dispatch(fetchGuideDataError(errorObject.message));
        });
};

export const recordedAssets = () => {
    //call to check the status of the shows
    return sotalAxios.get('/dvr-tasks', {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    });
};

sotalAxios.interceptors.request.use(
    (request) => {
        // console.log('sotal cloud request', request);
        request.retries = 1;
        request.retryDelay = 1500; //in ms
        requestCopy = request;
        sotalTimeOut && clearTimeout(sotalTimeOut);
        sotalTimeOut = setTimeout(() => {
            cancel && cancel('Network Error');
        }, 21000); //timeout if no response for 8 secs
        return request;
    },
    (error) => {
        return error;
    }
);
sotalAxios.interceptors.response.use(
    (response) => {
        // console.log('sotal cloud response', response);
        retryCount = 0;
        requestCopy = {};
        sotalTimeOut && clearTimeout(sotalTimeOut);
        cancel && (cancel = null);
        return response;
    },
    (error) => {
        //attention!!! modified in comparison to other services
        // console.log("sotal cloud error", error.message, error.config, error.response)
        sotalTimeOut && clearTimeout(sotalTimeOut);
        cancel && (cancel = null);
        let config = error.message !== 'Network Error' ? error.config : null;
        // If Network Error config does not exist or the retry option is not set, reject
        if (error.message !== 'Network Error' && (!error.config || !error.config.retries)) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (error.message === 'Network Error' && !config) {
            // console.log("Network Error Occured", error, error.response, error.status);
            config = requestCopy;
        }

        if (
            error &&
            error.message !== 'Network Error' &&
            error.response &&
            (error.response.status === 401 ||
                error.response.status === 409 ||
                error.response.status === 403 ||
                error.response.status === 405 ||
                error.response.status === 404) &&
            retryCount > 0
        ) {
            //401 auth error!!! Kick them out! 409 - duplicate
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        if (retryCount > config.retries) {
            errorObject = makingErrorObject(error, requestCopy, retryCount, null);
            retryCount = 0;
            requestCopy = {};
            return Promise.reject(errorObject);
        }

        console.log(retryCount, config.retryDelay);
        retryCount += 1;

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryDelay || 100); //default delay before retry = 100ms
        }); //for linear backoff - config.retryDelay

        return backoff.then(function () {
            return sotalAxios(config);
        });
    }
);

export const channelLineup = (user, dispatch, subDeviceInfo) => {
    subInfo = subDeviceInfo;
    let epArr = [];
    for (let i = 0; i < user.channels.length; i++) {
        epArr.push({
            name: user.channels[i].name,
            free_channel: user.channels[i].free_channel,
            ext_id: user.channels[i].sotal_cloud_channel_asset.ext_id,
            rovi_source_id: parseInt(user.channels[i].rovi_source_id),
            sc_asset_id: user.channels[i].sotal_cloud_channel_asset.sotal_cloud_channel_asset_id,
        });
    }
    getLiveChannels(user, dispatch, subDeviceInfo, epArr);
};

// const mergeChData = (epArr, scArr) => {
//     if (Array.isArray(liveStreamArr)) {
//         for (let i = 0; i < epArr.length; i++) {
//             liveStreamArr.push({
//                 ...epArr[i],
//                 ...scArr.find((itm) => itm.sc_asset_id === epArr[i].sc_asset_id),
//             });
//         }
//     }
// };

export const resetSCServices = () => {
    //null all objects
    liveStreamArr = null;
    subInfo = null; //we are getting it on the first call to SC when logging in

    //reinit these again
    liveStreamArr = [];
    subInfo = {}; //we are getting it on the first call to SC when logging in
    retryCount = 0;
};
