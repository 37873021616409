import { CHANGE_CARD_INDEX, CHANGE_CARD_MENU_INDEX, CHANGE_ROW_INDEX, UPDATE_SHOW_INFO, TOGGLE_CASTS } from '../types/showinfo';

export const changeCardIndex = (cardIndex) => ({
    type: CHANGE_CARD_INDEX,
    cardIndex,
});

export const changeMenuIndex = (menuIndex) => ({
    type: CHANGE_CARD_MENU_INDEX,
    menuIndex,
});

export const changeRowIndex = (rowIndex) => ({
    type: CHANGE_ROW_INDEX,
    rowIndex,
});

export const updateShowInfo = (newInfo) => ({
    type: UPDATE_SHOW_INFO,
    newInfo,
});

export const toggleCasts = (hasCasts) => ({
    type: TOGGLE_CASTS,
    hasCasts,
});
