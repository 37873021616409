import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// import blue from '@material-ui/core/colors/blue';
import { library } from '@fortawesome/fontawesome-svg-core';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
    faCheckSquare,
    faCoffee,
    faBars,
    faCaretDown,
    faAngleRight,
    faAngleLeft,
    faAngleUp,
    faAngleDown,
    faExclamationCircle,
    faExclamationTriangle,
    faTimes,
    faInfoCircle,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
var youbora = require('youboralib');
require('youbora-adapter-shaka');
//import youbora from 'youboralib'
const envCode = process.env.NODE_ENV === 'production' ? 'skittertvdev' : 'skittertvdev'; ////////////lets use dev account for YB until qa is cleared
// const envCode = process.env.NODE_ENV === 'production' ? 'skitter' : 'skittertvdev';

window.plugin = new youbora.Plugin({ accountCode: envCode });

library.add(
    faCheckSquare,
    faCoffee,
    faBars,
    faCaretDown,
    faAngleRight,
    faAngleLeft,
    faExclamationCircle,
    faInfoCircle,
    faExclamationTriangle,
    faTimes,
    faAngleUp,
    faAngleDown,
    faSearch
);

Bugsnag.start({
    apiKey: 'f8c6bad10356648dd91650b18c66d7f7',
    plugins: [new BugsnagPluginReact(React)],
    //otherOptions: value
});

var ErrorBoundary = Bugsnag.getPlugin('react');

const theme = createMuiTheme({
    palette: {
        type: 'dark',
    },
});

function ThemedApp() {
    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </ThemeProvider>
    );
}

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
