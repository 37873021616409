import { combineReducers } from 'redux';
import { RESET_APP_STATE } from './types/main';
import home from './reducers/home';
import search from './reducers/search';
import showinfo from './reducers/showinfo';
import menu from './reducers/menu';
import main from './reducers/main';
import guide from './reducers/guide';
import myshows from './reducers/myshows';
import progressbar from './reducers/progressbar';
import login from './reducers/login';

//the following construct resets all reducers to regular state
const appReducer = combineReducers({
    home,
    search,
    showinfo,
    menu,
    main,
    guide,
    myshows,
    progressbar,
    login,
});

const rootReducer = (state, action) => {
    if (action.type === RESET_APP_STATE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
