import React from 'react';

const JumpBackIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id="ic_numpad_back" transform="translate(-800 -557)">
                <g id="Group_2873" data-name="Group 2873" transform="translate(116 -143)">
                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="32" cy="32" r="32" transform="translate(684 700)" fill="#01b59c" />
                    <g id="Group_2809" data-name="Group 2809" transform="translate(-9521.715 -6768.713)">
                        <rect id="Rectangle_1010" data-name="Rectangle 1010" width="44" height="44" transform="translate(10215.714 7478.713)" fill="none" />
                        <rect id="Rectangle_1011" data-name="Rectangle 1011" width="22" height="23" transform="translate(10226.714 7489.713)" fill="none" />
                        <g id="Group_2808" data-name="Group 2808" transform="translate(10226.714 7487.725)">
                            <g id="Group_2807" data-name="Group 2807" transform="translate(0 5.824)">
                                <path
                                    id="Path_1359"
                                    data-name="Path 1359"
                                    d="M10239.841,7524.167h10.757a8.851,8.851,0,0,0,8.862-8.585v-.3c0-4.722-4.141-8.278-8.862-8.278H10238"
                                    transform="translate(-10238.001 -7506.999)"
                                    fill="none"
                                    stroke="#020202"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                />
                            </g>
                            <path
                                id="Path_1360"
                                data-name="Path 1360"
                                d="M10243.789,7509.148l-5.788-5.8,5.826-5.846"
                                transform="translate(-10238.001 -7497.499)"
                                fill="none"
                                stroke="#020202"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

const FullScreenIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id="ic_numpad_full_screen" transform="translate(-940 -700)">
                <circle id="Ellipse_41" data-name="Ellipse 41" cx="32" cy="32" r="32" transform="translate(940 700)" fill="#01b59c" />
                <g id="Group_2814" data-name="Group 2814" transform="translate(-9685 -6907)">
                    <g id="Group_2812" data-name="Group 2812" transform="translate(10635 7617)">
                        <g id="Group_2811" data-name="Group 2811">
                            <rect id="Rectangle_1012" data-name="Rectangle 1012" width="44" height="44" transform="translate(0 0)" fill="none" />
                        </g>
                    </g>
                    <g id="Group_2842" data-name="Group 2842">
                        <path
                            id="Path_1361"
                            data-name="Path 1361"
                            d="M10690.332,7643.013V7635h-8.015"
                            transform="translate(-21.49 -8.175)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_1362"
                            data-name="Path 1362"
                            d="M10661.014,7635H10653v8.014"
                            transform="translate(-8.176 -8.175)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_1363"
                            data-name="Path 1363"
                            d="M10690.332,7664.317v8.015h-8.015"
                            transform="translate(-21.49 -21.49)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_1364"
                            data-name="Path 1364"
                            d="M10661.014,7672.332H10653v-8.015"
                            transform="translate(-8.176 -21.49)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_25"
                            data-name="Line 25"
                            x1="8.014"
                            y1="8.014"
                            transform="translate(10660.828 7642.828)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_26"
                            data-name="Line 26"
                            x2="8.014"
                            y2="8.014"
                            transform="translate(10644.825 7626.825)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_27"
                            data-name="Line 27"
                            x1="8.014"
                            y2="8.014"
                            transform="translate(10660.828 7626.825)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_28"
                            data-name="Line 28"
                            x1="8.014"
                            y2="8.014"
                            transform="translate(10644.825 7642.828)"
                            fill="none"
                            stroke="#020202"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

const PrevDayIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id="ic_numpad_prev_page" transform="translate(-684 -700)">
                <circle id="Ellipse_40" data-name="Ellipse 40" cx="32" cy="32" r="32" transform="translate(684 700)" fill="#01b59c" />
                <g id="Group_2878" data-name="Group 2878" transform="translate(1663 -102) rotate(90)">
                    <g id="Group_370" data-name="Group 370" transform="translate(845.369 947.671) rotate(90)">
                        <g id="Group_74" data-name="Group 74" transform="translate(0.329 0.376)">
                            <g id="Group_71" data-name="Group 71">
                                <path id="Path_10" data-name="Path 10" d="M0,0,12,10.5,0,20.992" fill="none" stroke="#020202" strokeWidth="2" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_2880" data-name="Group 2880" transform="translate(845.369 935.671) rotate(90)">
                        <g id="Group_74-2" data-name="Group 74" transform="translate(0.329 0.376)">
                            <g id="Group_71-2" data-name="Group 71">
                                <path id="Path_10-2" data-name="Path 10" d="M0,0,12,10.5,0,20.992" fill="none" stroke="#020202" strokeWidth="2" />
                            </g>
                        </g>
                    </g>
                    <rect id="Rectangle_173" data-name="Rectangle 173" width="44" height="44" transform="translate(812 925)" fill="none" />
                </g>
            </g>
        </svg>
    );
};
const NextDayIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id="ic_numpad_next_page" transform="translate(748 764) rotate(180)">
                <circle id="Ellipse_40" data-name="Ellipse 40" cx="32" cy="32" r="32" transform="translate(684 700)" fill="#01b59c" />
                <g id="Group_2878" data-name="Group 2878" transform="translate(1663 -102) rotate(90)">
                    <g id="Group_370" data-name="Group 370" transform="translate(845.369 947.671) rotate(90)">
                        <g id="Group_74" data-name="Group 74" transform="translate(0.329 0.376)">
                            <g id="Group_71" data-name="Group 71">
                                <path id="Path_10" data-name="Path 10" d="M0,0,12,10.5,0,20.992" fill="none" stroke="#020202" strokeWidth="2" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_2880" data-name="Group 2880" transform="translate(845.369 935.671) rotate(90)">
                        <g id="Group_74-2" data-name="Group 74" transform="translate(0.329 0.376)">
                            <g id="Group_71-2" data-name="Group 71">
                                <path id="Path_10-2" data-name="Path 10" d="M0,0,12,10.5,0,20.992" fill="none" stroke="#020202" strokeWidth="2" />
                            </g>
                        </g>
                    </g>
                    <rect id="Rectangle_173" data-name="Rectangle 173" width="44" height="44" transform="translate(812 925)" fill="none" />
                </g>
            </g>
        </svg>
    );
};
const GoIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id="ic_numpad_go" transform="translate(-1056 -557)">
                <path id="Path_1370" data-name="Path 1370" d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z" transform="translate(1056 557)" fill="#01b59c" />
                <text id="go" transform="translate(1066 569)" fill="#020202" fontSize="28" fontFamily="Roboto-Regular, Roboto">
                    <tspan x="6.086" y="27">
                        go
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
const NumIcon = (props) => {
    const textID = '_' + props.num;
    const gID = 'ic_numpad_0' + props.num;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 64 64">
            <g id={gID} transform="translate(-800 -301)">
                <circle id="Ellipse_40" data-name="Ellipse 40" cx="32" cy="32" r="32" transform="translate(800 301)" fill="#01b59c" />
                <text id={textID} data-name={props.num} transform="translate(816 308)" fill="#020202" fontSize="42" fontFamily="Roboto-Regular, Roboto">
                    <tspan x="4.177" y="40">
                        {props.num}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export { JumpBackIcon, FullScreenIcon, PrevDayIcon, NextDayIcon, GoIcon, NumIcon };
