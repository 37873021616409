import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

const ImageHelper = ({ alt_text, image, cardIndex, program_name }) => {
    const [isValidImage, validImageSetter] = useState(true);

    return isValidImage ? (
        <img
            alt={alt_text}
            className={css(styles.GridItemImage)}
            src={image}
            onError={(e) => {
                validImageSetter(false);
            }}
        />
    ) : (
        <p className={css(styles.GridItemText)}>{program_name}</p>
    );
};

export default ImageHelper;

const styles = StyleSheet.create({
    GridItemImage: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20em',
        height: '10em',
        textAlign: 'center',
        transformOrigin: 'center center',
        transition: 'transform .25s', // filter .5s ease-in-out',
        //filter: 'brightness(50%)',
        ':hover': {
            //filter: 'brightness(100%)',
            transform: 'scale(1.1)',
        },
    },
    GridItemText: {
        padding: '1em',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '500',
        alignContent: 'center',
        transformOrigin: 'center center',
        overflow: 'hidden', //following 3 lines is equivalent of numberOfLines={1}
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        transition: 'transform .25s', // filter .5s ease-in-out',
        ':hover': {
            transform: 'scale(1.2)',
        },
    },
});
