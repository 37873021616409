import React, { Component } from 'react';
//import { Animated, DeviceEventEmitter, Dimensions, StyleSheet, Text, View } from 'react-native';
//import Icon from 'react-native-vector-icons/FontAwesome';
import { xScale, yScale, fontScale } from '../../utils/Scaling';
import { StyleSheet, css } from 'aphrodite';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//const dimensions = Dimensions.get('screen');

// export const Item = ({ text, isSelected }) => {
//     return (
//         // <View style={{ flexDirection: 'row' }}>
//         //     <View style={isSelected ? styles.itemSelected : styles.item}>
//         //         <Text style={isSelected ? styles.itemSelectedText : styles.itemText}>{text}</Text>
//         //     </View>
//         // </View>
//         <></>
//     );
// };

export const Item = ({ text, isSelected, onSelection }) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                onSelection();
            }}
            className={css(styles.OptionItem)}>
            {text}
        </div>
    );
};

export default class CancelMyTicket extends Component {
    constructor() {
        super();
        this.state = {
            selectedIndex: 0,
            //bounceValue: new Animated.Value(250)
        };
    }

    componentDidMount() {
        // Animated.spring(
        // 	this.state.bounceValue,
        // 	{
        // 		toValue: 0,
        // 		velocity: 3,
        // 		tension: 2,
        //         friction: 5,
        //         useNativeDriver: true
        // 	}
        // ).start();
        //this._optionsEmitter = DeviceEventEmitter.addListener('onKeyDown', this._listenerFunction);
    }
    componentWillUnmount() {
        this.closeOptions();
    }

    _listenerFunction = (key) => {
        const { keyMap } = this.props;
        if (!this.props.connTimeOut && !this.props.isFullScreen) {
            switch (key.keyCode) {
                case keyMap.up: //up
                    this.state.selectedIndex == 1 && this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                    break;
                case keyMap.down: //down
                    this.state.selectedIndex < 1 && this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                    break;
                // case 23:
                //          this.state.selectedIndex == 0 && this.props.CancelMyTicket ? this.props.CancelMyTicket : (this.props.toggleCancelTicketScreen(!this.props.isCancelTicketScreenOpen))

                // if (this.state.selectedIndex == 1) { //user selects back option
                // 	(this.props.toggleCancelTicketScreen(!this.props.isCancelTicketScreenOpen));
                // }

                case keyMap.select:
                    if (this.state.selectedIndex === 0) {
                        const type = 'disableOrder'; //this will add a parameter to the request that will preserve the order but delete the assets before the api call is made
                        const id = this.props.recording.order_id;
                        this.props.deleteRecording(type, id);
                        this.props.cancel();
                        this.closeOptions();
                        break;
                    } else if (this.state.selectedIndex === 1) {
                        this.closeOptions();
                        break;
                    } else {
                        break;
                    }
                case keyMap.back:
                    this.closeOptions();
                    break;
            }
        }
    };

    componentWillUnmount() {
        this._optionsEmitter && this._optionsEmitter.remove();
    }

    closeOptions() {
        this.props.toggleCancelTicketScreen(false);
    }

    render() {
        //const { height, width } = Dimensions.get('window');
        const { channelInfo, programIndex } = this.props;

        return (
            // <View style={{ position: 'absolute', height, width, backgroundColor: 'rgba(155, 155, 155, 0.5)', elevation: 1 }}>
            // 	<Animated.View style={[styles.optionsContainer, { transform: [{ translateY: this.state.bounceValue }] }]}>
            // 		<View style={styles.optionsHeader}>
            // 			<Text style={{ fontFamily: 'OpenSans-Regular', color: '#1299C5', fontSize: fontScale(26) }}>Cancel MyTicket</Text>
            // 		</View>
            //         <View style={styles.optionsHeader}>
            //             <Text style={{ fontFamily: 'OpenSans-Regular', color: '#9B9B9B', fontSize: fontScale(24) }}>Do you want to cancel this MyTicket?</Text>
            //         </View>
            // 		<View style={styles.itemListContainer}>
            // 			<Item text='Yes, cancel this MyTicket' isSelected={this.state.selectedIndex === 0} />
            // 			<Item text='No, keep this MyTicket' isSelected={this.state.selectedIndex === 1} />
            // 		</View>
            // 	</Animated.View>
            // </View>
            <motion.div
                key="optionsMyShows"
                initial={{ y: '110%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                exit={{ y: '110%', opacity: 0 }}
                transition={{ duration: 0.3 }}
                className={css(styles.motionContainer)}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={css(styles.Container)}>
                    <FontAwesomeIcon
                        onClick={() => {
                            this.closeOptions();
                            this.props.onOptionClose();
                        }}
                        className={css(styles.ExitButton)}
                        icon="times"
                    />
                    <div className={css(styles.Header)}>
                        <p className={css(styles.HeaderText)}>Are you sure?</p>
                    </div>
                    <div className={css(styles.SubHeader)}>
                        <p className={css(styles.SubHeaderText)}>Cancel Series Recording</p>
                    </div>
                    <div className={css(styles.Options)}>
                        <Item
                            text={'Confirm'}
                            onSelection={() => {
                                const type = 'disableOrder'; //this will add a parameter to the request that will preserve              the order but delete the assets before the api call ismade
                                const id = this.props.recording.order_id;
                                this.props.deleteRecording(type, id);
                                this.props.cancel();
                                this.closeOptions();
                                this.props.onOptionClose();
                            }}
                            isSelected={this.state.selectedIndex === 0}
                            key={0}
                        />
                        <Item text={'Exit'} onSelection={() => this.closeOptions()} isSelected={this.state.selectedIndex === 1} key={1} />
                    </div>
                </div>
            </motion.div>
        );
    }
}

const styles = StyleSheet.create({
    motionContainer: {
        zIndex: 3,
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    Container: {
        // width: '20em',
        minHeight: '8em',
        position: 'absolute',
        backgroundColor: '#202020',
        bottom: 0,
        width: '100%',
        zIndex: '3',
        color: 'white',
    },
    Options: {
        marginLeft: '4em',
        marginBottom: '3em',
        width: '17em',
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
    },
    OptionItem: {
        ':hover': {
            backgroundColor: '#00CC99',
            color: 'black',
            cursor: 'pointer',
        },
        borderColor: 'rgba(230,230,230, 0.3)',
        borderStyle: 'solid',
        borderWidth: '.01em',
        padding: '.6em',
    },
    Header: {
        marginTop: '1.5em',
        marginBottom: '1.5em',
        marginLeft: '4em',
        width: '40em',
    },
    HeaderText: {
        fontSize: '1.3em',
        textAlign: 'initial',
        color: '#00CC99',
    },
    SubHeader: {
        marginBottom: '1.5em',
        marginLeft: '4em',
        width: '40em',
    },
    SubHeaderText: {
        fontSize: '1.1em',
        textAlign: 'initial',
        color: 'white',
    },
    ExitButton: {
        margin: '0 .2em',
        padding: '.2em .5em',
        fontSize: '1.6em',
        right: 0,
        position: 'absolute',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: '#606060',
            borderRadius: '3em',
            pointer: 'arrow',
        },
    },
});
