import moment from 'moment';
import {
    GET_LOGIN_BEGIN,
    GET_LOGIN_FAILED,
    GET_LOGIN_SUCCESS,
    ON_LOGIN_NEEDED,
    LOGIN_COMPLETED,
    TOGGLE_RESET_NOTICE,
    // SET_SHOW_DIALOG
} from '../types/login';
import { getAssets, getUpcomingAssets } from './myshows';
import { errorHandler } from '../utils/ErrorHandler';
import {
    GetToken,
    GetSubscriberData,
    gatheringDeviceInfo,
    checkingIfLoginNeeded,
    boxModel,
    telcoBrandSetter,
    telcoEmailSetter,
    favTelcoLimitGetter,
} from '../services/LoginServices'; //telcoBrandSetter
import { setTokenInHeader, setScTokenInHeader } from '../utils/AxiosInstances';
import { fetchGuideDataBegin, updateGuideEndPagesTimes, getPrograms, setLightweightVersion } from './main';
import { saveBoxModel } from '../actions/main';
import StorageService from '../services/StorageService';
import { changeLockedChannels } from '../actions/menu';
import { channelLineup } from '../services/SotalCloudService';
import { bugSnagNotifierSetup } from '../services/BugSnagService'; //bug snag service

let subDeviceInfo = {}; //we'll plug it into subData
let storeToken = false;
const DEBUG_USER_LIST = [
    'nikolas@skittertv.com',
    'kyle.fratello@skittertv.com',
    'eschopler@gmail.com',
    'christian.coleman@skittertv.com',
    'mark.johnson@skitter.tv',
];

export const loginSequence = () => {
    return (dispatch) => {
        checkingIfLoginNeeded()
            .then((result) => {
                // let's see if we already have the token
                boxModel().then((model) => dispatch(saveBoxModel(model))); //box model should read
                if (result && result.username) {
                    //we have login data
                    let subData = {};
                    subData['email'] = result.username;
                    subData['password'] = result.password;
                    storeToken = false; //we already have that data - do not store it
                    dispatch(onLoginDataReady(subData));
                } else {
                    // we do not have the oAuth token
                    storeToken = true;
                    dispatch(onLoginNeeded());
                }
            })
            .catch((error) => {
                let errorObject = errorHandler(error, 'loginSequence', { device: 'unknown' });
                dispatch(onLoginFailed(errorObject.message)); //will show whatever error we get here
            });
    };
};

export const onLoginNeeded = () => ({
    type: ON_LOGIN_NEEDED,
});

export const onLoginBegin = () => ({
    type: GET_LOGIN_BEGIN,
});

export const onLoginFailed = (error, originalErrorResponse) => ({
    type: GET_LOGIN_FAILED,
    originalErrorResponse,
    error,
});

export const loginCompleted = () => ({
    type: LOGIN_COMPLETED,
});

export const onLoginSuccess = (subData) => {
    return {
        type: GET_LOGIN_SUCCESS,
        subData,
    };
};

export const toggleResetNotice = (isResetNoticeSet) => ({
    type: TOGGLE_RESET_NOTICE,
    isResetNoticeSet,
});

let verifyPlatform = () => {
    const excludedsubStringsArr = [
        //if any of these substrings are present, platform is invalid
        'mobile',
        'android',
        'iphone',
        'edg/',
        'edge/',
        'trident/',
        'msie',
        'opr/',
    ];
    const mustIncludeOneArr = [
        // useragent string must include atleast one of these substrings
        'chrome',
        'firefox',
    ];
    const uaString = window.navigator.userAgent.toLowerCase();
    for (let i = 0; i < excludedsubStringsArr.length; i++) {
        if (uaString.includes(excludedsubStringsArr[i])) {
            throw new Error('Invalid Platform');
        }
    }
    for (let i = 0; i < mustIncludeOneArr.length; i++) {
        if (uaString.includes(mustIncludeOneArr[i])) {
            return true;
        }
    }
    throw new Error('Invalid Platform');
};

export const onLoginDataReady = (subscriberData) => {
    //used when we do not have token - full login
    //storeToken && StoringInSecureStorage(subscriberData.email, subscriberData.password).then(res => console.log("success storing login"));//fist thing - store password
    return (dispatch) => {
        dispatch(onLoginBegin());
        //gatheringDeviceInfo(); //let's gather device info
        //.then(deviceInfo => {
        // console.log("deviceInfo: ", deviceInfo);
        //subDeviceInfo = deviceInfo;//if we have subDeviceInfo - that means that we successfully read the device
        return GetToken(subscriberData) //subDeviceInfo
            .then((response) => {
                //in case of successful response - store login into storage
                setTokenInHeader(response.data.token); // we go the token - update the header
                setScTokenInHeader(response.data.sotal_cloud_token);
                return GetSubscriberData().then((result) => {
                    let subData = result.data.user;
                    subData.token = result.config.headers.Authorization;
                    ///////////temporary until refresh sc call added
                    subData = {
                        ...subData,
                        tempPw: subscriberData.tempPw ? subscriberData.tempPw : subscriberData.password,
                    };
                    ///////////
                    subData.callSignToName = callSignToName(subData);
                    //getGridRows(dispatch, subData);
                    const cdvrAccess =
                        subData.services &&
                        subData.services.cdvr !== '' &&
                        subData.services.cdvr !== null &&
                        subData.services.cdvr !== false &&
                        subData.dvr_enabled &&
                        subData.dvr_enabled !== false;
                    bugSnagNotifierSetup(subData);
                    verifyPlatform();
                    dispatch(setLightweightVersion(cdvrAccess));
                    subData['subDeviceInfo'] = subDeviceInfo; //let's add subDeviceInfo
                    subData['cdvrAccess'] = cdvrAccess;
                    //TEMP? Adding debug boolean to user
                    if (DEBUG_USER_LIST.includes(subData['email'])) {
                        subData['isDebugUser'] = true;
                    } else {
                        subData['isDebugUser'] = false;
                    }
                    dispatch(onLoginSuccess(subData, dispatch));
                    cdvrAccess && dispatch(getAssets(subData.id));
                    //getAsyncStorageData(dispatch, subData);
                    telcoBrandSetter(subData); //telco branding
                    telcoEmailSetter(subData); //support email
                    favTelcoLimitGetter(subData); //get Telco Favorite Limit
                    // adminSetter(subData);
                    //dispatch(pusherChannelSetup(subData));//TO DO - uncomment all of them
                    dispatch(guideSetup(subData));
                });
            })
            .catch((error) => {
                let errorObject = errorHandler(error, 'onLoginDataReady', subDeviceInfo);
                dispatch(onLoginFailed(errorObject.message, error)); //will show whatever error we get from ErrorHandler
            });
        //})
        // .catch(error => {
        //     let errorObject = errorHandler(error, "onLoginDataReady", subscriberData);//using subData, because no subDeviceInfo
        //     dispatch(onLoginFailed(errorObject.message));//will show whatever error we get here
        // })
    };
};

export const guideSetup = (subData) => {
    const guideStartTime = moment()
        .subtract(moment().minute() % 30, 'minutes')
        .subtract(moment().second(), 'seconds')
        .subtract(4440, 'minutes')
        .unix();
    const guideEndTime = moment()
        .subtract(moment().minute() % 30, 'minutes')
        .subtract(moment().second(), 'seconds')
        .add(14400, 'minutes')
        .unix(); // // guideStart + 6000
    //7200s(2hr) buffer window
    const guideEndPagesTimes = {
        guideFirstPageTime: guideStartTime + 7200,
        guideLastPageTime: guideEndTime - 7200,
    };

    return (dispatch) => {
        channelLineup(subData, dispatch);
        dispatch(fetchGuideDataBegin());
        dispatch(updateGuideEndPagesTimes(guideEndPagesTimes));
        dispatch(getPrograms(guideStartTime, guideEndTime, subData));
    };
};

export const getLockedChannelData = (dispatch) => {
    //needs to be called after getting guide channels
    StorageService.getItem('lockedChannels')
        .then((res) => {
            res && dispatch(changeLockedChannels(JSON.parse(res)));
        })
        .catch((error) => console.log(error));
};

const callSignToName = (subData) => {
    let callsignToName = {};
    for (let i = 0; i < subData.channels.length; i++) {
        // callsignToName[subData.channels[i].callsign] = {"name" : subData.channels[i].name, "ch_number" : subData.channels[i].custom_channel.custom_channel_number};
        callsignToName[subData.channels[i].callsign] = subData.channels[i].name;
    }
    return callsignToName;
};
