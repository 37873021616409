import React from 'react';
import { connect } from 'react-redux';
import NavBar from '../NavBar';
import { resetAppState } from '../../../actions/main';

const NavBarContainer = (props) => {
    return <NavBar {...props} />;
};

const mapStateToProps = (state) => {
    return {
        lightweightVersion: state.main.lightweightVersion,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    resetAppState: () => dispatch(resetAppState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
