import { CHANGE_CARD, TOGGLE_MENU, CHANGE_MENU_INDEX } from '../types/home';

const initialState = {
    selectedCard: 0,
    isMenuEnabled: true,
    menuIndex: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CARD:
            return {
                ...state,
                selectedCard: action.cardIndex,
            };
        case TOGGLE_MENU:
            return {
                ...state,
                isMenuEnabled: action.isEnabled,
            };
        case CHANGE_MENU_INDEX:
            return {
                ...state,
                menuIndex: action.menuIndex,
            };
        default:
            return state;
    }
};
