import { SET_SEARCH_TEXT, CHANGE_KEY_PAD_COL, CHANGE_KEY_PAD_ROW, TOGGLE_KEY_PAD } from '../types/search';

const initialState = {
    searchText: '',
    keyPadCol: 0,
    keyPadRow: 0,
    isKeyPadEnabled: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.searchText,
            };
        case CHANGE_KEY_PAD_COL:
            return {
                ...state,
                keyPadCol: action.colIndex,
            };
        case CHANGE_KEY_PAD_ROW:
            return {
                ...state,
                keyPadRow: action.rowIndex,
            };
        case TOGGLE_KEY_PAD:
            return {
                ...state,
                isKeyPadEnabled: action.isEnabled,
            };
        default:
            return state;
    }
};
