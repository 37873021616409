import React from 'react';

//import { Dimensions, StyleSheet, Text, View, Image, FlatList, Animated, DeviceEventEmitter, Easing } from 'react-native';
//import Icon from 'react-native-vector-icons/FontAwesome';
//import { xScale, yScale, fontScale } from '../../utils/Scaling';
//const { height, width } = Dimensions.get('window');
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleSheet, css } from 'aphrodite';

const ScheduleTimesAndDates = (props) => {
    const { pageStartTime, horizontalPageIndex, guideMarkerRatio, tempChannelHolder } = props;
    return (
        <>
            {horizontalPageIndex < 0 ? (
                <div className={css(styles.Main)}>
                    {/* //     <View style={[styles.dateTimeContainer]}>
            //         {horizontalPage === 0 && <View style={{ flexDirection:'row', position: 'absolute', top: -5,   }}><View style={{ height: '50%', width: (((width / 5.2) / 30) * guideMarkerRatio) - 5, borderBottomColor: '#1299C5', borderBottomWidth: xScale(5) }}/><Icon name="caret-down" size={xScale(15)} color="#1299C5" /></View>}
            //         <Text style={styles.textStyle}>{guideStartTime.format('hh:mma')}</Text>
            //     </View> */}
                    <div className={css(styles.DateAndTImeGridPast)}>
                        <div className={css(styles.TimeContainerPast)}>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '.3em',
                                        bottom: '0',
                                        width: `425%`,
                                        backgroundColor: 'white',
                                    }}
                                />
                                <div className={css(styles.TimeText)}>{pageStartTime.format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(30, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(60, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(90, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.OverFlowContainer)}></div>
                        </div>
                            <div className={css(styles.DateDayContainer)}>
                                <div className={css(styles.DayDateText)}>
                                    {tempChannelHolder ? tempChannelHolder + '_' : pageStartTime.format('ddd M/DD').toUpperCase()}
                                </div>
                            </div>
                    </div>
                </div>
            ) : (
                <div className={css(styles.Main)}>
                    {/* //     <View style={{ width: width / 6, justifyContent: 'center', paddingHorizontal: xScale(20), borderColor: 'rgba(230,230,230, 0.7)', borderWidth: xScale(0.5), backgroundColor: 'rgba(0,0,0, 0.6)', }}>
            //         <View style={{ flexDirection: 'row', alignItems: 'center', width: xScale(160) }}>
            //             <Icon name="backward" size={xScale(14)} color="#979797" style={{ marginHorizontal: xScale(5) }} />
            //             <Icon name="undo" size={xScale(14)} color="#666971" style={{ marginHorizontal: xScale(5) }} />
            //             <Text style={{ fontFamily: 'OpenSans-Light', fontSize: fontScale(18), textAlign: "center", minWidth: xScale(90), color: tempChannelHolder ? '#1299C5' : '#dbdbdb'}}>{tempChannelHolder ? tempChannelHolder+"_" : pageStartTime}</Text>
            //             <Icon name="forward" size={xScale(14)} color="#666971" style={{ marginHorizontal: xScale(5)}} /> 
            //         </View>
            //     </View> */}
                    <div className={css(styles.DateTimeGrid)}>
                        <div className={css(styles.DateDayContainer)}>
                            <div className={css(styles.DayDateText)}>
                                {tempChannelHolder ? tempChannelHolder + '_' : pageStartTime.format('ddd M/DD').toUpperCase()}
                            </div>
                        </div>
                        <div className={css(styles.TimeContainer)}>
                            <div className={css(styles.ThirtyMinContainer)}>
                                {horizontalPageIndex === 0 && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            height: '.3em',
                                            // marginLeft: '.5em',
                                            bottom: '0',
                                            width: `${(guideMarkerRatio / 30) * 100}%`,
                                            backgroundColor: 'white',
                                        }}
                                    />
                                )}
                                <div className={css(styles.TimeText)}>{pageStartTime.format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(30, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(60, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.ThirtyMinContainer)}>
                                <div className={css(styles.TimeText)}>{pageStartTime.clone().add(90, 'minutes').format('hh:mma').toUpperCase()}</div>
                            </div>
                            <div className={css(styles.OverFlowContainer)}></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    TimeContainerPast: {
        display: 'flex',
        flexDirection: 'row',
        flex:5,
        justifyContent: 'flex-start',
    },
    TimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex:5,
        justifyContent: 'flex-start',
        
    },
    Main: {
        display: 'flex',
        flexDirection: 'row',
        // '@media screen and (min-width: 1240px)': {
        //     maxWidth: '90vw',
        // },
        // '@media screen and (max-width: 1239px)': {
        //     // minWidth: '1129px',
        //     maxWidth: '63em'
        // }
        width: '90vw',
        minWidth: '56em'
    },
    DateTimeGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '15% 85%'
    },
    DateAndTImeGridPast: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '85% 15%'
    },
    DateDayContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // flexDirection: 'column',
        flex: 1
    },
    ThirtyMinContainer: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 4,
    },
    OverFlowContainer: {
        flex: 1,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    TimeText: {
        color: '#dbdbdb',
        fontSize: '1.1em',
    },
    DayDateText: {
        color: '#dbdbdb',
        fontSize: '1.1em',
    },
});

export default ScheduleTimesAndDates;
