import { channelsLoadAxios } from '../utils/AxiosInstances';
import { makingErrorObject } from '../utils/ErrorHandler';
import { bugSnagNotifierGeneral } from '../services/BugSnagService';
import axios from 'axios';
const CancelToken = axios.CancelToken;
//let cancelMap= {};
let requestCancelCallbacks = []; //an array of callbacks to cancel open requests

export const cancelChannelsLoadRequests = () => {
    if (requestCancelCallbacks.length > 0) {
        requestCancelCallbacks.forEach((cur) => {
            cur('fcancel'); //if we forcefully cancel - do not do retries
        });
        requestCancelCallbacks = [];
    }
};

export const getChannelPrograms = (start, end, subData, numberOfPages, initialLoad, favoriteChannel) => {
    let number_of_pages = numberOfPages > 0 ? numberOfPages : subData.channels.length;
    const initialLoadLimit = 75;
    const initialInPast = 10; //how many of initially loaded channels should be from end/seen when user pages up
    let chansToRequest = initialLoad && number_of_pages > initialLoadLimit ? initialLoadLimit : number_of_pages;
    let initialLoadPageNums = [];

    if (initialLoad) {
        //only needed on first truncated load;
        for (let i = 0; i < chansToRequest; i++) {
            if (i < initialInPast) {
                initialLoadPageNums.push(number_of_pages - 1 - i); //-1 so we get index val
            } else {
                initialLoadPageNums.push(i - initialInPast);
            }
        }

        if (favoriteChannel && favoriteChannel !== '0') {
            // update initial channels-to-load depending on the favoriteChannel(index value).
            initialLoadPageNums.forEach((cur, i) => {
                const offset = cur + +favoriteChannel;
                if (offset > number_of_pages - 1) {
                    initialLoadPageNums[i] = offset - number_of_pages;
                } else {
                    initialLoadPageNums[i] = offset;
                }
            });
        }
    }

    //this is for splitting the guide times into X times to be requested at once;
    let intervalSlice = Math.floor((end - start) / 28); //split by 28, each chunk is 12 hours
    let batchArr = [];
    for (let i = 0; i < 28; i++) {
        batchArr.push({});
        if (i === 0) {
            batchArr[i].start = start;
            batchArr[i].end = start + intervalSlice;
        } else {
            batchArr[i].start = batchArr[i - 1].start + intervalSlice;
            batchArr[i].end = batchArr[i - 1].end + intervalSlice;
        }
    }

    //This is a time range of 12 hours (current - 4 hours, current + 8 hours). Used on initial Load only.
    let initialLoadStart = batchArr[6].start + 12800;
    let initialLoadEnd = batchArr[6].end + 12800;

    //cancel any open requests
    cancelChannelsLoadRequests();

    // const getPromiseArray = async () => {
    //     //redo
    //     const requestBatch = [];
    //     for (let i = 0; i < chansToRequest; i++) {
    //         requestBatch.push(
    //             channelsLoadAxios.get(
    //                 `/getguide/${initialLoad ? initialLoadStart : start}/${initialLoad ? initialLoadEnd : end}?page=${
    //                     initialLoad ? initialLoadPageNums[i] : i
    //                 }&items_per_page=${1}&no_data=false`,
    //                 {
    //                     cancelToken: new CancelToken(function executor(c) {
    //                         requestCancelCallbacks.push(c);
    //                     }),
    //                 }
    //             )
    //         );
    //     }
    //     return await Promise.all(requestBatch); //return all resolved requests
    // };
    const getPromiseArray = async () => {
        const requestBatch = [];

        let newarr = []; //created new array to store all chans
        for (let i = 0; i < chansToRequest; i++) {
            newarr.push(i);
        }

        for (let i = 0; i < newarr.length; i += 5) {
            const newBatch = newarr.slice(i, i + 5).map((chan, i) => {
                return channelsLoadAxios
                    .get(
                        `/getguide/${initialLoad ? initialLoadStart : start}/${initialLoad ? initialLoadEnd : end}?page=${
                            initialLoad ? initialLoadPageNums[chan] : chan
                        }&items_per_page=${1}&no_data=false`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                requestCancelCallbacks.push(c);
                            }),
                        }
                    )
                    .then((res) => {
                        if (res) {
                            requestBatch.push(res);
                        }
                    });
            });

            await Promise.all(newBatch);
        }
        return requestBatch;
    };

    return getPromiseArray().then((response) => {
        requestCancelCallbacks = [];
        //this extra check added so it won't crash if response is anything other than array
        if (response && Array.isArray(response) && response.length > 0) {
            //if there is something in guide array, let's sort it

            let getPlaceHolder = (isFirst, time, isEmpty, isInitialLoad, isInitialEmpty) => {
                //placeholder programs to fill the guide whe only a few programs are requested on initial load
                return {
                    category: '',
                    end_formated: '',
                    ep_name: isEmpty || !isInitialLoad ? '' : 'This Program is Loading',
                    ep_number: '',
                    genres: [],
                    long_title: isEmpty || !isInitialLoad ? '' : 'Loading Program Data...',
                    program_desc: '',
                    program_id: isEmpty || !isInitialLoad ? '' : 'placeholder',
                    program_rating: '',
                    release_year: '',
                    schedule_id: '',
                    se_name: '',
                    se_number: '',
                    series_id: '',
                    show_type: '',
                    source_id: '',
                    start_date: '',
                    start_formated: '',
                    start_time: '',
                    tv_rating: '',
                    unix_end_time: isFirst && !isEmpty && !isInitialEmpty ? time : +end.toString().concat('000'),
                    unix_start_time: !isFirst && !isEmpty && !isInitialEmpty ? time : +start.toString().concat('000'),
                };
            };

            //guideData will need to start with subdata.channels instead of just response so we can have channels without programs that still work/ have sc asset id and custom channel num
            const guideData = subData.channels.map((cur) => {
                let resIndex = response.findIndex((resCH) => cur?.custom_channel?.custom_channel_number === resCH?.custom_channel_number);
                if (resIndex !== -1) {
                    //if the channel data was included in initial call just dump info
                    return {
                        ...response[response.findIndex((resCH) => cur?.custom_channel?.custom_channel_number === resCH?.custom_channel_number)], //matching chan obj in res
                        sotal_cloud_channel_asset_id: cur.sotal_cloud_channel_asset.sotal_cloud_channel_asset_id,
                        type: cur.type,
                        restart_time_limit: cur.restart_time_limit,
                        fillerChannel: false,
                        rovi_custom_channel_logo: cur.channel_logo,
                    };
                } else {
                    //if not, then get neccesarry info from subData.channels
                    return {
                        custom_channel_number: cur.custom_channel.custom_channel_number,
                        sotal_cloud_channel_asset_id: cur.sotal_cloud_channel_asset.sotal_cloud_channel_asset_id,
                        source_id: +cur.rovi_source_id,
                        restart_time_limit: +cur.restart_time_limit,
                        fillerChannel: true,
                        rovi_custom_channel_logo: cur.channel_logo,
                    };
                }
            });

            guideData.sort((a, b) => {
                return +a.custom_channel_number - +b.custom_channel_number;
            });

            for (let i = 0; i < subData.channels.length; i++) {
                //loop for each channel the user has
                //guideData[i].sotal_cloud_channel_asset_id = subData.channels[subData.channels.findIndex((cur) => cur?.custom_channel?.custom_channel_number === guideData[i]?.custom_channel_number)]?.sotal_cloud_channel_asset?.sotal_cloud_channel_asset_id;
                guideData[i].channelIndex = i;
                guideData[i].restart_buffer = 720; //subData.channels[subData.channels.findIndex((cur) => cur.custom_data.custom_data.custom_channel_number === guideData[i].custom_channel_number)].sotal_cloud_channel_asset.sotal_cloud_channel_asset_id;
                if (guideData[i].programs) {
                    for (let y = 0; y < guideData[i].programs.length; y++) {
                        if (
                            guideData[i].programs[y].unix_end_time &&
                            guideData[i].programs[y].unix_start_time &&
                            typeof guideData[i].programs[y].unix_start_time === 'string' &&
                            typeof guideData[i].programs[y].unix_end_time === 'string'
                        ) {
                            guideData[i].programs[y].unix_start_time = +guideData[i].programs[y].unix_start_time.concat('000');
                            guideData[i].programs[y].unix_end_time = +guideData[i].programs[y].unix_end_time.concat('000');
                        } else {
                            // console.log("INVALID UNIX TIME", "CHANNEL :" ,guideData[i], "PROGRAM :",guideData[i].programs[y])
                            guideData[i].fillerChannel = true;
                            let customData = {
                                Channel_errored: { Channel_errored: guideData[i].custom_channel_number },
                                Program_errored: { Program_errored: guideData[i].programs[y] },
                            };
                            bugSnagNotifierGeneral('Missing/invalid unix time for get-guide program', 'ChannelsLoad', null, null, null, customData);
                            break;
                        }
                    }
                    if (!guideData[i].fillerChannel) {
                        guideData[i].programs = guideData[i].programs.sort((a, b) => {
                            return a.unix_start_time - b.unix_start_time;
                        });
                        //here we are just filling the empty space in each channel with placeholder programs for the initial load
                        const firstProgramStart = guideData[i].programs[0].unix_start_time;
                        const lastProgramStart = guideData[i].programs[guideData[i].programs.length - 1].unix_end_time;
                        if (firstProgramStart > start * 1000) {
                            guideData[i].programs.unshift(getPlaceHolder(true, firstProgramStart, false, initialLoad));
                        }
                        if (lastProgramStart < end * 1000) {
                            guideData[i].programs.push(getPlaceHolder(false, lastProgramStart, false, initialLoad));
                        }
                    } else {
                        guideData[i].programs = [getPlaceHolder(false, false, true)];
                    }
                } else {
                    guideData[i].fillerChannel
                        ? (guideData[i].programs = [getPlaceHolder(false, false, false, true, true)])
                        : (guideData[i].programs = [getPlaceHolder(false, false, true)]);
                }
            }
            return guideData;
        } else {
            return [];
        }
    });
};

channelsLoadAxios.interceptors.request.use(
    (request) => {
        if (!request.retries) {
            request.retries = 3;
        } else {
            request.retries -= 1;
        }
        return request;
    },
    (error) => {
        return error;
    }
);

channelsLoadAxios.interceptors.response.use(
    (response) => {
        // console.log('channelsLoadAxios',response);
        // return Array.isArray(response.data.data) ? response.data.data[0] : response.data.data[Object.keys(response.data.data)[0]]; //this is a workaround for backend bug: after 1st page, data comes back in an object
        return Array.isArray(response?.data?.data) ? response.data.data[0] || {} : response.data.data[Object.keys(response.data.data)[0]]; //this is a workaround for backend bug: after 1st page, data comes back in an object
    },
    (error) => {
        console.log('error', error);
        if (error?.message !== 'fcancel') {
            //only do error stuff if we not forcefully cancel
            let config = error?.config ? error.config : null;

            if (
                config &&
                error?.response &&
                ((config.retries < 3 && (error.response.status === 401 || error.response.status === 404 || error.response.status === 400)) ||
                    error.response.status === 500)
            ) {
                const errorObject = makingErrorObject(
                    (error && error.message) || error,
                    config || 'config not available',
                    (config && config.retries) || 0,
                    null
                );
                return Promise.reject(errorObject);
            }

            if (error && config && config.retries > 0) {
                let backoff = new Promise(function (resolve) {
                    //retry request 3 times, 1sec after every failure
                    setTimeout(function () {
                        resolve();
                    }, 1000);
                });

                return backoff.then(function () {
                    return channelsLoadAxios(config);
                });
            }

            const errorObject = makingErrorObject((error && error.message) || error, config || 'config not available', (config && config.retries) || 3, null);
            return Promise.reject(errorObject);
        } else {
            return;
        }
    }
);
