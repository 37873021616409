import React, { Component } from 'react';
import transEpic from '../../assets/transEpic.png';
import { clearTokenInHeader } from '../../utils/AxiosInstances';
import { disconnectFromPusherChannel } from '../../actions/main';
import { StyleSheet, css } from 'aphrodite';
import { NavLink } from 'react-router-dom';
import { branding_url } from '../../services/LoginServices';

export default class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNavDropOpen: false,
            isUserDropOpen: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this._resizeEvent);
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeEvent);
    }

    _resizeEvent = (x) => {
        // console.log('resize event', x)
        if (x.currentTarget.innerWidth >= 800 && this.state.isNavDropOpen === true) {
            // console.log("Target is greater or equal to 800", x.currentTarget.innerWidth)
            this.toogleUserWithNav();
        } else if (x.currentTarget.innerWidth >= 800 && this.state.isUserDropOpen === true) {
            this.toogleUserDropdown();
        }
    };
    _sessionCleanUp = () => {
        clearTokenInHeader();
        disconnectFromPusherChannel();
        this.props.resetAppState();
    };

    toggleNavDropdown = () => {
        this.setState((prevState) => {
            // console.log(prevState.isNavDropOpen);
            return {
                isNavDropOpen: !prevState.isNavDropOpen,
            };
        });
    };

    toogleUserDropdown = () => {
        this.setState((prevState) => {
            // console.log(prevState.isUserDropOpen);
            return {
                isUserDropOpen: !prevState.isUserDropOpen,
            };
        });
    };

    toogleUserWithNav = () => {
        if (this.state.isUserDropOpen === true) {
            this.setState((prevState) => {
                // console.log(prevState.isUserDropOpen);
                return {
                    isUserDropOpen: !prevState.isUserDropOpen,
                };
            });
            this.toggleNavDropdown();
        } else {
            this.toggleNavDropdown();
        }
    };

    hideUserDropAfterClick = (e) => {
        if (e && e.relatedTarget) {
            e.relatedTarget.click();
            // console.log(e);
        }
        this.setState({ isUserDropOpen: false });
    };

    render() {
        const currentLocation = window.location.pathname;
        const { extraContainerStyles } = this.props;
        const navDropItems = [
            <NavLink className={css(styles.PickerNavText)} to="/myshows">
                MyShows
            </NavLink>,
            <NavLink className={css(styles.PickerNavText)} to="/guide">
                Guide
            </NavLink>,
            <NavLink className={css(styles.PickerNavText)} onClick={() => this.toogleUserDropdown()} to={currentLocation}>
                <img className={css(styles.PickerNavLogo)} src={require('../../assets/user.png')} />
            </NavLink>,
        ];
        const settingsDropItems = [
            <NavLink className={css(styles.PickerNavText)} onClick={() => this._sessionCleanUp()} to="/">
                Logout
            </NavLink>,
        ];
        return (
            <nav className={css(styles.Nav, extraContainerStyles)}>
                <div className={css(styles.emptyDiv1)}></div>
                <div className={css(styles.LogoAndLinkContainer)}>
                    <div className={css(styles.LogoContainer)}>
                        {branding_url ? (
                            <img className={css(styles.Logo)} src={branding_url} alt="Logo" />
                        ) : (
                            <img className={css(styles.Logo)} src={transEpic} alt="Logo" />
                        )}
                    </div>
                    <div className={css(styles.NavLinksContainer)}>
                        <div className={css(styles.NavLinks)}>
                            <img
                                onClick={() => this.toogleUserWithNav()}
                                className={css(styles.Arrow)}
                                src={
                                    this.state.isNavDropOpen
                                        ? require('../../assets/SC_SVGs/ic_small_arrow_up.svg')
                                        : require('../../assets/SC_SVGs/ic_small_arrow_down.svg')
                                }
                            />
                            {this.state.isNavDropOpen && (
                                <div className={css(styles.DropContainer)}>
                                    {navDropItems.map((cur, i, arr) => {
                                        return (
                                            <div key={i}>
                                                <div className={css(styles.PickerItem)}>{cur}</div>
                                                {i !== arr.length - 1 && <div className={css(styles.PickerBottomDiv)}></div>}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {!this.props.lightweightVersion && (
                                <NavLink className={css(styles.NavItem)} activeClassName={css(styles.selectedNavItem)} to="/myshows">
                                    Recordings
                                </NavLink>
                            )}
                            <NavLink className={css(styles.NavItem)} activeClassName={css(styles.selectedNavItem)} to="/guide">
                                Guide
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className={css(styles.userContainer)}>
                    <img
                        className={css(styles.NavItemLogo)}
                        onClick={() => this.toogleUserDropdown()}
                        onBlur={(e) => this.hideUserDropAfterClick(e)}
                        src={require('../../assets/user.png')}
                        tabIndex="-1"
                    />
                    {this.state.isUserDropOpen && (
                        <div className={css(styles.ContainerForUserContainer)}>
                            <div className={css(styles.UserDropContainer)}>
                                {settingsDropItems.map((cur, i, arr) => {
                                    return (
                                        <div key={i}>
                                            <div className={css(styles.PickerItem)}>{cur}</div>
                                            {i !== arr.length - 1 && <div className={css(styles.PickerBottomDiv)}></div>}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        );
    }
}

const styles = StyleSheet.create({
    Nav: {
        backgroundColor: 'rgba(5,5,5,1)',
        height: '81px',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        // flexDirection: 'row',
        borderStyle: 'none',
        position: 'sticky',
        top: 0,
        zIndex: '100',
        background: 'linear-gradient(#050505, rgba(5,5,5,.9),rgba(5,5,5,.8),rgba(5,5,5,.7))',
    },
    emptyDiv1: {
        marginLeft: '70px',
        // marginRight: 'auto',
        visibility: 'hidden',
        '@media screen and (max-width: 800px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
        },
    },
    LogoAndLinkContainer: {
        gridColumnStart: '1',
        display: 'flex',
        marginLeft: '2em',
    },
    LogoContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    Logo: {
        width: '8.5em',
    },
    NavLinksContainer: {
        display: 'flex',
        marginLeft: '1em',
        alignItems: 'center',
        '@media screen and (max-width: 600px)': {
            top: '25px',
            left: '1em',
        },
    },
    NavLinks: {
        // margin: 'auto',

        // display: 'flex',
        // justifyContent: 'flex-start',
        // alignItems: 'center',
        '@media screen and (max-width: 600px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            margin: 'unset',
            marginTop: 'auto !important',
            marginBottom: '10px',
            marginLeft: '0px',
            marginRight: '0px',
            alignSelf: 'flex-end',
        },
    },
    NavItem: {
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        fontSize: '1.4em',
        color: 'white',
        fontFamily: 'Roboto',
        ':focus': {
            fontWeight: 'bold',
        },
        '@media screen and (max-width: 600px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
            flexDirection: 'column',
        },
    },
    NavItemLogo: {
        marginLeft: '50px',
        width: '1.4em',
        cursor: 'pointer',
        paddingTop: '7px',
        '@media screen and (max-width: 600px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
            flexDirection: 'column',
        },
        ':focus': {
            outline: 'none',
        },
    },
    selectedNavItem: {
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        fontSize: '1.9em',
        color: 'white',
        margin: '30px',
        paddingTop: '7px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        '@media screen and (max-width: 600px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
        },
    },
    DropContainer: {
        borderRadius: '4px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '120px',
        position: 'absolute',
        top: '63px',
        left: '200px',
        backgroundColor: '#202020',
        display: 'flex',
        zIndex: 11,
        '@media screen and (min-width: 601px)': {
            //if screen width is under 700px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
        },
    },
    ContainerForUserContainer: {
        gridColumnStart: '2',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    UserDropContainer: {
        borderRadius: '4px',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '200px',
        position: 'absolute',
        top: '60px',
        right: '25px',
        backgroundColor: '#202020',
        display: 'flex',
        zIndex: 11,
        '@media screen and (max-width: 600px)': {
            top: '200px',
            left: '200px',
            width: '120px',
        },
        '@media screen and (min-width: 601px)': {
            top: '60px',
            right: '25px',
            width: '120px',
        },
    },
    PickerItem: {
        margin: '6px',
        padding: '6px',
        whiteSpace: 'nowrap',
    },
    PickerNavText: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '1em',
    },
    PickerNavLogo: {
        color: 'white',
        width: '1em',
    },
    PickerBottomDiv: {
        height: '.1em',
        backgroundColor: '#a2a2a2',
        width: '100%',
    },
    Arrow: {
        width: '1.5em',
        color: 'white',
        ':hover': {
            cursor: 'pointer',
            pointer: 'arrow',
        },
        '@media screen and (min-width: 600px)': {
            //if screen width is under 600px, fontsize(our base scaling unit for all em's in guide/grid) shrinks
            display: 'none',
        },
    },
    userContainer: {
        gridColumnStart: '2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '2em',
    },
});
